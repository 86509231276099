import { ViewLead } from './view-lead';

export class LeadStatusCategory {
	public id: number;
	public name: string;
	public leadTypeId: number;
	public sortOrder: number;
	public isActive: boolean;
	public requiresPrimaryContact: boolean;
	public requiresFutureDemoScheduled: boolean;
	public requiresLeadDetails: boolean;
	public canAddTo: boolean;

	public leads: ViewLead[];
}
