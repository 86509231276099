

import { NumberUtils } from '@pk/powerkioskutils';

import * as moment from 'moment-timezone';

import { Commission } from './commission';
import { PayableIssue } from './payable-issue';

export class CommissionPayable {

	public id: number;
	public agentId: string;
	public contractId: string;
	public contractLocationId: string;
	public commissionId: string;
	public date: Date;
	public releaseDate: Date;
	public amount: number;
	public usage: number;
	public isProcessed: boolean;
	public addDate: string;
	public margin: number;
	public rate: number;
	public isManualEdit: boolean;
	public description: string;
	public cycleStartDate: Date;
	public cycleEndDate: Date;
	public commissionPayableStatusTypeId: number;
	public commissionPayableIssueTypeId: number;
	public commissionPayableStatusTypeName: string;

	public payableIssues: PayableIssue[];

	public commission: Commission;

	// internal use
	public agentName: string;
	public isPaid: boolean;
	public warnings: string;

	constructor(commissionPayable: CommissionPayable) {
		Object.assign(this, commissionPayable);

		this.date = this.date && !moment.isDate(this.date) ? new Date(moment(this.date).format('MMM DD, YYYY 00:00:00 a')) : this.date;
		this.releaseDate = this.releaseDate && !moment.isDate(this.releaseDate)
			? new Date(moment(this.releaseDate).format('MMM DD, YYYY 00:00:00 a'))
			: this.releaseDate;
		this.commission = this.commission ? new Commission(this.commission) : this.commission;
	}

	public getDisplayUsage(unit?: string): string {
		const usage = NumberUtils.amountToUnits(this.usage, unit);

		return Math.round(usage).toString();
	}

	public getDisplayMargin(unit?: string): string {
		const margin = NumberUtils.rateToUnits(this.margin * this.rate, unit);

		return margin.toString();
	}
}
