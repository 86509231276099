import * as moment from 'moment-timezone';

export class ReportContractStatus {

	public contractId: string;
	public contractNum: string;
	public status: number;
	public displayStatus: string;
	public contractDate: Date;
	public effectiveDate: Date;
	public expirationDate: Date;
	public addDate: Date;
	public term: number;
	public margin: number;
	public units: string;
	public annualUsage: number | string;
	public rate: number;
	public rejectReason: string;
	public externalId: string;
	public ipAddress: string;
	public signIPAddress: string;
	public agentIPAddress: string;
	public customerId: string;
	public dbaName: string;
	public phone: string;
	public email: string;
	public customerAddress1: string;
	public customerAddress2: string;
	public customerCity: string;
	public customerZipCode: string;
	public customerStateShort: string;
	public stateId: string;
	public stateShort: string;
	public agentId: string;
	public agentName: string;
	public serviceTypeId: string;
	public serviceTypeName: string;
	public contractLocationId: string;
	public utilityAccountNum: string;
	public supplierId: string;
	public supplierName: string;
	public promoCode: string;
	public utilityName: string;
	public hasHappyCustomer: boolean;
	public hasLVM: boolean;
	public hasIssueFound: boolean;
	public rejectReasonCategoryName: string;
	public commissionSplitBufferMargin: number;
	public bufferMargin: number;
	public isResidential: boolean;
	public commissionPayPlanName: string;

	constructor(reportContractStatus: ReportContractStatus) {
		Object.assign(this, reportContractStatus);

		this.effectiveDate = this.effectiveDate
			? new Date(moment(this.effectiveDate).format('MMM DD, YYYY hh:mm:ss a')) : this.effectiveDate;
		this.contractDate = this.contractDate
			? new Date(moment(this.contractDate).format('MMM DD, YYYY hh:mm:ss a')) : this.contractDate;
		this.expirationDate = this.expirationDate
			? new Date(moment(this.expirationDate).format('MMM DD, YYYY hh:mm:ss a')) : this.expirationDate;
		this.addDate = this.addDate ? new Date(moment(this.addDate).format('MMM DD, YYYY hh:mm:ss a')) : this.addDate;
		this.annualUsage = this.isResidential ? 'Residential' : this.annualUsage;
	}

	get fullAddressLine(): string {
		return `${this.customerAddress1} ${this.customerAddress2 ? this.customerAddress2 + ' ' : ''}`
			+ `${this.customerCity}, ${this.customerStateShort} ${this.customerZipCode}`;
	}

	get signatureDate(): Date {
		return [0, 2].includes(this.status)
			? this.contractDate
			: null;
	}

	public getTotalMargin(): number {
		return Math.round((this.margin + this.commissionSplitBufferMargin) * 1e12) / 1e12;
	}
}
