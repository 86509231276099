import { NumberUtils } from '@pk/powerkioskutils';

import * as moment from 'moment-timezone';

import { Agent } from './agent';
import { CommissionType } from './commission-type';
import { Contract } from './contract';
import { ContractLocation } from './contract-location';
import { MonthlyUsage } from './monthly-usage';
import { PayableIssue } from './payable-issue';
import { PendingCommission } from './pending-commission';

export class Commission {

	public commissionId: string;
	public paidDate: Date;
	public agentId: string;
	public utilityAccountNum: string;
	public suppliername: string;
	public cycleStartDate: Date;
	public cycleEndDate: Date;
	public dbaName: string;
	public stateLong: string;
	public term: string;
	public name: string;
	public usage: number;
	public units: string;
	public margin: number;
	public rate: number;
	public commissionDate: Date;
	public amount: number;
	public reverseCommissionId: string;
	public description: string;
	public contractId: string;
	public contractLocationId: string;
	public commissionTypeId: number;
	public queueStatus: string;
	public commissionPayableIssueTypeId: number;
	public commissionPayableStatusTypeId: number;
	public commissionPayableStatusTypeName: string;

	public payableIssues: PayableIssue[];

	private usageThreshold = 0.9;

	public agent: Agent;
	public type: CommissionType;
	public contract: Contract;
	public contractLocation: ContractLocation;
	public monthlyUsage: MonthlyUsage;
	public pendingCommission: PendingCommission;

	// internal
	public removing = false;
	public agentName: string;
	public warnings: string;

	constructor(commission: Commission) {
		Object.assign(this, commission);

		this.paidDate = this.paidDate && !moment.isDate(this.paidDate)
			? new Date(moment(this.paidDate).format('MMM DD, YYYY 00:00:00 a')) : this.paidDate;
		this.commissionDate = this.commissionDate && !moment.isDate(this.commissionDate)
			? new Date(moment(this.commissionDate).format('MMM DD, YYYY hh:mm:ss a')) : this.commissionDate;
		this.agent = this.agent ? new Agent(this.agent) : this.agent;
		this.type = this.type ? new CommissionType(this.type) : this.type;
		this.contract = this.contract ? new Contract(this.contract) : this.contract;
		this.contractLocation = this.contractLocation ? new ContractLocation(this.contractLocation) : this.contractLocation;
		this.cycleStartDate = this.cycleStartDate && !moment.isDate(this.cycleStartDate)
			? new Date(moment(this.cycleStartDate).format('MMM DD, YYYY hh:mm:ss a')) : this.cycleStartDate;
		this.cycleEndDate = this.cycleEndDate && !moment.isDate(this.cycleEndDate)
			? new Date(moment(this.cycleEndDate).format('MMM DD, YYYY hh:mm:ss a')) : this.cycleEndDate;
		this.pendingCommission = this.pendingCommission ? new PendingCommission(this.pendingCommission) : this.pendingCommission;
	}

	public getDisplayUsage(unit?: string): string {
		const usage = NumberUtils.amountToUnits(this.usage, unit);

		return Math.round(usage).toString();
	}

	public getDisplayMargin(unit?: string): string {
		const margin = NumberUtils.rateToUnits(this.margin, unit);

		return margin.toString();
	}

	public getBackground(annualUsage: number): string {
		let background = '';

		if (!this.paidDate) {
			background = '#E6FFCC';
		}

		if (
			!isNaN(this.usage) &&
			!isNaN(annualUsage) &&
			(this.usage === 0 ||
				annualUsage === 0 ||
				this.withinUsageThreshold(annualUsage))
		) {
			background = '#F2DEDE';
		}

		return background;
	}

	private withinUsageThreshold(annualUsage: number): boolean {
		const usageDividend: number = Math.min(this.usage, annualUsage);
		const usageDivisor: number = Math.max(this.usage, annualUsage);

		return usageDividend / usageDivisor <= this.usageThreshold;
	}
}
