export class LeadContact {
	public id: number;
	public leadId: number;
	public fName: string;
	public lName: string;
	public mobile: string;
	public mobileCountryId: number;
	public phone: string;
	public phoneCountryId: number;
	public phoneExt: string;
	public email: string;
	public isPrimary: boolean;
	public jobTitle: string;

	constructor(leadContact?: Partial<LeadContact>) {
		Object.assign(this, leadContact);
	}

	get hasAtLeastOneMainDetail(): boolean {
		return [this.fName, this.lName, this.mobile, this.email].some(Boolean);
	}

	get hasPrimaryLeadContactBasic(): boolean {
		return !this.isPrimary || ([this.fName, this.lName].every(Boolean) && [this.mobile, this.email].some(Boolean));
	}

	get hasPrimaryLeadContactAllExcludeEmail(): boolean {
		return !this.isPrimary || [this.fName, this.lName, this.mobile].every(Boolean);
	}

	get hasPrimaryLeadContactAll(): boolean {
		return !this.isPrimary || [this.fName, this.lName, this.mobile, this.email].every(Boolean);
	}
}
