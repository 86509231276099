import { DatePipe } from '@angular/common';
import { Component, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StringUtils } from '@pk/powerkioskutils';

import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

import { Holiday, RfqSession, RfqSessionProduct } from 'src/app/shared/models';
import { AbstractPageForm } from 'src/app/shared/pages/page-form.abstract';
import { AuctionExtendComponent } from '../../../auction/auction-extend/auction-extend.component';
import { AuctionInProgressComponent } from '../../../auction/auction-in-progress/auction-in-progress.component';
import { AuctionSupplierCreateBidsComponent } from '../../../auction/auction-supplier-create-bids/auction-supplier-create-bids.component';
import { AuctionSupplierNoteEditComponent } from '../../../auction/auction-supplier-note-edit/auction-supplier-note-edit.component';
import { GraphqlService } from '../../../graphql/graphql.service';
import { BlockType, Product, Supplier } from '../../../shared/models';

@Component({
	selector: 'pk-broker-contract-auction-in-progress',
	templateUrl: './contract-auction-in-progress.component.html',
	styleUrls: ['./contract-auction-in-progress.component.scss'],
})
export class ContractAuctionInProgressModalComponent extends AbstractPageForm implements OnDestroy {

	@ViewChild(AuctionInProgressComponent, { static: false }) auction: AuctionInProgressComponent;
	@ViewChild(AuctionSupplierCreateBidsComponent, { static: false }) auctionSupplierCreateBids: AuctionSupplierCreateBidsComponent;
	@ViewChild(AuctionExtendComponent, { static: false }) auctionExtend: AuctionExtendComponent;
	@ViewChild(AuctionSupplierNoteEditComponent, { static: false }) auctionSupplierNoteEdit: AuctionSupplierNoteEditComponent;

	public onSuccess = new Subject<RfqSession>();
	public onExtend = new Subject<RfqSession>();

	public rfqSessionId: string;
	public contractId: string;
	public serviceTypeId: string;
	public stateId: string;
	public rfqSession: RfqSession;
	public rfqSessionProducts: RfqSessionProduct[] = [];
	public products: Product[];
	public blockTypes: BlockType[];
	public suppliers: Supplier[];
	public otherSuppliers: Supplier[];
	public holidays: Holiday[];

	public bidsSupplierId: string;
	public auctionView:
		'auction' | 'help' | 'requirement' | 'supplierCreateBids' | 'supplierBids' | 'supplierNoteEdit' | 'extend' = 'auction';

	public noteKey: string;
	public noteKeyDisplay: string;

	private interval: number;
	private route: ActivatedRoute;

	constructor(public activeModal: BsModalRef,
		private datePipe: DatePipe,
		private router: Router,
		private graphqlService: GraphqlService) { super(); }

	public init(contractId: string, serviceTypeId: string, stateId: string, rfqSessionId: string, route?: ActivatedRoute): void {
		this.rfqSessionId = rfqSessionId;
		this.serviceTypeId = serviceTypeId;
		this.stateId = stateId;
		this.contractId = contractId;
		this.route = route;

		this.getAuctionInProgressData(true, false);

		this.interval = window.setInterval(() => {
			if (this.auctionView === 'auction') {
				this.refreshBids();
			}
		}, 30000);
	}

	get agentIsOnHold(): boolean {
		return this.loggedInUser.agent?.isOnHold;
	}

	private refreshBids(): void {
		this.getRefreshAuctionInProgressData();
	}

	private async getRefreshAuctionInProgressData(): Promise<void> {
		try {
			const contractResult = await this.graphqlService.getRefreshAuctionInProgressModalData(this.rfqSessionId);
			const refreshedRfqSession = new RfqSession(contractResult.data.rfqSession);
			this.rfqSession.suppliers = refreshedRfqSession.suppliers;
			this.rfqSession.bids = refreshedRfqSession.bids;
			this.rfqSession.bestBids = refreshedRfqSession.bestBids;
			this.rfqSession.products = refreshedRfqSession.products;

			await this.setupProductsAndSuppliers();
		} catch (e) {
			this.warnings = [`There was a problem loading the auction. We have been `
				+ `notified and are working to fix the issue. Please check back again in 30 minutes.`];
		}
	}

	private async getAuctionInProgressData(loadingInitialVal: boolean, loadingFinalVal: boolean): Promise<void> {
		this.loading = loadingInitialVal;
		this.warnings = [];
		try {
			if (this.contractId && !this.rfqSessionId) {
				const rfqSessionResult = await this.graphqlService.getRfqSessionContract(this.contractId);
				this.rfqSessionId = rfqSessionResult.data.contract.rfqSessionId;
			}

			const dateGTE = this.datePipe.transform(new Date(), 'yyyy-MM-ddT00:00:00');

			const contractResult = await this.graphqlService.getAuctionInProgressModalData(
				this.rfqSessionId,
				this.serviceTypeId,
				this.stateId,
				dateGTE,
			);
			this.rfqSession = new RfqSession(contractResult.data.rfqSession);
			this.products = contractResult.data.products.message.map(p => new Product(p));
			this.blockTypes = contractResult.data.blockTypes.message.map(b => new BlockType(b));
			this.holidays = contractResult.data.holidays.message.map(h => new Holiday(h));

			await this.setupProductsAndSuppliers();
			this.loading = loadingFinalVal;

			if (this.route) {
				const showExtend = StringUtils.toBoolean(this.route.snapshot.queryParamMap.get('showExtend'));
				if (showExtend) {
					this.onShowAuctionExtendClicked();
				}
			}
		} catch (e) {
			this.warnings = [`There was a problem loading the pricing session. We have been `
				+ `notified and are working to fix the issue. Please check back again in 30 minutes.`];
		}
	}

	private async setupProductsAndSuppliers(): Promise<void> {
		const sortedProducts = _.orderBy(this.rfqSession.products,
			[obj => obj.product.sortOrder, obj => obj.term, obj => obj.greenPercentage], ['asc', 'asc', 'asc']);
		for (let i = 0; i < sortedProducts.length; i++) {
			// keep the row open if it was already open
			let shown = false;
			if (this.rfqSessionProducts[i] && this.rfqSessionProducts[i]['shown']) {
				shown = true;
			}
			// assign to the product individually, keep overall array ref unchanged
			this.rfqSessionProducts[i] = sortedProducts[i];
			this.rfqSessionProducts[i]['shown'] = shown;
		}

		this.rfqSessionProducts = _.orderBy(this.rfqSessionProducts,
			[obj => obj.product.sortOrder, obj => obj.term, obj => obj.greenPercentage], ['asc', 'asc', 'asc']);
		this.rfqSession.products = this.rfqSessionProducts;
		this.rfqSession.suppliers = _.orderBy(this.rfqSession.suppliers, obj => obj.supplier.name);
		const availableSupplierResult = await this.graphqlService.getAvailableSuppliers(
			this.serviceTypeId,
			this.stateId,
			this.rfqSession.contract.getUtilityId(),
			this.rfqSession.contract.annualUsage,
			null,
			moment(this.rfqSession.contract.effectiveDate).format('YYYY-MM-DD'),
		);
		this.suppliers = _.orderBy(availableSupplierResult.data.availableSuppliers.message.map(s => new Supplier(s)), s => s.name);
		const supplierIds = this.suppliers.map(s => s.supplierId);
		const otherSuppliers = availableSupplierResult.data.otherSuppliers.message
			.map(s => new Supplier(s))
			.filter(s => !supplierIds.includes(s.supplierId));
		this.otherSuppliers = _.orderBy(otherSuppliers, s => s.name);
		this.rfqSession.suppliers.forEach(s => {
			s.setDocumentRequestNotes(this.rfqSession.contract.attachments);
		});
	}

	public onBidsSubmitted(): void {
		this.refreshBids();
	}

	public async updateRfqSession(): Promise<void> {
		await this.auction.submit();
	}

	public submitBids(): void {
		this.auctionSupplierCreateBids.submit();
	}

	public extendSession(): void {
		this.auctionExtend.submit();
	}

	public submitSupplierNote(): void {
		this.auctionSupplierNoteEdit.submit();
	}

	public onAuctionCreateCompleted(rfqSession: RfqSession): void {
		window.clearInterval(this.interval);
		this.init(this.contractId, this.serviceTypeId, this.stateId, this.rfqSessionId, this.route);
		this.onSuccess.next(rfqSession);
	}

	public onAuctionUpdateCompleted(rfqSession: RfqSession): void {
		window.clearInterval(this.interval);
		this.init(this.contractId, this.serviceTypeId, this.stateId, this.rfqSessionId, this.route);
		this.onSuccess.next(rfqSession);
	}

	public onAuctionExtendCompleted(rfqSession: RfqSession): void {
		this.onExtend.next(rfqSession);
	}

	public onShowAuctionSupplierBidsClicked(supplierId: string): void {
		this.bidsSupplierId = supplierId;
		this.auctionView = 'supplierBids';
	}

	public onShowAuctionSupplierCreateBidsClicked(supplierId: string): void {
		this.bidsSupplierId = supplierId;
		this.auctionView = 'supplierCreateBids';
	}

	public onShowAuctionSupplierNoteEditClicked({ supplierId, noteKey, noteKeyDisplay }:
		{ supplierId: string; noteKey: string; noteKeyDisplay: string }): void {
		this.bidsSupplierId = supplierId;
		this.auctionView = 'supplierNoteEdit';
		this.noteKey = noteKey;
		this.noteKeyDisplay = noteKeyDisplay;
	}

	public onShowAuctionExtendClicked(): void {
		this.auctionView = 'extend';

		if (this.route) {
			this.router.navigate(['.'], {
				relativeTo: this.route, queryParams: {
					showAuction: true, showExtend: true, auctionContractId: this.contractId,
				},
			});
		}
	}

	public backToSession(): void {
		this.auctionView = 'auction';

		if (this.route) {
			this.router.navigate(['.'], {
				relativeTo: this.route, queryParams: {
					showAuction: true, auctionContractId: this.contractId,
				},
			});
		}
	}

	public backToSessionAndIgnoreDocuments(): void {
		this.auctionView = 'auction';

		this.auction.ignoreRequiredDocuments = this.loggedInUser.isAdmin ? true : false;
	}

	public onDeletedBid(): void {
		if (!this.loading) {
			this.refreshBids();
		}
	}

	public cancel(): void {
		if (!this.auction || !this.auction.submitDisabled) {
			this.activeModal.hide();
		}
	}

	// eslint-disable-next-line max-len
	public onUpdateAuctionView(auctionView: 'auction' | 'help' | 'requirement' | 'supplierCreateBids' | 'supplierBids' | 'supplierNoteEdit' | 'extend'): void {
		this.auctionView = auctionView;
	}

	ngOnDestroy() {
		window.clearInterval(this.interval);
	}
}
