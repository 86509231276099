import * as moment from 'moment-timezone';

import { LeadCalendarMeeting } from './lead-calendar-meeting';
import { LeadEmailCampaign } from './lead-email-campaign';

export class ViewLeadAction {
	public id: string;
	public message: string;
	public details: string;
	public date: Date;
	public noteTypeId: string;
	public noteTypeName: string;
	public noteTypeDescription: string;
	public noteTypeImageIconPrefix: string;
	public noteTypeImageIcon: string;
	public addDate: Date;

	public leadCalendarMeeting: LeadCalendarMeeting;
	public leadEmailCampaign: LeadEmailCampaign;

	constructor(viewLeadAction?: Partial<ViewLeadAction>) {
		Object.assign(this, viewLeadAction);

		this.date = this.date
			? new Date(moment(this.date).format('MMM DD, YYYY hh:mm:ss a')) : this.date;
		this.addDate = this.addDate
			? new Date(moment(this.addDate).format('MMM DD, YYYY 00:00:00 a')) : this.addDate;

		this.leadEmailCampaign = this.leadEmailCampaign ? new LeadEmailCampaign(this.leadEmailCampaign) : this.leadEmailCampaign;
	}

	get calendarDate() {
		return moment(this.date).format('YYYY-MM-DD');
	}

	get calendarTime() {
		return moment(this.date).format('HH:mm');
	}
}
