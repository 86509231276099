export class TinyUri {

	public id: string;
	public slug: string;
	public longUri: string;

	constructor(tinyUri?: TinyUri) {
		Object.assign(this, tinyUri);
	}
}
