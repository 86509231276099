export class LeadContact {
	public id: number;
	public leadId: number;
	public fName: string;
	public lName: string;
	public mobile: string;
	public mobileCountryId: number;
	public phone: string;
	public phoneCountryId: number;
	public phoneExt: string;
	public email: string;
	public isPrimary: boolean;

	constructor(leadContact?: Partial<LeadContact>) {
		Object.assign(this, leadContact);
	}

	get hasContactDetail(): boolean {
		return !!this.email || !!this.mobile || !!this.fName || !!this.lName;
	}
}
