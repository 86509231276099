import { LeadStatusReasonType } from './lead-status-reason-type';

export class LeadStatus {
	public id: number;
	public name: string;
	public leadStatusCategoryId: number;
	public cssClass: string;
	public sortOrder: number;

	public leadStatusReasonTypes: LeadStatusReasonType[];

	constructor(leadStatus?: LeadStatus) {
		Object.assign(this, leadStatus);
	}

	get itemCssClass(): string {
		return this.cssClass + '-item';
	}

	get statusCssClass(): string {
		return this.cssClass + '-status';
	}
}
