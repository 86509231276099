import { ContractMarketPerformance } from './contract-market-performance';
import { ContractPrice } from './contract-price';

export class ContractPerformance {

	public contractRate: number;
	public utilityRates: { rate: number; date: Date }[];
	public utilitySavings: number;
	public marketSavings: number;
	public previousContractSavings: number;
	public marketPerformance: ContractMarketPerformance[];
	public contractPrices: ContractPrice[];

	constructor(contractPerformance?: ContractPerformance) {
		Object.assign(this, contractPerformance);
	}
}
