import { NumberUtils } from '@pk/powerkioskutils';

import * as moment from 'moment-timezone';

export class CommissionPayablesSchedule {

	public agentId: string;
	public contractId: string;
	public contractLocationId: string;
	public date: Date;
	public usage: number;
	public amount: number;
	public margin: number;
	public rate: number;
	public isPaid: boolean;
	public isForecast: boolean;
	public isAdjustment: boolean;
	public isSameMonthUpfront: boolean;
	public totalUsage: number;
	public totalAmount: number;
	public totalPayout: number;
	public dateOffset: number;
	public purchasingLayerHistoryId: number;


	// internal use
	public agentName: string;

	constructor(commissionPayablesSchedule: CommissionPayablesSchedule) {
		Object.assign(this, commissionPayablesSchedule);

		this.date = this.date && !moment.isDate(this.date) ? new Date(moment(this.date).format('MMM DD, YYYY 00:00:00 a')) : this.date;
	}

	public getDisplayUsage(unit?: string): string {
		const usage = NumberUtils.amountToUnits(this.usage, unit);

		return Math.round(usage).toString();
	}

	public getDisplayMargin(unit?: string): string {
		const margin = NumberUtils.rateToUnits(this.margin * this.rate, unit);

		return margin.toString();
	}
}
