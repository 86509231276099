

import { CONSTANTS } from '@pk/powerkioskutils';

import { Agent } from './agent';
import { Attachment } from './attachment';
import { CompanyCalendlySetting } from './company-calendly-setting';
import { Country } from './country';
import { EmailAddress } from './email-address';
import { LendingModuleSetting } from './lending-module-setting';
import { LicensePlan } from './license-plan';
import { Logo } from './logo';

export class Company {

	public companyId: string;
	public modifiedDate: string;
	public name: string;
	public contactFname: string;
	public contactMiddle: string;
	public contactLname: string;
	public address1: string;
	public address2: string;
	public city: string;
	public zipCode: string;
	public stateId: string;
	public phone: string;
	public phone2: string;
	public fax: string;
	public mobile: string;
	public email: string;
	public supportEmail: string;
	public isActive: boolean;
	public addDate: string;
	public termsOfServiceAttachmentId: string;
	public coverPageAttachmentId: string;
	public defaultNavbarLogoId: string;
	public defaultFooterLogoId: string;
	public defaultEmailLogoId: string;
	public countryId: number;
	public phoneCountryId: number;
	public phone2CountryId: number;
	public mobileCountryId: number;
	public faxCountryId: number;
	public phoneExt: string;
	public phone2Ext: string;
	public faxExt: string;
	public commissionRate: number;
	public commissionRate2: number;
	public commissionRate3: number;
	public discountFactor: number;
	public isDecoupled: boolean;
	public domainAlias: string;
	public supplierDomainAlias: string;
	public isSaas: boolean;
	public automatedUserId: string;
	public defaultAccountManagerId: string;
	public defaultAgentComplianceDropPredictionThreshold: number;
	public defaultQuickPayRate: number;

	// alias for displayLogo
	public navbarLogo: Logo;
	public footerLogo: Logo;

	public manuals: Attachment[][];
	public gasMaps: Attachment[][];
	public elecMaps: Attachment[][];
	public images: Attachment[][];
	public attachments: Attachment[];
	public defaultNavbarLogo: Logo;
	public defaultFooterLogo: Logo;
	public defaultEmailLogo: Logo;
	public houseAccount: Agent;
	public country: Country;
	public phoneCountry: Country;
	public phone2Country: Country;
	public mobileCountry: Country;
	public faxCountry: Country;
	public emailAddresses: EmailAddress[];
	public calendlySettings: CompanyCalendlySetting[];
	public lendingModuleSetting: LendingModuleSetting;
	public licensePlans: LicensePlan[];
	public termsOfServiceAttachment: Attachment;

	constructor(company?: Company) {
		Object.assign(this, company);

		this.houseAccount = this.houseAccount ? new Agent(this.houseAccount) : this.houseAccount;
	}

	get hasCommission(): boolean {
		return !this.isSaas || this.licensePlans?.some(l => l.id === CONSTANTS.licensePlans.commission[0]);
	}
}
