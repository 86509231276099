import { DecimalPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { RfqSessionBidUtils, StringUtils } from '@pk/powerkioskutils';

import { alert, custom } from 'devextreme/ui/dialog';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { ToastrService } from 'ngx-toastr';

import { bidAmountValidator } from 'src/app/shared/validators/bid-amount-validator';
import { supplierProductsValidator } from 'src/app/shared/validators/supplier-products.validator';
import { GraphqlService } from '../../graphql/graphql.service';
import { HelperService } from '../../shared/helper.service';
import { BlockType, Product, RfqSession, RfqSessionBid, RfqSessionProduct, RfqSessionStat, RfqSessionSupplier } from '../../shared/models';
import { AbstractPageForm } from '../../shared/pages/page-form.abstract';

@Component({
	selector: 'pk-broker-auction-supplier-create-bids',
	templateUrl: './auction-supplier-create-bids.component.html',
	styleUrls: ['./auction-supplier-create-bids.component.scss'],
})
export class AuctionSupplierCreateBidsComponent extends AbstractPageForm implements OnDestroy {

	@Input() rfqSession: RfqSession;
	@Input() supplierId: string;
	@Input() products: Product[];
	@Input() blockTypes: BlockType[];
	@Input() isSupplierUser: boolean;

	@Output() bidsSubmitted: EventEmitter<boolean> = new EventEmitter<boolean>();

	private isRefreshing = false;
	public terms: number[];
	public lags: number[];
	public bidMeasureUnits: { text: string; multiplier: number }[];
	public rfqSessionSupplier: RfqSessionSupplier;
	public showIsGRTIncluded: boolean;

	public greenPercentages: number[];
	public initialFixedUsages: number[];
	public bandwidthPercentages: number[];
	public billingMethods: { value: string; text: string }[];

	public interval: NodeJS.Timer;

	constructor(
		private toastrService: ToastrService,
		private decimalPipe: DecimalPipe,
		private graphqlService: GraphqlService,
		private helperService: HelperService,
		private fb: FormBuilder,
	) {
		super();
		this.submitText = 'Submit Bids';
		this.submitAudit = 'auction-supplier-create-bids:submitBids';
	}

	public bandwidthPercentageTextMap = (option: number) => `${option}%`;
	public yesNoTextMap = (option: boolean) => option ? 'Yes' : 'No';
	public termTextMap = (option: number) => `${option} month${option === 1 ? '' : 's'}`;
	public greenPercentageTextMap = (option: number) => `${option}%`;
	public lagTextMap = (option: number) => `+${option} month${option === 1 ? '' : 's'}`;
	public initialFixedUsageTextMap = (option: number) => `${option}%`;

	get isAuctionComplete() {
		const isLate = moment(this.rfqSession.endDate2) < moment();
		return this.rfqSession.contract.status === this.CONSTANTS.statuses.acomp || isLate;
	}

	get maxBids() {
		return this.isAuctionComplete && (!this.loggedInUser || !this.loggedInUser.isAdmin) ? 1 : this.rfqSession.maxBids;
	}

	public async loadPageData(): Promise<void> {
		this.bandwidthPercentages = this.helperService.generateNumberList(100, 0, 5);
		this.billingMethods = [
			{ value: 'Single Bill From Utility', text: 'Single Bill From Utility' },
			{ value: 'Single Bill from Supplier', text: 'Single Bill from Supplier' },
			{ value: 'Two Bills', text: 'Two Bills' },
		];
		this.terms = [...this.helperService.generateNumberList(1, 59), ...this.helperService.generateNumberList(60, 120, 12)];
		this.lags = this.helperService.generateNumberList(0, 60);
		this.greenPercentages = this.helperService.generateNumberList(0, 100, 10);
		this.initialFixedUsages = this.helperService.generateNumberList(0, 100, 5);
		this.bidMeasureUnits = this.CONSTANTS.units[this.rfqSession.contract.serviceTypeId];
		this.showIsGRTIncluded = this.rfqSession.contract.showGRTIncluded();
		this.rfqSessionSupplier = this.rfqSession.suppliers.find(s => s.supplierId === this.supplierId);
		this.rfqSession.products.forEach(p => {
			if (p.bestBids) {
				p.supplierBestBid = _.orderBy(p.bestBids.filter(b => b.rfqSessionSupplierId === this.supplierId), 'rank')[0];
			}
		});
	}

	public getForm() {
		const bestBid = this.rfqSession.products?.[0].supplierBestBid ?? null;
		const units = bestBid?.units ? this.bidMeasureUnits.find(u => u.text === bestBid.units) : this.bidMeasureUnits[0];
		return this.fb.group({
			bandwidthPercentage: [bestBid?.bandwidthPercentage ?? this.bandwidthPercentages[0], [Validators.required]],
			billingMethod: [bestBid?.billingMethod
				? this.billingMethods.find(b => b.value.toLowerCase() === bestBid.billingMethod.toLowerCase()).value
				: this.billingMethods[0].value, [Validators.required]],
			salesTax: [bestBid?.salesTax ?? 0, [Validators.required]],
			isGRTIncluded: this.fb.control(
				bestBid ? StringUtils.toBoolean(bestBid.isGRTIncluded) : null,
				this.showIsGRTIncluded ? [Validators.required] : [],
			),
			units: this.fb.control(units),
			productBids: this.fb.array(this.rfqSession.products?.map(p => this.getProductBidGroup(p, units)) ?? []),
			sweetSpotProducts: this.fb.array([] as ReturnType<this['getSweetSpotProductGroup']>[]),
		}, { validators: supplierProductsValidator });
	}

	private getProductBidGroup(product: RfqSessionProduct, units: this['bidMeasureUnits'][0], bidAmount: number = null) {
		return this.fb.group({
			bidAmount,
			entity: product,
			units: this.fb.control(units),
			serviceTypeId: this.rfqSession.contract.serviceTypeId,
			stateId: this.rfqSession.contract.stateId,
		}, { validators: [bidAmountValidator] });
	}

	private addProductBids(bidAmount: number, product: RfqSessionProduct): void {
		this.form.productBids.control.push(this.getProductBidGroup(product, this.form.units.value, bidAmount));
	}

	private getDefaultProduct(): Product {
		let defaultProduct = this.products.find(p => {
			switch (this.rfqSession.contract.serviceTypeId) {
				case this.ELECTRICITY_ID: {
					return p.name.toLowerCase().includes('fixed');
				}
				case this.NATURAL_GAS_ID: {
					return p.name.toLowerCase().includes('nymex adder');
				}
			}
		});

		if (!defaultProduct) {
			defaultProduct = this.products[0];
		}

		return defaultProduct;
	}

	public getSweetSpotProductGroup(units: this['bidMeasureUnits'][0]) {
		const defaultProductId = this.getDefaultProduct().id;

		return this.fb.group({
			type: [defaultProductId, [Validators.required]],
			term: [this.terms[0], [Validators.required]],
			greenPercentage: [this.greenPercentages[0], [Validators.required]],
			lag: [this.lags[0], [Validators.required]],
			blockType: [this.blockTypes[0].name, [Validators.required]],
			blockSize: ['', [Validators.required]],
			initialFixedUsage: [this.initialFixedUsages[0], [Validators.required]],
			bidAmount: ['', [Validators.required]],
			units: this.fb.control(units),
			serviceTypeId: [this.rfqSession.contract.serviceTypeId],
			stateId: [this.rfqSession.contract.stateId],
			hidden: [false],
		}, { validators: [bidAmountValidator] });
	}

	public addSweetSpotProduct() {
		const group = this.getSweetSpotProductGroup(this.form.units.value);
		this.setSweetSpotProductRowState(group);
		this.form.sweetSpotProducts.control.push(group);
	}

	private setSweetSpotProductRowState(product: this['form']['sweetSpotProducts'][0]['control']): void {
		switch (product.value.type) {
			case this.CONSTANTS.products.block: {
				product.get('lag').enable();
				product.get('blockType').enable();
				product.get('blockSize').enable();

				product.get('initialFixedUsage').setValue(0);
				product.get('initialFixedUsage').disable();
				break;
			}
			case this.CONSTANTS.products.nymexPlus: {
				product.get('initialFixedUsage').enable();

				product.get('lag').setValue(0);
				product.get('lag').disable();
				product.get('blockType').setValue('0');
				product.get('blockType').disable();
				product.get('blockSize').setValue('');
				product.get('blockSize').disable();
				break;
			}
			default: {
				product.get('lag').setValue(0);
				product.get('lag').disable();
				product.get('blockType').setValue('0');
				product.get('blockType').disable();
				product.get('blockSize').setValue('');
				product.get('blockSize').disable();
				product.get('initialFixedUsage').setValue(0);
				product.get('initialFixedUsage').disable();
			}
		}
	}

	public sweetSpotProductChanged(product: this['form']['sweetSpotProducts'][0]): void {
		this.setSweetSpotProductRowState(product.control);
	}

	public async onFormLoaded(): Promise<void> {
		this.form.units.valueChanges.subscribe(units => {
			this.updateBidMeasureUnits(units);
		});

		// get the best bid every 30 seconds
		this.interval = setInterval(() => {
			this.refreshBids();
		}, 30000);

		if (!this.form.productBids.some(p => this.getBidsRemaining(p))) {
			this.submitDisabled = true;
		}
	}

	ngOnDestroy(): void {
		clearInterval(this.interval);
	}

	protected async onFormSubmitted() {
		const symbol = this.rfqSession.contract.state.country.symbol;
		const marginText = this.rfqSession.contract.getTotalMargin(true, true, false, this.rfqSession.contract.units);
		const margin = `${this.decimalPipe.transform(marginText, '1.6-6')} ` +
			`${symbol}/${this.rfqSession.contract.units}`;
		let subTitle = this.rfqSession.contract.stateId === this.CONSTANTS.states.newJersey
			? '<li>Please confirm that you included SUT into your bid(s).</li>' : '';
		subTitle += '<li>Please confirm the margin included in your bid(s).</li>';
		const message = `
		<ul>
			${subTitle}
		</ul>
		<div class="row">
			<div class="col-md-12">
				<div class="form-element-container">
					<label class="title">Margin</label>
					<input type="text" class="form-control" id="margin" value="${margin}" readonly>
				</div>
			</div>
		</div>`;
		const customDialog = custom({
			title: 'Bid Confirmation',
			messageHtml: message,
			buttons: [
				{
					text: this.rfqSession.contract.stateId === this.CONSTANTS.states.newJersey ? 'Yes to all' : 'Yes',
					onClick: () => true,
				},
				{
					text: 'Go Back',
					onClick: () => false,
				},
			],
		});
		const dialogResult = await customDialog.show();
		if (dialogResult) {
			// first create the new sweet spot products if any...
			const newProducts = [];
			const productResults = [];
			for (const sweetSpotProduct of this.form.sweetSpotProducts.filter(s => !s.hidden.value)) {
				const supplierProduct = this.createSupplierProductDTO(sweetSpotProduct);
				newProducts.push(sweetSpotProduct.bidAmount.value);
				productResults.push(await this.graphqlService.createRfqSessionProduct(supplierProduct));
			}
			// add the bidAmounts to the products now, and then submit the bids
			productResults.forEach((productResult, index) => {
				if (productResult.data.createRfqSessionProduct.id) {
					this.addProductBids(newProducts[index], productResult.data.createRfqSessionProduct);
				}
			});

			const productBids: Subset<RfqSessionBid[]> = [];
			// send all bids in one API request
			for (const productBid of this.form.productBids.filter(p => p.bidAmount.value)) {
				productBids.push(this.createProductBidDTO(productBid));
			}
			await this.graphqlService.createRfqSessionBids(productBids);

			if (!this.warnings.length) {
				this.refreshBids(true);
				this.form.sweetSpotProducts.control.clear();
			}
		}
	}

	private createSupplierProductDTO(sweetSpotProduct: this['form']['sweetSpotProducts'][0]) {
		return {
			productId: sweetSpotProduct.type.value,
			term: Number(sweetSpotProduct.term.value) || undefined,
			greenPercentage: Number(sweetSpotProduct.greenPercentage.value) || 0,
			lag: Number(sweetSpotProduct.lag.value) || undefined,
			blockType: sweetSpotProduct.blockType.value || undefined,
			blockSize: sweetSpotProduct.blockSize.value || undefined,
			initialFixedUsage: Number(sweetSpotProduct.initialFixedUsage.value) || undefined,
			rfqSessionId: this.rfqSession.id,
		};
	}

	private createProductBidDTO(productBid: this['form']['productBids'][0]) {
		return {
			supplierId: this.supplierId,
			bandwidthPercentage: Number(this.form.bandwidthPercentage.value),
			salesTax: Number(this.form.salesTax.value),
			billingMethod: this.form.billingMethod.value,
			rfqSessionId: this.rfqSession.id,
			amount: RfqSessionBidUtils.convertBidToInternalUnits(
				Number(productBid.bidAmount.value),
				this.rfqSession.contract.serviceTypeId,
				this.form.units.value.text,
				false,
			),
			rfqSessionProductId: productBid.entity.value.id,
			isGRTIncluded: this.showIsGRTIncluded ? StringUtils.toBoolean(this.form.isGRTIncluded.value) : false,
			units: this.form.units.value.text,
		};
	}

	public getProductBidText(productBid: this['form']['productBids'][0]) {
		const entity = productBid.entity.value;

		let text = `${entity.product.name} - ${entity.term} month${entity.term === 1 ? '' : 's'}`;
		if (entity.blockType && entity.blockType !== '0') { text += ` - ${entity.blockType}`; }
		if (entity.blockSize) { text += ` - ${entity.blockSize}`; }
		if (entity.initialFixedUsage) { text += ` - ${entity.initialFixedUsage}% Initial Fixed Usage`; }
		return text;
	}

	public generateErrorMessage(productId: string): string {
		const minBid = this.getProductMinBidAmount(productId);
		const maxBid = this.getProductMaxBidAmount(productId);

		return `Bid Amount must be between ${minBid} and ${maxBid}. Please enter bids in unit ${this.form.units.value.text}.`;
	}

	public getProductMinBidAmount(productId: string): string {
		return this.helperService.getProductMinBidAmount(productId, this.rfqSession.contract.serviceTypeId, this.form.units.value.text);
	}

	public getProductMaxBidAmount(productId: string): string {
		return this.helperService.getProductMaxBidAmount(
			productId,
			this.rfqSession.contract.serviceTypeId,
			this.rfqSession.contract.stateId,
			this.form.units.value.text,
		);
	}

	private async refreshBids(isSubmitting = false) {
		if (!this.isRefreshing) {
			this.isRefreshing = true;
			try {
				const result = await this.graphqlService.getAuctionInProgressBestBids(this.rfqSession.id);
				const bestBids = result.data.rfqSession.bestBids.map(b => new RfqSessionStat(b));
				for (const p of this.form.productBids) {
					if (result.data.rfqSession.bids?.length) {
						p.entity.value.bids = result.data.rfqSession.bids.filter(b => b.rfqSessionProductId === p.entity.value.id);
					}
					if (bestBids) {
						p.entity.value.supplierBestBid = _.chain(bestBids)
							.filter(b => b.rfqSessionProductId === p.entity.value.id && b.rfqSessionSupplierId === this.supplierId)
							.orderBy(b => b.rank)
							.first()
							.value();
					}
				}
				this.isRefreshing = false;
				if (isSubmitting) {
					this.toastrService.success('Successfully Submitted Bids', 'Pricing Session');
					this.form.productBids.forEach(p => p.bidAmount.setValue(null));
					if (this.isAuctionComplete) {
						alert(`You're late! Try to make the deadline next time!`, 'Pricing Session');
					}
					this.bidsSubmitted.emit(true);
				}
			} catch (e) {
				this.isRefreshing = false;
			}
		}
	}

	public updateBidMeasureUnits(units: { text: string; multiplier: number }): void {
		this.form.productBids.forEach(p => p.units.setValue(units));
		this.form.sweetSpotProducts.filter(s => !s.hidden.value).forEach(s => s.units.setValue(units));
	}

	public getBidsRemaining(product: this['form']['productBids'][0]): number {
		const entity = product.entity.value;

		if (!entity.bids) { return this.maxBids; }
		const bids = entity.bids.filter(b => b.supplierId === this.supplierId
			&& ((this.loggedInUser && this.loggedInUser.isAdmin) || !this.isAuctionComplete
				|| new Date(b.addDate).valueOf() > this.rfqSession.endDate2.valueOf()));
		const bidsRemaining = Math.max(0, this.maxBids - (bids ? bids.length : 0));
		return !entity.supplierBestBid ? this.maxBids : bidsRemaining;
	}
}
