export class ReportCommissionProcessingIssuesRow {
	contractNum: string;
	contractId: string;
	legalEntityName: string;
	agentName: string;
	accountNum: string;
	commissionDate: string;
	effectiveDate: string;
	confirmationDate: string;
	cycleStartDate: string;
	cycleEndDate: string;
	usage: number;
	units: string;
	margin: number;
	payablePlan: string;
	amount: number;
	status: string;
	issueType: string;
	oldIssueType: string;
	oldWarning: string;
	description: string;

	constructor(reportCommissionProcessingIssuesRow: ReportCommissionProcessingIssuesRow) {
		Object.assign(this, reportCommissionProcessingIssuesRow);
	}
}
