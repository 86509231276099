export class LendingModuleSetting {

	public id: number;
	public borrowingRate: number;
	public baseFee: number;
	public minRate: number;
	public maxRate: number;
	public maxUpfrontDropRatio: number;
	public maxUpfrontPayment: number;
	public maxAdvanceMonths: number;
	public defaultBrokerDropRatio: number;
	public newBrokerMaxFlowedTermValue: number;
	public newBrokerMaxTotalTermValue: number;
	public newBrokerMaxActiveMonths: number;
	public maxQuickPayUpfrontPayment: number;

	constructor(lendingModuleSetting: LendingModuleSetting) {
		Object.assign(this, lendingModuleSetting);
	}
}
