import { CONSTANTS } from '@pk/powerkioskutils';

import * as _ from 'lodash';
import * as moment from 'moment-timezone';

import { HelperService } from '../helper.service';
import { AgentAllowedCommissionPayPlanType } from './agent-allowed-commission-pay-plan-type';
import { AgentBiddingStats } from './agent-bidding-stats';
import { AgentDiscountProgram } from './agent-discount-program';
import { AgentEclPackage } from './agent-ecl-package';
import { AgentIsEmployee } from './agent-is-employee';
import { AgentRateMatrix } from './agent-rate-matrix';
import { AgentStripeCustomer } from './agent-stripe-customer';
import { AgentTheme } from './agent-theme';
import { Attachment } from './attachment';
import { BankAccount } from './bank-account';
import { BusinessEntityType } from './business-entity-type';
import { BusinessEntityTypeTaxClassification } from './business-entity-type-tax-classification';
import { CommissionPayPlanType } from './commission-pay-plan-type';
import { CommissionUpfrontRate } from './commission-upfront-rate';
import { CommissionUpfrontRateLevel } from './commission-upfront-rate-level';
import { Company } from './company';
import { Country } from './country';
import { ECommerceWebsite } from './e-commerce-website';
import { EclAnnualUsageBracket } from './ecl-annual-usage-bracket';
import { EclLoadFactorBracket } from './ecl-load-factor-bracket';
import { Education } from './education';
import { EmailPreference } from './email-preference';
import { LicensePlan } from './license-plan';
import { Logo } from './logo';
import { MailServer } from './mail-server';
import { Note } from './note';
import { PromoCode } from './promo-code';
import { State } from './state';
import { StripeCard } from './stripe';
import { Supplier } from './supplier';
import { Task } from './task';
import { User } from './user';
import { UserEmail } from './user-email';
import { ViewAgentCommissionPayPlanOption } from './view-agent-commission-pay-plan-option';

export class Agent {

	public agentId: string;
	public parentId: string;
	public brotherId: string;
	public name: string;
	public address1: string;
	public address2: string;
	public city: string;
	public stateId: string;
	public state: State;
	public zipCode: string;
	public phone: string;
	public mobile: string;
	public phone2: string;
	public phoneExt: string;
	public fax: string;
	public technicalEmail: string;
	public supportEmail: string;
	public addDate: Date;
	public isActive: boolean;
	public isFraudulent: boolean;
	public countryId: number;
	public phoneCountryId: number;
	public phone2CountryId: number;
	public faxCountryId: number;
	public mobileCountryId: number;
	public stateOther: string;
	public useDefaultMailServer: boolean;
	public useReports: boolean;
	public useSupportSystem: boolean;
	public mailServerId: string;
	public ssn: string;
	public legalTaxName: string;
	public businessTaxName: string;
	public commissionRate: number;
	public commissionRate2: number;
	public commissionRate3: number;
	public residentialCommissionRate: number;
	public residentialCommissionRate2: number;
	public residentialCommissionRate3: number;
	public discountFactor: number;
	public customerCompany: string;
	public supplierCompany: string;
	public agentDomainAlias: string;
	public eCommerceDomainName: string;
	public businessEntityTypeId: number;
	public businessEntityTypeTaxClassificationId: number;
	public underwriterId: string;
	public suppliers: Supplier[];
	public commissionCheckMethod: boolean;
	public showTrainingVideos: boolean;
	public showEducation: boolean;
	public isEmployee: boolean;
	public userId: string;
	public promoCodeCount: number;
	public oldPromoCodeCount: number;
	public agentBundleCount: number;
	public oldAgentBundleCount: number;
	public licensePlanId: number;
	public agentOrParentOrBrotherId: string;
	public isOnboarded: boolean;
	public hasParent: boolean;
	public onboardDate: Date;
	public volume: number;
	public id: string;
	public isEnterprise: boolean;
	public hasFailedPayment: boolean;
	public paymentIntentRequiresActionDate: Date;
	public electricityResidentialThankYouLink: string;
	public gasResidentialThankYouLink: string;
	public isInHouseIndirectSale: boolean;
	public commissionAmountYTD: number;
	public futureCommissionAmount: number;
	public totalUpfrontCommissionPaidAmount: number;
	public flowedTermValue: number;
	public showUtilityCheck: boolean;
	public negativeBalance: number;
	public reversibleCommission: number;
	public currMonthEstimatedCommission: number;
	public lastMonthPaidCommission: number;
	public allowAutomaticResidentialConfirmation: boolean;
	public headshotUrl: string;
	public isDefaultInHouseIndirectSale: boolean;
	public isVerified: boolean;
	public residentialContractRatio: number;
	public termsOfServiceAttachmentId: string;
	public coverPageAttachmentId: string;
	public showFinancialProfile: boolean;
	public showLeadLink: boolean;
	public eclPackageDiscountExpirationDate: Date;
	public isOnHold: boolean;
	public idDocumentAttachmentId: string;
	public isCommissionPaused: boolean;
	public pauseCommissionReasonNoteTypeId: string;
	public pauseCommissionReasonNoteBody: string;
	public isBundledAgent: boolean;
	public companyId: string;
	public accountManagerId: string;
	public includeBufferMargin: boolean;
	public includeBufferMarginForAllSubAgents: boolean;
	public isNewBroker: boolean;
	public commissionPayPlanTypeId: number;
	public marketOpportunitiesAlertEffectiveDate: Date;
	public dropRatio: number;
	public droppedTermValue: number;
	public rejectedTermValue: number;
	public maxReversibleCommission: number;
	public maxReversibleCommissionOverride: number;
	public hasConfirmedContracts: boolean;
	public overrideCommissionPayPlanTypeId: number;
	public commissionSplitTypeId: number;
	public commissionSplitElectricityBufferMargin: number;
	public commissionSplitGasBufferMargin: number;
	public showCapSplitWarning: boolean;
	public webHookUrl: string;
	public webHookSecret: string;
	public darkBackgroundsLogoId: string;
	public lightBackgroundsLogoId: string;
	public hideContractDetailsFromBrother: boolean;
	public isHighImpactAgent: boolean;
	public matrixElectricityBufferMargin: number;
	public matrixGasBufferMargin: number;
	public matrixElectricityBufferMarginStaging: number;
	public matrixGasBufferMarginStaging: number;
	public auctionElectricityBufferMargin: number;
	public auctionGasBufferMargin: number;
	public auctionElectricityBufferMarginStaging: number;
	public auctionGasBufferMarginStaging: number;
	public isHighImpactAgentOverride: boolean;
	public useAgentDiscountProgram: boolean;
	public hasUnlimitedSalesCodes: boolean;
	public hasResidentialCustomerPoa: boolean;
	public commissionSplitBufferMarginCommissionRate: number;
	public commissionSplitBufferMarginCommissionRate2: number;
	public complianceDropPredictionThreshold: number;
	public w9AttachmentId: string;
	public showCustomerCrm: string;
	public showBrokerCrm: string;
	public canDoQuickPay: boolean;
	public quickPayRate: number;
	public matrixElectricityMarginCap: number;
	public matrixGasMarginCap: number;
	public useResidualCommissionRate: boolean;
	public residualCommissionRate: number;

	public selectedForBundle: boolean;
	public selectedForUnbundle: boolean;
	public isSaas: boolean;
	public displayName: string;
	public extendMarketOpportunitiesAlert: boolean;

	public darkDisplayLogo: Logo; // graphql alias property
	public lightDisplayLogo: Logo; // graphql alias property

	public parent: Agent;
	public brother: Agent;
	public country: Country;
	public phoneCountry: Country;
	public phone2Country: Country;
	public mobileCountry: Country;
	public faxCountry: Country;
	public mailServer: MailServer;
	public eCommerceWebsite: ECommerceWebsite;
	public attachments: Attachment[];
	public tasks: Task[];
	public rateMatrixes: AgentRateMatrix[];
	public promoCodes: PromoCode[];
	public licensePlans: LicensePlan[];
	public businessEntityType: BusinessEntityType;
	public businessEntityTypeTaxClassification: BusinessEntityTypeTaxClassification;
	public underwriter: User;
	public bankAccounts: Subset<BankAccount>[];
	public logins: User[];
	public subAgents: Agent[];
	public brothers: Agent[];
	public addOns: LicensePlan[];
	public discountProgram: AgentDiscountProgram;
	public stats: AgentBiddingStats;
	public emailPreferences: EmailPreference[];
	public parentIsEmployee: AgentIsEmployee;
	public brotherIsEmployee: AgentIsEmployee;
	public commissionUpfrontRateLevels: CommissionUpfrontRateLevel[];
	public commissionUpfrontRates: CommissionUpfrontRate[];
	public educations: Education[];
	public eclStates: State[];
	public eclAnnualUsageBrackets: EclAnnualUsageBracket[];
	public eclLoadFactorBrackets: EclLoadFactorBracket[];
	public eclPackages: AgentEclPackage[];
	public customerCards: StripeCard[];
	public company: Company;
	public accountManager: User;
	public marketOpportunities: { utilityName: string; stateShort: string }[];
	public agentStripeCustomer: AgentStripeCustomer;
	public commissionPayPlanOptionStats: { description: string; maxRate: number }[];
	public pauseCommissionReason: { noteId: string; noteTypeId: string; subject: string; body: string };
	public pauseCommissionReasonNote: Note;
	public darkBackgroundsLogo: Logo;
	public lightBackgroundsLogo: Logo;
	public themes: AgentTheme[];
	public commissionPayPlanTypes: CommissionPayPlanType[];
	public agentAllowedCommissionPayPlanTypes: AgentAllowedCommissionPayPlanType[];
	public viewAgentCommissionPayPlanOptions: ViewAgentCommissionPayPlanOption[];

	get email(): string {
		const login = this.getLogin();

		if (login && login.emails.length) {
			return login.emails[0].email;
		}
	}

	get contactFname(): string {
		const login = this.getLogin();

		if (login) {
			return login.fname;
		}
	}

	get contactMiddle(): string {
		const login = this.getLogin();

		if (login) {
			return login.middle;
		}
	}

	get contactLname(): string {
		const login = this.getLogin();

		if (login) {
			return login.lname;
		}
	}

	private businessFullIds = CONSTANTS.licensePlans.businessFullIds;
	private salesCodeBundleIds = CONSTANTS.licensePlans.salesCodeBundleIds;
	private newAgentBundleIds = CONSTANTS.licensePlans.newAgentBundleIds;
	private oldAgentBundleIds = CONSTANTS.licensePlans.oldAgentBundleIds;
	private agentBundleIds = this.newAgentBundleIds.concat(this.oldAgentBundleIds);
	private oldTurnKeyIds = CONSTANTS.licensePlans.oldTurnKeyIds;
	private newTurnKeyIds = CONSTANTS.licensePlans.newTurnKeyIds;
	private turnKeyIds = this.oldTurnKeyIds.concat(this.newTurnKeyIds);
	private oldApiIds = CONSTANTS.licensePlans.oldApiIds;
	private newApiIds = CONSTANTS.licensePlans.newApiIds;
	private apiIds = this.oldApiIds.concat(this.newApiIds);
	private bundleIds = this.salesCodeBundleIds.concat(this.agentBundleIds);
	private newAffiliateIds = CONSTANTS.licensePlans.newAffiliateIds;
	private affiliateIds = this.newAffiliateIds;

	constructor(agent?: Agent) {
		Object.assign(this, agent);

		this.addDate = this.addDate ? new Date(moment(this.addDate).format('MMM DD, YYYY hh:mm:ss a')) : this.addDate;
		this.parent = this.parent ? new Agent(this.parent) : this.parent;
		this.brother = this.brother ? new Agent(this.brother) : this.brother;
		this.country = this.country ? new Country(this.country) : this.country;
		this.faxCountry = this.faxCountry ? new Country(this.faxCountry) : this.faxCountry;
		this.phoneCountry = this.phoneCountry ? new Country(this.phoneCountry) : this.phoneCountry;
		this.mobileCountry = this.mobileCountry ? new Country(this.mobileCountry) : this.mobileCountry;
		this.logins = this.logins ? this.logins.map(u => new User(u)) : this.logins;
		this.underwriter = this.underwriter ? new User(this.underwriter) : this.underwriter;
		this.suppliers = this.suppliers ? this.suppliers.map(s => new Supplier(s)) : this.suppliers;
		this.subAgents = this.subAgents ? this.subAgents.map(a => new Agent(a)) : this.subAgents;
		this.brothers = this.brothers ? this.brothers.map(b => new Agent(b)) : this.brothers;
		this.promoCodes = this.promoCodes ? this.promoCodes.map(p => new PromoCode(p)) : this.promoCodes;
		this.licensePlans = this.licensePlans ? this.licensePlans.map(l => new LicensePlan(l)) : this.licensePlans;
		this.eclPackageDiscountExpirationDate = this.eclPackageDiscountExpirationDate
			? new Date(moment(this.eclPackageDiscountExpirationDate).format('MMM DD, YYYY hh:mm:ss a'))
			: this.eclPackageDiscountExpirationDate;
		this.marketOpportunitiesAlertEffectiveDate = this.marketOpportunitiesAlertEffectiveDate
			? new Date(moment(this.marketOpportunitiesAlertEffectiveDate).format('MMM DD, YYYY hh:mm:ss a'))
			: this.marketOpportunitiesAlertEffectiveDate;
		this.company = this.company ? new Company(this.company) : this.company;
		this.paymentIntentRequiresActionDate = this.paymentIntentRequiresActionDate
			? new Date(moment(this.paymentIntentRequiresActionDate).format('MMM DD, YYYY hh:mm:ss a'))
			: this.paymentIntentRequiresActionDate;

		this.displayName = HelperService.showAsteriskForBrokerFirstImpression(this.isNewBroker)
			? this.name + '*'
			: this.name;
	}

	get agentLabel() {
		return `${this.displayName} [${this.email}]`;
	}

	public getContactFullName(): string {
		const contactMiddle = this.contactMiddle && this.contactMiddle.trim() ? this.contactMiddle.trim() + ' ' : '';
		return `${this.contactFname} ${contactMiddle}${this.contactLname}`;
	}

	public getContactFullNameLastFirst(): string {
		return `${this.contactLname}, ${this.contactFname} ${(this.contactMiddle && this.contactMiddle.trim()
			? ' ' + this.contactMiddle.trim() : '')}`;
	}

	public getFullAddressLine(): string {
		let addressLine = '';
		const stateOther = this.stateOther ? this.stateOther + ' ' : ' ';

		addressLine += this.address1 + ' ';
		addressLine += this.address2 ? this.address2 + ' ' : '';
		addressLine += this.city + ', ';
		addressLine += this.state && (!this.countryId || this.countryId === CONSTANTS.countries.unitedStates)
			? this.state.stateShort + ' '
			: stateOther;
		addressLine += this.zipCode;

		return addressLine;
	}

	public getLicensePlan(): LicensePlan {
		return this.licensePlans
			? this.licensePlans.find(l => !l.parents?.length)
			: null;
	}

	get isBusinessFull(): boolean {
		const licensePlan = this.getLicensePlan();
		return !this.licensePlans || !this.licensePlans.length || (licensePlan && this.businessFullIds.includes(licensePlan.id));
	}

	public getAddOns(): LicensePlan[] {
		return this.licensePlans
			? this.licensePlans
				.filter(l => !!l.parents?.length && !this.bundleIds.includes(l.id))
				.concat(this.getSalesCodeBundles())
				.concat(this.getAgentBundles())
			: null;
	}

	public hasNoAgentBundle(): boolean {
		return !this.licensePlans.some(l => this.agentBundleIds.includes(l.id));
	}

	public hasSeparateAgentBundle(): boolean {
		return this.licensePlans.some(l => this.oldAgentBundleIds.includes(l.id));
	}

	public hasDefaultAgentBundle(): boolean {
		return this.licensePlans.some(l => this.newAgentBundleIds.includes(l.id));
	}

	public hasNoTurnKey(): boolean {
		return !this.licensePlans.some(l => this.turnKeyIds.includes(l.id));
	}

	public hasSeparateTurnKey(): boolean {
		return this.licensePlans.some(l => this.oldTurnKeyIds.includes(l.id));
	}

	public hasDefaultTurnKey(): boolean {
		return this.licensePlans.some(l => this.newTurnKeyIds.includes(l.id));
	}

	public hasNoAPI(): boolean {
		return !this.licensePlans.some(l => this.apiIds.includes(l.id));
	}

	public hasSeparateAPI(): boolean {
		return this.licensePlans.some(l => this.oldApiIds.includes(l.id));
	}

	public hasDefaultAPI(): boolean {
		return this.licensePlans.some(l => this.newApiIds.includes(l.id));
	}

	public hasDefaultAffiliatePlan(): boolean {
		return this.licensePlans.some(l => this.newAffiliateIds.includes(l.id));
	}

	public hasSeparateBusinessLite(): boolean {
		return this.licensePlans.some(l => [1].includes(l.id));
	}

	private getSalesCodeBundles(): LicensePlan[] {
		const salesCodePackage = this.licensePlans.find(l => this.salesCodeBundleIds.includes(l.id));
		const salesCodeBundles = [];
		if (salesCodePackage) {
			for (let i = 0; i < this.promoCodeCount; i++) {
				salesCodeBundles.push(salesCodePackage);
			}
		}
		return salesCodeBundles;
	}

	private getAgentBundles(): LicensePlan[] {
		const agentBundle = this.licensePlans.find(l => this.agentBundleIds.includes(l.id));
		const agentBundles = [];
		if (agentBundle) {
			for (let i = 0; i < this.agentBundleCount; i++) {
				agentBundles.push(agentBundle);
			}
		}
		return agentBundles;
	}

	public getTotalCommissionRate(): number {
		// residualPercentage is always 85
		const value = 85 * this.commissionRate / (this.commissionRate + this.commissionRate2 + this.commissionRate3);

		return Math.round(value);
	}

	public getCurrentLevelIcon(): string {
		return this.getLevelIcon(this.discountProgram);
	}

	public getLevelIcon(discountProgram: AgentDiscountProgram): string {
		if (!discountProgram) { return 'check-circle'; }

		switch (discountProgram.id) {
			case 3: {
				return 'check-circle';
			}
			case 4:
			case 5:
			case 6: {
				return 'medal';
			}
			case 7: {
				return 'gem';
			}
			default: {
				return 'check-circle';
			}
		}
	}

	public getLogin(): User | null {
		if (this.logins) {
			const logins = _.orderBy(this.logins, ['isPrimary', 'addDate'], ['desc', 'asc']);

			return logins[0];
		}
	}

	public getEmails(): UserEmail[] {
		return _.flatMap(this.logins ?? [], l => l.emails);
	}
}
