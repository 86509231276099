<form [formGroup]="formGroup" *ngIf="formGroup">
	<div class="row" *ngIf="noteTypes?.length > 1">
		<div class="col form-element-container" [class.invalid]="err.noteType">
			<pk-broker-form-dropdown
				label="Type"
				formControlName="noteType"
				[options]="noteTypeOptions"
				[isRequired]="true"
				textKey="name"
				nullOption="-- Select --"
				[nullOptionSelectable]="false">
			</pk-broker-form-dropdown>
		</div>
		<p class="mb-0 invalid-feedback" *ngIf="err.noteType">
			<ng-container *ngIf="err.noteType.required">Type is required</ng-container>
		</p>
	</div>
	<div class="row">
		<div class="col form-element-container" [class.invalid]="err.subject">
			<label for="noteSubject" class="required">Subject</label>
			<input type="text" id="noteSubject" class="form-control" formControlName="subject" />
		</div>
		<p class="mb-0 invalid-feedback" *ngIf="err.subject">
			<ng-container *ngIf="err.subject.required">Subject is required</ng-container>
			<ng-container *ngIf="err.subject.maxlength">Subject must be 50 characters or fewer</ng-container>
		</p>
	</div>
	<div class="row">
		<div class="col form-element-container" [class.invalid]="err.body">
			<label for="noteBody" [ngClass]="{ required: isBodyRequired }">{{ bodyLabelName }}</label>
			<textarea class="form-control" id="noteBody" rows="4" formControlName="body"></textarea>
			<p class="mb-0 invalid-feedback" *ngIf="err.body">
				<ng-container *ngIf="err.body.required">{{ bodyLabelName }} is required</ng-container>
				<ng-container *ngIf="err.body.maxlength">{{ bodyLabelName }} must be 500 characters or fewer</ng-container>
			</p>
		</div>
	</div>
	<ng-container *ngIf="(loggedInUser.isAdmin || loggedInUser.isOwner) && someNonInternalNoteTypes">
		<div class="row">
			<div class="col form-element-container pb-0">
				<label for="noteIsInternal" class="required">Internal</label>
			</div>
		</div>
		<div class="row">
			<div class="col-auto form-element-container">
				<div class="custom-control custom-radio">
					<input type="radio" id="noteInternalYes" class="custom-control-input" [value]="true" formControlName="isInternal" />
					<label class="custom-control-label" for="noteInternalYes">Yes</label>
				</div>
			</div>
			<div class="col-auto offset-xl-1 form-element-container">
				<div class="custom-control custom-radio">
					<input type="radio" id="noteInternalNo" class="custom-control-input" [value]="false" formControlName="isInternal" />
					<label class="custom-control-label" for="noteInternalNo">No</label>
				</div>
			</div>
		</div>
	</ng-container>
</form>
<div class="row">
	<div class="col text-right">
		<button type="button" class="btn btn-link" (click)="cancel()" [disabled]="submitDisabled">Cancel</button>
		<button type="button" class="btn btn-primary" (click)="submit()" [disabled]="submitDisabled">
			{{ submitText }}
		</button>
	</div>
</div>
