import { Injectable } from '@angular/core';
import { ApolloQueryResult, FetchResult } from '@apollo/client/core';
import { CONSTANTS } from '@pk/powerkioskutils';

import { Apollo } from 'apollo-angular';
import { lastValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import * as models from '../shared/models';
import * as mutations from './graphql.mutations';
import * as queries from './graphql.queries';
import * as responses from './models/responses';

@Injectable({
	providedIn: 'root',
})
export class GraphqlService {
	constructor(private apollo: Apollo) {
	}

	public setSystemClock(date: string): Promise<FetchResult<responses.SetSystemClockResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.SetSystemClockResponse>({
			mutation: mutations.setSystemClock,
			variables: { date },
		}));
	}

	public getSystemClock(): Promise<ApolloQueryResult<responses.SystemClockData>> {
		return lastValueFrom(this.apollo.query<responses.SystemClockData>({
			query: queries.getSystemClock,
			variables: {},
		}));
	}

	public getEdiUtilityUsageSettingsAddEditData(id: number):
		Promise<ApolloQueryResult<responses.EdiUtilityUsageSettingsAddEditData>> {
		return lastValueFrom(this.apollo.query<responses.EdiUtilityUsageSettingsAddEditData>({
			query: queries.getEdiUtilityUsageSettingsAddEditData,
			variables: { id },
		}));
	}

	public getUtilityWebPortalUsageSettingsAddEditData(id: number):
		Promise<ApolloQueryResult<responses.UtilityWebPortalUsageSettingsAddEditData>> {
		return lastValueFrom(this.apollo.query<responses.UtilityWebPortalUsageSettingsAddEditData>({
			query: queries.getUtilityWebPortalUsageSettingsAddEditData,
			variables: { id },
		}));
	}

	public getUtilityUsageSettingsAddEditPageData(): Promise<ApolloQueryResult<responses.UtilityUsageSettingsAddEditPageData>> {
		return lastValueFrom(this.apollo.query<responses.UtilityUsageSettingsAddEditPageData>({
			query: queries.getUtilityUsageSettingsAddEditPageData,
		}));
	}

	public getUtilityUsageSettingsListPageData(): Promise<ApolloQueryResult<responses.UtilityUsageSettingsListPageData>> {
		return lastValueFrom(this.apollo.query<responses.UtilityUsageSettingsListPageData>({
			query: queries.getUtilityUsageSettingsListPageData,
		}));
	}

	public getRateMatrixLoaderDetails(companyRateMatrixLoaderId: number, finishedAtGTE: string):
		Promise<ApolloQueryResult<responses.RateMatrixLoaderDetailsData>> {
		return lastValueFrom(this.apollo.query<responses.RateMatrixLoaderDetailsData>({
			query: queries.getRateMatrixLoaderDetails,
			variables: { companyRateMatrixLoaderId, finishedAtGTE },
		}));
	}

	public getRateMatrixLoaderAuditDetails(companyRateMatrixLoaderId: number, finishedAtGTE: string):
		Promise<ApolloQueryResult<responses.RateMatrixLoaderAuditDetailsData>> {
		return lastValueFrom(this.apollo.query<responses.RateMatrixLoaderAuditDetailsData>({
			query: queries.getRateMatrixLoaderAuditDetails,
			variables: { companyRateMatrixLoaderId, finishedAtGTE },
		}));
	}

	public getContractLocationStatusReasonTypesAddEditData(id: number):
		Promise<ApolloQueryResult<responses.ContractLocationStatusReasonTypesAddEditData>> {
		return lastValueFrom(this.apollo.query<responses.ContractLocationStatusReasonTypesAddEditData>({
			query: queries.getContractLocationStatusReasonTypesAddEditData,
			variables: { id },
		}));
	}

	public getContractLocationStatusReasonTypesListData(): Promise<ApolloQueryResult<responses.ContractLocationStatusReasonTypesListData>> {
		return lastValueFrom(this.apollo.query<responses.ContractLocationStatusReasonTypesListData>({
			query: queries.getContractLocationStatusReasonTypesListData,
		}));
	}

	public getPopUpListPageData(): Promise<ApolloQueryResult<responses.PopUpListPageData>> {
		return lastValueFrom(this.apollo.query<responses.PopUpListPageData>({
			query: queries.getPopUpListPageData,
		}));
	}

	public getPopUpAddEditPageData(): Promise<ApolloQueryResult<responses.PopUpAddEditPageData>> {
		return lastValueFrom(this.apollo.query<responses.PopUpAddEditPageData>({
			query: queries.getPopUpAddEditPageData,
		}));
	}

	public getPopUpAddEditData(id: number): Promise<ApolloQueryResult<responses.PopUpAddEditData>> {
		return lastValueFrom(this.apollo.query<responses.PopUpAddEditData>({
			query: queries.getPopUpAddEditData,
			variables: { id },
		}));
	}

	public getNextPopUp(): Promise<ApolloQueryResult<responses.NextPopUp>> {
		return lastValueFrom(this.apollo.query<responses.NextPopUp>({
			query: queries.getNextPopUp,
		}));
	}

	public getFeatureTourListPageData(): Promise<ApolloQueryResult<responses.FeatureTourListPageData>> {
		return lastValueFrom(this.apollo.query<responses.FeatureTourListPageData>({
			query: queries.getFeatureTourListPageData,
		}));
	}

	public getFeatureTourAddEditPageData(): Promise<ApolloQueryResult<responses.FeatureTourAddEditPageData>> {
		return lastValueFrom(this.apollo.query<responses.FeatureTourAddEditPageData>({
			query: queries.getFeatureTourAddEditPageData,
		}));
	}

	public getFeatureTourAddEditData(id: number): Promise<ApolloQueryResult<responses.FeatureTourAddEditData>> {
		return lastValueFrom(this.apollo.query<responses.FeatureTourAddEditData>({
			query: queries.getFeatureTourAddEditData,
			variables: { id },
		}));
	}

	public getNextFeatureTour(featureTourLocationId: number): Promise<ApolloQueryResult<responses.NextFeatureTour>> {
		return lastValueFrom(this.apollo.query<responses.NextFeatureTour>({
			query: queries.getNextFeatureTour,
			variables: { featureTourLocationId },
		}));
	}

	public getContractDropSettingsData(): Promise<ApolloQueryResult<responses.ContractDropSettingsData>> {
		return lastValueFrom(this.apollo.query<responses.ContractDropSettingsData>({
			query: queries.getContractDropSettingsData,
		}));
	}

	public getDisclaimer(disclaimerId: number): Promise<ApolloQueryResult<responses.DisclaimerData>> {
		return lastValueFrom(this.apollo.query<responses.DisclaimerData>({
			query: queries.getDisclaimer,
			variables: { disclaimerId },
		}));
	}

	public getPurchasingLayerTypeListData(): Promise<ApolloQueryResult<responses.PurchasingLayerTypeListData>> {
		return lastValueFrom(this.apollo.query<responses.PurchasingLayerTypeListData>({
			query: queries.getPurchasingLayerTypeListData,
		}));
	}

	public getCommissionPayPlanTypeListData(): Promise<ApolloQueryResult<responses.CommissionPayPlanTypeListData>> {
		return lastValueFrom(this.apollo.query<responses.CommissionPayPlanTypeListData>({
			query: queries.getCommissionPayPlanTypeListData,
		}));
	}

	public getCommissionTypeListData(): Promise<ApolloQueryResult<responses.CommissionTypeListData>> {
		return lastValueFrom(this.apollo.query<responses.CommissionTypeListData>({
			query: queries.getCommissionTypeListData,
		}));
	}

	public getPendingCommissionRollupData(contractId: string):
		Promise<ApolloQueryResult<responses.PendingCommissionRollupData>> {
		return lastValueFrom(this.apollo.query<responses.PendingCommissionRollupData>({
			query: queries.getPendingCommissionRollupData,
			variables: { contractId },
		}));
	}

	public getPendingCommissionData(pendingCommissionId: number):
		Promise<ApolloQueryResult<responses.PendingCommissionData>> {
		return lastValueFrom(this.apollo.query<responses.PendingCommissionData>({
			query: queries.getPendingCommissionData,
			variables: { pendingCommissionId },
		}));
	}

	public getPendingCommissionEditContractData(contractId: string):
		Promise<ApolloQueryResult<responses.PendingCommissionEditContractData>> {
		return lastValueFrom(this.apollo.query<responses.PendingCommissionEditContractData>({
			query: queries.getPendingCommissionEditContractData,
			variables: { contractId },
		}));
	}

	public getPayableEditContractData(contractId: string):
		Promise<ApolloQueryResult<responses.PayableEditContractData>> {
		return lastValueFrom(this.apollo.query<responses.PayableEditContractData>({
			query: queries.getPayableEditContractData,
			variables: { contractId },
		}));
	}

	public getPendingCommissionNotes(pendingCommissionId: number):
		Promise<ApolloQueryResult<responses.PendingCommissionData>> {
		return lastValueFrom(this.apollo.query<responses.PendingCommissionData>({
			query: queries.getPendingCommissionNotes,
			variables: { pendingCommissionId },
		}));
	}

	public getInvoiceNotes(invoiceId: number, noteTypeCategoryId: number):
		Promise<ApolloQueryResult<responses.InvoiceNotesData>> {
		return lastValueFrom(this.apollo.query<responses.InvoiceNotesData>({
			query: queries.getInvoiceNotes,
			variables: { invoiceId, noteTypeCategoryId },
		}));
	}
	public getSupplierCommissionAttachmentNotes(supplierCommissionAttachmentId: number):
		Promise<ApolloQueryResult<responses.SupplierCommissionAttachmentNotesData>> {
		return lastValueFrom(this.apollo.query<responses.SupplierCommissionAttachmentNotesData>({
			query: queries.getSupplierCommissionAttachmentNotes,
			variables: { supplierCommissionAttachmentId },
		}));
	}

	public getPendingCommmissionIssues(pendingCommissionId: number): Promise<FetchResult<responses.PendingCommissionIssueResponse>> {
		return lastValueFrom(this.apollo.query<responses.PendingCommissionIssueResponse>({
			query: queries.getPendingCommissionIssues,
			variables: { pendingCommissionId },
		}));
	}

	public getCommissionPayPlanListData(): Promise<ApolloQueryResult<responses.CommissionPayPlanListData>> {
		return lastValueFrom(this.apollo.query<responses.CommissionPayPlanListData>({
			query: queries.getCommissionPayPlanListData,
		}));
	}

	public getDisclaimersListData(criteria: any): Promise<ApolloQueryResult<responses.DisclaimerListData>> {
		return lastValueFrom(this.apollo.query<responses.DisclaimerListData>({
			query: queries.getDisclaimerListData,
			variables: { criteria },
		}));
	}

	public getDisclaimerListPageData(): Promise<ApolloQueryResult<responses.DisclaimerListPageData>> {
		return lastValueFrom(this.apollo.query<responses.DisclaimerListPageData>({
			query: queries.getDisclaimerListPageData,
		}));
	}

	public getDisclaimerAddEditPageData(): Promise<ApolloQueryResult<responses.DisclaimerAddEditPageData>> {
		return lastValueFrom(this.apollo.query<responses.DisclaimerAddEditPageData>({
			query: queries.getDisclaimerListPageData,
		}));
	}

	public getPurchasingLayerTypeData(id: number): Promise<ApolloQueryResult<responses.PurchasingLayerTypeData>> {
		return lastValueFrom(this.apollo.query<responses.PurchasingLayerTypeData>({
			query: queries.getPurchasingLayerTypeData,
			variables: { id },
		}));
	}

	public getCommissionPayPlanTypeData(id: number): Promise<ApolloQueryResult<responses.CommissionPayPlanTypeData>> {
		return lastValueFrom(this.apollo.query<responses.CommissionPayPlanTypeData>({
			query: queries.getCommissionPayPlanTypeData,
			variables: { id },
		}));
	}

	public getCommissionTypeAddEditData(id: number): Promise<ApolloQueryResult<responses.CommissionTypeData>> {
		return lastValueFrom(this.apollo.query<responses.CommissionTypeData>({
			query: queries.getCommissionTypeData,
			variables: { id },
		}));
	}

	public getCommissionPayPlansAddEditData(commissionPayPlanTypeId: number): Promise<ApolloQueryResult<responses.CommissionPayPlansData>> {
		return lastValueFrom(this.apollo.query<responses.CommissionPayPlansData>({
			query: queries.getCommissionPayPlansData,
			variables: { commissionPayPlanTypeId, criteria: { commissionPayPlanTypeId } },
		}));
	}

	public getSupplierPricingAnalyticsPageData():
		Promise<ApolloQueryResult<responses.SupplierPricingAnalyticsPageData>> {
		return lastValueFrom(this.apollo.query<responses.SupplierPricingAnalyticsPageData>({
			query: queries.getSupplierPricingAnalyticsPageData,
		}));
	}

	public getSupplierBiddingSessionStateData(
		supplierId: string, performanceCriteria: any, rankingCriteria: any, competitiveCriteria: any, sort: string):
		Promise<ApolloQueryResult<responses.SupplierMonthlyAuctionPerformanceData>> {
		return lastValueFrom(this.apollo.query<responses.SupplierMonthlyAuctionPerformanceData>({
			query: queries.getSupplierBiddingSessionStateData,
			variables: {
				supplierId,
				performanceCriteria,
				rankingCriteria,
				competitiveCriteria,
				sort,
			},
		}));
	}

	public getSupplierMarketMatrixMaxAddDate(): Promise<ApolloQueryResult<responses.GetSupplierMarketMatrixMaxAddDate>> {
		return lastValueFrom(this.apollo.query<responses.GetSupplierMarketMatrixMaxAddDate>({
			query: queries.getSupplierMarketMatrixMaxAddDate,
		}));
	}

	public getSupplierResidentialMarketMatrixPageData(supplierId: string, serviceTypeId: string, isResidential: boolean, addDate: string):
		Promise<ApolloQueryResult<responses.GetSupplierResidentialMarketMatrixPageData>> {
		return lastValueFrom(this.apollo.query<responses.GetSupplierResidentialMarketMatrixPageData>({
			query: queries.getSupplierResidentialMarketMatrixPageData,
			variables: { supplierId, serviceTypeId, isResidential, addDate },
		}));
	}

	public getSupplierMarketMatrixPageData(supplierId: string, serviceTypeId: string, isResidential: boolean, addDate: string):
		Promise<ApolloQueryResult<responses.GetSupplierMarketMatrixPageData>> {
		return lastValueFrom(this.apollo.query<responses.GetSupplierMarketMatrixPageData>({
			query: queries.getSupplierMarketMatrixPageData,
			variables: { supplierId, serviceTypeId, isResidential, addDate },
		}));
	}

	public getSupplierMarketMatrixUtilities(
		supplierId: string, serviceTypeId: string, isResidential: boolean, addDate: string, stateId: string):
		Promise<ApolloQueryResult<responses.GetSupplierMarketMatrixUtilities>> {
		return lastValueFrom(this.apollo.query<responses.GetSupplierMarketMatrixUtilities>({
			query: queries.getSupplierMarketMatrixUtilities,
			variables: { supplierId, serviceTypeId, isResidential, addDate, stateId },
		}));
	}

	public getSupplierMarketMatrixStates(
		supplierId: string, serviceTypeId: string, isResidential: boolean, addDate: string):
		Promise<ApolloQueryResult<responses.GetSupplierMarketMatrixStates>> {
		return lastValueFrom(this.apollo.query<responses.GetSupplierMarketMatrixStates>({
			query: queries.getSupplierMarketMatrixStates,
			variables: { supplierId, serviceTypeId, isResidential, addDate },
		}));
	}

	public getSupplierMarketMatrixEffectiveDates(
		supplierId: string, serviceTypeId: string, isResidential: boolean, addDate: string, stateId: string,
		utilityId: string):
		Promise<ApolloQueryResult<responses.GetSupplierMarketMatrixEffectiveDates>> {
		return lastValueFrom(this.apollo.query<responses.GetSupplierMarketMatrixEffectiveDates>({
			query: queries.getSupplierMarketMatrixEffectiveDates,
			variables: { supplierId, serviceTypeId, isResidential, addDate, stateId, utilityId },
		}));
	}

	public getSupplierMarketMatrixTerms(
		supplierId: string, serviceTypeId: string, isResidential: boolean, addDate: string, stateId?: string,
		utilityId?: string, effectiveDate?: string):
		Promise<ApolloQueryResult<responses.GetSupplierMarketMatrixTerms>> {
		return lastValueFrom(this.apollo.query<responses.GetSupplierMarketMatrixTerms>({
			query: queries.getSupplierMarketMatrixTerms,
			variables: { supplierId, serviceTypeId, isResidential, addDate, stateId, utilityId, effectiveDate },
		}));
	}

	public getSupplierMarketMatrixUsages(
		supplierId: string, serviceTypeId: string, isResidential: boolean, addDate: string, stateId?: string,
		utilityId?: string, effectiveDate?: string, term?: string):
		Promise<ApolloQueryResult<responses.GetSupplierMarketMatrixUsages>> {
		return lastValueFrom(this.apollo.query<responses.GetSupplierMarketMatrixUsages>({
			query: queries.getSupplierMarketMatrixUsages,
			variables: { supplierId, serviceTypeId, isResidential, addDate, stateId, utilityId, effectiveDate, term },
		}));
	}

	public getChaseAchFileAttachmentsData(sort: string, max: number, offset: number):
		Promise<ApolloQueryResult<responses.GetChaseAchFileAttachmentsData>> {
		return lastValueFrom(this.apollo.query<responses.GetChaseAchFileAttachmentsData>({
			query: queries.getChaseAchFileAttachmentsData,
			variables: { sort, max, offset },
		}));
	}

	public getSupplierMarketMatrixRankingStats(criteria: any):
		Promise<ApolloQueryResult<responses.GetSupplierMarketMatrixRankingStats>> {
		return lastValueFrom(this.apollo.query<responses.GetSupplierMarketMatrixRankingStats>({
			query: queries.getSupplierMarketMatrixRankingStats,
			variables: { criteria },
		}));
	}

	public getMarketMatrixRankingResidentialCompetitivePlans(criteria: any):
		Promise<ApolloQueryResult<responses.GetSupplierMarketMatrixRankingResidentialCompetitivePlans>> {
		return lastValueFrom(this.apollo.query<responses.GetSupplierMarketMatrixRankingResidentialCompetitivePlans>({
			query: queries.getSupplierMarketMatrixRankingResidentialCompetitivePlans,
			variables: { criteria },
		}));
	}

	public getSupplierMarketMatrixRankingStatsResidential(criteria: any, sort: string, max: number, offset: number):
		Observable<ApolloQueryResult<responses.GetSupplierMarketMatrixResidentialRankingStatsByState>> {
		return this.apollo.query<responses.GetSupplierMarketMatrixResidentialRankingStatsByState>({
			query: queries.getSupplierMarketMatrixRankingStatsResidential,
			variables: { criteria, sort, max, offset },
		});
	}

	public getSupplierMarketMatrixRankingStatsByState(criteria: any, sort: string, max: number, offset: number):
		Observable<ApolloQueryResult<responses.GetSupplierMarketMatrixRankingStatsByState>> {
		return this.apollo.query<responses.GetSupplierMarketMatrixRankingStatsByState>({
			query: queries.getSupplierMarketMatrixRankingStatsByState,
			variables: { criteria, sort, max, offset },
		});
	}

	public getSupplierMarketMatrixRankingStatsBreakdown(criteria: any):
		Promise<ApolloQueryResult<responses.GetSupplierMarketMatrixRankingStatsBreakdown>> {
		return lastValueFrom(this.apollo.query<responses.GetSupplierMarketMatrixRankingStatsBreakdown>({
			query: queries.getSupplierMarketMatrixRankingStatsBreakdown,
			variables: { criteria },
		}));
	}

	public getSupplierBiddingSessionUtilityData(supplierId: string, criteria: any, sort: string):
		Promise<ApolloQueryResult<responses.SupplierBiddingSessionUtilityData>> {
		return lastValueFrom(this.apollo.query<responses.SupplierBiddingSessionUtilityData>({
			query: queries.getSupplierBiddingSessionUtilityData,
			variables: {
				supplierId,
				criteria,
				sort,
			},
		}));
	}

	public getFeedbackTypesListData(): Promise<ApolloQueryResult<responses.FeedbackTypesListData>> {
		return lastValueFrom(this.apollo.query<responses.FeedbackTypesListData>({
			query: queries.getFeedbackTypesListData,
		}));
	}

	public getFeedbackTypeAddEditData(id: number): Promise<ApolloQueryResult<responses.FeedbackTypeAddEditData>> {
		return lastValueFrom(this.apollo.query<responses.FeedbackTypeAddEditData>({
			query: queries.getFeedbackTypeAddEditData,
			variables: { id },
		}));
	}

	public getFeedbackModalData(): Promise<ApolloQueryResult<responses.FeedbackModalData>> {
		return lastValueFrom(this.apollo.query<responses.FeedbackModalData>({
			query: queries.getFeedbackModalData,
		}));
	}

	public getFileTransferDirectoryListing(serviceName: string): Promise<ApolloQueryResult<responses.FileTransferDirectoryListingWrapper>> {
		return lastValueFrom(this.apollo.query<responses.FileTransferDirectoryListingWrapper>({
			query: queries.getFileTransferDirectoryListing,
			variables: { serviceName },
		}));
	}

	public getFileTransferDownload(serviceName: string, fileName: string): Promise<ApolloQueryResult<responses.FileTransferDownload>> {
		return lastValueFrom(this.apollo.query<responses.FileTransferDownload>({
			query: queries.getFileTransferDownload,
			variables: { serviceName, fileName },
		}));
	}

	public deleteFileTransfer(serviceName: string, fileName: string): Promise<ApolloQueryResult<responses.FileTransferDeleteResponse>> {
		return lastValueFrom(this.apollo.query<responses.FileTransferDeleteResponse>({
			query: queries.deleteFileTransfer,
			variables: { serviceName, fileName },
		}));
	}

	public rebuildEsignDocument(esignDocumentId: string): Promise<FetchResult<responses.RebuildEsignDocumentResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.RebuildEsignDocumentResponse>({
			mutation: mutations.rebuildEsignDocument,
			variables: { esignDocumentId },
		}));
	}

	public fileTransfer(serviceName: string, fileName: string, file: string): Promise<FetchResult<responses.FileTransferResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.FileTransferResponse>({
			mutation: mutations.fileTransfer,
			variables: { serviceName, fileName, file },
		}));
	}

	public sendPeakDemandEmails(criteria: any): Promise<FetchResult<responses.SendPeakDemandEmailsResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.SendPeakDemandEmailsResponse>({
			mutation: mutations.sendPeakDemandEmails,
			variables: { criteria },
		}));
	}

	public getPeakDemandData(): Promise<ApolloQueryResult<responses.PeakDemandData>> {
		return lastValueFrom(this.apollo.query<responses.PeakDemandData>({
			query: queries.getPeakDemandData,
		}));
	}

	public getRfqSessionOptOutReasonTypeListData(): Promise<ApolloQueryResult<responses.RfqSessionOptOutReasonTypesListData>> {
		return lastValueFrom(this.apollo.query<responses.RfqSessionOptOutReasonTypesListData>({
			query: queries.getRfqSessionOptOutReasonTypesData,
			variables: {},
		}));
	}

	public getAttachmentBase64Data(attachmentId: string): Promise<ApolloQueryResult<responses.AttachmentBase64Data>> {
		return lastValueFrom(this.apollo.query<responses.AttachmentBase64Data>({
			query: queries.getAttachmentBase64Data,
			variables: { attachmentId },
		}));
	}


	public getExtendAuctionData(): Promise<ApolloQueryResult<responses.ExtendAuctionData>> {
		return lastValueFrom(this.apollo.query<responses.ExtendAuctionData>({
			query: queries.getExtendAuctionData,
		}));
	}

	public getGlobalUserGridConfigsByGridId(userId: string, gridId: string):
		Promise<ApolloQueryResult<responses.GlobalUserGridConfigsByGridIdResponse>> {
		return lastValueFrom(this.apollo.query<responses.GlobalUserGridConfigsByGridIdResponse>({
			query: queries.getGlobalUserGridConfigsByGridId,
			variables: { userId, gridId },
		}));
	}

	public getGlobalGridConfigByGridId(gridId: string):
		Promise<ApolloQueryResult<responses.GlobalUserGridConfigResponse>> {
		return lastValueFrom(this.apollo.query<responses.GlobalUserGridConfigResponse>({
			query: queries.getGlobalGridConfigByGridId,
			variables: { gridId },
		}));
	}

	public getGlobalUserGridConfigs(): Promise<ApolloQueryResult<responses.GlobalUserGridConfigsResponse>> {
		return lastValueFrom(this.apollo.query<responses.GlobalUserGridConfigsResponse>({
			query: queries.getGlobalUserGridConfigs,
		}));
	}

	public getRateMatrixBenchmarks(rateBarRequest: models.RateMatrixBenchmarkInput):
		Promise<ApolloQueryResult<responses.RateMatrixBenchmarksResponse>> {
		return lastValueFrom(this.apollo.query<responses.RateMatrixBenchmarksResponse>({
			query: queries.getRateMatrixBenchmarks,
			variables: { criteria: rateBarRequest },
		}));
	}

	public updateOctipowerConfig(stringifiedConfig: string): Promise<FetchResult<responses.UpdateOctipowerConfigResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateOctipowerConfigResponse>({
			mutation: mutations.updateOctipowerConfig,
			variables: { file: stringifiedConfig },
		}));
	}

	public getOctipowerConfig(): Promise<ApolloQueryResult<responses.OctipowerConfigResponse>> {
		return lastValueFrom(this.apollo.query<responses.OctipowerConfigResponse>({
			query: queries.getOctipowerConfig,
		}));
	}

	public updateHoliday(id: number, holiday: Subset<models.Holiday>): Promise<FetchResult<responses.UpdateHolidayResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateHolidayResponse>({
			mutation: mutations.updateHoliday,
			variables: { id, holiday },
		}));
	}

	public createHoliday(holiday: Subset<models.Holiday>): Promise<FetchResult<responses.CreateHolidayResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateHolidayResponse>({
			mutation: mutations.createHoliday,
			variables: { holiday },
		}));
	}

	public getAllHolidays(): Promise<ApolloQueryResult<responses.GetAllHolidaysResponse>> {
		return lastValueFrom(this.apollo.query<responses.GetAllHolidaysResponse>({
			query: queries.getAllHolidays,
		}));
	}

	public getHoliday(id: number): Promise<ApolloQueryResult<responses.GetHolidayResponse>> {
		return lastValueFrom(this.apollo.query<responses.GetHolidayResponse>({
			query: queries.getHoliday,
			variables: { id },
		}));
	}

	public getHolidayEditPageData(): Promise<ApolloQueryResult<responses.GetHolidayEditPageData>> {
		return lastValueFrom(this.apollo.query<responses.GetHolidayEditPageData>({
			query: queries.getHolidayEditPageData,
		}));
	}

	public getEmailPreferenceAddEditPageData():
		Promise<ApolloQueryResult<responses.GetEmailPreferenceAddEditPageData>> {
		return lastValueFrom(this.apollo.query<responses.GetEmailPreferenceAddEditPageData>({
			query: queries.getEmailPreferenceAddEditPageData,
		}));
	}

	public getCommissionAuditReport(reportId: string, criteria?: Partial<models.ReportCommissionAuditCriteria>):
		Promise<ApolloQueryResult<responses.CommissionAuditReportResponse>> {
		return lastValueFrom(this.apollo.query<responses.CommissionAuditReportResponse>({
			query: queries.getCommissionAuditReport,
			variables: { reportId, criteria },
		}));
	}

	public getCustomerEditPageData(customerId: string): Promise<ApolloQueryResult<responses.CustomerEditPageData>> {
		return lastValueFrom(this.apollo.query<responses.CustomerEditPageData>({
			query: queries.getCustomerEditPageData,
			variables: { customerId },
		}));
	}

	public getViewContractLogChanges(criteria: any, sort: string, max: number, offset: number):
		Promise<ApolloQueryResult<responses.GetViewContractLogChangesResponse>> {
		return lastValueFrom(this.apollo.query<responses.GetViewContractLogChangesResponse>({
			query: queries.getViewContractLogChanges,
			variables: { criteria, sort, offset, max },
		}));
	}

	public enroll(contractId: string): Promise<FetchResult<responses.EnrollResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.EnrollResponse>({
			mutation: mutations.enroll,
			variables: { contractId },
		}));
	}

	public doSignedContractEmail(sendContractType: string): Promise<FetchResult<responses.DoSignedContractEmailResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.DoSignedContractEmailResponse>({
			mutation: mutations.doSignedContractEmail,
			variables: { sendContractType },
		}));
	}

	public sendSignedContract(contractId: string): Promise<FetchResult<responses.SendSignedContractResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.SendSignedContractResponse>({
			mutation: mutations.sendSignedContract,
			variables: { contractId },
		}));
	}

	public createCustomerLogin(contractId: string): Promise<FetchResult<responses.CreateCustomerLoginResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateCustomerLoginResponse>({
			mutation: mutations.createCustomerLogin,
			variables: { contractId },
		}));
	}

	public updateContractsAdvancedSearch(contractId: string): Promise<FetchResult<responses.UpdateContractsAdvancedResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateContractsAdvancedResponse>({
			mutation: mutations.updateContractsAdvanced,
			variables: { contractId },
		}));
	}

	public updateCustomer(customerId: string, customer: Subset<models.Customer>) {
		return lastValueFrom(this.apollo.mutate<responses.UpdateCustomerData>({
			mutation: mutations.updateCustomer,
			variables: { customerId, customer },
		}));
	}

	public getCustomer(customerId: string): Promise<ApolloQueryResult<responses.GetCustomerData>> {
		return lastValueFrom(this.apollo.query<responses.GetCustomerData>({
			query: queries.getCustomer,
			variables: { customerId },
		}));
	}

	public getSupplierRateSettingsData(criteria: Partial<models.SupplierRateSetting>, utilityId: string, stateId: string):
		Promise<ApolloQueryResult<responses.SupplierRateSettingsData>> {
		return lastValueFrom(this.apollo.query<responses.SupplierRateSettingsData>({
			query: queries.getSupplierRateSettingsData,
			variables: { criteria, utilityId, stateId },
		}));
	}

	public sendErrorReport(message: string, report: any): Promise<FetchResult<responses.SendErrorReportData>> {
		return lastValueFrom(this.apollo.mutate<responses.SendErrorReportData>({
			mutation: mutations.sendErrorReport,
			variables: { message, report },
		}));
	}

	public createFeedback(feedback: Subset<models.Feedback>): Promise<FetchResult<responses.CreateFeedbackResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateFeedbackResponse>({
			mutation: mutations.createFeedback,
			variables: { feedback },
		}));
	}

	public updateFeedback(id: number, feedback: models.Feedback): Promise<FetchResult<responses.UpdateFeedbackResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateFeedbackResponse>({
			mutation: mutations.updateFeedback,
			variables: { id, feedback },
		}));
	}

	public createFeedbackType(feedbackType: Subset<models.FeedbackType>): Promise<FetchResult<responses.CreateFeedbackTypeResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateFeedbackTypeResponse>({
			mutation: mutations.createFeedbackType,
			variables: { feedbackType },
		}));
	}

	public updateFeedbackType(id: number, feedbackType: Subset<models.FeedbackType>):
		Promise<FetchResult<responses.UpdateFeedbackTypeResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateFeedbackTypeResponse>({
			mutation: mutations.updateFeedbackType,
			variables: { id, feedbackType },
		}));
	}

	public getCustomerInfoData(customerId: string): Promise<ApolloQueryResult<responses.CustomerInfoData>> {
		return lastValueFrom(this.apollo.query<responses.CustomerInfoData>({
			query: queries.getCustomerInfoData,
			variables: { customerId },
		}));
	}

	public getReportBillingConsumptionData(): Promise<ApolloQueryResult<responses.ReportBillingConsumptionDataResponse>> {
		return lastValueFrom(this.apollo.query<responses.ReportBillingConsumptionDataResponse>({
			query: queries.getReportBillingConsumptionData,
		}));
	}

	public getAppListData(): Promise<ApolloQueryResult<responses.AppListData>> {
		return lastValueFrom(this.apollo.query<responses.AppListData>({
			query: queries.getAppListData,
		}));
	}

	public getAppEditData(id: number): Promise<ApolloQueryResult<responses.AppEditData>> {
		return lastValueFrom(this.apollo.query<responses.AppEditData>({
			query: queries.getAppEditData,
			variables: { id },
		}));
	}

	public sendAllAccountsEmail(criteria: any): Promise<ApolloQueryResult<responses.SendAllAccountsEmailData>> {
		return lastValueFrom(this.apollo.query<responses.SendAllAccountsEmailData>({
			query: queries.sendAllAccountsEmail,
			variables: { criteria },
		}));
	}

	public sendAccountsInactiveUtilityDistribution():
		Promise<ApolloQueryResult<responses.SendAccountsInactiveUtilityDistributionEmailData>> {
		return lastValueFrom(this.apollo.query<responses.SendAccountsInactiveUtilityDistributionEmailData>({
			query: queries.sendAccountsInactiveUtilityDistribution,
		}));
	}

	public sendUtilityUsageComparedToReceivableUsage():
		Promise<ApolloQueryResult<responses.SendUtilityUsageComparedToReceivableUsageData>> {
		return lastValueFrom(this.apollo.query<responses.SendUtilityUsageComparedToReceivableUsageData>({
			query: queries.sendUtilityUsageComparedToReceivableUsage,
		}));
	}

	public sendAccountsMissingUsage(criteria: any): Promise<ApolloQueryResult<responses.SendAccountsMissingUsageData>> {
		return lastValueFrom(this.apollo.query<responses.SendAccountsMissingUsageData>({
			query: queries.sendAccountsMissingUsage,
			variables: { criteria },
		}));
	}

	public getAccountUsageReportAccountUsage(criteria: any, sort: string):
		Promise<ApolloQueryResult<responses.AccountUsageReportAccountUsageData>> {
		return lastValueFrom(this.apollo.query<responses.AccountUsageReportAccountUsageData>({
			query: queries.getAccountUsageReportAccountUsage,
			variables: { criteria, sort },
		}));
	}

	public getAccountUsageReportData(reportId: string, criteria: any): Promise<ApolloQueryResult<responses.AccountUsageReportData>> {
		return lastValueFrom(this.apollo.query<responses.AccountUsageReportData>({
			query: queries.getAccountUsageReportData,
			variables: { reportId, criteria },
		}));
	}

	public getAccountUsageReportPageData(reportId: string): Promise<ApolloQueryResult<responses.AccountUsageReportPageData>> {
		return lastValueFrom(this.apollo.query<responses.AccountUsageReportPageData>({
			query: queries.getAccountUsageReportPageData,
			variables: { reportId },
		}));
	}

	public getAuctionReportPageData(reportId: string): Promise<ApolloQueryResult<responses.AuctionReportPageData>> {
		return lastValueFrom(this.apollo.query<responses.AuctionReportPageData>({
			query: queries.getAuctionReportPageData,
			variables: { reportId },
		}));
	}

	public getTicketReportPageData(reportId: string): Promise<ApolloQueryResult<responses.TicketReportPageData>> {
		return lastValueFrom(this.apollo.query<responses.TicketReportPageData>({
			query: queries.getTicketReportPageData,
			variables: { reportId },
		}));
	}


	public getContractCommissionPayPlanEditPageData(contractId: string):
		Promise<ApolloQueryResult<responses.ContractPayPlanEditPageData>> {
		return lastValueFrom(this.apollo.query<responses.ContractPayPlanEditPageData>({
			query: queries.getContractCommissionPayPlanEditPageData,
			variables: { contractId },
		}));
	}

	public getRedisKeys(pattern?: string): Promise<ApolloQueryResult<responses.RedisKeysData>> {
		return lastValueFrom(this.apollo.query<responses.RedisKeysData>({
			query: queries.getRedisKeys,
			variables: { pattern },
		}));
	}

	public getRedisKey(name: string): Promise<ApolloQueryResult<responses.RedisKeyData>> {
		return lastValueFrom(this.apollo.query<responses.RedisKeyData>({
			query: queries.getRedisKey,
			variables: { name },
		}));
	}

	public deleteRedisKey(name: string): Promise<FetchResult<responses.DeleteRedisKeyResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.DeleteRedisKeyResponse>({
			mutation: mutations.deleteRedisKey,
			variables: { name },
		}));
	}

	public getAuctionSendMessageSuppliers(rfqSessionId: string, supplierId: string):
		Promise<ApolloQueryResult<responses.AuctionSendMessageSuppliersData>> {
		return lastValueFrom(this.apollo.query<responses.AuctionSendMessageSuppliersData>({
			query: queries.getAuctionSendMessageSuppliers,
			variables: { rfqSessionId, supplierId },
		}));
	}

	public getAuctionReportSupplierEventData(rfqSessionId: string):
		Promise<ApolloQueryResult<responses.AuctionReportSupplierEventData>> {
		return lastValueFrom(this.apollo.query<responses.AuctionReportSupplierEventData>({
			query: queries.getAuctionReportSupplierEventData,
			variables: { rfqSessionId },
		}));
	}

	public getAuctionListAuctionMonitorReportModalData(rfqSessionId: string):
		Promise<ApolloQueryResult<responses.AuctionListAuctionMonitorReportModalData>> {
		return lastValueFrom(this.apollo.query<responses.AuctionListAuctionMonitorReportModalData>({
			query: queries.getAuctionListAuctionMonitorReportModalData,
			variables: { rfqSessionId },
		}));
	}

	public sendAuctionMonitorReport(rfqSessionId: string, supplierId: string):
		Promise<FetchResult<responses.AuctionMonitorReportResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.AuctionMonitorReportResponse>({
			mutation: mutations.sendAuctionMonitorReport,
			variables: { rfqSessionId, supplierId },
		}));
	}

	public getAuctionReportDataSub(reportId: string, criteria: any, sort: string, max: number, offset: number):
		Observable<ApolloQueryResult<responses.AuctionReportData>> {
		return this.apollo.query<responses.AuctionReportData>({
			query: queries.getAuctionReportData,
			variables: { reportId, criteria, sort, max, offset },
		});
	}

	public getBillingReports(reportId: string, criteria: any): Promise<ApolloQueryResult<responses.BillingReportsData>> {
		return lastValueFrom(this.apollo.query<responses.BillingReportsData>({
			query: queries.getBillingReports,
			variables: { reportId, criteria },
		}));
	}

	public getSystemLogsDataSub(reportId: string, criteria: Subset<models.ReportSystemLog>, sort: string, max: number, offset: number):
		Observable<ApolloQueryResult<responses.SystemLogsReportData>> {
		return this.apollo.query<responses.SystemLogsReportData>({
			query: queries.getSystemLogsData,
			variables: { reportId, criteria, sort, max, offset },
		});
	}

	public getSystemLogsReportData(reportId: string): Promise<ApolloQueryResult<responses.SystemLogsReportPageData>> {
		return lastValueFrom(this.apollo.query<responses.SystemLogsReportPageData>({
			query: queries.getSystemLogsReportData,
			variables: { reportId },
		}));
	}

	public createAgentDiscountProgram(
		agentDiscountProgram: Subset<models.AgentDiscountProgram>,
	): Promise<FetchResult<responses.CreateAgentDiscountProgramResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateAgentDiscountProgramResponse>({
			mutation: mutations.createAgentDiscountProgram,
			variables: { agentDiscountProgram },
		}));
	}

	public updateAgentDiscountProgram(
		id: number,
		agentDiscountProgram: Subset<models.AgentDiscountProgram>,
	): Promise<FetchResult<responses.UpdateAgentDiscountProgramResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateAgentDiscountProgramResponse>({
			mutation: mutations.updateAgentDiscountProgram,
			variables: { id, agentDiscountProgram },
		}));
	}

	public getAllAgentDiscountPrograms(): Promise<ApolloQueryResult<responses.AgentDiscountProgramsResponse>> {
		return lastValueFrom(this.apollo.query<responses.AgentDiscountProgramsResponse>({
			query: queries.getAllAgentDiscountPrograms,
		}));
	}

	public getSingleAgentDiscountProgram(id: number): Promise<ApolloQueryResult<responses.AgentDiscountProgramResponse>> {
		return lastValueFrom(this.apollo.query<responses.AgentDiscountProgramResponse>({
			query: queries.getSingleAgentDiscountProgram,
			variables: { id },
		}));
	}

	public importRatesFromSupplierApi(assemblyName: string, companyId: string): Promise<ApolloQueryResult<any>> {
		return lastValueFrom(this.apollo.query<any>({
			query: queries.importRatesFromSupplierApi,
			variables: { assemblyName, companyId },
		}));
	}

	public supplierRates(assemblyName: string, criteria: models.SupplierResidentialRates):
		Promise<ApolloQueryResult<responses.SupplierRatesData>> {
		return lastValueFrom(this.apollo.query<responses.SupplierRatesData>({
			query: queries.supplierRates,
			variables: { assemblyName, criteria },
		}));
	}

	public getSupplierRatesPageData(): Promise<ApolloQueryResult<responses.GetSupplierRatesPageData>> {
		return lastValueFrom(this.apollo.query<responses.GetSupplierRatesPageData>({
			query: queries.getSupplierRatesPageData,
		}));
	}

	public getSupplierRatesCompanyData(companyId: string): Promise<ApolloQueryResult<responses.GetSupplierRatesCompanyData>> {
		return lastValueFrom(this.apollo.query<responses.GetSupplierRatesCompanyData>({
			query: queries.getSupplierRatesCompanyData,
			variables: { companyId },
		}));
	}

	public getDirectMatrixPriceData(contractId: string): Promise<ApolloQueryResult<responses.DirectMatrixPriceResponse>> {
		return lastValueFrom(this.apollo.query<responses.DirectMatrixPriceResponse>({
			query: queries.getDirectMatrixPriceData,
			variables: { contractId },
		}));
	}

	public forgotUsername(email: string): Promise<ApolloQueryResult<responses.ForgotUsernameResponse>> {
		return lastValueFrom(this.apollo.query<responses.ForgotUsernameResponse>({
			query: queries.forgotUsername,
			variables: { email },
		}));
	}

	public confirmCustomerEnrollments(contractIds: string[]): Promise<FetchResult<responses.ConfirmCustomerEnrollmentResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.ConfirmCustomerEnrollmentResponse>({
			mutation: mutations.confirmCustomerEnrollments,
			variables: { contractIds },
		}));
	}

	public submitCustomerCallNoResponses(contractIds: string[]): Promise<FetchResult<responses.SubmitCustomerCallNoResponses>> {
		return lastValueFrom(this.apollo.mutate<responses.SubmitCustomerCallNoResponses>({
			mutation: mutations.submitCustomerCallNoResponses,
			variables: { contractIds },
		}));
	}

	public submitCustomerFrauds(contractIds: string[]): Promise<FetchResult<responses.SubmitCustomerFrauds>> {
		return lastValueFrom(this.apollo.mutate<responses.SubmitCustomerFrauds>({
			mutation: mutations.submitCustomerFrauds,
			variables: { contractIds },
		}));
	}

	public forgotPassword(username: string, email: string): Promise<ApolloQueryResult<responses.ForgotPasswordResponse>> {
		return lastValueFrom(this.apollo.query<responses.ForgotPasswordResponse>({
			query: queries.forgotPassword,
			variables: { username, email },
		}));
	}

	public getRateMatrixEditPageData(): Promise<ApolloQueryResult<responses.RateMatrixEditPageResponse>> {
		return lastValueFrom(this.apollo.query<responses.RateMatrixEditPageResponse>({
			query: queries.getRateMatrixEditPageData,
		}));
	}

	public getContractAccountAdjustmentData(contractId: string): Promise<ApolloQueryResult<responses.ContractAccountAdjustmentResponse>> {
		return lastValueFrom(this.apollo.query<responses.ContractAccountAdjustmentResponse>({
			query: queries.getContractAccountAdjustmentData,
			variables: { contractId },
		}));
	}

	public deleteAccountAdjustment(id: number): Promise<FetchResult<responses.DeleteAccountAdjustmentResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.DeleteAccountAdjustmentResponse>({
			mutation: mutations.deleteAccountAdjustment,
			variables: { id },
		}));
	}

	public createAccountAdjustment(accountAdjustment: Subset<models.AccountAdjustment>):
		Promise<FetchResult<responses.CreateAccountAdjustmentResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateAccountAdjustmentResponse>({
			mutation: mutations.createAccountAdjustment,
			variables: { accountAdjustment },
		}));
	}

	public updateAccountAdjustment(id: number, accountAdjustment: Subset<models.AccountAdjustment>):
		Promise<FetchResult<responses.UpdateAccountAdjustmentResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateAccountAdjustmentResponse>({
			mutation: mutations.updateAccountAdjustment,
			variables: { id, accountAdjustment },
		}));
	}

	public getCustomerSignupPageData(): Promise<ApolloQueryResult<responses.CustomerSignupBeginResponse>> {
		return lastValueFrom(this.apollo.query<responses.CustomerSignupBeginResponse>({
			query: queries.getCustomerSignupPageData,
		}));
	}

	public getContractNotes(contractId: string, noteTypeIds: string): Promise<ApolloQueryResult<responses.ContractNotesResponse>> {
		return lastValueFrom(this.apollo.query<responses.ContractNotesResponse>({
			query: queries.getContractNotes,
			variables: { contractId, noteTypeIds },
		}));
	}

	public getPendingCommissionStats(criteria: any):
		Promise<ApolloQueryResult<responses.GetPendingCommissionStatsResponse>> {
		return lastValueFrom(this.apollo.query<responses.GetPendingCommissionStatsResponse>({
			query: queries.getPendingCommissionStats,
			variables: { criteria },
		}));
	}

	public listPostProcessingCommissionAudits(criteria: any):
		Promise<ApolloQueryResult<responses.ListPostProcessingCommissionAuditsResponse>> {
		return lastValueFrom(this.apollo.query<responses.ListPostProcessingCommissionAuditsResponse>({
			query: queries.listPostProcessingCommissionAudits,
			variables: { criteria },
		}));
	}

	public listGroupedPostProcessingCommissionAudits(criteria: any, sort: string, max: number, offset: number):
		Promise<ApolloQueryResult<responses.ListGroupedPostProcessingCommissionAuditsResponse>> {
		return lastValueFrom(this.apollo.query<responses.ListGroupedPostProcessingCommissionAuditsResponse>({
			query: queries.listGroupedPostProcessingCommissionAudits,
			variables: { criteria, sort, max, offset },
		}));
	}

	public getPostProcessingEditData(contractId: string):
		Promise<ApolloQueryResult<responses.PostProcessingCommissionAuditEditDataResponse>> {
		return lastValueFrom(this.apollo.query<responses.PostProcessingCommissionAuditEditDataResponse>({
			query: queries.getPostProcessingCommissionAuditEditData,
			variables: { contractId },
		}));
	}

	public getPostProcessingRefreshProgress(companyId: string):
		Promise<ApolloQueryResult<responses.PostProcessingRefreshProgressResponse>> {
		return lastValueFrom(this.apollo.query<responses.PostProcessingRefreshProgressResponse>({
			query: queries.getPostProcessingRefreshProgress,
			variables: { companyId },
		}));
	}

	public exportViewPostProcessingCommissionAudits(criteria: any):
		Promise<ApolloQueryResult<responses.ExportViewPostProcessingCommissionAuditsResponse>> {
		return lastValueFrom(this.apollo.query<responses.ExportViewPostProcessingCommissionAuditsResponse>({
			query: queries.exportViewPostProcessingCommissionAudits,
			variables: { criteria },
		}));
	}

	public exportViewPostProcessingCommissionSupplierReport(criteria: any):
		Promise<ApolloQueryResult<responses.ExportViewPostProcessingCommissionSupplierReportResponse>> {
		return lastValueFrom(this.apollo.query<responses.ExportViewPostProcessingCommissionSupplierReportResponse>({
			query: queries.exportViewPostProcessingCommissionSupplierReport,
			variables: { criteria },
		}));
	}

	public exportTicketReport(criteria: any):
		Promise<ApolloQueryResult<responses.ExportTicketReportResponse>> {
		return lastValueFrom(this.apollo.query<responses.ExportTicketReportResponse>({
			query: queries.exportTicketReport,
			variables: { criteria },
		}));
	}


	public exportHistoricalViewPostProcessingCommissionAudits(criteria: any):
		Promise<ApolloQueryResult<responses.ExportHistoricalViewPostProcessingCommissionAuditsResponse>> {
		return lastValueFrom(this.apollo.query<responses.ExportHistoricalViewPostProcessingCommissionAuditsResponse>({
			query: queries.exportHistoricalViewPostProcessingCommissionAudits,
			variables: { criteria },
		}));
	}

	public postProcessingCommissionAuditsRefresh(addMonthDate: string, companyId: string):
		Promise<FetchResult<responses.PostProcessingCommissionAuditsRefreshResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.PostProcessingCommissionAuditsRefreshResponse>({
			mutation: mutations.postProcessingCommissionAuditsRefresh,
			variables: { addMonthDate, companyId },
		}));
	}

	public listPendingCommissionDashboard(criteria: any, sort: string, max: number, offset: number):
		Promise<ApolloQueryResult<responses.ListPendingCommissionsResponse>> {
		return lastValueFrom(this.apollo.query<responses.ListPendingCommissionsResponse>({
			query: queries.listPendingCommissionDashboard,
			variables: { criteria, sort, max, offset },
		}));
	}

	public getPendingCommissionMatchedAccounts(pendingCommissionId: number):
		Promise<ApolloQueryResult<responses.GetPendingCommissionMatchedAccounts>> {
		return lastValueFrom(this.apollo.query<responses.GetPendingCommissionMatchedAccounts>({
			query: queries.getPendingCommissionMatchedAccounts,
			variables: { pendingCommissionId },
		}));
	}

	public listPendingCommissionsQueue(criteria: any): Promise<ApolloQueryResult<responses.ListPendingCommissionsQueueResponse>> {
		return lastValueFrom(this.apollo.query<responses.ListPendingCommissionsQueueResponse>({
			query: queries.listPendingCommissionsQueue,
			variables: { criteria },
		}));
	}

	public listMarkAsPaidCommissionsQueue():
		Promise<ApolloQueryResult<responses.ListMarkAsPaidCommissionsQueueResponse>> {
		return lastValueFrom(this.apollo.query<responses.ListMarkAsPaidCommissionsQueueResponse>({
			query: queries.listMarkAsPaidCommissionsQueue,
		}));
	}

	public listPendingConfirmationsFilterOptions(criteria: any):
		Promise<ApolloQueryResult<responses.ListPendingConfirmationsFilterOptionsResponse>> {
		return lastValueFrom(this.apollo.query<responses.ListPendingConfirmationsFilterOptionsResponse>({
			query: queries.listPendingConfirmationsFilterOptions,
			variables: { criteria },
		}));
	}

	public listPendingConfirmations(criteria: any, sort: string, max: number, offset: number):
		Promise<ApolloQueryResult<responses.ListPendingConfirmationsResponse>> {
		return lastValueFrom(this.apollo.query<responses.ListPendingConfirmationsResponse>({
			query: queries.listPendingConfirmations,
			variables: { criteria, sort, max, offset },
		}));
	}

	public listPendingConfirmationsQueue(criteria: any): Promise<ApolloQueryResult<responses.ListPendingConfirmationsResponse>> {
		return lastValueFrom(this.apollo.query<responses.ListPendingConfirmationsResponse>({
			query: queries.listPendingConfirmationsQueue,
			variables: { criteria },
		}));
	}

	public listPendingInvoices(
		criteria: any,
		sort: string,
		max: number,
		offset: number,
	): Promise<ApolloQueryResult<responses.ListPendingInvoicesResponse>> {
		return lastValueFrom(this.apollo.query<responses.ListPendingInvoicesResponse>({
			query: queries.listPendingInvoices,
			variables: { criteria, sort, max, offset },
		}));
	}

	public getSingleAccountAdjustment(id: number): Promise<ApolloQueryResult<responses.AccountAdjustmentResponse>> {
		return lastValueFrom(this.apollo.query<responses.AccountAdjustmentResponse>({
			query: queries.getSingleAccountAdjustment,
			variables: { id },
		}));
	}

	public getAllAccountAdjustments(criteria: any): Promise<ApolloQueryResult<responses.AccountAdjustmentsResponse>> {
		return lastValueFrom(this.apollo.query<responses.AccountAdjustmentsResponse>({
			query: queries.getAllAccountAdjustments,
			variables: { criteria },
		}));
	}

	public getImportResidentialRatesCompanyData(companyId: string):
		Promise<ApolloQueryResult<responses.GetImportResidentialRatesCompanyData>> {
		return lastValueFrom(this.apollo.query<responses.GetImportResidentialRatesCompanyData>({
			query: queries.getImportResidentialRatesCompanyData,
			variables: { companyId },
		}));
	}

	public getImportResidentialRatesPageData():
		Promise<ApolloQueryResult<responses.GetImportResidentialRatesPageData>> {
		return lastValueFrom(this.apollo.query<responses.GetImportResidentialRatesPageData>({
			query: queries.getImportResidentialRatesPageData,
		}));
	}

	public updateUserApiKey(id: number, userApiKey: Subset<models.UserAPIKey>): Promise<FetchResult<responses.UpdateUserApiKeyResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateUserApiKeyResponse>({
			mutation: mutations.updateUserApiKey,
			variables: { id, userApiKey },
		}));
	}

	public createUserApiKey(userApiKey: Subset<models.UserAPIKey>): Promise<FetchResult<responses.CreateUserApiKeyResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateUserApiKeyResponse>({
			mutation: mutations.createUserApiKey,
			variables: { userApiKey },
		}));
	}

	public getUserShowPageData(userId: string) {
		return lastValueFrom(this.apollo.query<responses.UserShowPageResponse>({
			query: queries.getUserShowPageData,
			variables: { userId },
		}));
	}

	public getUserShowPageApiKeyData(userId: string) {
		return lastValueFrom(this.apollo.query<responses.UserShowPageResponse>({
			query: queries.getUserShowPageApiKeyData,
			variables: { userId },
		}));
	}

	public getAuctionInProgressBestBids(rfqSessionId: string): Promise<ApolloQueryResult<responses.AuctionInProgressModalData>> {
		return lastValueFrom(this.apollo.query<responses.AuctionInProgressModalData>({
			query: queries.auctionInProgressBestBids,
			variables: { rfqSessionId },
		}));
	}

	public getNavbarData(userId: string): Promise<ApolloQueryResult<responses.NavbarDataResponse>> {
		return lastValueFrom(this.apollo.query<responses.NavbarDataResponse>({
			query: queries.getNavbarData,
			variables: { userId, menuTypeId: CONSTANTS.menuTypes.admin },
		}));
	}

	public updateSecurityRule(securityRuleId: string, securityRule: models.SecurityRule):
		Promise<FetchResult<responses.UpdateSecurityRuleResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateSecurityRuleResponse>({
			mutation: mutations.updateSecurityRule,
			variables: { securityRuleId, securityRule },
		}));
	}

	public createSecurityRule(securityRule: models.SecurityRule): Promise<FetchResult<responses.CreateSecurityRuleResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateSecurityRuleResponse>({
			mutation: mutations.createSecurityRule,
			variables: { securityRule },
		}));
	}

	public getSecurityRuleEditPageData(): Promise<ApolloQueryResult<responses.SecurityRuleResponse>> {
		return lastValueFrom(this.apollo.query<responses.SecurityRuleResponse>({
			query: queries.getSecurityRuleEditPageData,
		}));
	}

	public getSingleSecurityRule(securityRuleId: string): Promise<ApolloQueryResult<responses.SecurityRuleResponse>> {
		return lastValueFrom(this.apollo.query<responses.SecurityRuleResponse>({
			query: queries.getSingleSecurityRule,
			variables: { securityRuleId },
		}));
	}

	public getAllSecurityRules(): Promise<ApolloQueryResult<responses.SecurityRulesResponse>> {
		return lastValueFrom(this.apollo.query<responses.SecurityRulesResponse>({
			query: queries.getAllSecurityRules,
		}));
	}

	public createDocument(document: Subset<models.Document>): Promise<FetchResult<responses.CreateDocumentResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateDocumentResponse>({
			mutation: mutations.createDocument,
			variables: { document },
		}));
	}

	public updateRole(roleId: string, role: Subset<models.Role>): Promise<FetchResult<responses.UpdateRoleResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateRoleResponse>({
			mutation: mutations.updateRole,
			variables: { roleId, role },
		}));
	}

	public createRole(role: Subset<models.Role>): Promise<FetchResult<responses.CreateRoleResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateRoleResponse>({
			mutation: mutations.createRole,
			variables: { role },
		}));
	}

	public createPostProcessingCommissionAuditStatus(postProcessingCommissionAuditStatus:
		Subset<models.PostProcessingCommissionAuditStatus>):
		Promise<FetchResult<responses.CreatePostProcessingCommissionAuditStatusResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreatePostProcessingCommissionAuditStatusResponse>({
			mutation: mutations.createPostProcessingCommissionAuditStatus,
			variables: { postProcessingCommissionAuditStatus },
		}));
	}

	public updatePostProcessingCommissionAuditStatus(id: number, postProcessingCommissionAuditStatus:
		Subset<models.PostProcessingCommissionAuditStatus>):
		Promise<FetchResult<responses.UpdatePostProcessingCommissionAuditStatusResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdatePostProcessingCommissionAuditStatusResponse>({
			mutation: mutations.updatePostProcessingCommissionAuditStatus,
			variables: { id, postProcessingCommissionAuditStatus },
		}));
	}

	public getCustomerBeginData(rfqSessionId: string): Promise<ApolloQueryResult<responses.CustomerBeginData>> {
		return lastValueFrom(this.apollo.query<responses.CustomerBeginData>({
			query: queries.getCustomerBeginData,
			variables: { rfqSessionId },
		}));
	}

	public getRatesQuotesAgentData(contractId: string): Promise<ApolloQueryResult<responses.RatesQuotesBeginData>> {
		return lastValueFrom(this.apollo.query<responses.RatesQuotesBeginData>({
			query: queries.getRatesQuotesAgentData,
			variables: { contractId },
		}));
	}

	public updateSupplierRateSetting(id: number, supplierRateSetting: Subset<models.SupplierRateSetting>):
		Promise<FetchResult<responses.UpdateSupplierRateSettingResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateSupplierRateSettingResponse>({
			mutation: mutations.updateSupplierRateSetting,
			variables: { id, supplierRateSetting },
		}));
	}

	public createSupplierRateSetting(supplierRateSetting: Subset<models.SupplierRateSetting>):
		Promise<FetchResult<responses.CreateSupplierRateSettingResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateSupplierRateSettingResponse>({
			mutation: mutations.createSupplierRateSetting,
			variables: { supplierRateSetting },
		}));
	}

	public deleteSupplierRateSetting(id: number): Promise<FetchResult<responses.DeleteSupplierRateSettingResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.DeleteSupplierRateSettingResponse>({
			mutation: mutations.deleteSupplierRateSetting,
			variables: { id },
		}));
	}

	public updateUtilityRateClass(utilityRateClassId: string, utilityRateClass: Subset<models.UtilityRateClass>):
		Promise<FetchResult<responses.UpdateUtilityRateClassResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateUtilityRateClassResponse>({
			mutation: mutations.updateUtilityRateClass,
			variables: { utilityRateClassId, utilityRateClass },
		}));
	}

	public createUtilityRateClass(utilityRateClass: Subset<models.UtilityRateClass>):
		Promise<FetchResult<responses.CreateUtilityRateClassResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateUtilityRateClassResponse>({
			mutation: mutations.createUtilityRateClass,
			variables: { utilityRateClass },
		}));
	}

	public updateUtilityHub(id: number, utilityHub: Subset<models.UtilityHub>):
		Promise<FetchResult<responses.UpdateUtilityHubResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateUtilityHubResponse>({
			mutation: mutations.updateUtilityHub,
			variables: { id, utilityHub },
		}));
	}

	public createUtilityHub(utilityHub: Subset<models.UtilityHub>): Promise<FetchResult<responses.CreateUtilityHubResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateUtilityHubResponse>({
			mutation: mutations.createUtilityHub,
			variables: { utilityHub },
		}));
	}

	public getSupplierRateClassEditPageData(utilityId: string, stateId: string, supplierId: string):
		Promise<ApolloQueryResult<responses.SupplierRateClassResponse>> {
		return lastValueFrom(this.apollo.query<responses.SupplierRateClassResponse>({
			query: queries.getSupplierRateClassEditPageData,
			variables: { criteria: { utilityId, stateId, supplierId }, stateId, utilityId },
		}));
	}

	public getUtilitySupplierEditPageData(criteria: any): Promise<ApolloQueryResult<responses.UtilitySupplierMapResponse>> {
		return lastValueFrom(this.apollo.query<responses.UtilitySupplierMapResponse>({
			query: queries.getUtilitySupplierEditPageData,
			variables: {
				criteria: {
					utilityId: criteria.utilityId,
					stateId: criteria.stateId,
					serviceTypeId: criteria.serviceTypeId,
					supplierId: criteria.supplierId,
					isResidential: criteria.isResidential,
				},
				stateId: criteria.stateId,
				utilityId: criteria.utilityId,
				supplierNeedZipCode: criteria.supplierNeedZipCode,
			},
		}));
	}


	public getSupplierCreateBidsData(rfqSessionId: string): Promise<ApolloQueryResult<responses.SupplierCreateBidsData>> {
		return lastValueFrom(this.apollo.query<responses.SupplierCreateBidsData>({
			query: queries.getSupplierCreateBidsData,
			variables: { rfqSessionId },
		}));
	}

	public getUtilityRateClassEditPageData(): Promise<ApolloQueryResult<responses.UtilityRateClassResponse>> {
		return lastValueFrom(this.apollo.query<responses.UtilityRateClassResponse>({
			query: queries.getUtilityRateClassEditPageData,
		}));
	}

	public getSingleUtilityRateClass(utilityRateClassId: string): Promise<ApolloQueryResult<responses.UtilityRateClassResponse>> {
		return lastValueFrom(this.apollo.query<responses.UtilityRateClassResponse>({
			query: queries.getSingleUtilityRateClass,
			variables: { utilityRateClassId },
		}));
	}

	public getRoleEditPageData(): Promise<FetchResult<responses.RoleResponse>> {
		return lastValueFrom(this.apollo.query<responses.RoleResponse>({
			query: queries.getRoleEditPageData,
		}));
	}

	public viewSupplierDashboard(criteria: any, sort: string, max: number, offset: number):
		Promise<ApolloQueryResult<responses.SupplierPricingSessionData>> {
		return lastValueFrom(this.apollo.query<responses.SupplierPricingSessionData>({
			query: queries.viewSupplierDashboard,
			variables: { criteria, sort, max, offset },
		}));
	}

	public getDirectAuctionSupplierDashboardData(criteria: any): Promise<ApolloQueryResult<responses.AuctionSupplierDashboardData>> {
		return lastValueFrom(this.apollo.query<responses.AuctionSupplierDashboardData>({
			query: queries.getDirectAuctionSupplierDashboardData,
			variables: { criteria },
		}));
	}

	public getSupplierDashboardData(criteria: any, recentCriteria: any): Promise<ApolloQueryResult<responses.SupplierDashboardData>> {
		return lastValueFrom(this.apollo.query<responses.SupplierDashboardData>({
			query: queries.getSupplierDashboardData,
			variables: { criteria, recentCriteria },
		}));
	}

	public getSupplierArchivePageData(): Promise<ApolloQueryResult<responses.SupplierArchivePageData>> {
		return lastValueFrom(this.apollo.query<responses.SupplierArchivePageData>({
			query: queries.getSupplierArchivePageData,
		}));
	}

	public getSupplierCreateBidsUserData(userId: string): Promise<ApolloQueryResult<responses.SupplierCreateBidsUserData>> {
		return lastValueFrom(this.apollo.query<responses.SupplierCreateBidsUserData>({
			query: queries.getSupplierCreateBidsUserData,
			variables: { userId },
		}));
	}

	public getSupplierArchiveDataSub(criteria: any, sort: string, max: number, offset: number):
		Observable<ApolloQueryResult<responses.SupplierArchiveDataSub>> {
		return this.apollo.query<responses.SupplierArchiveDataSub>({
			query: queries.getSupplierArchiveDataSub,
			variables: { criteria, sort, max, offset },
		});
	}

	public getSupplierAuctionData(criteria: any): Promise<ApolloQueryResult<responses.SupplierDashboardData>> {
		return lastValueFrom(this.apollo.query<responses.SupplierDashboardData>({
			query: queries.getSupplierDashboardData,
			variables: { criteria },
		}));
	}

	public getBrokerBidInfo(agentId: string): Promise<ApolloQueryResult<responses.BrokerBidInfo>> {
		return lastValueFrom(this.apollo.query<responses.BrokerBidInfo>({
			query: queries.getBrokerBidInfo,
			variables: { agentId },
		}));
	}

	public getAllUtilityRateClasses(): Promise<ApolloQueryResult<responses.UtilityRateClassListResponse>> {
		return lastValueFrom(this.apollo.query<responses.UtilityRateClassListResponse>({
			query: queries.getAllUtilityRateClasses,
		}));
	}

	public getSingleRole(roleId: string): Promise<ApolloQueryResult<responses.RoleResponse>> {
		return lastValueFrom(this.apollo.query<responses.RoleResponse>({
			query: queries.getSingleRole,
			variables: { roleId },
		}));
	}

	public getAgentListPageData(): Promise<ApolloQueryResult<responses.AgentListPageDataResponse>> {
		return lastValueFrom(this.apollo.query<responses.AgentListPageDataResponse>({
			query: queries.getAgentListPageData,
			variables: {},
		}));
	}

	public getUserListPageData(): Promise<ApolloQueryResult<responses.UserListPageDataResponse>> {
		return lastValueFrom(this.apollo.query<responses.UserListPageDataResponse>({
			query: queries.getUserListPageData,
			variables: {},
		}));
	}

	public getUserData(userId: string): Promise<ApolloQueryResult<responses.UserResponse>> {
		return lastValueFrom(this.apollo.query<responses.UserResponse>({
			query: queries.getUserData,
			variables: { userId },
		}));
	}

	public getUserListData(criteria: any, sort: string, max: number, offset: number): Promise<ApolloQueryResult<responses.UsersResponse>> {
		return lastValueFrom(this.apollo.query<responses.UsersResponse>({
			query: queries.getUserListData,
			variables: { criteria, sort, max, offset },
		}));
	}

	public getUserAgentsListData(criteria: any, sort: string, max: number, offset: number):
		Promise<ApolloQueryResult<responses.UserAgentsResponse>> {
		return lastValueFrom(this.apollo.query<responses.UserAgentsResponse>({
			query: queries.getUserAgentsListData,
			variables: { criteria, sort, max, offset },
		}));
	}

	public getAllRoles(): Promise<ApolloQueryResult<responses.RolesResponse>> {
		return lastValueFrom(this.apollo.query<responses.RolesResponse>({
			query: queries.getAllRoles,
		}));
	}

	public getEsignDocumentData(esignId: string, isCustomer?: boolean): Promise<ApolloQueryResult<responses.EsignBeginData>> {
		return lastValueFrom(this.apollo.query<responses.EsignBeginData>({
			query: queries.getEsignDocumentData,
			variables: { esignId, isCustomer },
		}));
	}

	public getCustomerContractPageData(customerId: string): Promise<ApolloQueryResult<responses.CustomerContractResponse>> {
		return lastValueFrom(this.apollo.query<responses.CustomerContractResponse>({
			query: queries.getCustomerContractPageData,
			variables: {
				criteria: { customerId, isResidential: false },
			},
		}));
	}

	public getCustomerContractDashboardData(customerId: string): Promise<ApolloQueryResult<responses.CustomerContractDashboardResponse>> {
		return lastValueFrom(this.apollo.query<responses.CustomerContractDashboardResponse>({
			query: queries.getCustomerContractDashboardData,
			variables: { customerId },
		}));
	}

	public updateDocument(documentId: string, document: Subset<models.Document>): Promise<FetchResult<responses.UpdateDocumentResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateDocumentResponse>({
			mutation: mutations.updateDocument,
			variables: { documentId, document },
		}));
	}

	public getFaqEditPageData(): Promise<ApolloQueryResult<responses.FaqEditPageData>> {
		return lastValueFrom(this.apollo.query<responses.FaqEditPageData>({
			query: queries.getFaqEditPageData,
		}));
	}

	public getSingleFaq(id: number): Promise<ApolloQueryResult<responses.FaqResponse>> {
		return lastValueFrom(this.apollo.query<responses.FaqResponse>({
			query: queries.getSingleFaq,
			variables: { id },
		}));
	}

	public getFaqData(id: number): Promise<ApolloQueryResult<responses.FaqResponse>> {
		return lastValueFrom(this.apollo.query<responses.FaqResponse>({
			query: queries.getFaqData,
			variables: { id },
		}));
	}

	public getEducationEditPageData(): Promise<ApolloQueryResult<responses.EducationEditPageData>> {
		return lastValueFrom(this.apollo.query<responses.EducationEditPageData>({
			query: queries.getEducationEditPageData,
		}));
	}

	public getEducationData(id: number): Promise<ApolloQueryResult<responses.EducationResponse>> {
		return lastValueFrom(this.apollo.query<responses.EducationResponse>({
			query: queries.getEducationData,
			variables: { id },
		}));
	}

	public getEducationAddEditData(id: number): Promise<ApolloQueryResult<responses.EducationResponse>> {
		return lastValueFrom(this.apollo.query<responses.EducationResponse>({
			query: queries.getEducationAddEditData,
			variables: { id },
		}));
	}

	public getDocumentEditPageData(attachmentTypeId: string): Promise<ApolloQueryResult<responses.GetDocumentEditPageData>> {
		return lastValueFrom(this.apollo.query<responses.GetDocumentEditPageData>({
			query: queries.getDocumentEditPageData,
			variables: { attachmentTypeId },
		}));
	}

	public getShortUrlBySlug(slug: string): Promise<ApolloQueryResult<responses.GetShortUrlBySlugData>> {
		return lastValueFrom(this.apollo.query<responses.GetShortUrlBySlugData>({
			query: queries.getShortUrlBySlug,
			variables: { slug },
		}));
	}

	public getDocumentEditPageDocumentData(documentId: string): Promise<ApolloQueryResult<responses.GetSingleDocumentData>> {
		return lastValueFrom(this.apollo.query<responses.GetSingleDocumentData>({
			query: queries.getDocumentEditPageDocumentData,
			variables: { documentId },
		}));
	}

	public getAllDocuments(criteria: any): Promise<ApolloQueryResult<responses.ViewDocumentsResponse>> {
		return lastValueFrom(this.apollo.query<responses.ViewDocumentsResponse>({
			query: queries.getAllDocuments,
			variables: { criteria },
		}));
	}

	public getContractDocuments(productId: string, supplierId: string, stateId: string,
		utilityId: string, usage: number, status?: number): Promise<ApolloQueryResult<responses.DocumentData>> {
		return lastValueFrom(this.apollo.query<responses.DocumentData>({
			query: queries.getContractDocuments,
			variables: {
				criteria: {
					productId,
					supplierId,
					stateId,
					utilityId,
					usage,
					status,
					attachmentTypeId: CONSTANTS.attachmentTypes.contract,
				},
			},
		}));
	}

	public getResidentialEnrollData(contractId: string): Promise<ApolloQueryResult<responses.ResidentialEnrollData>> {
		return lastValueFrom(this.apollo.query<responses.ResidentialEnrollData>({
			query: queries.residentialEnrollData,
			variables: { contractId },
		}));
	}

	public processPaymentConfirmation(contractId: string, confirmationNum: string):
		Promise<FetchResult<responses.ProcessPaymentConfirmationResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.ProcessPaymentConfirmationResponse>({
			mutation: mutations.processPaymentConfirmation,
			variables: { contractId, confirmationNum },
		}));
	}

	public getNewPaymentData(contractId: string): Promise<ApolloQueryResult<responses.NewPaymentData>> {
		return lastValueFrom(this.apollo.query<responses.NewPaymentData>({
			query: queries.getNewPaymentData,
			variables: { contractId },
		}));
	}

	public getSupplierUploadEsignAttachmentData(esignId: string, isCustomer: boolean):
		Promise<ApolloQueryResult<responses.SupplierUploadEsignAttachmentData>> {
		return lastValueFrom(this.apollo.query<responses.SupplierUploadEsignAttachmentData>({
			query: queries.getSupplierUploadEsignAttachmentData,
			variables: { esignId, isCustomer },
		}));
	}

	public getSupplierUploadContractAttachmentData(contractId: string):
		Promise<ApolloQueryResult<responses.SupplierUploadContractAttachmentData>> {
		return lastValueFrom(this.apollo.query<responses.SupplierUploadContractAttachmentData>({
			query: queries.getSupplierUploadContractAttachmentData,
			variables: { contractId },
		}));
	}

	public updateMenu(menuId: string, menu: Subset<models.Menu>): Promise<FetchResult<responses.UpdateMenuResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateMenuResponse>({
			mutation: mutations.updateMenu,
			variables: { menuId, menu },
		}));
	}

	public createMenu(menu: Subset<models.Menu>): Promise<FetchResult<responses.CreateMenuResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateMenuResponse>({
			mutation: mutations.createMenu,
			variables: { menu },
		}));
	}

	public getMenuEditPageData(): Promise<ApolloQueryResult<responses.MenuResponse>> {
		return lastValueFrom(this.apollo.query<responses.MenuResponse>({
			query: queries.getMenuEditPageData,
		}));
	}

	public getSingleMenu(menuId: string): Promise<ApolloQueryResult<responses.MenuResponse>> {
		return lastValueFrom(this.apollo.query<responses.MenuResponse>({
			query: queries.getSingleMenu,
			variables: { menuId },
		}));
	}

	public getAllMenus(): Promise<ApolloQueryResult<responses.MenusResponse>> {
		return lastValueFrom(this.apollo.query<responses.MenusResponse>({
			query: queries.getAllMenus,
		}));
	}

	public updateTicketCategory(id: number, ticketCategory: Subset<models.TicketCategory>):
		Promise<FetchResult<responses.UpdateTicketCategoryResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateTicketCategoryResponse>({
			mutation: mutations.updateTicketCategory,
			variables: { id, ticketCategory },
		}));
	}

	public createTicketCategory(ticketCategory: Subset<models.TicketCategory>):
		Promise<FetchResult<responses.CreateTicketCategoryResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateTicketCategoryResponse>({
			mutation: mutations.createTicketCategory,
			variables: { ticketCategory },
		}));
	}

	public getTicketCategoryEditPageData(): Promise<ApolloQueryResult<responses.TicketCategoryResponse>> {
		return lastValueFrom(this.apollo.query<responses.TicketCategoryResponse>({
			query: queries.getTicketCategoryEditPageData,
		}));
	}

	public getSingleTicketCategory(id: number): Promise<ApolloQueryResult<responses.TicketCategoryResponse>> {
		return lastValueFrom(this.apollo.query<responses.TicketCategoryResponse>({
			query: queries.getSingleTicketCategory,
			variables: { id },
		}));
	}

	public getAllTicketCategories(): Promise<ApolloQueryResult<responses.TicketCategoriesResponse>> {
		return lastValueFrom(this.apollo.query<responses.TicketCategoriesResponse>({
			query: queries.getAllTicketCategories,
		}));
	}

	public updateEmailCriteria(emailCriteriaId: string, emailCriteria: Subset<models.EmailCriteria>):
		Promise<FetchResult<responses.UpdateEmailCriteriaResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateEmailCriteriaResponse>({
			mutation: mutations.updateEmailCriteria,
			variables: { emailCriteriaId, emailCriteria },
		}));
	}

	public createEmailCriteria(emailCriteria: Subset<models.EmailCriteria>): Promise<FetchResult<responses.CreateEmailCriteriaResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateEmailCriteriaResponse>({
			mutation: mutations.createEmailCriteria,
			variables: { emailCriteria },
		}));
	}

	public getEmailCriteria(emailCriteriaId: string): Promise<ApolloQueryResult<responses.GetEmailCriteriaData>> {
		return lastValueFrom(this.apollo.query<responses.GetEmailCriteriaData>({
			query: queries.getEmailCriteria,
			variables: { emailCriteriaId },
		}));
	}

	public updateEmailAccount(emailAccountId: string, emailAccount: Subset<models.EmailAccount>):
		Promise<FetchResult<responses.UpdateEmailAccountResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateEmailAccountResponse>({
			mutation: mutations.updateEmailAccount,
			variables: { emailAccountId, emailAccount },
		}));
	}

	public createEmailAccount(emailAccount: Subset<models.EmailAccount>): Promise<FetchResult<responses.CreateEmailAccountResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateEmailAccountResponse>({
			mutation: mutations.createEmailAccount,
			variables: { emailAccount },
		}));
	}


	public getSingleEmailAccount(emailAccountId: string): Promise<ApolloQueryResult<responses.EmailAccountResponse>> {
		return lastValueFrom(this.apollo.query<responses.EmailAccountResponse>({
			query: queries.getSingleEmailAccount,
			variables: { emailAccountId },
		}));
	}

	public getEmailCriteriaEditPageData(): Promise<ApolloQueryResult<responses.GetEmailCriteriaEditPageData>> {
		return lastValueFrom(this.apollo.query<responses.GetEmailCriteriaEditPageData>({
			query: queries.getEmailCriteriaEditPageData,
		}));
	}

	public getEmailCriteriaEditEmailAccountsData(companyId: string):
		Promise<ApolloQueryResult<responses.GetEmailCriteriaEditEmailAccountsData>> {
		return lastValueFrom(this.apollo.query<responses.GetEmailCriteriaEditEmailAccountsData>({
			query: queries.getEmailCriteriaEditEmailAccountsData,
			variables: { companyId },
		}));
	}

	public listEmailCriterias(criteria: any, sort: string, max: number, offset: number):
		Promise<ApolloQueryResult<responses.ListEmailCriteriasData>> {
		return lastValueFrom(this.apollo.query<responses.ListEmailCriteriasData>({
			query: queries.listEmailCriterias,
			variables: { criteria, sort, max, offset },
		}));
	}

	public getEmailAccountListPageData(): Promise<ApolloQueryResult<responses.GetEmailAccountListPageData>> {
		return lastValueFrom(this.apollo.query<responses.GetEmailAccountListPageData>({
			query: queries.getEmailAccountListPageData,
		}));
	}

	public listEmailAccounts(criteria: any, sort: string, max: number, offset: number):
		Promise<ApolloQueryResult<responses.GetEmailAccountsData>> {
		return lastValueFrom(this.apollo.query<responses.GetEmailAccountsData>({
			query: queries.listEmailAccounts,
			variables: { criteria, sort, max, offset },
		}));
	}

	public getUtilityGeneralEditPageData(): Promise<ApolloQueryResult<responses.GetUtilityGeneralEditPageData>> {
		return lastValueFrom(this.apollo.query<responses.GetUtilityGeneralEditPageData>({
			query: queries.getUtilityGeneralEditPageData,
		}));
	}

	public getUtilityEditData(utilityId: string): Promise<ApolloQueryResult<responses.GetUtilityEditData>> {
		return lastValueFrom(this.apollo.query<responses.GetUtilityEditData>({
			query: queries.getUtilityEditData,
			variables: { utilityId },
		}));
	}

	public updateSupplier(supplierId: string, supplier: Subset<models.Supplier>): Promise<FetchResult<responses.UpdateSupplierResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateSupplierResponse>({
			mutation: mutations.updateSupplier,
			variables: { supplierId, supplier },
		}));
	}

	public createSupplier(supplier: Subset<models.Supplier>): Promise<FetchResult<responses.CreateSupplierResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateSupplierResponse>({
			mutation: mutations.createSupplier,
			variables: { supplier },
		}));
	}

	public updateUtilityRateClassMap(id: number, utilityRateClassMap: Subset<models.UtilityRateClassMap>):
		Promise<FetchResult<responses.UpdateRateClassMapResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateRateClassMapResponse>({
			mutation: mutations.updateUtilityRateClassMap,
			variables: { id, utilityRateClassMap },
		}));
	}

	public createUtilityRateClassMap(utilityRateClassMap: Subset<models.UtilityRateClassMap>):
		Promise<FetchResult<responses.CreateRateClassMapResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateRateClassMapResponse>({
			mutation: mutations.createUtilityRateClassMap,
			variables: { utilityRateClassMap },
		}));
	}

	public deleteUtilityRateClassMap(id: number): Promise<FetchResult<responses.DeleteRateClassMapResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.DeleteRateClassMapResponse>({
			mutation: mutations.deleteUtilityRateClassMap,
			variables: { id },
		}));
	}

	public updateUtilitySupplierMap(id: number, utilitySupplierMap: Subset<models.UtilitySupplierMap>):
		Promise<FetchResult<responses.UpdateUtilitySupplierMapResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateUtilitySupplierMapResponse>({
			mutation: mutations.updateUtilitySupplierMap,
			variables: { id, utilitySupplierMap },
		}));
	}

	public createUtilitySupplierMap(utilitySupplierMap: Subset<models.UtilitySupplierMap>):
		Promise<FetchResult<responses.CreateUtilitySupplierMapResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateUtilitySupplierMapResponse>({
			mutation: mutations.createUtilitySupplierMap,
			variables: { utilitySupplierMap },
		}));
	}

	public deleteUtilitySupplierMap(id: number): Promise<FetchResult<responses.DeleteUtilitySupplierMapResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.DeleteUtilitySupplierMapResponse>({
			mutation: mutations.deleteUtilitySupplierMap,
			variables: { id },
		}));
	}

	public updateUtilityMarketSetting(id: number, utilityMarketSetting: Subset<models.UtilityMarketSetting>):
		Promise<FetchResult<responses.UpdateUtilityMarketSettingResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateUtilityMarketSettingResponse>({
			mutation: mutations.updateUtilityMarketSetting,
			variables: { id, utilityMarketSetting },
		}));
	}

	public createUtilityMarketSetting(utilityMarketSetting: Subset<models.UtilityMarketSetting>):
		Promise<FetchResult<responses.CreateUtilityMarketSettingResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateUtilityMarketSettingResponse>({
			mutation: mutations.createUtilityMarketSetting,
			variables: { utilityMarketSetting },
		}));
	}

	public deleteUtilityMarketSetting(id: number): Promise<FetchResult<responses.DeleteUtilityMarketSettingResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.DeleteUtilityMarketSettingResponse>({
			mutation: mutations.deleteUtilityMarketSetting,
			variables: { id },
		}));
	}

	public updateUtility(utilityId: string, utility: Subset<models.Utility>): Promise<FetchResult<responses.UpdateUtilityResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateUtilityResponse>({
			mutation: mutations.updateUtility,
			variables: { utilityId, utility },
		}));
	}

	public createUtility(utility: Subset<models.Utility>): Promise<FetchResult<responses.CreateUtilityResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateUtilityResponse>({
			mutation: mutations.createUtility,
			variables: { utility },
		}));
	}

	public getUtilityEditPageData(utilityId: string): Promise<ApolloQueryResult<responses.GetUtilityEditPageData>> {
		return lastValueFrom(this.apollo.query<responses.GetUtilityEditPageData>({
			query: queries.getUtilityEditPageData,
			variables: { utilityId },
		}));
	}

	public getAllUtilities(): Promise<ApolloQueryResult<responses.UtilitiesResponse>> {
		return lastValueFrom(this.apollo.query<responses.UtilitiesResponse>({
			query: queries.getAllUtilities,
		}));
	}

	public createISO(iso: Subset<models.ISO>): Promise<FetchResult<responses.CreateISOResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateISOResponse>({
			mutation: mutations.createISO,
			variables: { iso },
		}));
	}

	public updateISO(id: number, iso: Subset<models.ISO>): Promise<FetchResult<responses.UpdateISOResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateISOResponse>({
			mutation: mutations.updateISO,
			variables: { id, iso },
		}));
	}

	public getSingleISO(id: number): Promise<ApolloQueryResult<responses.ISOResponse>> {
		return lastValueFrom(this.apollo.query<responses.ISOResponse>({
			query: queries.getSingleISO,
			variables: { id },
		}));
	}

	public getAllISOs(): Promise<ApolloQueryResult<responses.ISOsResponse>> {
		return lastValueFrom(this.apollo.query<responses.ISOsResponse>({
			query: queries.getAllISOs,
		}));
	}

	public getProductsGroupedByServiceType(): Promise<ApolloQueryResult<responses.GroupedProductsResponse>> {
		return lastValueFrom(this.apollo.query<responses.GroupedProductsResponse>({
			query: queries.getProductsGroupedByServiceType,
		}));
	}

	public getSupplierInfoPageData(supplierId: string): Promise<ApolloQueryResult<responses.SupplierInfoPageData>> {
		return lastValueFrom(this.apollo.query<responses.SupplierInfoPageData>({
			query: queries.getSupplierInfoPageData,
			variables: { supplierId },
		}));
	}

	public getSupplierInfoPageLoginsData(supplierId: string, companyId: string):
		Promise<ApolloQueryResult<responses.SupplierInfoPageLoginsData>> {
		return lastValueFrom(this.apollo.query<responses.SupplierInfoPageLoginsData>({
			query: queries.getSupplierInfoPageLoginsData,
			variables: { supplierId, companyId },
		}));
	}

	public getSupplierDashboardSupplierData(supplierId: string):
		Promise<ApolloQueryResult<responses.SupplierDashboardSupplierData>> {
		return lastValueFrom(this.apollo.query<responses.SupplierDashboardSupplierData>({
			query: queries.getSupplierDashboardSupplierData,
			variables: { supplierId },
		}));
	}

	public getSupplierEditData(supplierId: string): Promise<ApolloQueryResult<responses.SupplierEditData>> {
		return lastValueFrom(this.apollo.query<responses.SupplierEditData>({
			query: queries.getSupplierEditData,
			variables: { supplierId },
		}));
	}

	public getSupplierEditGeneralData(supplierId: string): Promise<ApolloQueryResult<responses.SupplierEditGeneralData>> {
		return lastValueFrom(this.apollo.query<responses.SupplierEditGeneralData>({
			query: queries.getSupplierEditGeneralData,
			variables: { supplierId },
		}));
	}

	public getSupplierEditCompanySettingsPageData(supplierId: string):
		Promise<ApolloQueryResult<responses.SupplierEditCompanySettingsPageData>> {
		return lastValueFrom(this.apollo.query<responses.SupplierEditCompanySettingsPageData>({
			query: queries.getSupplierEditCompanySettingsPageData,
			variables: { supplierId },
		}));
	}

	public getSupplierAttachmentData(attachmentId: string): Promise<ApolloQueryResult<responses.SupplierAttachmentData>> {
		return lastValueFrom(this.apollo.query<responses.SupplierAttachmentData>({
			query: queries.getSupplierAttachmentData,
			variables: { attachmentId },
		}));
	}

	public sendMissingSupplierEmail(suppliers: models.Supplier[]): Promise<ApolloQueryResult<responses.StringResponse>> {
		return lastValueFrom(this.apollo.query<responses.StringResponse>({
			query: queries.sendMissingSupplierEmail,
			variables: { suppliers },
		}));
	}

	public sendAndUpdateReinstatementInfo(contractId: string, reinstatementInfo: any): Promise<FetchResult<responses.StringResponse>> {
		return lastValueFrom(this.apollo.query<responses.StringResponse>({
			query: queries.sendAndUpdateReinstatementInfo,
			variables: { contractId, reinstatementInfo },
		}));
	}

	public getSupplierEditDocumentsData(supplierId: string, companyId: string):
		Promise<ApolloQueryResult<responses.SupplierEditDocumentsData>> {
		return lastValueFrom(this.apollo.query<responses.SupplierEditDocumentsData>({
			query: queries.getSupplierEditDocumentsData,
			variables: { supplierId, companyId },
		}));
	}

	public getSupplierEditDocumentsPageData(): Promise<ApolloQueryResult<responses.SupplierEditCompanySettingsPageData>> {
		return lastValueFrom(this.apollo.query<responses.SupplierEditCompanySettingsPageData>({
			query: queries.getSupplierEditDocumentsPageData,
		}));
	}

	public getSupplierEditGeneralPageData(): Promise<ApolloQueryResult<responses.SupplierEditGeneralPageData>> {
		return lastValueFrom(this.apollo.query<responses.SupplierEditGeneralPageData>({
			query: queries.getSupplierEditGeneralPageData,
		}));
	}

	public getSupplierGeneralEditPageData(): Promise<ApolloQueryResult<responses.SupplierGeneralEditGeneralPageData>> {
		return lastValueFrom(this.apollo.query<responses.SupplierGeneralEditGeneralPageData>({
			query: queries.getSupplierGeneralEditPageData,
		}));
	}

	public getCompanySupplierEditData(supplierId: string, companyId: string):
		Promise<ApolloQueryResult<responses.CompanySupplierEditData>> {
		return lastValueFrom(this.apollo.query<responses.CompanySupplierEditData>({
			query: queries.getCompanySupplierEditData,
			variables: { supplierId, companyId },
		}));
	}

	public getAllSuppliers(criteria: any, sort: string, max: number, offset: number):
		Promise<ApolloQueryResult<responses.SupplierListPageData>> {
		return lastValueFrom(this.apollo.query<responses.SupplierListPageData>({
			query: queries.getAllSuppliers,
			variables: { criteria, sort, max, offset },
		}));
	}

	public getReceivedMappingSupplierCommissionAttachments(companySupplierId: number):
		Promise<ApolloQueryResult<responses.ReceivedMappingSupplierCommissionAttachmentsResponse>> {
		return lastValueFrom(this.apollo.query<responses.ReceivedMappingSupplierCommissionAttachmentsResponse>({
			query: queries.getReceivedMappingSupplierCommissionAttachments,
			variables: { companySupplierId },
		}));
	}

	public getViewSupplierCommissionAttachmentDeposits(criteria: any, sort: string, max: number, offset: number):
		Promise<ApolloQueryResult<responses.ViewSupplierCommissionAttachmentDepositsResponse>> {
		return lastValueFrom(this.apollo.query<responses.ViewSupplierCommissionAttachmentDepositsResponse>({
			query: queries.getViewSupplierCommissionAttachmentDeposits,
			variables: { criteria, sort, max, offset },
		}));
	}

	public getCompanySupplierCommissionAttachments(criteria: any, sort: string, max: number, offset: number):
		Promise<ApolloQueryResult<responses.CompanySupplierCommissionAttachmentsResponse>> {
		return lastValueFrom(this.apollo.query<responses.CompanySupplierCommissionAttachmentsResponse>({
			query: queries.getCompanySupplierCommissionAttachments,
			variables: { criteria, sort, max, offset },
		}));
	}

	public getViewCompanySupplierQuickBookDeposit(criteria: any, sort: string, max: number, offset: number):
		Promise<ApolloQueryResult<responses.ViewCompanySupplierQuickBookDepositPageData>> {
		return lastValueFrom(this.apollo.query<responses.ViewCompanySupplierQuickBookDepositPageData>({
			query: queries.viewCompanySupplierQuickBookDeposits,
			variables: { criteria, sort, max, offset },
		}));
	}

	public getContractCommissionForecastedPayables(criteria: any, sort: string, max: number, offset: number):
		Promise<ApolloQueryResult<responses.ContractCommissionForecastedPayablesResponse>> {
		return lastValueFrom(this.apollo.query<responses.ContractCommissionForecastedPayablesResponse>({
			query: queries.getContractCommissionForecastedPayables,
			variables: { criteria, sort, max, offset },
		}));
	}

	public getContractCommissionForecastedReceivables(criteria: any, sort: string, max: number, offset: number):
		Promise<ApolloQueryResult<responses.ContractCommissionForecastedReceivablesResponse>> {
		return lastValueFrom(this.apollo.query<responses.ContractCommissionForecastedReceivablesResponse>({
			query: queries.getContractCommissionForecastedReceivables,
			variables: { criteria, sort, max, offset },
		}));
	}

	public exportViewCommissionReports(criteria: any):
		Promise<ApolloQueryResult<responses.ExportViewCommissionReportsResponse>> {
		return lastValueFrom(this.apollo.query<responses.ExportViewCommissionReportsResponse>({
			query: queries.exportViewCommissionReports,
			variables: { criteria },
		}));
	}

	public getViewCommissionReportDashboard(criteria: any):
		Promise<ApolloQueryResult<responses.ViewCommissionReportDashboard>> {
		return lastValueFrom(this.apollo.query<responses.ViewCommissionReportDashboard>({
			query: queries.getViewCommissionReportDashboard,
			variables: { criteria },
		}));
	}

	public getViewPricingAnalyticsUtilityPerformances(criteria: any, sort: string, max: number, offset: number):
		Promise<ApolloQueryResult<responses.ViewPricingAnalyticsUtilityPerformancesResponse>> {
		return lastValueFrom(this.apollo.query<responses.ViewPricingAnalyticsUtilityPerformancesResponse>({
			query: queries.getViewPricingAnalyticsUtilityPerformances,
			variables: { criteria, sort, max, offset },
		}));
	}

	public getPricingAnalyticsMatrixCompetitiveUtilityPerformances(criteria: any):
		Promise<ApolloQueryResult<responses.PricingAnalyticsMatrixCompetitiveUtilityPerformancesResponse>> {
		return lastValueFrom(this.apollo.query<responses.PricingAnalyticsMatrixCompetitiveUtilityPerformancesResponse>({
			query: queries.getPricingAnalyticsMatrixCompetitiveUtilityPerformances,
			variables: { criteria },
		}));
	}

	public getPricingAnalyticsSupplierSettingDashboard(supplierId: string, serviceTypeId: string):
		Promise<ApolloQueryResult<responses.PricingAnalyticsSupplierSettingDashboardResponse>> {
		return lastValueFrom(this.apollo.query<responses.PricingAnalyticsSupplierSettingDashboardResponse>({
			query: queries.getPricingAnalyticsSupplierSettingDashboard,
			variables: { supplierId, serviceTypeId },
		}));
	}

	public getPricingAnalyticsSupplierSettingDashboardUtilityOptions(supplierId: string, stateId: string):
		Promise<ApolloQueryResult<responses.PricingAnalyticsSupplierSettingDashboardUtilityOptionsResponse>> {
		return lastValueFrom(this.apollo.query<responses.PricingAnalyticsSupplierSettingDashboardUtilityOptionsResponse>({
			query: queries.getPricingAnalyticsSupplierSettingDashboardUtilityOptions,
			variables: { supplierId, stateId },
		}));
	}

	public getPricingAnalyticsSupplierSettingDashboardRateClassOptions(supplierId: string, stateId: string, utilityId: string, zone = ''):
		Promise<ApolloQueryResult<responses.PricingAnalyticsSupplierSettingDashboardRateClassOptionsResponse>> {
		return lastValueFrom(this.apollo.query<responses.PricingAnalyticsSupplierSettingDashboardRateClassOptionsResponse>({
			query: queries.getPricingAnalyticsSupplierSettingDashboardRateClassOptions,
			variables: { supplierId, stateId, utilityId, zone },
		}));
	}

	public sendPricingAnalyticsSupplierSummary(supplierId: string):
		Promise<ApolloQueryResult<responses.PricingAnalyticsSupplierSettingDashboardResponse>> {
		return lastValueFrom(this.apollo.query<responses.PricingAnalyticsSupplierSettingDashboardResponse>({
			query: queries.sendPricingAnalyticsSupplierSummary,
			variables: { supplierId },
		}));
	}

	public updateSupplierCommissionAttachment(id: number, supplierCommissionAttachment: Subset<models.SupplierCommissionAttachment>) {
		return lastValueFrom(this.apollo.mutate<responses.UpdateSupplierCommissionAttachment>({
			mutation: mutations.updateSupplierCommissionAttachment,
			variables: { id, supplierCommissionAttachment },
		}));
	}

	public approveSupplierCommissionAttachmentDeposits(supplierCommissionAttachmentId: number) {
		return lastValueFrom(this.apollo.mutate<responses.ApproveSupplierCommissionAttachmentDepositsResponse>({
			mutation: mutations.approveSupplierCommissionAttachmentDeposits,
			variables: { supplierCommissionAttachmentId },
		}));
	}

	public bulkUpsertSupplierCommissionAttachmentDeposits(
		removeCriteria: { supplierCommissionAttachmentId?: number; depositId?: number },
		supplierCommissionAttachmentDeposits: models.SupplierCommissionAttachmentDeposit[],
	) {
		return lastValueFrom(this.apollo.mutate<responses.BulkUpsertSupplierCommissionAttachmentDepositsResponse>({
			mutation: mutations.bulkUpsertSupplierCommissionAttachmentDeposits,
			variables: { removeCriteria, supplierCommissionAttachmentDeposits },
		}));
	}

	public createProduct(product: Subset<models.Product>) {
		return lastValueFrom(this.apollo.mutate<responses.CreateProductResponse>({
			mutation: mutations.createProduct,
			variables: { product },
		}));
	}

	public updateProduct(productId: string, product: Subset<models.Product>): Promise<FetchResult<responses.UpdateProductResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateProductResponse>({
			mutation: mutations.updateProduct,
			variables: { productId, product },
		}));
	}

	public getProductEditPageData(): Promise<ApolloQueryResult<responses.GetProductEditPageData>> {
		return lastValueFrom(this.apollo.query<responses.GetProductEditPageData>({
			query: queries.getProductEditPageData,
		}));
	}

	public getProduct(productId: string): Promise<ApolloQueryResult<responses.GetProductResponse>> {
		return lastValueFrom(this.apollo.query<responses.GetProductResponse>({
			query: queries.getProduct,
			variables: { productId },
		}));
	}

	public getAllProducts(): Promise<ApolloQueryResult<responses.GetAllProductsResponse>> {
		return lastValueFrom(this.apollo.query<responses.GetAllProductsResponse>({
			query: queries.getAllProducts,
		}));
	}

	public getSalesReportData(reportId: string, criteria: any): Promise<ApolloQueryResult<responses.SalesReportData>> {
		return lastValueFrom(this.apollo.query<responses.SalesReportData>({
			query: queries.getSalesReportData,
			variables: { reportId, criteria },
		}));
	}

	public getSalesReportPageData(reportId: string): Promise<ApolloQueryResult<responses.SalesReportPageData>> {
		return lastValueFrom(this.apollo.query<responses.SalesReportPageData>({
			query: queries.getSalesReportPageData,
			variables: { reportId },
		}));
	}

	public getSalesTeamCommissionReportPageData(reportId: string): Promise<ApolloQueryResult<responses.SalesTeamCommissionReportPageData>> {
		return lastValueFrom(this.apollo.query<responses.SalesTeamCommissionReportPageData>({
			query: queries.getSalesTeamCommissionReportPageData,
			variables: { reportId },
		}));
	}

	public getQuoteLogsReportPageData(reportId: string): Promise<ApolloQueryResult<responses.QuoteLogsReportPageData>> {
		return lastValueFrom(this.apollo.query<responses.QuoteLogsReportPageData>({
			query: queries.getQuoteLogsReportPageData,
			variables: { reportId },
		}));
	}

	public getSiteTrafficMonitorData(reportId: string, reportRequest: any):
		Promise<ApolloQueryResult<responses.SiteTrafficMonitorReportData>> {
		return lastValueFrom(this.apollo.query<responses.SiteTrafficMonitorReportData>({
			query: queries.getSiteTrafficMonitorData,
			variables: { reportId, reportRequest },
		}));
	}

	public getSiteTrafficMonitorReportPageData(reportId: string): Promise<ApolloQueryResult<responses.SiteTrafficMonitorReportPageData>> {
		return lastValueFrom(this.apollo.query<responses.SiteTrafficMonitorReportPageData>({
			query: queries.getSiteTrafficMonitorReportPageData,
			variables: { reportId },
		}));
	}

	public getRateMatrixData(reportId: string, reportRequest: any): Promise<ApolloQueryResult<responses.RateMatrixReportData>> {
		return lastValueFrom(this.apollo.query<responses.RateMatrixReportData>({
			query: queries.getRateMatrixData,
			variables: { reportId, reportRequest },
		}));
	}

	public getSupplierListData(criteria: any): Promise<ApolloQueryResult<responses.SupplierListReportData>> {
		return lastValueFrom(this.apollo.query<responses.SupplierListReportData>({
			query: queries.getSupplierListReportData,
			variables: { criteria },
		}));
	}

	public getSupplierLatencyReportPageData(): Promise<ApolloQueryResult<responses.SupplierLatencyReportPageData>> {
		return lastValueFrom(this.apollo.query<responses.SupplierLatencyReportPageData>({
			query: queries.getSupplierLatencyReportPageData,
		}));
	}

	public getSupplierLatencyData(criteria: any): Promise<ApolloQueryResult<responses.SupplierLatencyReportData>> {
		return lastValueFrom(this.apollo.query<responses.SupplierLatencyReportData>({
			query: queries.getSupplierLatencyReportData,
			variables: { criteria },
		}));
	}

	public getGuaranteedPaymentsReportData(criteria: any): Promise<ApolloQueryResult<responses.GuaranteedPaymentsReportData>> {
		return lastValueFrom(this.apollo.query<responses.GuaranteedPaymentsReportData>({
			query: queries.getGuaranteedPaymentsReportData,
			variables: { criteria },
		}));
	}

	public getGuaranteedPaymentsReportPageData(reportId: string): Promise<ApolloQueryResult<responses.GuaranteedPaymentsReportPageData>> {
		return lastValueFrom(this.apollo.query<responses.GuaranteedPaymentsReportPageData>({
			query: queries.getGuaranteedPaymentsReportPageData,
			variables: { reportId },
		}));
	}

	public getSupplierListReportPageData(): Promise<ApolloQueryResult<responses.SupplierListReportPageData>> {
		return lastValueFrom(this.apollo.query<responses.SupplierListReportPageData>({
			query: queries.getSupplierListReportPageData,
		}));
	}

	public getAuctionStatsReportPageData(reportId: string): Promise<ApolloQueryResult<responses.AuctionStatsReportPageData>> {
		return lastValueFrom(this.apollo.query<responses.AuctionStatsReportPageData>({
			query: queries.getAuctionStatsReportPageData,
			variables: { reportId },
		}));
	}

	public getAgentFinancialProfilesReportData(criteria: any, sort: string):
		Promise<ApolloQueryResult<responses.AgentFinancialProfileReportData>> {
		return lastValueFrom(this.apollo.query<responses.AgentFinancialProfileReportData>({
			query: queries.getAgentFinancialProfilesReportData,
			variables: { criteria, sort },
		}));
	}

	public getAgentFinancialProfilesReportPageData(reportId: string):
		Promise<ApolloQueryResult<responses.AgentFinancialProfileReportPageData>> {
		return lastValueFrom(this.apollo.query<responses.AgentFinancialProfileReportPageData>({
			query: queries.getAgentFinancialProfilesReportPageData,
			variables: { reportId },
		}));
	}

	public getAgentFinancialProfileBreakdownReportData(agentId: string, companyId: string):
		Promise<ApolloQueryResult<responses.AgentFinancialProfileBreakdownReportData>> {
		return lastValueFrom(this.apollo.query<responses.AgentFinancialProfileBreakdownReportData>({
			query: queries.getAgentFinancialProfileBreakdownReportData,
			variables: { agentId, companyId },
		}));
	}

	public getAgentFinancialProfileBreakdownReportPageData(reportId: string):
		Promise<ApolloQueryResult<responses.AgentFinancialProfileBreakdownReportPageData>> {
		return lastValueFrom(this.apollo.query<responses.AgentFinancialProfileBreakdownReportPageData>({
			query: queries.getAgentFinancialProfileBreakdownReportPageData,
			variables: { reportId },
		}));
	}

	public getRateMatrixReportPageData(reportId: string): Promise<ApolloQueryResult<responses.RateMatrixReportPageData>> {
		return lastValueFrom(this.apollo.query<responses.RateMatrixReportPageData>({
			query: queries.getRateMatrixReportPageData,
			variables: { reportId },
		}));
	}

	public getRateClassPageData(): Promise<ApolloQueryResult<responses.RateClassPageData>> {
		return lastValueFrom(this.apollo.query<responses.RateClassPageData>({
			query: queries.getRateClassPageData,
		}));
	}

	public getUtilitySupplierMapPageData(): Promise<ApolloQueryResult<responses.UtilitySupplierMapPageData>> {
		return lastValueFrom(this.apollo.query<responses.UtilitySupplierMapPageData>({
			query: queries.getUtilitySupplierMapPageData,
		}));
	}

	public getServiceTypesUnitBeginData(serviceTypeId: string): Promise<ApolloQueryResult<responses.GetServiceTypesUnitBeginData>> {
		return lastValueFrom(this.apollo.query<responses.GetServiceTypesUnitBeginData>({
			query: queries.getServiceTypesUnitBeginData,
			variables: { serviceTypeId },
		}));
	}

	public getServiceTypesUnitsData(serviceTypeId: string): Promise<ApolloQueryResult<responses.GetServiceTypesUnitBeginData>> {
		return lastValueFrom(this.apollo.query<responses.GetServiceTypesUnitBeginData>({
			query: queries.getServiceTypesUnitsData,
			variables: { serviceTypeId },
		}));
	}

	public getCsatReportPageData(): Promise<ApolloQueryResult<responses.CsatReportPageData>> {
		return lastValueFrom(this.apollo.query<responses.CsatReportPageData>({
			query: queries.getCsatReportPageData,
			variables: { userSurveyCategoryTypeId: CONSTANTS.userSurveyCategoryTypes.csat },
		}));
	}

	public getCsatReportData(
		overallCriteria: Partial<models.UserSurveyInput>,
		categoryCriteria: Partial<models.UserSurveyInput>,
		sort: string,
		max: number,
		offset: number,
	): Promise<ApolloQueryResult<responses.GetCsatReportDataResponse>> {
		return lastValueFrom(this.apollo.query<responses.GetCsatReportDataResponse>({
			query: queries.getCsatReportData,
			variables: { overallCriteria, categoryCriteria, sort, max, offset },
		}));
	}

	public getNpsReportData(criteria: Partial<models.UserSurveyInput>, sort: string, max: number, offset: number):
		Promise<ApolloQueryResult<responses.GetNpsReportDataResponse>> {
		return lastValueFrom(this.apollo.query<responses.GetNpsReportDataResponse>({
			query: queries.getNpsReportData,
			variables: { criteria, sort, max, offset },
		}));
	}

	public getRateClassEditPageData(utilityId: string, stateId: string):
		Promise<ApolloQueryResult<responses.RateClassEditPageData>> {
		return lastValueFrom(this.apollo.query<responses.RateClassEditPageData>({
			query: queries.getRateClassEditPageData,
			variables: { utilityId, stateId },
		}));
	}

	public getRateClassBeginPageData(utilityId: string): Promise<ApolloQueryResult<responses.RateClassBeginPageData>> {
		return lastValueFrom(this.apollo.query<responses.RateClassBeginPageData>({
			query: queries.getRateClassBeginPageData,
			variables: { utilityId },
		}));
	}

	public getUtilityHubPageData(): Promise<ApolloQueryResult<responses.UtilityHubPageData>> {
		return lastValueFrom(this.apollo.query<responses.UtilityHubPageData>({
			query: queries.getUtilityHubPageData,
		}));
	}

	public getUtilityHubEditPageData(utilityId: string, stateId: string):
		Promise<ApolloQueryResult<responses.UtilityHubEditPageData>> {
		return lastValueFrom(this.apollo.query<responses.UtilityHubEditPageData>({
			query: queries.getUtilityHubEditPageData,
			variables: { utilityId, stateId },
		}));
	}

	public getUtilityHubBeginPageData(utilityId: string): Promise<ApolloQueryResult<responses.UtilityHubBeginPageData>> {
		return lastValueFrom(this.apollo.query<responses.UtilityHubBeginPageData>({
			query: queries.getUtilityHubBeginPageData,
			variables: { utilityId },
		}));
	}

	public getBaseRateEditPageData(utilityId: string, serviceTypeId: string, stateId: string):
		Promise<ApolloQueryResult<responses.BaseRateEditPageData>> {
		return lastValueFrom(this.apollo.query<responses.BaseRateEditPageData>({
			query: queries.getBaseRateEditPageData,
			variables: { utilityId, serviceTypeId, stateId },
		}));
	}

	public getServiceFeeEditPageData(utilityId: string, serviceTypeId: string, stateId: string):
		Promise<ApolloQueryResult<responses.ServiceFeeEditPageData>> {
		return lastValueFrom(this.apollo.query<responses.ServiceFeeEditPageData>({
			query: queries.getServiceFeeEditPageData,
			variables: { utilityId, serviceTypeId, stateId },
		}));
	}

	public getBaseRateBeginPageData(utilityId: string): Promise<ApolloQueryResult<responses.BaseRateBeginPageData>> {
		return lastValueFrom(this.apollo.query<responses.BaseRateBeginPageData>({
			query: queries.getBaseRateBeginPageData,
			variables: { utilityId },
		}));
	}

	public getServiceFeeBeginPageData(utilityId: string): Promise<ApolloQueryResult<responses.ServiceFeeBeginPageData>> {
		return lastValueFrom(this.apollo.query<responses.ServiceFeeBeginPageData>({
			query: queries.getServiceFeeBeginPageData,
			variables: { utilityId },
		}));
	}

	public getRateSettingsPageData(supplierId: string): Promise<ApolloQueryResult<responses.RateSettingsPageData>> {
		return lastValueFrom(this.apollo.query<responses.RateSettingsPageData>({
			query: queries.getRateSettingsPageData,
			variables: { supplierId },
		}));
	}

	public getNavbarAgentData(agentId: string): Promise<ApolloQueryResult<responses.NavbarAgentDataResponse>> {
		return lastValueFrom(this.apollo.query<responses.NavbarAgentDataResponse>({
			query: queries.getNavbarAgentData,
			variables: { agentId },
		}));
	}

	public getNavbarCompanyData(companyId: string): Promise<ApolloQueryResult<responses.NavbarCompanyDataResponse>> {
		return lastValueFrom(this.apollo.query<responses.NavbarCompanyDataResponse>({
			query: queries.getNavbarCompanyData,
			variables: { companyId },
		}));
	}

	public getTermsOfService(): Promise<ApolloQueryResult<responses.TermsOfServiceData>> {
		return lastValueFrom(this.apollo.query<responses.TermsOfServiceData>({
			query: queries.getTermsOfService,
		}));
	}

	public getLogo(logoId: string): Promise<ApolloQueryResult<responses.LogoData>> {
		return lastValueFrom(this.apollo.query<responses.LogoData>({
			query: queries.getLogo,
			variables: { logoId },
		}));
	}

	public getLicensePlansAddEditPageData(agentId: string): Promise<ApolloQueryResult<responses.LicensePlansAddEditPageData>> {
		return lastValueFrom(this.apollo.query<responses.LicensePlansAddEditPageData>({
			query: queries.getLicensePlansAddEditPageData,
			variables: { agentId },
		}));
	}

	public getStripeAgentCustomerCardData(agentId: string): Promise<ApolloQueryResult<responses.StripeAgentCustomerCardData>> {
		return lastValueFrom(this.apollo.query<responses.StripeAgentCustomerCardData>({
			query: queries.getStripeAgentCustomerCardData,
			variables: { agentId },
		}));
	}

	public getStripeAgentCustomerInvoicesData(agentId: string): Promise<ApolloQueryResult<responses.StripeAgentCustomerInvoicesData>> {
		return lastValueFrom(this.apollo.query<responses.StripeAgentCustomerInvoicesData>({
			query: queries.getStripeAgentCustomerInvoicesData,
			variables: { agentId },
		}));
	}

	public getSecurityEditData(loggedInUserId: string, userId: string): Promise<ApolloQueryResult<responses.SecurityEditPageData>> {
		return lastValueFrom(this.apollo.query<responses.SecurityEditPageData>({
			query: queries.getSecurityEditPageData,
			variables: { loggedInUserId, userId },
		}));
	}

	public getTaskEditUsers(): Promise<ApolloQueryResult<responses.TaskEditUsersData>> {
		return lastValueFrom(this.apollo.query<responses.TaskEditUsersData>({
			query: queries.getTaskEditUsers,
		}));
	}

	public getContractLocationPageData(contractId: string): Promise<ApolloQueryResult<responses.ContractLocationPageData>> {
		return lastValueFrom(this.apollo.query<responses.ContractLocationPageData>({
			query: queries.getContractLocationPageData,
			variables: { contractId },
		}));
	}

	public getReportMenu(): Promise<ApolloQueryResult<responses.ReportData>> {
		return lastValueFrom(this.apollo.query<responses.ReportData>({
			query: queries.getReportMenu,
			variables: {
				criteria: { isActive: true },
				sort: 'name',
			},
		}));
	}

	public getContractLocationData(contractLocationId: string): Promise<ApolloQueryResult<responses.ContractLocationData>> {
		return lastValueFrom(this.apollo.query<responses.ContractLocationData>({
			query: queries.getContractLocationData,
			variables: { contractLocationId },
		}));
	}

	public getTaskEditAgentUsers(agentIds: string): Promise<ApolloQueryResult<responses.TaskEditAgentUsersData>> {
		return lastValueFrom(this.apollo.query<responses.TaskEditAgentUsersData>({
			query: queries.getTaskEditAgentUsers,
			variables: { agentIds },
		}));
	}

	public getLookupReportData(): Promise<ApolloQueryResult<responses.LookupReporterData>> {
		return lastValueFrom(this.apollo.query<responses.LookupReporterData>({
			query: queries.getLookupReporterData,
			variables: {},
		}));
	}

	public getTaskEditAgents(agentId: string): Promise<ApolloQueryResult<responses.TaskEditAgentsData>> {
		return lastValueFrom(this.apollo.query<responses.TaskEditAgentsData>({
			query: queries.getTaskEditAgents,
			variables: { agentId },
		}));
	}

	public getReportersData(criteria: any, sort: string, max: number, offset: number): Promise<ApolloQueryResult<responses.ReportersData>> {
		return lastValueFrom(this.apollo.query<responses.ReportersData>({
			query: queries.getReportersData,
			variables: { criteria, sort, max, offset },
		}));
	}

	public getTicketPageData(): Promise<ApolloQueryResult<responses.TicketPageData>> {
		return lastValueFrom(this.apollo.query<responses.TicketPageData>({
			query: queries.getTicketPageData,
		}));
	}

	public getTaskListDataSub(criteria: any, sort: string, max: number, offset: number):
		Observable<ApolloQueryResult<responses.TaskListData>> {
		return this.apollo.query<responses.TaskListData>({
			query: queries.getTaskListData,
			variables: { criteria, sort, max, offset },
		});
	}

	public getTaskListData(criteria: any, sort: string, max: number, offset: number):
		Promise<ApolloQueryResult<responses.TaskListData>> {
		return lastValueFrom(this.apollo.query<responses.TaskListData>({
			query: queries.getTaskListData,
			variables: { criteria, sort, max, offset },
		}));
	}

	public getTaskListDataCount(criteria: any, sort: string, max: number, offset: number):
		Promise<ApolloQueryResult<responses.TaskListData>> {
		return lastValueFrom(this.apollo.query<responses.TaskListData>({
			query: queries.getTaskListDataCount,
			variables: { criteria, sort, max, offset },
		}));
	}

	public getAlertListDataSub(criteria: any, sort: string, max: number, offset: number):
		Observable<ApolloQueryResult<responses.AlertListData>> {
		return this.apollo.query<responses.AlertListData>({
			query: queries.getAlertListData,
			variables: { criteria, sort, max, offset },
		});
	}

	public getAlertListDataCount(criteria: any, sort: string, max: number, offset: number):
		Promise<ApolloQueryResult<responses.AlertListData>> {
		return lastValueFrom(this.apollo.query<responses.AlertListData>({
			query: queries.getAlertListDataCount,
			variables: { criteria, sort, max, offset },
		}));
	}

	public getMarketUpdateListDataSub(sort: string, max: number, offset: number):
		Promise<ApolloQueryResult<responses.MarketUpdateListData>> {
		return lastValueFrom(this.apollo.query<responses.MarketUpdateListData>({
			query: queries.getMarketUpdateListData,
			variables: { sort, max, offset },
		}));
	}

	public getMarketUpdateLog(id: number): Promise<ApolloQueryResult<responses.MarketUpdateLogData>> {
		return lastValueFrom(this.apollo.query<responses.MarketUpdateLogData>({
			query: queries.getMarketUpdateLog,
			variables: { id },
		}));
	}

	public getLatestMarketUpdateLog(): Promise<ApolloQueryResult<responses.LatestMarketUpdateLogData>> {
		return lastValueFrom(this.apollo.query<responses.LatestMarketUpdateLogData>({
			query: queries.getLatestMarketUpdateLog,
		}));
	}

	public getSingleTicketData(id: number): Promise<ApolloQueryResult<responses.SingleTicketData>> {
		return lastValueFrom(this.apollo.query<responses.SingleTicketData>({
			query: queries.getSingleTicketPageData,
			variables: { id },
		}));
	}

	public getViewTicketDashboardDetailData(id: number): Promise<ApolloQueryResult<responses.ViewTicketDashboardDetailData>> {
		return lastValueFrom(this.apollo.query<responses.ViewTicketDashboardDetailData>({
			query: queries.getViewTicketDashboardDetailData,
			variables: { id },
		}));
	}

	public getTicketDetailPageData(): Promise<ApolloQueryResult<responses.TicketDetailPageData>> {
		return lastValueFrom(this.apollo.query<responses.TicketDetailPageData>({
			query: queries.getTicketDetailPageData,
		}));
	}

	public viewTicketDashboardSub(criteria: any, sort: string, max: number, offset: number):
		Observable<ApolloQueryResult<responses.ViewTicketData>> {
		return this.apollo.query<responses.ViewTicketData>({
			query: queries.viewTicketDashboard,
			variables: { criteria, sort, max, offset },
		});
	}

	public getTicketData(criteria: any, sort: string, max: number, offset: number): Promise<ApolloQueryResult<responses.TicketData>> {
		return lastValueFrom(this.apollo.query<responses.TicketData>({
			query: queries.getTicketData,
			variables: { criteria, sort, max, offset },
		}));
	}

	public getDocumentBase64(documentId: string, criteria: any): Promise<ApolloQueryResult<responses.DocumentBase64Response>> {
		return lastValueFrom(this.apollo.query<responses.DocumentBase64Response>({
			query: queries.getDocumentBase64,
			variables: { documentId, criteria },
		}));
	}

	public getAttachmentEditData(attachmentId: string): Promise<ApolloQueryResult<responses.AttachmentEditData>> {
		return lastValueFrom(this.apollo.query<responses.AttachmentEditData>({
			query: queries.getAttachmentEditData,
			variables: { attachmentId },
		}));
	}

	public getServiceAddData(criteria: any, sort: string): Promise<ApolloQueryResult<responses.ServiceAddData>> {
		return lastValueFrom(this.apollo.query<responses.ServiceAddData>({
			query: queries.getServiceAddData,
			variables: { criteria, sort },
		}));
	}

	public getOffPeakElectricityStatistics(marginalPricesRequest: models.MarginalPricesStatisticsRequest):
		Promise<ApolloQueryResult<responses.ChartStatisticsData>> {
		return lastValueFrom(this.apollo.query<responses.ChartStatisticsData>({
			query: queries.getOffPeakElectricityMarginalPricingStatistics,
			variables: { marginalPricesRequest },
		}));
	}

	public getElectricityStatistics(marginalPricesRequest: models.MarginalPricesStatisticsRequest):
		Promise<ApolloQueryResult<responses.ChartStatisticsData>> {
		return lastValueFrom(this.apollo.query<responses.ChartStatisticsData>({
			query: queries.getElectricityMarginalPricingStatistics,
			variables: { marginalPricesRequest },
		}));
	}

	public getLayeredPurchasingEnergyElectricityStatistics(
		marginalPricesRequest: models.MarginalPricesStatisticsRequest,
		marginalPricesCongestionRequest: models.MarginalPricesStatisticsCongestionRequest
	):
		Promise<ApolloQueryResult<responses.ChartStatisticsData>> {
		return lastValueFrom(this.apollo.query<responses.ChartStatisticsData>({
			query: queries.getLayeredPurchasingEnergyElectricityStatistics,
			variables: { marginalPricesRequest, marginalPricesCongestionRequest },
		}));
	}


	public getEducationAgentData(agentId: string): Promise<ApolloQueryResult<responses.EducationAgentData>> {
		return lastValueFrom(this.apollo.query<responses.EducationAgentData>({
			query: queries.getEducationAgentData,
			variables: { agentId },
		}));
	}

	public getOffPeakGasStatistics(
		marginalPricesRequest: models.MarginalPricesStatisticsRequest,
	): Promise<ApolloQueryResult<responses.ChartStatisticsData>> {
		return lastValueFrom(this.apollo
			.query<responses.ChartStatisticsData>({
				query: queries.getOffPeakGasMarginalPricingStatistics,
				variables: {
					nymexMarginalPricesRequest: {
						...marginalPricesRequest,
						component: 'NYMEX',
						subCategory: 'NYMEX',
					},
					supplyMarginalPricesRequest: {
						...marginalPricesRequest,
						component: 'Supply',
						subCategory: 'Supply',
					},
					basisMarginalPricesRequest: {
						...marginalPricesRequest,
						component: 'Basis',
						subCategory: 'Basis',
					},
				},
			}));
	}

	public getGasStatistics(
		marginalPricesRequest: models.MarginalPricesStatisticsRequest,
	): Promise<ApolloQueryResult<responses.ChartStatisticsData>> {
		return lastValueFrom(this.apollo
			.query<responses.ChartStatisticsData>({
				query: queries.getGasMarginalPricingStatistics,
				variables: {
					nymexMarginalPricesRequest: {
						...marginalPricesRequest,
						component: 'NYMEX',
						subCategory: 'NYMEX',
					},
					supplyMarginalPricesRequest: {
						...marginalPricesRequest,
						component: 'Supply',
						subCategory: 'Supply',
					},
					basisMarginalPricesRequest: {
						...marginalPricesRequest,
						component: 'Basis',
						subCategory: 'Basis',
					},
				},
			}));
	}

	public getLayeredPurchasingNymexGasStatistics(
		marginalPricesRequest: models.MarginalPricesStatisticsRequest,
	): Promise<ApolloQueryResult<responses.ChartStatisticsData>> {
		return lastValueFrom(this.apollo
			.query<responses.ChartStatisticsData>({
				query: queries.getLayeredPurchasingNymexGasStatistics,
				variables: {
					nymexMarginalPricesRequest: {
						...marginalPricesRequest,
						component: 'NYMEX',
						subCategory: 'NYMEX',
					},
				},
			}));
	}

	public getNymexGasStatistics(
		marginalPricesRequest: models.MarginalPricesStatisticsRequest,
	): Promise<ApolloQueryResult<responses.ChartStatisticsData>> {
		return lastValueFrom(this.apollo
			.query<responses.ChartStatisticsData>({
				query: queries.getNymexGasMarginalPricingStatistics,
				variables: {
					nymexMarginalPricesRequest: {
						...marginalPricesRequest,
						component: 'NYMEX',
						subCategory: 'NYMEX',
					},
				},
			}));
	}

	public getMarginalPricesNymexIntradayStatistics(): Promise<ApolloQueryResult<responses.ChartNymexData>> {
		return lastValueFrom(this.apollo.query<responses.ChartNymexData>({
			query: queries.getMarginalPricesNymexIntradayStatistics,
		}));
	}

	public getDocumentTemplatesData(): Promise<ApolloQueryResult<responses.DocumentTemplatesData>> {
		return lastValueFrom(this.apollo.query<responses.DocumentTemplatesData>({
			query: queries.getDocumentTemplatesData,
		}));
	}

	public getMarketOptions(marketType: 'matrix' | 'residential' | 'auction'): Promise<ApolloQueryResult<responses.MarketOptionData>> {
		return lastValueFrom(this.apollo.query<responses.MarketOptionData>({
			query: queries.getMarketOptions,
			variables: {
				criteria: { marketType },
			},
		}));
	}

	public exportGroupedMarkets(
		stateId: string,
		utilityId: string,
		serviceTypeId: string,
		marketType: 'matrix' | 'residential' | 'auction',
	): Promise<ApolloQueryResult<responses.ExportGroupedMarkets>> {
		return lastValueFrom(this.apollo.query<responses.ExportGroupedMarkets>({
			query: queries.exportGroupedMarkets,
			variables: {
				criteria: { stateId, utilityId, serviceTypeId, marketType },
			},
		}));
	}

	public exportActualPayables(criteria: any): Promise<ApolloQueryResult<responses.ExportActualPayables>> {
		return lastValueFrom(this.apollo.query<responses.ExportActualPayables>({
			query: queries.exportActualPayables,
			variables: { criteria },
		}));
	}

	public exportActualReceivables(criteria: any): Promise<ApolloQueryResult<responses.ExportActualReceivables>> {
		return lastValueFrom(this.apollo.query<responses.ExportActualReceivables>({
			query: queries.exportActualReceivables,
			variables: { criteria },
		}));
	}

	public getGroupedMarketsUtility(
		stateId: string,
		utilityId: string,
		serviceTypeId: string,
		marketType: 'matrix' | 'residential' | 'auction',
	): Promise<ApolloQueryResult<responses.GroupedMarketUtilityData>> {
		return lastValueFrom(this.apollo.query<responses.GroupedMarketUtilityData>({
			query: queries.getGroupedMarketsUtility,
			variables: {
				criteria: { stateId, utilityId, serviceTypeId, marketType },
			},
		}));
	}

	public getGroupedMarketsState(
		marketType: 'matrix' | 'residential' | 'auction',
	): Promise<ApolloQueryResult<responses.GroupedMarketStateData>> {
		return lastValueFrom(this.apollo.query<responses.GroupedMarketStateData>({
			query: queries.getGroupedMarketsState,
			variables: {
				criteria: { marketType },
			},
		}));
	}

	public getContractSupplierCompensationReportData(supplierId: string, contractDate: string, contractId: string):
		Promise<ApolloQueryResult<responses.ContractSupplierCompensationReportData>> {
		return lastValueFrom(this.apollo.query<responses.ContractSupplierCompensationReportData>({
			query: queries.getContractSupplierCompensationReportData,
			variables: {
				reportId: '8a25800564cc09470164cc1a5a970000', criteria: {
					supplierId,
					contractDate,
					contractId,
				},
			},
		}));
	}

	public getSupplierEnrollmentSettings(supplierId: string,
		supplierRateSettingsCriteria: any,
		securityQuestionsCriteria: any,
		esignFontsCriteria: any): Promise<ApolloQueryResult<responses.SupplierEnrollmentSettings>> {
		return lastValueFrom(this.apollo.query<responses.SupplierEnrollmentSettings>({
			query: queries.getSupplierEnrollmentSettings,
			variables: {
				supplierId,
				supplierRateSettingsCriteria,
				securityQuestionsCriteria,
				esignFontsCriteria,
			},
		}));
	}

	public getUnit(serviceTypeId: string, stateId: string, utilityId: string): Promise<ApolloQueryResult<responses.UnitData>> {
		return lastValueFrom(this.apollo.query<responses.UnitData>({
			query: queries.getUnit,
			variables: { serviceTypeId, stateId, utilityId },
		}));
	}

	public getContractMarketData(contractId: string):
		Promise<ApolloQueryResult<responses.GetContractMarketData>> {
		return lastValueFrom(this.apollo.query<responses.GetContractMarketData>({
			query: queries.getContractMarketData,
			variables: { contractId },
		}));
	}

	public checkLayeredPurchasing(contractId: string): Promise<ApolloQueryResult<responses.CheckLayeredPurchasing>> {
		return lastValueFrom(this.apollo.query<responses.CheckLayeredPurchasing>({
			query: queries.checkLayeredPurchasing,
			variables: { contractId },
		}));
	}

	public getContractPerformanceData(contractId: string):
		Promise<ApolloQueryResult<responses.GetContractPerformanceData>> {
		return lastValueFrom(this.apollo.query<responses.GetContractPerformanceData>({
			query: queries.getContractPerformanceData,
			variables: { contractId },
		}));
	}

	/**
	 * Note: For performance reasons, `contract.locations` only includes a maxumum of two of the contract locations,
	 * and `contract.commissionPayablesSchedules` / `contract.agent.stats` are not included.
	 * Get the remaining contract properties with `getContractInfoLocationsData`.
	 */
	public getContractInfoData(contractId: string): Promise<ApolloQueryResult<responses.ContractInfoData>> {
		return lastValueFrom(this.apollo.query<responses.ContractInfoData>({
			query: queries.getContractInfoData,
			variables: { contractId },
		}));
	}

	public getContractInfo(contractId: string): Promise<ApolloQueryResult<responses.RfqSessionInfoData>> {
		return lastValueFrom(this.apollo.query<responses.RfqSessionInfoData>({
			query: queries.contractInfoData,
			variables: { contractId },
		}));
	}

	public getContractCommissionPurchaseHistory(id: number) {
		return lastValueFrom(this.apollo.query<responses.ContractCommissionPurchaseHistory>({
			query: queries.getContractCommissionPurchaseHistory,
			variables: { id },
		}));
	}

	public getContractEsignDocuments(contractId: string): Promise<ApolloQueryResult<responses.ContractEsignDocumentsData>> {
		return lastValueFrom(this.apollo.query<responses.ContractEsignDocumentsData>({
			query: queries.getContractEsignDocuments,
			variables: { contractId },
		}));
	}

	public getMarketTrendCharts(
		startDate: string, endDate: string,
		serviceTypeId: string, utilityId: string, stateId: string, rateClass: string,
		usage: number, demand: number,
	): Promise<ApolloQueryResult<responses.ChartRetailData>> {
		return lastValueFrom(this.apollo.query<responses.ChartRetailData>({
			query: queries.getMarketTrendCharts,
			variables: {
				startDate, endDate,
				serviceTypeId, utilityId, stateId, rateClass,
				usage, demand,
			},
		}));
	}

	public getChartRetailUtilities(serviceTypeId: string): Promise<ApolloQueryResult<responses.ChartRetailUtilitiesData>> {
		return lastValueFrom(this.apollo.query<responses.ChartRetailUtilitiesData>({
			query: queries.getChartRetailUtilities,
			variables: { serviceTypeId },
		}));
	}

	public getChartUtilitiesWithProperties(serviceTypeId: string, chartType: string):
		Promise<ApolloQueryResult<responses.ChartUtilitiesWithProperties>> {
		return lastValueFrom(this.apollo.query<responses.ChartUtilitiesWithProperties>({
			query: queries.getChartUtilitiesWithProperties,
			variables: { serviceTypeId, chartType },
		}));
	}

	public getRfqSessionInfo(criteria: any): Promise<ApolloQueryResult<responses.RfqSessionInfoData>> {
		return lastValueFrom(this.apollo.query<responses.RfqSessionInfoData>({
			query: queries.rfqSessionInfoData,
			variables: criteria,
		}));
	}

	public getRfqSessionContract(contractId: string): Promise<ApolloQueryResult<responses.RfqSessionContractData>> {
		return lastValueFrom(this.apollo.query<responses.RfqSessionContractData>({
			query: queries.rfqSessionContract,
			variables: { contractId },
		}));
	}

	public getAuctionInProgressModalData(rfqSessionId: string, serviceTypeId: string, stateId: string, dateGTE: string):
		Promise<ApolloQueryResult<responses.AuctionInProgressModalData>> {
		return lastValueFrom(this.apollo.query<responses.AuctionInProgressModalData>({
			query: queries.auctionInProgressModalData,
			variables: { rfqSessionId, serviceTypeId, stateId, dateGTE },
		}));
	}

	public getRefreshAuctionInProgressModalData(rfqSessionId: string):
		Promise<ApolloQueryResult<responses.RefreshAuctionInProgressModalData>> {
		return lastValueFrom(this.apollo.query<responses.RefreshAuctionInProgressModalData>({
			query: queries.refreshAuctionInProgressModalData,
			variables: { rfqSessionId },
		}));
	}

	public getAvailableSuppliers(serviceTypeId: string, stateId: string, utilityId: string,
		annualUsage: number, supplierId: string, effectiveDate: string):
		Promise<ApolloQueryResult<responses.AvailableSuppliersData>> {
		return lastValueFrom(this.apollo.query<responses.AvailableSuppliersData>({
			query: queries.availableSuppliers,
			variables: { serviceTypeId, stateId, utilityId, annualUsage, supplierId, effectiveDate },
		}));
	}

	public getChartRetailFormData(): Promise<ApolloQueryResult<responses.ChartRetailFormData>> {
		return lastValueFrom(this.apollo.query<responses.ChartRetailFormData>({
			query: queries.getChartRetailFormData,
			variables: {},
		}));
	}

	public getChartFormData(serviceTypeId: string, chartType: string): Promise<ApolloQueryResult<responses.ChartFormData>> {
		return lastValueFrom(this.apollo.query<responses.ChartFormData>({
			query: queries.getChartFormData,
			variables: { serviceTypeId, chartType },
		}));
	}

	public getAuctionModalData(contractId: string, serviceTypeId: string, stateId: string, dateGTE: string):
		Promise<ApolloQueryResult<responses.AuctionModalData>> {
		return lastValueFrom(this.apollo.query<responses.AuctionModalData>({
			query: queries.auctionModalData,
			variables: { contractId, serviceTypeId, stateId, dateGTE },
		}));
	}

	public getServiceTypeUnit(serviceTypeId: string, stateId: string, utilityId: string):
		Promise<ApolloQueryResult<responses.ServiceTypeUnitData>> {
		return lastValueFrom(this.apollo.query<responses.ServiceTypeUnitData>({
			query: queries.serviceTypeUnitData,
			variables: { serviceTypeId, stateId, utilityId },
		}));
	}

	public viewCustomerEnrollmentDashboard(criteria: any, sort: string, max: number, offset: number):
		Promise<ApolloQueryResult<responses.ViewCustomerEnrollmentDashboardData>> {
		return lastValueFrom(this.apollo.query<responses.ViewCustomerEnrollmentDashboardData>({
			query: queries.viewCustomerEnrollmentDashboard,
			variables: { criteria, sort, max, offset },
		}));
	}

	public viewContractDashboard(criteria: any, sort: string, max: number, offset: number):
		Promise<ApolloQueryResult<responses.CustomView1ContractsData>> {
		return lastValueFrom(this.apollo.query<responses.CustomView1ContractsData>({
			query: queries.viewContractDashboard,
			variables: { criteria, sort, max, offset },
		}));
	}

	public viewOperationsContractActivityDashboard(criteria: any, sort: string, max: number, offset: number):
		Promise<ApolloQueryResult<responses.ViewOperationsContractActivityDashboardData>> {
		return lastValueFrom(this.apollo.query<responses.ViewOperationsContractActivityDashboardData>({
			query: queries.viewOperationsContractActivityDashboard,
			variables: { criteria, sort, max, offset },
		}));
	}

	public getUsageCharts(locations: models.ContractLocation[]): Promise<ApolloQueryResult<responses.UsageChartData>> {
		return lastValueFrom(this.apollo.query<responses.UsageChartData>({
			query: queries.usageCharts,
			variables: { locations },
		}));
	}

	public getProposalModalData(locations: models.ContractLocation[]): Promise<ApolloQueryResult<responses.ProposalModalData>> {
		return lastValueFrom(this.apollo.query<responses.ProposalModalData>({
			query: queries.getProposalModalData,
			variables: { locations },
		}));
	}

	public rerunUsage(contractLocationId: string): Promise<FetchResult<responses.RequestUsageResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.RequestUsageResponse>({
			mutation: mutations.requestUsage,
			variables: { contractLocationId },
		}));
	}

	public addressValidation(address: models.AddressValidationRequest): Promise<ApolloQueryResult<responses.AddressValidationData>> {
		return lastValueFrom(this.apollo.query<responses.AddressValidationData>({
			query: queries.addressValidation,
			variables: { address },
		}));
	}

	public utilityAccountNumValidation(utilityAccountNum: models.UtilityAccountNumValidationRequest):
		Promise<ApolloQueryResult<responses.UtilityAccountNumValidationData>> {
		return lastValueFrom(this.apollo.query<responses.UtilityAccountNumValidationData>({
			query: queries.utilityAccountNumValidation,
			variables: { utilityAccountNum },
		}));
	}

	public utilityAccountNumRenewalValidation(utilityAccountNum: models.UtilityAccountNumRenewalValidationRequest):
		Promise<ApolloQueryResult<responses.UtilityAccountNumRenewalValidationData>> {
		return lastValueFrom(this.apollo.query<responses.UtilityAccountNumRenewalValidationData>({
			query: queries.utilityAccountNumRenewalValidation,
			variables: { utilityAccountNum },
		}));
	}

	public getTexasAccountNumber(address: string): Promise<ApolloQueryResult<responses.AddressSearchResultData>> {
		return lastValueFrom(this.apollo.query<responses.AddressSearchResultData>({
			query: queries.texasAccountNumber,
			variables: { address },
		}));
	}

	public searchCustomerSub(dbaName: string, city: string, stateId: string, zipCode: string):
		Observable<ApolloQueryResult<responses.SearchCustomerData>> {
		return this.apollo.query<responses.SearchCustomerData>({
			query: queries.customerSearch,
			variables: { dbaName, city, stateId, zipCode },
		});
	}

	public getSavingsData(contract: models.Contract, baseRate: number): Promise<ApolloQueryResult<responses.SavingsData>> {
		return lastValueFrom(this.apollo.query<responses.SavingsData>({
			query: queries.getSavingsData,
			variables: { contract, baseRate },
		}));
	}

	public getSavingsContracts(contractId: string): Promise<ApolloQueryResult<responses.SavingsContractData>> {
		return lastValueFrom(this.apollo.query<responses.SavingsContractData>({
			query: queries.getSavingsContracts,
			variables: { contractId },
		}));
	}

	public getContractServiceType(contractId: string): Promise<ApolloQueryResult<responses.ContractServiceTypeData>> {
		return lastValueFrom(this.apollo.query<responses.ContractServiceTypeData>({
			query: queries.getContractServiceType,
			variables: { contractId },
		}));
	}

	public getRates(criteria: any, max = 0): Promise<ApolloQueryResult<responses.RateData>> {
		return lastValueFrom(this.apollo.query<responses.RateData>({
			query: queries.getRates,
			variables: { criteria, max },
		}));
	}

	public getCalendarData(criteria: any): Promise<ApolloQueryResult<responses.RateCalendarData>> {
		return lastValueFrom(this.apollo.query<responses.RateCalendarData>({
			query: queries.getCalendarData,
			variables: { criteria },
		}));
	}

	public getUtilitiesWithProperties(stateId: string, serviceTypeId: string, sampleAnnualUsage?: number, samplePlc?: number):
		Promise<ApolloQueryResult<responses.UtilityData>> {
		return lastValueFrom(this.apollo.query<responses.UtilityData>({
			query: queries.getUtilitiesWithProperties,
			variables: { stateId, serviceTypeId, sampleAnnualUsage, samplePlc },
		}));
	}

	public getUtilitiesWithPropertiesForMapping(stateId: string): Promise<ApolloQueryResult<responses.GetBulkUploadModalData>> {
		return lastValueFrom(this.apollo.query<responses.UtilityData>({
			query: queries.getUtilitiesWithPropertiesForMapping,
			variables: { stateId },
		}));
	}

	public getBulkUploadModalData(stateId: string, serviceTypeId: string): Promise<ApolloQueryResult<responses.GetBulkUploadModalData>> {
		return lastValueFrom(this.apollo.query<responses.GetBulkUploadModalData>({
			query: queries.getBulkUploadModalData,
			variables: { stateId, serviceTypeId },
		}));
	}

	public getAllTimezones(): Promise<ApolloQueryResult<responses.TimezonesResponse>> {
		return lastValueFrom(this.apollo.query<responses.TimezonesResponse>({
			query: queries.getAllTimezones,
		}));
	}

	public getZipCodeWithDefaultProperties(zipCode: string, serviceTypeId: string): Promise<ApolloQueryResult<responses.ZipCodeData>> {
		return lastValueFrom(this.apollo.query<responses.ZipCodeData>({
			query: queries.getZipCodeWithDefaultProperties,
			variables: { zipCode, serviceTypeId },
		}));
	}

	public getErcotZipCodeAddresses(zipCode: string): Promise<ApolloQueryResult<responses.ErcotZipCodeAddressData>> {
		return lastValueFrom(this.apollo.query<responses.ErcotZipCodeAddressData>({
			query: queries.getErcotZipCodeAddresses,
			variables: { zipCode },
		}));
	}

	public getQuoteVerifyData(criteria: any): Promise<ApolloQueryResult<responses.QuoteVerifyData>> {
		return lastValueFrom(this.apollo.query<responses.QuoteVerifyData>({
			query: queries.getQuoteVerifyData,
			variables: {
				supplierId: criteria.supplierId,
				signUpCriteria: {
					rateMatrixId: criteria.rateMatrixId,
					email: criteria.email,
				},
				switchTypeCriteria: {
					serviceTypeId: criteria.serviceTypeId,
					stateId: criteria.stateId,
					utilityId: criteria.utilityId,
					rateClass: criteria.rateClass,
					utilityAccountNum: criteria.utilityAccountNum,
					effectiveDate: criteria.effectiveDate,
				},
			},
		}));
	}

	public getQuoteVerifyRFQData(criteria: any): Promise<ApolloQueryResult<responses.QuoteVerifyRFQData>> {
		return lastValueFrom(this.apollo.query<responses.QuoteVerifyRFQData>({
			query: queries.getQuoteVerifyRFQData,
			variables: {
				criteria: {
					stateId: criteria.stateId,
					utilityId: criteria.utilityId,
					serviceTypeId: criteria.serviceTypeId,
					rateClass: criteria.rateClass,
					utilityAccountNum: criteria.utilityAccountNum,
					effectiveDate: criteria.effectiveDate,
				},
			},
		}));
	}

	public getQuoteInfoData(supplierId: string, zipCode: string): Promise<ApolloQueryResult<responses.QuoteInfoData>> {
		return lastValueFrom(this.apollo.query<responses.QuoteInfoData>({
			query: queries.getQuoteInfoData,
			variables: { supplierId, zipCode },
		}));
	}

	public getMarginAdjustments(serviceTypeId: string, stateId: string, utilityId: string, margin: number):
		Promise<ApolloQueryResult<responses.RatesQuotesData>> {
		return lastValueFrom(this.apollo.query<responses.RatesQuotesData>({
			query: queries.getMarginAdjustments,
			variables: { serviceTypeId, stateId, utilityId, margin },
		}));
	}

	public getSessionRatesData(contractId: string, rfqSessionRateCriteria: models.RateInput, rateCriteria: any):
		Promise<ApolloQueryResult<responses.SessionRateData>> {
		return lastValueFrom(this.apollo.query<responses.SessionRateData>({
			query: queries.getSessionRatesData,
			variables: {
				contractId,
				rfqSessionRateCriteria,
				rateCriteria,
			},
		}));
	}

	public getRatesQuotesContractSessionData(contractId: string, rfqSessionId: string):
		Promise<ApolloQueryResult<responses.RatesQuotesContractSessionData>> {
		return lastValueFrom(this.apollo.query<responses.RatesQuotesContractSessionData>({
			query: queries.getRatesQuotesContractSessionData,
			variables: { contractId, rfqSessionId },
		}));
	}

	public getRatesQuotesEclData(accountUuid: string, agentId: string):
		Promise<ApolloQueryResult<responses.RatesQuotesEclsData>> {
		return lastValueFrom(this.apollo.query<responses.RatesQuotesEclsData>({
			query: queries.getRatesQuotesEclsData,
			variables: { accountUuid, agentId },
		}));
	}

	public getRatesQuotesLeadData(leadId: number, agentId: string):
		Promise<ApolloQueryResult<responses.RatesQuotesLeadData>> {
		return lastValueFrom(this.apollo.query<responses.RatesQuotesLeadData>({
			query: queries.getRatesQuotesLeadData,
			variables: { leadId, agentId },
		}));
	}

	public getRatesQuotesContractAddServiceData(contractId: string):
		Promise<ApolloQueryResult<responses.RatesQuotesContractData>> {
		return lastValueFrom(this.apollo.query<responses.RatesQuotesContractData>({
			query: queries.getRatesQuotesContractAddServiceData,
			variables: { contractId },
		}));
	}

	public getRatesQuotesContractData(contractId: string):
		Promise<ApolloQueryResult<responses.RatesQuotesContractData>> {
		return lastValueFrom(this.apollo.query<responses.RatesQuotesContractData>({
			query: queries.getRatesQuotesContractData,
			variables: { contractId },
		}));
	}

	public getBeginAgentData(agentId: string): Promise<ApolloQueryResult<responses.BeginAgentData>> {
		return lastValueFrom(this.apollo.query<responses.BeginAgentData>({
			query: queries.getBeginAgentData,
			variables: { agentId },
		}));
	}

	public getBeginData(promoCode: string): Promise<ApolloQueryResult<responses.BeginData>> {
		return lastValueFrom(this.apollo.query<responses.BeginData>({
			query: queries.getBeginData,
			variables: { promoCode },
		}));
	}

	public getBeginCountryData(stateId: string): Promise<ApolloQueryResult<responses.BeginCountryData>> {
		return lastValueFrom(this.apollo.query<responses.BeginCountryData>({
			query: queries.getBeginCountryData,
			variables: { stateId },
		}));
	}

	public getRatesQuotesPageData(commissionPayPlanDate: string): Promise<ApolloQueryResult<responses.RatesQuotesPageData>> {
		return lastValueFrom(this.apollo.query<responses.RatesQuotesPageData>({
			query: queries.getRatesQuotesPageData,
			variables: { contractDate: commissionPayPlanDate },
		}));
	}

	public getRatesQuotesPageAgentData(agentId: string): Promise<ApolloQueryResult<responses.RatesQuotesPageAgentData>> {
		return lastValueFrom(this.apollo.query<responses.RatesQuotesPageAgentData>({
			query: queries.getRatesQuotesPageAgentData,
			variables: { agentId },
		}));
	}

	public getUserEditCustomerData(customerId: string): Promise<ApolloQueryResult<responses.UserEditCustomerData>> {
		return lastValueFrom(this.apollo.query<responses.UserEditCustomerData>({
			query: queries.getUserEditCustomerData,
			variables: { customerId },
		}));
	}

	public getUserEditAgentData(agentId: string): Promise<ApolloQueryResult<responses.UserEditAgentData>> {
		return lastValueFrom(this.apollo.query<responses.UserEditAgentData>({
			query: queries.getUserEditAgentData,
			variables: { agentId },
		}));
	}

	public getUserEditSupplierData(supplierId: string): Promise<ApolloQueryResult<responses.UserEditSupplierData>> {
		return lastValueFrom(this.apollo.query<responses.UserEditSupplierData>({
			query: queries.getUserEditSupplierData,
			variables: { supplierId },
		}));
	}

	public getUserEditRfqSettingsData(): Promise<ApolloQueryResult<responses.UserEditRfqSettingsData>> {
		return lastValueFrom(this.apollo.query<responses.UserEditRfqSettingsData>({
			query: queries.getUserEditRfqSettingsData,
		}));
	}

	public getUserEditUserData(userId: string): Promise<ApolloQueryResult<responses.UserEditUserData>> {
		return lastValueFrom(this.apollo.query<responses.UserEditUserData>({
			query: queries.getUserEditUserData,
			variables: { userId },
		}));
	}

	public getUserEditUserDataRfqSettings(userId: string): Promise<ApolloQueryResult<responses.UserEditUserDataRfqSettings>> {
		return lastValueFrom(this.apollo.query<responses.UserEditUserDataRfqSettings>({
			query: queries.getUserEditUserDataRfqSettings,
			variables: { userId },
		}));
	}

	public getUserEditUserDataApiKeys(userId: string): Promise<ApolloQueryResult<responses.UserEditUserData>> {
		return lastValueFrom(this.apollo.query<responses.UserEditUserData>({
			query: queries.getUserEditUserDataApiKeys,
			variables: { userId },
		}));
	}

	public getUserAddEditPageData(): Promise<ApolloQueryResult<responses.UserAddEditPageData>> {
		return lastValueFrom(this.apollo.query<responses.UserAddEditPageData>({
			query: queries.getUserAddEditPageData,
		}));
	}

	public getServiceTypeSuppliers(serviceTypeId: string): Promise<ApolloQueryResult<responses.ServiceTypeData>> {
		return lastValueFrom(this.apollo.query<responses.ServiceTypeData>({
			query: queries.getServiceTypeSuppliers,
			variables: { serviceTypeId },
		}));
	}

	public getContractEditAgentChanged(agentId: string): Promise<ApolloQueryResult<responses.ContractEditAgentChangedData>> {
		return lastValueFrom(this.apollo.query<responses.ContractEditAgentChangedData>({
			query: queries.getContractEditAgentChanged,
			variables: { agentId },
		}));
	}

	public getContractEditData(contractId: string): Promise<ApolloQueryResult<responses.ContractEditData>> {
		return lastValueFrom(this.apollo.query<responses.ContractEditData>({
			query: queries.getContractEditData,
			variables: { contractId },
		}));
	}

	public getSwitchTypes(
		serviceTypeId: string, stateId: string, supplierId: string, utilityId: string,
		rateClass: string, utilityAccountNum: string, effectiveDate: string, planId: string
	): Promise<ApolloQueryResult<responses.SwitchTypesData>> {
		return lastValueFrom(this.apollo.query<responses.SwitchTypesData>({
			query: queries.getSwitchTypes,
			variables: {
				supplierId,
				criteria: {
					serviceTypeId,
					stateId,
					utilityId,
					rateClass,
					utilityAccountNum,
					effectiveDate,
					planId,
				},
			},
		}));
	}

	public getServiceTypeEditData(serviceTypeId: string): Promise<ApolloQueryResult<responses.ServiceTypeData>> {
		return lastValueFrom(this.apollo.query<responses.ServiceTypeData>({
			query: queries.getServiceTypeEditData,
			variables: { serviceTypeId },
		}));
	}

	public getServiceTypesListData(sort: string): Promise<ApolloQueryResult<responses.ServiceTypesData>> {
		return lastValueFrom(this.apollo.query<responses.ServiceTypesData>({
			query: queries.getServiceTypesListData,
			variables: { sort },
		}));
	}

	public getAttachmentTypeEditData(attachmentTypeId: string): Promise<ApolloQueryResult<responses.AttachmentTypeData>> {
		return lastValueFrom(this.apollo.query<responses.AttachmentTypeData>({
			query: queries.getAttachmentTypeEditData,
			variables: { attachmentTypeId },
		}));
	}

	public getAttachmentData(attachmentId: string): Promise<ApolloQueryResult<responses.AttachmentData>> {
		return lastValueFrom(this.apollo.query<responses.AttachmentData>({
			query: queries.getAttachmentData,
			variables: { attachmentId },
		}));
	}

	public getContractAttachmentAddModalData(attachmentCriteria: any, documentCriteria: any):
		Promise<ApolloQueryResult<responses.GetContractAttachmentAddModalData>> {
		return lastValueFrom(this.apollo.query<responses.GetContractAttachmentAddModalData>({
			query: queries.getContractAttachmentAddModalData,
			variables: { attachmentCriteria, documentCriteria },
		}));
	}

	public getAttachmentTypesListData(criteria: any): Promise<ApolloQueryResult<responses.AttachmentTypesData>> {
		return lastValueFrom(this.apollo.query<responses.AttachmentTypesData>({
			query: queries.getAttachmentTypesListData,
			variables: { criteria },
		}));
	}

	public getCompanyAddEditPageData(): Promise<ApolloQueryResult<responses.CompanyAddEditPageData>> {
		return lastValueFrom(this.apollo.query<responses.CompanyAddEditPageData>({
			query: queries.getCompanyAddEditPageData,
		}));
	}

	public getCompanyEditData(companyId: string): Promise<ApolloQueryResult<responses.CompanyEditData>> {
		return lastValueFrom(this.apollo.query<responses.CompanyEditData>({
			query: queries.getCompanyEditData,
			variables: { companyId },
		}));
	}

	public getDefaultCompanyEditData(companyId: string): Promise<ApolloQueryResult<responses.CompanyEditData>> {
		return lastValueFrom(this.apollo.query<responses.CompanyEditData>({
			query: queries.getDefaultCompanyEditData,
			variables: { companyId },
		}));
	}

	public getCompanyListData(sort: string): Promise<ApolloQueryResult<responses.CompaniesData>> {
		return lastValueFrom(this.apollo.query<responses.CompaniesData>({
			query: queries.getCompanyListData,
			variables: { sort },
		}));
	}

	public getCompanyEmailData(companyId: string): Promise<ApolloQueryResult<responses.CompanyEmailData>> {
		return lastValueFrom(this.apollo.query<responses.CompanyEmailData>({
			query: queries.getCompanyEmailData,
			variables: { companyId },
		}));
	}

	public getCompanyCalendlySettingsData(companyId: string): Promise<ApolloQueryResult<responses.CompanyCalendlySettingsData>> {
		return lastValueFrom(this.apollo.query<responses.CompanyCalendlySettingsData>({
			query: queries.getCompanyCalendlySettingsData,
			variables: { companyId },
		}));
	}

	public getFaqPageData(): Promise<ApolloQueryResult<responses.FaqsData>> {
		return lastValueFrom(this.apollo.query<responses.FaqsData>({
			query: queries.getFaqPageData,
		}));
	}

	public getEducationPageData(): Promise<ApolloQueryResult<responses.EducationsData>> {
		return lastValueFrom(this.apollo.query<responses.EducationsData>({
			query: queries.getEducationPageData,
		}));
	}

	public getFaqListData(): Promise<ApolloQueryResult<responses.FaqsData>> {
		return lastValueFrom(this.apollo.query<responses.FaqsData>({
			query: queries.getFaqListData,
		}));
	}

	public getEducationListData(): Promise<ApolloQueryResult<responses.EducationsData>> {
		return lastValueFrom(this.apollo.query<responses.EducationsData>({
			query: queries.getEducationListData,
		}));
	}

	public getAgents(): Promise<ApolloQueryResult<responses.AgentsData>> {
		return lastValueFrom(this.apollo.query<responses.AgentsData>({
			query: queries.getAgents,
		}));
	}

	public getStateEditData(stateId: string): Promise<ApolloQueryResult<responses.StateData>> {
		return lastValueFrom(this.apollo.query<responses.StateData>({
			query: queries.getStateEditData,
			variables: { stateId },
		}));
	}

	public getStateEditPageData(): Promise<ApolloQueryResult<responses.StatePageData>> {
		return lastValueFrom(this.apollo.query<responses.StatePageData>({
			query: queries.getStateEditPageData,
		}));
	}

	public getStateListData(sort: string): Promise<ApolloQueryResult<responses.StatesData>> {
		return lastValueFrom(this.apollo.query<responses.StatesData>({
			query: queries.getStateListData,
			variables: { sort },
		}));
	}

	public getMenuTypesAddEditData(menuTypeId: string): Promise<ApolloQueryResult<responses.MenuTypeData>> {
		return lastValueFrom(this.apollo.query<responses.MenuTypeData>({
			query: queries.getMenuTypeAddEditData,
			variables: { menuTypeId },
		}));
	}

	public getMenuTypesListData(sort: string): Promise<ApolloQueryResult<responses.MenuTypesData>> {
		return lastValueFrom(this.apollo.query<responses.MenuTypesData>({
			query: queries.getMenuTypeListData,
			variables: { sort },
		}));
	}

	public getLicensePlanAddEditData(id: number): Promise<ApolloQueryResult<responses.LicensePlanData>> {
		return lastValueFrom(this.apollo.query<responses.LicensePlanData>({
			query: queries.getLicensePlanAddEditData,
			variables: { id },
		}));
	}

	public getLicensePlanAddEditPageData(): Promise<ApolloQueryResult<responses.LicensePlanAddEditPageData>> {
		return lastValueFrom(this.apollo.query<responses.LicensePlanAddEditPageData>({
			query: queries.getLicensePlanAddEditPageData,
		}));
	}

	public getLicensePlansListData(sort: string): Promise<ApolloQueryResult<responses.LicensePlansData>> {
		return lastValueFrom(this.apollo.query<responses.LicensePlansData>({
			query: queries.getLicensePlanListData,
			variables: { sort },
		}));
	}

	public getPermissionAddEditPageData(): Promise<ApolloQueryResult<responses.PermissionAddEditPageData>> {
		return lastValueFrom(this.apollo.query<responses.PermissionAddEditPageData>({
			query: queries.getPermissionAddEditPageData,
		}));
	}

	public getPermissionsAddEditData(permissionId: string): Promise<ApolloQueryResult<responses.PermissionData>> {
		return lastValueFrom(this.apollo.query<responses.PermissionData>({
			query: queries.getPermissionAddEditData,
			variables: { permissionId },
		}));
	}

	public getPermissionsListData(sort: string): Promise<ApolloQueryResult<responses.PermissionsData>> {
		return lastValueFrom(this.apollo.query<responses.PermissionsData>({
			query: queries.getPermissionListData,
			variables: { sort },
		}));
	}

	public getReportTypesAddEditData(reportTypeId: string): Promise<ApolloQueryResult<responses.ReportTypeData>> {
		return lastValueFrom(this.apollo.query<responses.ReportTypeData>({
			query: queries.getReportTypeAddEditData,
			variables: { reportTypeId },
		}));
	}

	public getReportTypesListData(sort: string): Promise<ApolloQueryResult<responses.ReportTypesData>> {
		return lastValueFrom(this.apollo.query<responses.ReportTypesData>({
			query: queries.getReportTypeListData,
			variables: { sort },
		}));
	}

	public getPriceElementsAddEditPageData(): Promise<ApolloQueryResult<responses.PriceElementPageData>> {
		return lastValueFrom(this.apollo.query<responses.PriceElementPageData>({
			query: queries.getPriceElementAddEditPageData,
		}));
	}

	public getPriceElementsAddEditData(priceElementId: string): Promise<ApolloQueryResult<responses.PriceElementData>> {
		return lastValueFrom(this.apollo.query<responses.PriceElementData>({
			query: queries.getPriceElementAddEditData,
			variables: { priceElementId },
		}));
	}

	public getReportsAddEditData(reportId: string): Promise<ApolloQueryResult<responses.ReportAddEditData>> {
		return lastValueFrom(this.apollo.query<responses.ReportAddEditData>({
			query: queries.getReportAddEditData,
			variables: { reportId },
		}));
	}

	public getReportsAddEditPageData(): Promise<ApolloQueryResult<responses.ReportAddEditPageData>> {
		return lastValueFrom(this.apollo.query<responses.ReportAddEditPageData>({
			query: queries.getReportAddEditPageData,
		}));
	}

	public getRateMatrixLoaderAuditsData(criteria: any): Promise<ApolloQueryResult<responses.RateMatrixLoaderAuditsData>> {
		return lastValueFrom(this.apollo.query<responses.RateMatrixLoaderAuditsData>({
			query: queries.getRateMatrixLoaderAuditsData,
			variables: { criteria },
		}));
	}

	public getRateMatrixLoaderAuditsPageData(): Promise<ApolloQueryResult<responses.RateMatrixLoaderAuditsPageData>> {
		return lastValueFrom(this.apollo.query<responses.RateMatrixLoaderAuditsPageData>({
			query: queries.getRateMatrixLoaderAuditsPageData,
		}));
	}

	public getFeedbackDataSub(criteria: any, sort: string, max: number, offset: number):
		Observable<ApolloQueryResult<responses.FeedbackDataSub>> {
		return this.apollo.query<responses.FeedbackDataSub>({
			query: queries.getFeedbackPageDataSub,
			variables: { criteria, sort, max, offset },
		});
	}

	public getFeedbackPageData(userId: string): Promise<ApolloQueryResult<responses.FeedbackPageData>> {
		return lastValueFrom(this.apollo.query<responses.FeedbackPageData>({
			query: queries.getFeedbackPageData,
			variables: { userId },
		}));
	}

	public getReportsListData(sort: string): Promise<ApolloQueryResult<responses.ReportsData>> {
		return lastValueFrom(this.apollo.query<responses.ReportsData>({
			query: queries.getReportListData,
			variables: { sort },
		}));
	}

	public getPriceElementsListData(sort: string): Promise<ApolloQueryResult<responses.PriceElementsData>> {
		return lastValueFrom(this.apollo.query<responses.PriceElementsData>({
			query: queries.getPriceElementListData,
			variables: { sort },
		}));
	}

	public getSettingsAddEditData(settingId: string): Promise<ApolloQueryResult<responses.SettingData>> {
		return lastValueFrom(this.apollo.query<responses.SettingData>({
			query: queries.getSettingAddEditData,
			variables: { settingId },
		}));
	}

	public getRfqSessionOptOutReasonTypeAddEditData(id: number): Promise<ApolloQueryResult<responses.RfqSessionOptOutReasonTypeData>> {
		return lastValueFrom(this.apollo.query<responses.RfqSessionOptOutReasonTypeData>({
			query: queries.getRfqSessionOptOutReasonTypeAddEditData,
			variables: { id },
		}));
	}

	public getSettingsListData(sort: string): Promise<ApolloQueryResult<responses.SettingsData>> {
		return lastValueFrom(this.apollo.query<responses.SettingsData>({
			query: queries.getSettingListData,
			variables: { sort },
		}));
	}

	public getAlertTypeEditData(id: number): Promise<ApolloQueryResult<responses.AlertTypeData>> {
		return lastValueFrom(this.apollo.query<responses.AlertTypeData>({
			query: queries.getAlertTypeEditData,
			variables: { id },
		}));
	}

	public getAlertTypesListData(sort: string): Promise<ApolloQueryResult<responses.AlertTypesData>> {
		return lastValueFrom(this.apollo.query<responses.AlertTypesData>({
			query: queries.getAlertTypesListData,
			variables: { sort },
		}));
	}

	public createNoteType(noteType: Subset<models.NoteType>): Promise<FetchResult<responses.CreateNoteTypeResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateNoteTypeResponse>({
			mutation: mutations.createNoteType,
			variables: { noteType },
		}));
	}

	public updateNoteType(noteTypeId: string, noteType: Subset<models.NoteType>): Promise<FetchResult<responses.UpdateNoteTypeResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateNoteTypeResponse>({
			mutation: mutations.updateNoteType,
			variables: { noteTypeId, noteType },
		}));
	}

	public getNoteTypes(criteria: any, sort: string): Promise<ApolloQueryResult<responses.NoteTypeData>> {
		return lastValueFrom(this.apollo.query<responses.NoteTypeData>({
			query: queries.getNoteTypes,
			variables: { criteria, sort },
		}));
	}

	public getSingleNoteType(noteTypeId: string): Promise<ApolloQueryResult<responses.NoteTypeResponse>> {
		return lastValueFrom(this.apollo.query<responses.NoteTypeResponse>({
			query: queries.getSingleNoteType,
			variables: { noteTypeId },
		}));
	}

	public getSetupMenu(): Promise<ApolloQueryResult<responses.SetupMenuData>> {
		return lastValueFrom(this.apollo.query<responses.SetupMenuData>({
			query: queries.getSetupMenu,
			variables: {
				criteria: {
					menuTypeId: '297ed5063d4b3cb4013d4b9d01b20003',
					parentId: '',
					isActive: true,
				},
				sort: 'sortOrder, name',
			},
		}));
	}

	public checkPromoCodeUniqueAsync(slug: string):
		Observable<ApolloQueryResult<responses.PromoCodeUniqueData>> {
		return this.apollo.query<responses.PromoCodeUniqueData>({
			query: queries.checkPromoCodeUnique,
			variables: { slug },
		});
	}

	public getAgentNotesData(
		criteria: any,
		sort: string,
		max: number,
		offset: number,
	): Promise<ApolloQueryResult<responses.AgentNoteData>> {
		return lastValueFrom(this.apollo.query<responses.AgentNoteData>({
			query: queries.getAgentNotesData,
			variables: {
				criteria,
				sort,
				max,
				offset,
			},
		}));
	}

	public analyticsClickEmail(apiId: string): Promise<FetchResult<responses.AnalyticsClickEmailResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.AnalyticsClickEmailResponse>({
			mutation: mutations.analyticsClickEmail,
			variables: { apiId },
		}));
	}

	public getEmailAnalyticsReportPageData(reportId: string):
		Promise<ApolloQueryResult<responses.EmailAnalyticsReportPageData>> {
		return lastValueFrom(this.apollo.query<responses.EmailAnalyticsReportPageData>({
			query: queries.getEmailAnalyticsReportPageData,
			variables: { reportId },
		}));
	}

	public getEmailAnalyticsReportData(criteria: Partial<models.EmailAnalyticsInput>):
		Promise<ApolloQueryResult<responses.EmailAnalyticsReportData>> {
		return lastValueFrom(this.apollo.query<responses.EmailAnalyticsReportData>({
			query: queries.getEmailAnalyticsReportData,
			variables: { criteria },
		}));
	}

	public getAgentActivityReportPageData(reportId: string):
		Promise<ApolloQueryResult<responses.EmailAnalyticsReportPageData>> {
		return lastValueFrom(this.apollo.query<responses.AgentActivityReportPageData>({
			query: queries.getAgentActivityReportPageData,
			variables: { reportId },
		}));
	}

	public getAgentActivityStatisticsGeneralReportData(criteria: any, sort?: string):
		Promise<ApolloQueryResult<responses.AgentActivityStatisticsGeneralReportData>> {
		return lastValueFrom(this.apollo.query<responses.AgentActivityStatisticsGeneralReportData>({
			query: queries.getAgentActivityStatisticsGeneralReportData,
			variables: { criteria, sort },
		}));
	}

	public getAgentActivityStatisticsConversionReportData(criteria: any, sort?: string):
		Promise<ApolloQueryResult<responses.AgentActivityStatisticsConversionReportData>> {
		return lastValueFrom(this.apollo.query<responses.AgentActivityStatisticsConversionReportData>({
			query: queries.getAgentActivityStatisticsConversionStats,
			variables: { criteria, sort },
		}));
	}

	public getAgentActivityStatisticsVelocityReportData(criteria: any, sort?: string):
		Promise<ApolloQueryResult<responses.AgentActivityStatisticsVelocityReportData>> {
		return lastValueFrom(this.apollo.query<responses.AgentActivityStatisticsVelocityReportData>({
			query: queries.getAgentActivityStatisticsVelocityStats,
			variables: { criteria, sort },
		}));
	}

	public getAgentActivityStatisticsWinbackReportData(criteria: any, sort?: string):
		Promise<ApolloQueryResult<responses.AgentActivityStatisticsWinbackReportData>> {
		return lastValueFrom(this.apollo.query<responses.AgentActivityStatisticsWinbackReportData>({
			query: queries.getAgentActivityStatisticsWinbackStats,
			variables: { criteria, sort },
		}));
	}

	public getAgentActivityStatisticsChurnRateReportData(criteria: any, sort?: string):
		Promise<ApolloQueryResult<responses.AgentActivityStatisticsChurnRateReportData>> {
		return lastValueFrom(this.apollo.query<responses.AgentActivityStatisticsChurnRateReportData>({
			query: queries.getAgentActivityStatisticsChurnRateStats,
			variables: { criteria, sort },
		}));
	}

	public getAgentActivityRampingReportData(criteria: any, sort?: string):
		Promise<ApolloQueryResult<responses.AgentActivityRampingReportData>> {
		return lastValueFrom(this.apollo.query<responses.AgentActivityRampingReportData>({
			query: queries.getAgentActivityRampingReportData,
			variables: { criteria, sort },
		}));
	}

	public getAgentActivityEmergingReportData(criteria: any, sort?: string):
		Promise<ApolloQueryResult<responses.AgentActivityEmergingReportData>> {
		return lastValueFrom(this.apollo.query<responses.AgentActivityEmergingReportData>({
			query: queries.getAgentActivityEmergingReportData,
			variables: { criteria, sort },
		}));
	}

	public sendTestEmail(email: string): Promise<FetchResult<responses.SendEmail>> {
		return lastValueFrom(this.apollo.mutate<responses.SendEmail>({
			mutation: mutations.sendTestEmail,
			variables: { email },
		}));
	}

	public getAgentEditParentChanged(agentId: string): Promise<ApolloQueryResult<responses.AgentEditData>> {
		return lastValueFrom(this.apollo.query<responses.AgentEditData>({
			query: queries.getAgentEditParentChanged,
			variables: { agentId },
		}));
	}

	public getAgentEditData(agentId: string): Promise<ApolloQueryResult<responses.AgentEditData>> {
		return lastValueFrom(this.apollo.query<responses.AgentEditData>({
			query: queries.getAgentEditData,
			variables: { agentId },
		}));
	}

	public getAgentEditPageData(): Promise<ApolloQueryResult<responses.AgentEditPageData>> {
		return lastValueFrom(this.apollo.query<responses.AgentEditPageData>({
			query: queries.getAgentEditPageData,
		}));
	}

	public getAgentAdvancedListData(criteria: any, sort?: string, max?: number, offset?: number):
		Promise<ApolloQueryResult<responses.AgentAdvancedListData>> {
		return lastValueFrom(this.apollo.query<responses.AgentAdvancedListData>({
			query: queries.getAgentAdvancedListPageData,
			variables: { criteria, sort, max, offset },
		}));
	}

	public getAgentAdvancedListDataSub(search: string, max: number): Observable<ApolloQueryResult<responses.AgentAdvancedListData>> {
		return this.apollo.query<responses.AgentAdvancedListData>({
			query: queries.getAgentAdvancedListPageData,
			variables: { search, max },
		});
	}

	public getParentAgentInfoData(parentAgentId: string): Promise<ApolloQueryResult<responses.AgentInfoData>> {
		return lastValueFrom(this.apollo.query<responses.AgentInfoData>({
			query: queries.getParentAgentInfoPageData,
			variables: { agentId: parentAgentId },
		}));
	}

	public getAgentInfoData(agentId: string): Promise<ApolloQueryResult<responses.AgentInfoData>> {
		return lastValueFrom(this.apollo.query<responses.AgentInfoData>({
			query: queries.getAgentInfoPageData,
			variables: { agentId },
		}));
	}

	public getTaskPageUser(userId: string): Promise<ApolloQueryResult<responses.UserData>> {
		return lastValueFrom(this.apollo.query<responses.UserData>({
			query: queries.getTaskPageUser,
			variables: { userId },
		}));
	}

	public getGridConfigData(gridId: string, userId: string): Promise<ApolloQueryResult<responses.GridConfigData>> {
		return lastValueFrom(this.apollo.query<responses.GridConfigData>({
			query: queries.getGridConfigData,
			variables: { gridId, userId },
		}));
	}

	public getContractTablePageData(): Promise<ApolloQueryResult<responses.ContractTablePageData>> {
		return lastValueFrom(this.apollo.query<responses.ContractTablePageData>({
			query: queries.getContractTablePageData,
		}));
	}

	public revokeToken(): Promise<FetchResult<string>> {
		return lastValueFrom(this.apollo.mutate<string>({
			mutation: mutations.revokeToken,
		}));
	}

	public refreshToken(): Promise<FetchResult<responses.RefreshData>> {
		return lastValueFrom(this.apollo.mutate<responses.RefreshData>({
			mutation: mutations.refreshToken,
		}));
	}

	public authenticateUser(username: string, password: string): Promise<ApolloQueryResult<responses.AuthFieldsData>> {
		return lastValueFrom(this.apollo.query<responses.AuthFieldsData>({
			query: queries.authenticateUser,
			variables: { username, password },
		}));
	}

	public logoutUser(tokens: string[]): Promise<ApolloQueryResult<string>> {
		return lastValueFrom(this.apollo.query<string>({
			query: queries.logoutUser,
			variables: { tokens },
		}));
	}

	public deleteNote(noteId: string): Promise<FetchResult<responses.DeleteNoteResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.DeleteNoteResponse>({
			mutation: mutations.deleteNote,
			variables: { noteId },
		}));
	}

	public updateNote(noteId: string, note: Subset<models.Note>): Promise<FetchResult<responses.UpdateNoteResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateNoteResponse>({
			mutation: mutations.updateNote,
			variables: { noteId, note },
		}));
	}

	public createNote(note: Subset<models.Note>): Promise<FetchResult<responses.CreateNoteResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateNoteResponse>({
			mutation: mutations.createNote,
			variables: { note },
		}));
	}

	public createAgent(agent: Subset<models.Agent>): Promise<FetchResult<responses.CreateAgentResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateAgentResponse>({
			mutation: mutations.createAgent,
			variables: { agent },
		}));
	}

	public updateAddOns(addOnRequest: any): Promise<FetchResult<responses.UpdateAddOnsResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateAddOnsResponse>({
			mutation: mutations.updateAddOns,
			variables: { addOnRequest },
		}));
	}

	public deletePromoCode(id: number): Promise<FetchResult<responses.DeletePromoCodeResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.DeletePromoCodeResponse>({
			mutation: mutations.deletePromoCode,
			variables: { id },
		}));
	}

	public updateSalesforceAgentByAgentId(agentId: string): Promise<FetchResult<responses.UpdateSalesforceAgentResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateSalesforceAgentResponse>({
			mutation: mutations.updateSalesforceAgentByAgentId,
			variables: { agentId },
		}));
	}

	public updateAgent(id: string, agent: Subset<models.Agent>): Promise<FetchResult<responses.UpdateAgentResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateAgentResponse>({
			mutation: mutations.updateAgent,
			variables: { id, agent },
		}));
	}

	public updateAlertType(id: number, alertType: Partial<models.AlertType>): Promise<FetchResult<responses.UpdateAlertTypeResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateAlertTypeResponse>({
			mutation: mutations.updateAlertType,
			variables: { id, alertType },
		}));
	}

	public createAlertType(alertType: Partial<models.AlertType>): Promise<FetchResult<responses.CreateAlertTypeResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateAlertTypeResponse>({
			mutation: mutations.createAlertType,
			variables: { alertType },
		}));
	}

	public updateAttachmentType(attachmentTypeId: string, attachmentType: Subset<models.AttachmentType>):
		Promise<FetchResult<responses.UpdateAttachmentTypeResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateAttachmentTypeResponse>({
			mutation: mutations.updateAttachmentType,
			variables: { attachmentTypeId, attachmentType },
		}));
	}

	public createAttachmentType(attachmentType: Subset<models.AttachmentType>):
		Promise<FetchResult<responses.CreateAttachmentTypeResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateAttachmentTypeResponse>({
			mutation: mutations.createAttachmentType,
			variables: { attachmentType },
		}));
	}

	public updateContractLocationStatusReasonType(
		id: number, contractLocationStatusReasonType: Subset<models.ContractLocationStatusReasonType>,
	):
		Promise<FetchResult<responses.UpdateContractLocationStatusReasonTypeResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateContractLocationStatusReasonTypeResponse>({
			mutation: mutations.updateContractLocationStatusReasonType,
			variables: { id, contractLocationStatusReasonType },
		}));
	}

	public createContractLocationStatusReasonType(contractLocationStatusReasonType: Subset<models.ContractLocationStatusReasonType>):
		Promise<FetchResult<responses.CreateContractLocationStatusReasonTypeResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateContractLocationStatusReasonTypeResponse>({
			mutation: mutations.createContractLocationStatusReasonType,
			variables: { contractLocationStatusReasonType },
		}));
	}

	public updatePopUp(id: number, popUp: Subset<models.PopUp>):
		Promise<FetchResult<responses.UpdatePopUpResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdatePopUpResponse>({
			mutation: mutations.updatePopUp,
			variables: { id, popUp },
		}));
	}

	public createPopUp(popUp: Subset<models.PopUp>): Promise<FetchResult<responses.CreatePopUpResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreatePopUpResponse>({
			mutation: mutations.createPopUp,
			variables: { popUp },
		}));
	}

	public deletePopUp(id: number): Promise<FetchResult<responses.DeletePopUpResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.DeletePopUpResponse>({
			mutation: mutations.deletePopUp,
			variables: { id },
		}));
	}

	public updateFeatureTour(id: number, featureTour: Subset<models.FeatureTour>):
		Promise<FetchResult<responses.UpdateFeatureTourResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateFeatureTourResponse>({
			mutation: mutations.updateFeatureTour,
			variables: { id, featureTour },
		}));
	}

	public createFeatureTour(featureTour: Subset<models.FeatureTour>): Promise<FetchResult<responses.CreateFeatureTourResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateFeatureTourResponse>({
			mutation: mutations.createFeatureTour,
			variables: { featureTour },
		}));
	}

	public deleteFeatureTour(id: number): Promise<FetchResult<responses.DeleteFeatureTourResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.DeleteFeatureTourResponse>({
			mutation: mutations.deleteFeatureTour,
			variables: { id },
		}));
	}

	public createCommissionPayableIssueType(commissionPayableIssueType: Subset<models.CommissionPayableIssueType>):
		Promise<FetchResult<responses.CreateCommissionPayableIssueTypeResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateCommissionPayableIssueTypeResponse>({
			mutation: mutations.createCommissionPayableIssueType,
			variables: { commissionPayableIssueType },
		}));
	}

	public createPayableIssueType(payableIssueType: Subset<models.PayableIssueType>):
		Promise<FetchResult<responses.CreatePayableIssueTypeResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreatePayableIssueTypeResponse>({
			mutation: mutations.createPayableIssueType,
			variables: { payableIssueType },
		}));
	}

	public getCommissionPayableIssueTypes():
		Promise<ApolloQueryResult<responses.GetCommissionPayableIssueTypeResponse>> {
		return lastValueFrom(this.apollo.query<responses.GetCommissionPayableIssueTypeResponse>({
			query: queries.getCommissionPayableIssueTypes,
		}));
	}

	public updateCommissionPayPlanType(id: number, commissionPayPlanType: Subset<models.CommissionPayPlanType>):
		Promise<FetchResult<responses.UpdateCommissionPayPlanTypeResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateCommissionPayPlanTypeResponse>({
			mutation: mutations.updateCommissionPayPlanType,
			variables: { id, commissionPayPlanType },
		}));
	}

	public createCommissionPayPlanType(commissionPayPlanType: Subset<models.CommissionPayPlanType>):
		Promise<FetchResult<responses.CreateCommissionPayPlanTypeResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateCommissionPayPlanTypeResponse>({
			mutation: mutations.createCommissionPayPlanType,
			variables: { commissionPayPlanType },
		}));
	}

	public updatePurchasingLayer(id: number, purchasingLayer: Subset<models.PurchasingLayer>):
		Promise<FetchResult<responses.UpdatePurchasingLayerResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdatePurchasingLayerResponse>({
			mutation: mutations.updatePurchasingLayer,
			variables: { id, purchasingLayer },
		}));
	}

	public deletePurchasingLayer(id: number):
		Promise<FetchResult<responses.DeletePurchasingLayerResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.DeletePurchasingLayerResponse>({
			mutation: mutations.deletePurchasingLayer,
			variables: { id },
		}));
	}

	public updatePurchasingLayerType(id: number, purchasingLayerType: Subset<models.PurchasingLayerType>):
		Promise<FetchResult<responses.UpdatePurchasingLayerTypeResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdatePurchasingLayerTypeResponse>({
			mutation: mutations.updatePurchasingLayerType,
			variables: { id, purchasingLayerType },
		}));
	}

	public createPurchasingLayerType(purchasingLayerType: Subset<models.PurchasingLayerType>):
		Promise<FetchResult<responses.CreatePurchasingLayerTypeResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreatePurchasingLayerTypeResponse>({
			mutation: mutations.createPurchasingLayerType,
			variables: { purchasingLayerType },
		}));
	}

	public updateCommissionType(id: number, commissionType: Subset<models.CommissionType>):
		Promise<FetchResult<responses.UpdateCommissionTypeResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateCommissionTypeResponse>({
			mutation: mutations.updateCommissionType,
			variables: { id, commissionType },
		}));
	}

	public createCommissionType(commissionType: Subset<models.CommissionType>):
		Promise<FetchResult<responses.CreateCommissionTypeResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateCommissionTypeResponse>({
			mutation: mutations.createCommissionType,
			variables: { commissionType },
		}));
	}

	public updateCommissionPaymentApproval(id: number, commissionPaymentApproval: models.CommissionPaymentApproval):
		Promise<FetchResult<responses.UpdateCommissionPaymentApprovalResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateCommissionPaymentApprovalResponse>({
			mutation: mutations.updateCommissionPaymentApproval,
			variables: { id, commissionPaymentApproval },
		}));
	}

	public createCommissionPaymentApproval(commissionPaymentApproval: models.CommissionPaymentApproval):
		Promise<FetchResult<responses.CreateCommissionTypeResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateCommissionTypeResponse>({
			mutation: mutations.createCommissionPaymentApproval,
			variables: { commissionPaymentApproval },
		}));
	}

	public updateCommissionPayPlan(id: number, commissionPayPlan: Subset<models.CommissionPayPlan>):
		Promise<FetchResult<responses.UpdateCommissionPayPlanResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateCommissionPayPlanResponse>({
			mutation: mutations.updateCommissionPayPlan,
			variables: { id, commissionPayPlan },
		}));
	}

	public createCommissionPayPlan(commissionPayPlan: Subset<models.CommissionPayPlan>):
		Promise<FetchResult<responses.CreateCommissionPayPlanResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateCommissionPayPlanResponse>({
			mutation: mutations.createCommissionPayPlan,
			variables: { commissionPayPlan },
		}));
	}

	public deleteCommissionPayPlan(id: number): Promise<FetchResult<responses.DeleteCommissionPayPlanResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.DeleteCommissionPayPlanResponse>({
			mutation: mutations.deleteCommissionPayPlan,
			variables: { id },
		}));
	}

	public updateServiceType(serviceTypeId: string, serviceType: Subset<models.ServiceType>):
		Promise<FetchResult<responses.UpdateServiceTypeResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateServiceTypeResponse>({
			mutation: mutations.updateServiceType,
			variables: { serviceTypeId, serviceType },
		}));
	}

	public createServiceType(serviceType: Subset<models.ServiceType>): Promise<FetchResult<responses.CreateServiceTypeResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateServiceTypeResponse>({
			mutation: mutations.createServiceType,
			variables: { serviceType },
		}));
	}

	public updateCompany(companyId: string, company: Subset<models.Company>): Promise<FetchResult<responses.UpdateCompanyResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateCompanyResponse>({
			mutation: mutations.updateCompany,
			variables: { companyId, company },
		}));
	}

	public createCompany(company: Subset<models.Company>): Promise<FetchResult<responses.CreateCompanyResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateCompanyResponse>({
			mutation: mutations.createCompany,
			variables: { company },
		}));
	}

	public updateFaq(id: number, faq: Subset<models.Faq>): Promise<FetchResult<responses.UpdateFaqResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateFaqResponse>({
			mutation: mutations.updateFaq,
			variables: { id, faq },
		}));
	}

	public createFaq(faq: Subset<models.Faq>): Promise<FetchResult<responses.CreateFaqResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateFaqResponse>({
			mutation: mutations.createFaq,
			variables: { faq },
		}));
	}

	public updateFaqCategory(id: number, faqCategory: Subset<models.FaqCategory>):
		Promise<FetchResult<responses.UpdateFaqCategoryResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateFaqCategoryResponse>({
			mutation: mutations.updateFaqCategory,
			variables: { id, faqCategory },
		}));
	}

	public createFaqCategory(faqCategory: Subset<models.FaqCategory>): Promise<FetchResult<responses.CreateFaqCategoryResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateFaqCategoryResponse>({
			mutation: mutations.createFaqCategory,
			variables: { faqCategory },
		}));
	}

	public updateEducation(id: number, education: Subset<models.Education>): Promise<FetchResult<responses.UpdateEducationResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateEducationResponse>({
			mutation: mutations.updateEducation,
			variables: { id, education },
		}));
	}

	public deleteEducation(id: number): Promise<FetchResult<responses.DeleteEducationResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.DeleteEducationResponse>({
			mutation: mutations.deleteEducation,
			variables: { id },
		}));
	}

	public createEducation(education: Subset<models.Education>): Promise<FetchResult<responses.CreateEducationResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateEducationResponse>({
			mutation: mutations.createEducation,
			variables: { education },
		}));
	}

	public updateEducationCategory(id: number, educationCategory: Subset<models.EducationCategory>):
		Promise<FetchResult<responses.UpdateEducationCategoryResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateEducationCategoryResponse>({
			mutation: mutations.updateEducationCategory,
			variables: { id, educationCategory },
		}));
	}

	public createEducationCategory(educationCategory: Subset<models.EducationCategory>):
		Promise<FetchResult<responses.CreateEducationCategoryResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateEducationCategoryResponse>({
			mutation: mutations.createEducationCategory,
			variables: { educationCategory },
		}));
	}

	public updateEducationType(id: number, educationType: Subset<models.EducationType>):
		Promise<FetchResult<responses.UpdateEducationCategoryResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateEducationCategoryResponse>({
			mutation: mutations.updateEducationType,
			variables: { id, educationType },
		}));
	}

	public createEducationType(educationType: Subset<models.EducationType>):
		Promise<FetchResult<responses.CreateEducationCategoryResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateEducationCategoryResponse>({
			mutation: mutations.createEducationType,
			variables: { educationType },
		}));
	}

	public updateApp(id: number, app: Subset<models.App>): Promise<FetchResult<responses.UpdateAppResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateAppResponse>({
			mutation: mutations.updateApp,
			variables: { id, app },
		}));
	}

	public createApp(app: Subset<models.App>): Promise<FetchResult<responses.CreateAppResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateAppResponse>({
			mutation: mutations.createApp,
			variables: { app },
		}));
	}

	public updateState(stateId: string, state: Subset<models.State>): Promise<FetchResult<responses.UpdateStateResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateStateResponse>({
			mutation: mutations.updateState,
			variables: { stateId, state },
		}));
	}

	public createState(state: Subset<models.State>): Promise<FetchResult<responses.CreateStateResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateStateResponse>({
			mutation: mutations.createState,
			variables: { state },
		}));
	}

	public updateTask(taskId: string, task: Subset<models.Task>): Promise<FetchResult<responses.UpdateTaskResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateTaskResponse>({
			mutation: mutations.updateTask,
			variables: { taskId, task },
		}));
	}

	public createTask(task: Subset<models.Task>): Promise<FetchResult<responses.CreateTaskResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateTaskResponse>({
			mutation: mutations.createTask,
			variables: { task },
		}));
	}

	public updatePricingAnalyticsSupplierSetting(
		id: number, pricingAnalyticsSupplierSetting: Subset<models.PricingAnalyticsSupplierSetting>,
	): Promise<FetchResult<responses.UpdatePricingAnalyticsSupplierSettingResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdatePricingAnalyticsSupplierSettingResponse>({
			mutation: mutations.updatePricingAnalyticsSupplierSetting,
			variables: { id, setting: pricingAnalyticsSupplierSetting },
		}));
	}

	public createPricingAnalyticsSupplierSetting(
		pricingAnalyticsSupplierSetting: Subset<models.PricingAnalyticsSupplierSetting>,
	): Promise<FetchResult<responses.CreatePricingAnalyticsSupplierSettingResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreatePricingAnalyticsSupplierSettingResponse>({
			mutation: mutations.createPricingAnalyticsSupplierSetting,
			variables: { setting: pricingAnalyticsSupplierSetting },
		}));
	}


	public resetCommissionRates(agentId: string): Promise<FetchResult<responses.ResetCommissionRatesResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.ResetCommissionRatesResponse>({
			mutation: mutations.resetCommissionRates,
			variables: { agentId },
		}));
	}

	public reassignContracts(oldAgentId: string, newAgentId: string): Promise<FetchResult<responses.ReassignContractsResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.ReassignContractsResponse>({
			mutation: mutations.reassignContracts,
			variables: { oldAgentId, newAgentId },
		}));
	}

	public createContractDirect(contract: models.Contract): Promise<FetchResult<responses.CreateContractResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateContractResponse>({
			mutation: mutations.createContractDirect,
			variables: { contract },
		}));
	}

	public updateContractDirect(contractId: string, contract: models.Contract): Promise<FetchResult<responses.UpdateContractResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateContractResponse>({
			mutation: mutations.updateContractDirect,
			variables: { contractId, contract },
		}));
	}

	public updateContract(contractId: string, contract: Subset<models.Contract>): Promise<FetchResult<responses.UpdateContractResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateContractResponse>({
			mutation: mutations.updateContract,
			variables: { contractId, contract },
		}));
	}

	public createUser(user: Subset<models.User>): Promise<FetchResult<responses.CreateUserResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateUserResponse>({
			mutation: mutations.createUser,
			variables: { user },
		}));
	}

	public updateUser(id: string, user: Subset<models.User>): Promise<FetchResult<responses.UpdateUserResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateUserResponse>({
			mutation: mutations.updateUser,
			variables: { id, user },
		}));
	}

	public sendQuoteEmail(quoteEmail: any): Promise<FetchResult<responses.SendQuoteEmail>> {
		return lastValueFrom(this.apollo.mutate<responses.SendQuoteEmail>({
			mutation: mutations.sendQuoteEmail,
			variables: { quoteEmail },
		}));
	}

	public createContract(contract: models.Contract): Promise<FetchResult<responses.CreateContractResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateContractResponse>({
			mutation: mutations.createContract,
			variables: { contract },
		}));
	}

	public createCustomerAvailability(customerAvailability: models.CustomerAvailability):
		Promise<FetchResult<responses.CreateCustomerAvailabilityResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateCustomerAvailabilityResponse>({
			mutation: mutations.createCustomerAvailability,
			variables: { customerAvailability },
		}));
	}

	public updateCustomerAvailability(id: number, customerAvailability: models.CustomerAvailability):
		Promise<FetchResult<responses.UpdateCustomerAvailabilityResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateCustomerAvailabilityResponse>({
			mutation: mutations.updateCustomerAvailability,
			variables: { id, customerAvailability },
		}));
	}

	public generateProposal(proposal: any): Promise<ApolloQueryResult<responses.ProposalBase64Response>> {
		return lastValueFrom(this.apollo.query<responses.ProposalBase64Response>({
			query: queries.proposal,
			variables: { proposal },
		}));
	}

	public generateInvoicePdf(invoiceBase64: any): Promise<ApolloQueryResult<responses.InvoiceBase64Response>> {
		return lastValueFrom(this.apollo.query<responses.InvoiceBase64Response>({
			query: queries.invoicePdf,
			variables: { invoiceBase64 },
		}));
	}

	public getContractSupplierLoaContractPdfBase64(contractId: string):
		Promise<ApolloQueryResult<responses.GetContractSupplierLoaContractPdfBase64>> {
		return lastValueFrom(this.apollo.query<responses.GetContractSupplierLoaContractPdfBase64>({
			query: queries.getContractSupplierLoaContractPdfBase64,
			variables: { contractId },
		}));
	}

	public createRfqSession(rfqSession: Subset<models.RfqSession>): Promise<FetchResult<responses.CreateRfqSessionResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateRfqSessionResponse>({
			mutation: mutations.createRfqSession,
			variables: { rfqSession },
		}));
	}

	public updateRfqSession(id: string, rfqSession: Subset<models.RfqSession>): Promise<FetchResult<responses.UpdateRfqSessionResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateRfqSessionResponse>({
			mutation: mutations.updateRfqSession,
			variables: { id, rfqSession },
		}));
	}

	public extendRfqSession(id: string, rfqSession: Subset<models.RfqSession>): Promise<FetchResult<responses.ExtendRfqSessionResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.ExtendRfqSessionResponse>({
			mutation: mutations.extendRfqSession,
			variables: { id, rfqSession },
		}));
	}

	public deleteRfqSessionProduct(rfqSessionProductId: string):
		Promise<FetchResult<responses.DeleteRfqSessionProductResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.DeleteRfqSessionProductResponse>({
			mutation: mutations.deleteRfqSessionProduct,
			variables: { rfqSessionProductId },
		}));
	}

	public deleteRfqSessionBid(rfqSessionBidId: string): Promise<FetchResult<responses.DeleteRfqSessionBidResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.DeleteRfqSessionBidResponse>({
			mutation: mutations.deleteRfqSessionBid,
			variables: { rfqSessionBidId },
		}));
	}

	public createRfqSessionBid(rfqSessionBid: models.RfqSessionBid):
		Promise<FetchResult<responses.CreateRfqSessionBidResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateRfqSessionBidResponse>({
			mutation: mutations.createRfqSessionBid,
			variables: { rfqSessionBid },
		}));
	}

	public createRfqSessionBids(rfqSessionBids: Subset<models.RfqSessionBid[]>):
		Promise<FetchResult<responses.CreateRfqSessionBidsResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateRfqSessionBidsResponse>({
			mutation: mutations.createRfqSessionBids,
			variables: { rfqSessionBids },
		}));
	}

	public createRfqSessionProduct(rfqSessionProduct: Subset<models.RfqSessionProduct>):
		Promise<FetchResult<responses.CreateRfqSessionProductResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateRfqSessionProductResponse>({
			mutation: mutations.createRfqSessionProduct,
			variables: { rfqSessionProduct },
		}));
	}

	public sendRfqSessionMessage(rfqSessionId: string, body: string, subject: string, supplierId: string, template: string):
		Promise<FetchResult<responses.SendRfqSessionMessageResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.SendRfqSessionMessageResponse>({
			mutation: mutations.sendRfqSessionMessage,
			variables: { rfqSessionId, body, subject, supplierId, template },
		}));
	}

	public updateRfqSessionSupplier(rfqSessionSupplierId: string, rfqSessionSupplier: Subset<models.RfqSessionSupplier>):
		Promise<FetchResult<responses.UpdateRfqSessionSupplierResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateRfqSessionSupplierResponse>({
			mutation: mutations.updateRfqSessionSupplier,
			variables: { rfqSessionSupplierId, rfqSessionSupplier },
		}));
	}

	public updateRfqSessionSupplierAuctionMonitorEmailAudit(id: number, rfqSessionSupplierAuctionMonitorEmailAudit: any):
		Promise<FetchResult<responses.UpdateRfqSessionSupplierAuctionMonitorEmailAuditResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateRfqSessionSupplierAuctionMonitorEmailAuditResponse>({
			mutation: mutations.updateRfqSessionSupplierAuctionMonitorEmailAudit,
			variables: { id, rfqSessionSupplierAuctionMonitorEmailAudit },
		}));
	}

	public createRfqSessionSupplier(rfqSessionSupplier: Subset<models.RfqSessionSupplier>):
		Promise<FetchResult<responses.CreateRfqSessionSupplierResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateRfqSessionSupplierResponse>({
			mutation: mutations.createRfqSessionSupplier,
			variables: { rfqSessionSupplier },
		}));
	}

	public getContractAdvancedSearchData(search: string): Promise<ApolloQueryResult<responses.ContractListAdvancedData>> {
		return lastValueFrom(this.apollo.query<responses.ContractListAdvancedData>({
			query: queries.getContractAdvancedSearchData,
			variables: { search },
		}));
	}

	public getSupplierSearchData(criteria: any): Promise<ApolloQueryResult<responses.SupplierSearchData>> {
		return lastValueFrom(this.apollo.query<responses.SupplierSearchData>({
			query: queries.getSupplierSearchData,
			variables: { criteria },
		}));
	}

	public getContractSearchData(criteria: any, sort: string): Promise<ApolloQueryResult<responses.ContractListData>> {
		return lastValueFrom(this.apollo.query<responses.ContractListData>({
			query: queries.getContractSearchData,
			variables: { criteria, sort },
		}));
	}

	public getLookupContractSearchData(criteria: any, sort: string, max: number, offset: number):
		Promise<ApolloQueryResult<responses.TicketContractSearchData>> {
		return lastValueFrom(this.apollo.query<responses.TicketContractSearchData>({
			query: queries.getLookupContractSearchData,
			variables: { criteria, sort, max, offset },
		}));
	}

	public deleteRfqSessionSupplier(rfqSessionSupplierId: string):
		Promise<FetchResult<responses.DeleteRfqSessionSupplierResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.DeleteRfqSessionSupplierResponse>({
			mutation: mutations.deleteRfqSessionSupplier,
			variables: { rfqSessionSupplierId },
		}));
	}

	public createContractLocation(location: Subset<models.ContractLocation>):
		Promise<FetchResult<responses.CreateContractLocationResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateContractLocationResponse>({
			mutation: mutations.createContractLocation,
			variables: { location },
		}));
	}

	public updateContractLocation(contractLocationId: string, location: Subset<models.ContractLocation>):
		Promise<FetchResult<responses.UpdateContractLocationResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateContractLocationResponse>({
			mutation: mutations.updateContractLocation,
			variables: { contractLocationId, location },
		}));
	}

	public deleteContractLocation(contractLocationId: string): Promise<FetchResult<responses.DeleteContractLocationResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.DeleteContractLocationResponse>({
			mutation: mutations.deleteContractLocation,
			variables: { contractLocationId },
		}));
	}

	public updateAttachment(attachmentId: string, attachment: Subset<models.Attachment>):
		Promise<FetchResult<responses.UpdateAttachmentResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateAttachmentResponse>({
			mutation: mutations.updateAttachment,
			variables: { attachmentId, attachment },
		}));
	}

	public createTicket(ticket: Subset<models.Ticket>): Promise<FetchResult<responses.CreateTicketResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateTicketResponse>({
			mutation: mutations.createTicket,
			variables: { ticket },
		}));
	}

	public createFeedbackTicket(ticket: models.Ticket): Promise<FetchResult<responses.CreateTicketResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateTicketResponse>({
			mutation: mutations.createFeedbackTicket,
			variables: { ticket },
		}));
	}

	public updateTicket(id: number, ticket: Subset<models.Ticket>): Promise<FetchResult<responses.UpdateTicketResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateTicketResponse>({
			mutation: mutations.updateTicket,
			variables: { id, ticket },
		}));
	}

	public updateFeedbackTicket(id: number, ticket: models.Ticket): Promise<FetchResult<responses.UpdateTicketResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateTicketResponse>({
			mutation: mutations.updateFeedbackTicket,
			variables: { id, ticket },
		}));
	}

	public createTicketComment(ticketComment: Subset<models.TicketComment>): Promise<FetchResult<responses.CreateTicketCommentResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateTicketCommentResponse>({
			mutation: mutations.createTicketComment,
			variables: { ticketComment },
		}));
	}

	public updateTicketComment(id: number, ticketComment: Subset<models.TicketComment>):
		Promise<FetchResult<responses.UpdateTicketCommentResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateTicketCommentResponse>({
			mutation: mutations.updateTicketComment,
			variables: { id, ticketComment },
		}));
	}

	public deleteAttachment(attachmentId: string): Promise<FetchResult<responses.DeleteAttachmentResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.DeleteAttachmentResponse>({
			mutation: mutations.deleteAttachment,
			variables: { attachmentId },
		}));
	}

	public addCustomerCard(stripeCustomerId: string, sourceToken: string): Promise<FetchResult<responses.AddStripeCardResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.AddStripeCardResponse>({
			mutation: mutations.addCustomerCard,
			variables: { stripeCustomerId, sourceToken },
		}));
	}

	public addAgentCustomerCard(agentId: string, sourceToken: string): Promise<FetchResult<responses.AddAgentStripeCardResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.AddAgentStripeCardResponse>({
			mutation: mutations.addAgentCustomerCard,
			variables: { agentId, sourceToken },
		}));
	}

	public getContractStatusReportData(reportId: string): Promise<ApolloQueryResult<responses.ContractStatusReportData>> {
		return lastValueFrom(this.apollo.query<responses.ContractStatusReportData>({
			query: queries.getContractStatusReportData,
			variables: {
				reportId,
				roleId: '297ed5063d288ec7013d290e2dab003e,297ed5063d288ec7013d290e2dc7003f,8a258005641ce83201641cef2db30000',
			},
		}));
	}

	public getSupplierPaymentAuditReportData(reportId: string): Promise<ApolloQueryResult<responses.SupplierPaymentAuditReportData>> {
		return lastValueFrom(this.apollo.query<responses.SupplierPaymentAuditReportData>({
			query: queries.getSupplierPaymentAuditReportData,
			variables: {
				reportId,
			},
		}));
	}

	public getSubmittedNotConfirmedReportData(reportId: string): Promise<ApolloQueryResult<responses.SubmittedNotConfirmedReportData>> {
		return lastValueFrom(this.apollo.query<responses.SubmittedNotConfirmedReportData>({
			query: queries.getSubmittedNotConfirmedReportData,
			variables: {
				reportId,
			},
		}));
	}

	public getSubmittedNotConfirmedReport(): Promise<ApolloQueryResult<responses.SubmittedNotConfirmedReport>> {
		return lastValueFrom(this.apollo.query<responses.SubmittedNotConfirmedReport>({
			query: queries.getSubmittedNotConfirmedReport,
			variables: { status: CONSTANTS.statuses.signed },
		}));
	}

	public exportAgentListReport(criteria: any, sort: string):
		Promise<ApolloQueryResult<responses.ExportAgentListReportData>> {
		return lastValueFrom(this.apollo.query<responses.ExportAgentListReportData>({
			query: queries.exportAgentListReport,
			variables: { criteria, sort },
		}));
	}

	public exportSupplierListReport(criteria: any): Promise<ApolloQueryResult<responses.ExportSupplierListReportData>> {
		return lastValueFrom(this.apollo.query<responses.ExportSupplierListReportData>({
			query: queries.exportSupplierListReport,
			variables: { criteria },
		}));
	}

	public getAgentListReport(criteria: any, sort: string, max: number, offset: number):
		Promise<ApolloQueryResult<responses.AgentListReportData>> {
		return lastValueFrom(this.apollo.query<responses.AgentListReportData>({
			query: queries.getAgentListReport,
			variables: { criteria, sort, max, offset },
		}));
	}

	public getLowAuctionPerformanceAgents(sort: string): Promise<ApolloQueryResult<responses.LowAuctionPerformanceAgentsData>> {
		return lastValueFrom(this.apollo.query<responses.LowAuctionPerformanceAgentsData>({
			query: queries.getLowAuctionPerformanceAgents,
			variables: { sort },
		}));
	}

	public getFaqEducationReports(criteria: any, sort: string): Promise<ApolloQueryResult<responses.FaqEducationReportsData>> {
		return lastValueFrom(this.apollo.query<responses.FaqEducationReportsData>({
			query: queries.getFaqEducationReports,
			variables: { criteria, sort },
		}));
	}

	public getAuctionMonitorEmailReportList(criteria: any, sort: string, max: number, offset: number):
		Promise<ApolloQueryResult<responses.AuctionMonitorEmailReportListData>> {
		return lastValueFrom(this.apollo.query<responses.AuctionMonitorEmailReportListData>({
			query: queries.getAuctionMonitorEmailReport,
			variables: { criteria, sort, max, offset },
		}));
	}

	public getAgentReportData(): Promise<ApolloQueryResult<responses.AgentReportData>> {
		return lastValueFrom(this.apollo.query<responses.AgentReportData>({
			query: queries.getAgentReportData,
			variables: {},
		}));
	}

	public getFaqEducationReportData(reportId: string): Promise<ApolloQueryResult<responses.FaqEducationReportData>> {
		return lastValueFrom(this.apollo.query<responses.FaqEducationReportData>({
			query: queries.getFaqEducationReportData,
			variables: { reportId },
		}));
	}

	public getAuctionMonitorEmailReportData(reportId: string):
		Promise<ApolloQueryResult<responses.AuctionMonitorEmailReportData>> {
		return lastValueFrom(this.apollo.query<responses.AuctionMonitorEmailReportData>({
			query: queries.getAuctionMonitorEmailReportData,
			variables: { reportId },
		}));
	}

	public getCommissionAuditReportPageData(reportId: string): Promise<ApolloQueryResult<responses.CommissionAuditReportData>> {
		return lastValueFrom(this.apollo.query<responses.CommissionAuditReportData>({
			query: queries.getCommissionAuditReportPageData,
			variables: { reportId },
		}));
	}

	public getSupplierCompensationReportPageData(reportId: string, contractDate: string):
		Promise<ApolloQueryResult<responses.SupplierCompensationReportData>> {
		return lastValueFrom(this.apollo.query<responses.SupplierCompensationReportData>({
			query: queries.getSupplierCompensationReportPageData,
			variables: { reportId, contractDate },
		}));
	}

	public getCommissionPaidReportData(reportId: string): Promise<ApolloQueryResult<responses.CommissionPaidReportData>> {
		return lastValueFrom(this.apollo.query<responses.CommissionPaidReportData>({
			query: queries.getCommissionPaidReportData,
			variables: { reportId },
		}));
	}

	public getCommissionKpiReportData(reportId: string): Promise<ApolloQueryResult<responses.CommissionKpiReportData>> {
		return lastValueFrom(this.apollo.query<responses.CommissionKpiReportData>({
			query: queries.getCommissionKpiReportData,
			variables: { reportId },
		}));
	}

	public getDirectSalePaidCommissionData(reportId: string): Promise<ApolloQueryResult<responses.DirectSalePaidCommissionData>> {
		return lastValueFrom(this.apollo.query<responses.DirectSalePaidCommissionData>({
			query: queries.getDirectSalePaidCommissionData,
			variables: {
				reportId,
			},
		}));
	}

	public getCommissionProcessingIssuesReportData(reportId: string):
		Promise<ApolloQueryResult<responses.CommissionProcessingIssuesReportData>> {
		return lastValueFrom(this.apollo.query<responses.CommissionProcessingIssuesReportData>({
			query: queries.getCommissionProcessingIssuesReportData,
			variables: { reportId },
		}));
	}

	public getTaskReportData(reportId: string): Promise<ApolloQueryResult<responses.TaskReportData>> {
		return lastValueFrom(this.apollo.query<responses.TaskReportData>({
			query: queries.getTaskReportData,
			variables: { reportId },
		}));
	}

	public getRenewalReportData(reportId: string): Promise<ApolloQueryResult<responses.RenewalReportData>> {
		return lastValueFrom(this.apollo.query<responses.RenewalReportData>({
			query: queries.getRenewalReportData,
			variables: { reportId },
		}));
	}

	public getUsageModalContractLocationInfoData(contractId: string):
		Promise<ApolloQueryResult<responses.UsageModalContractLocationInfoData>> {
		return lastValueFrom(this.apollo.query<responses.UsageModalContractLocationInfoData>({
			query: queries.getUsageModalContractLocationInfoData,
			variables: { contractId },
		}));
	}

	public getContractInfoLocationsData(contractId: string):
		Promise<ApolloQueryResult<responses.ContractLocationInfoData>> {
		return lastValueFrom(this.apollo.query<responses.ContractLocationInfoData>({
			query: queries.getContractInfoLocationsData,
			variables: { contractId },
		}));
	}

	public getContractLocationAccountUsage(criteria: any, contractLocationId: string):
		Promise<ApolloQueryResult<responses.ContractLocationAccountUsage>> {
		return lastValueFrom(this.apollo.query<responses.ContractLocationAccountUsage>({
			query: queries.getContractLocationAccountUsage,
			variables: { criteria, contractLocationId },
		}));
	}

	public impersonateUser(userId: string): Promise<ApolloQueryResult<responses.ImpersonateUserResponse>> {
		return lastValueFrom(this.apollo.query<responses.ImpersonateUserResponse>({
			query: queries.impersonateUser,
			variables: { userId },
		}));
	}
	public updateLicensePlan(id: number, licensePlan: any): Promise<FetchResult<responses.UpdateLicensePlanResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateLicensePlanResponse>({
			mutation: mutations.updateLicensePlan,
			variables: { id, licensePlan },
		}));
	}

	public createLicensePlan(licensePlan: any): Promise<FetchResult<responses.CreateLicensePlanResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateLicensePlanResponse>({
			mutation: mutations.createLicensePlan,
			variables: { licensePlan },
		}));
	}

	public updateMenuType(menuTypeId: string, menuType: any): Promise<FetchResult<responses.UpdateMenuTypeResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateMenuTypeResponse>({
			mutation: mutations.updateMenuType,
			variables: { menuTypeId, menuType },
		}));
	}

	public createMenuType(menuType: any): Promise<FetchResult<responses.CreateMenuTypeResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateMenuTypeResponse>({
			mutation: mutations.createMenuType,
			variables: { menuType },
		}));
	}

	public createPendingCommission(pendingCommission: models.PendingCommission):
		Promise<FetchResult<responses.CreatePendingCommissionResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreatePendingCommissionResponse>({
			mutation: mutations.createPendingCommission,
			variables: { pendingCommission },
		}));
	}

	public updatePendingCommission(id: number, pendingCommission: Subset<models.PendingCommission>):
		Promise<FetchResult<responses.UpdatePendingCommissionResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdatePendingCommissionResponse>({
			mutation: mutations.updatePendingCommission,
			variables: { id, pendingCommission },
		}));
	}

	public updateResolvePendingCommission(id: number, pendingCommission: Subset<models.PendingCommission>):
		Promise<FetchResult<responses.UpdateResolvePendingCommissionResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateResolvePendingCommissionResponse>({
			mutation: mutations.updateResolvePendingCommission,
			variables: { id, pendingCommission },
		}));
	}

	public updatePendingConfirmation(id: number, pendingConfirmation: Subset<models.PendingConfirmation>):
		Promise<FetchResult<responses.UpdatePendingConfirmationResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdatePendingConfirmationResponse>({
			mutation: mutations.updatePendingConfirmation,
			variables: { id, pendingConfirmation },
		}));
	}

	public updatePendingInvoice(id: number, pendingInvoice: models.PendingInvoice):
		Promise<FetchResult<responses.UpdatePendingInvoiceResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdatePendingInvoiceResponse>({
			mutation: mutations.updatePendingInvoice,
			variables: { id, pendingInvoice },
		}));
	}

	public errorCheckPendingCommissions(ids: number[]): Promise<FetchResult<responses.ErrorCheckPendingCommissionsResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.ErrorCheckPendingCommissionsResponse>({
			mutation: mutations.errorCheckPendingCommissions,
			variables: { ids },
		}));
	}

	public errorCheckPendingConfirmations(ids: number[]): Promise<FetchResult<responses.ErrorCheckPendingConfirmationsResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.ErrorCheckPendingConfirmationsResponse>({
			mutation: mutations.errorCheckPendingConfirmations,
			variables: { ids },
		}));
	}

	public errorCheckPendingInvoices(ids: number[]): Promise<FetchResult<responses.ErrorCheckPendingInvoicesResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.ErrorCheckPendingInvoicesResponse>({
			mutation: mutations.errorCheckPendingInvoices,
			variables: { ids },
		}));
	}

	public destroyPendingConfirmations(ids: number[]): Promise<FetchResult<responses.DestroyPendingConfirmationsResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.DestroyPendingConfirmationsResponse>({
			mutation: mutations.destroyPendingConfirmations,
			variables: { ids },
		}));
	}

	public destroyPendingInvoices(ids: number[]): Promise<FetchResult<responses.DestroyPendingInvoicesResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.DestroyPendingInvoicesResponse>({
			mutation: mutations.destroyPendingInvoices,
			variables: { ids },
		}));
	}

	public completeReviewPendingCommissions(ids: number[]): Promise<FetchResult<responses.CompleteReviewPendingCommissionsResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CompleteReviewPendingCommissionsResponse>({
			mutation: mutations.completeReviewPendingCommissions,
			variables: { ids },
		}));
	}

	public completeForceResolvePendingCommissions(ids: number[]):
		Promise<FetchResult<responses.CompleteForceResolvePendingCommissionsResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CompleteForceResolvePendingCommissionsResponse>({
			mutation: mutations.completeForceResolvePendingCommissions,
			variables: { ids },
		}));
	}


	public archivePendingCommissions(ids: number[]): Promise<FetchResult<responses.ArchivePendingCommissionsResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.ArchivePendingCommissionsResponse>({
			mutation: mutations.archivePendingCommissions,
			variables: { ids },
		}));
	}

	public unarchivePendingCommissions(ids: number[]): Promise<FetchResult<responses.UnarchivePendingCommissionsResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UnarchivePendingCommissionsResponse>({
			mutation: mutations.unarchivePendingCommissions,
			variables: { ids },
		}));
	}

	public completeReviewPendingInvoices(ids: number[]): Promise<FetchResult<responses.CompleteReviewPendingInvoicesResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CompleteReviewPendingInvoicesResponse>({
			mutation: mutations.completeReviewPendingInvoices,
			variables: { ids },
		}));
	}

	public incompleteReviewPendingCommissions(ids: number[]): Promise<FetchResult<responses.IncompleteReviewPendingCommissionsResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.IncompleteReviewPendingCommissionsResponse>({
			mutation: mutations.incompleteReviewPendingCommissions,
			variables: { ids },
		}));
	}

	public completeSetStatusPostProcessingCommissionAudits(ids: number[], statusId: number):
		Promise<FetchResult<responses.CompleteSetStatusPostProcessingCommissionAuditsResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CompleteSetStatusPostProcessingCommissionAuditsResponse>({
			mutation: mutations.completeSetStatusPostProcessingCommissionAudits,
			variables: { ids, statusId },
		}));
	}

	public completeUnreviewPostProcessingCommissionAudits(ids: number[]):
		Promise<FetchResult<responses.CompleteUnreviewPostProcessingCommissionAuditsResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CompleteUnreviewPostProcessingCommissionAuditsResponse>({
			mutation: mutations.completeUnreviewPostProcessingCommissionAudits,
			variables: { ids },
		}));
	}

	public completeReviewCommissionPayables(ids: number[]): Promise<FetchResult<responses.CompleteReviewCommissionPayablesResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CompleteReviewCommissionPayablesResponse>({
			mutation: mutations.completeReviewCommissionPayables,
			variables: { ids },
		}));
	}

	public incompleteReviewCommissionPayables(ids: number[]): Promise<FetchResult<responses.IncompleteReviewCommissionPayablesResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.IncompleteReviewCommissionPayablesResponse>({
			mutation: mutations.incompleteReviewCommissionPayables,
			variables: { ids },
		}));
	}

	public rejectCommissionPayables(ids: number[]): Promise<FetchResult<responses.RejectCommissionPayablesResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.RejectCommissionPayablesResponse>({
			mutation: mutations.rejectCommissionPayables,
			variables: { ids },
		}));
	}

	public incompleteReviewPendingInvoices(ids: number[]): Promise<FetchResult<responses.IncompleteReviewPendingInvoicesResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.IncompleteReviewPendingInvoicesResponse>({
			mutation: mutations.incompleteReviewPendingInvoices,
			variables: { ids },
		}));
	}

	public exportPendingCommissions(ids: number[], sort: string): Promise<FetchResult<responses.ExportPendingCommissionsResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.ExportPendingCommissionsResponse>({
			mutation: queries.exportPendingCommissions,
			variables: { ids, sort },
		}));
	}

	public exportPendingConfirmations(ids: number[], sort: string): Promise<FetchResult<responses.ExportPendingConfirmationsResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.ExportPendingConfirmationsResponse>({
			mutation: queries.exportPendingConfirmations,
			variables: { ids, sort },
		}));
	}

	public processPendingCommissions(ids: number[]): Promise<FetchResult<responses.ProcessPendingCommissionsResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.ProcessPendingCommissionsResponse>({
			mutation: mutations.processPendingCommissions,
			variables: { ids },
		}));
	}

	public processPendingConfirmations(ids: number[]): Promise<FetchResult<responses.ProcessPendingConfirmationsResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.ProcessPendingConfirmationsResponse>({
			mutation: mutations.processPendingConfirmations,
			variables: { ids },
		}));
	}

	public processPendingInvoices(ids: number[]): Promise<FetchResult<responses.ProcessPendingInvoicesResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.ProcessPendingInvoicesResponse>({
			mutation: mutations.processPendingInvoices,
			variables: { ids },
		}));
	}

	public updatePermission(permissionId: string, permission: any): Promise<FetchResult<responses.UpdatePermissionResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdatePermissionResponse>({
			mutation: mutations.updatePermission,
			variables: { permissionId, permission },
		}));
	}

	public createPermission(permission: any): Promise<FetchResult<responses.CreatePermissionResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreatePermissionResponse>({
			mutation: mutations.createPermission,
			variables: { permission },
		}));
	}

	public updateReportType(reportTypeId: string, reportType: any): Promise<FetchResult<responses.UpdateReportTypeResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateReportTypeResponse>({
			mutation: mutations.updateReportType,
			variables: { reportTypeId, reportType },
		}));
	}

	public createReportType(reportType: any): Promise<FetchResult<responses.CreateReportTypeResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateReportTypeResponse>({
			mutation: mutations.createReportType,
			variables: { reportType },
		}));
	}

	public updatePriceElement(priceElementId: string, priceElement: Subset<models.CpanelPriceElement>):
		Promise<FetchResult<responses.UpdatePriceElementResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdatePriceElementResponse>({
			mutation: mutations.updatePriceElement,
			variables: { priceElementId, priceElement },
		}));
	}

	public createPriceElement(priceElement: Subset<models.CpanelPriceElement>): Promise<FetchResult<responses.CreatePriceElementResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreatePriceElementResponse>({
			mutation: mutations.createPriceElement,
			variables: { priceElement },
		}));
	}

	public updateReport(reportId: string, report: Subset<models.CpanelReport>): Promise<FetchResult<responses.UpdateReportResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateReportResponse>({
			mutation: mutations.updateReport,
			variables: { reportId, report },
		}));
	}

	public createReport(report: Subset<models.CpanelReport>): Promise<FetchResult<responses.CreateReportResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateReportResponse>({
			mutation: mutations.createReport,
			variables: { report },
		}));
	}

	public updateSetting(settingId: string, setting: any): Promise<FetchResult<responses.UpdateSettingResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateSettingResponse>({
			mutation: mutations.updateSetting,
			variables: { settingId, setting },
		}));
	}

	public updateRfqSessionOptOutReasonType(id: number, rfqSessionOptOutReason: any):
		Promise<FetchResult<responses.UpdateRfqSessionOptOutReasonTypeResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateRfqSessionOptOutReasonTypeResponse>({
			mutation: mutations.updateRfqSessionOptOutReasonType,
			variables: { id, rfqSessionOptOutReason },
		}));
	}

	public createSetting(setting: any): Promise<FetchResult<responses.CreateSettingResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateSettingResponse>({
			mutation: mutations.createSetting,
			variables: { setting },
		}));
	}

	public createRfqSessionOptOutReasonType(optOutReasonType: any): Promise<FetchResult<responses.CreateRfqSessionOptOutReasonType>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateRfqSessionOptOutReasonType>({
			mutation: mutations.createRfqSessionOptOutReasonType,
			variables: { optOutReasonType },
		}));
	}

	public deleteSetting(settingId: string): Promise<FetchResult<responses.DeleteSettingResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.DeleteSettingResponse>({
			mutation: mutations.deleteSetting,
			variables: { settingId },
		}));
	}

	public createPayment(payment: Subset<models.Payment>): Promise<FetchResult<responses.CreatePaymentResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreatePaymentResponse>({
			mutation: mutations.createPayment,
			variables: { payment },
		}));
	}

	public completeEsign(esignId: string, esign: Subset<models.ESign>): Promise<FetchResult<responses.CompleteEsignResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CompleteEsignResponse>({
			mutation: mutations.completeEsign,
			variables: { esignId, esign },
		}));
	}

	public enrollResidential(criteria: any): Promise<FetchResult<responses.EnrollResidentialResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.EnrollResidentialResponse>({
			mutation: mutations.enrollResidential,
			variables: { criteria },
		}));
	}

	public generateLOE(contractId: string, addLogo: boolean): Promise<FetchResult<responses.GenerateLOEResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.GenerateLOEResponse>({
			mutation: mutations.generateLOE,
			variables: { contractId, addLogo },
		}));
	}

	public generateLOA(contractId: string): Promise<FetchResult<responses.GenerateLOAResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.GenerateLOAResponse>({
			mutation: mutations.generateLOA,
			variables: { contractId },
		}));
	}

	public generateLOALOE(criteria: any):
		Promise<FetchResult<responses.GenerateLOALOEResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.GenerateLOALOEResponse>({
			mutation: mutations.generateLOALOE,
			variables: { criteria },
		}));
	}

	public requestLOALOE(criteria: any): Promise<FetchResult<responses.RequestLOALOEResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.RequestLOALOEResponse>({
			mutation: mutations.requestLOALOE,
			variables: { criteria },
		}));
	}

	public requestBill(contractId: string, supplierId: string): Promise<FetchResult<responses.RequestBillResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.RequestBillResponse>({
			mutation: mutations.requestBill,
			variables: { contractId, supplierId },
		}));
	}

	public createContractRenewal(contractId: string): Promise<FetchResult<responses.CreateContractRenewalResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateContractRenewalResponse>({
			mutation: mutations.createContractRenewal,
			variables: { contractId },
		}));
	}

	public resendEnrollmentEmail(contractId: string): Promise<FetchResult<responses.ResendEnrollmentEmailResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.ResendEnrollmentEmailResponse>({
			mutation: mutations.resendEnrollmentEmail,
			variables: { contractId },
		}));
	}

	public getPreviousContract(contractId: string): Promise<FetchResult<responses.PreviousContractData>> {
		return lastValueFrom(this.apollo.query<responses.PreviousContractData>({
			query: queries.getPreviousContract,
			variables: { contractId },
		}));
	}

	public sendExpirationNoticeEmail(contractId: string): Promise<FetchResult<responses.SendExpirationNoticeEmailResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.SendExpirationNoticeEmailResponse>({
			mutation: mutations.sendExpirationNoticeEmail,
			variables: { contractId },
		}));
	}

	public sendUtilityUsageRequestEmail(contractId: string): Promise<FetchResult<responses.SendUtilityUsageRequestEmailResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.SendUtilityUsageRequestEmailResponse>({
			mutation: mutations.sendUtilityUsageRequestEmail,
			variables: { contractId },
		}));
	}

	public sendAutomatedRenewalEmail(contractId: string, margin: number):
		Promise<FetchResult<responses.SendAutomatedRenewalEmailResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.SendAutomatedRenewalEmailResponse>({
			mutation: mutations.sendAutomatedRenewalEmail,
			variables: { contractId, margin },
		}));
	}

	public sendDisconnectionStatusEmail(contractId: string, disconnectionStatuses: Subset<models.DisconnectionStatus>[]):
		Promise<FetchResult<responses.SendDisconnectionStatusEmailResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.SendDisconnectionStatusEmailResponse>({
			mutation: mutations.sendDisconnectionStatusEmail,
			variables: { contractId, disconnectionStatuses },
		}));
	}

	public createUserGridConfig(userGridConfig: Partial<models.UserGridConfig>):
		Promise<FetchResult<responses.CreateUserGridConfigResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateUserGridConfigResponse>({
			mutation: mutations.createUserGridConfig,
			variables: { userGridConfig },
		}));
	}

	public updateUserGridConfig(id: number, userGridConfig: Partial<models.UserGridConfig>):
		Promise<FetchResult<responses.UpdateUserGridConfigResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateUserGridConfigResponse>({
			mutation: mutations.updateUserGridConfig,
			variables: { id, userGridConfig },
		}));
	}

	public deleteUserGridConfig(id: number):
		Promise<FetchResult<responses.DeleteUserGridConfigResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.DeleteUserGridConfigResponse>({
			mutation: mutations.deleteUserGridConfig,
			variables: { id },
		}));
	}

	public deleteUtilityBaseRate(id: number): Promise<FetchResult<responses.UpdateUtilityBaseRateResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateUtilityBaseRateResponse>({
			mutation: mutations.deleteUtilityBaseRate,
			variables: { id },
		}));
	}

	public deleteUtilityBaseRates(id: string): Promise<FetchResult<responses.DeleteUtilityBaseRatesResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.DeleteUtilityBaseRatesResponse>({
			mutation: mutations.deleteUtilityBaseRates,
			variables: { id },
		}));
	}

	public updateUtilityBaseRate(id: number, utilityBaseRate: Subset<models.UtilityBaseRate>):
		Promise<FetchResult<responses.UpdateUtilityBaseRateResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateUtilityBaseRateResponse>({
			mutation: mutations.updateUtilityBaseRate,
			variables: { id, utilityBaseRate },
		}));
	}

	public createUtilityBaseRate(utilityBaseRate: Subset<models.UtilityBaseRate>):
		Promise<FetchResult<responses.CreateUtilityBaseRateResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateUtilityBaseRateResponse>({
			mutation: mutations.createUtilityBaseRate,
			variables: { utilityBaseRate },
		}));
	}

	public createUtilityBaseRates(utilityBaseRates: Subset<models.UtilityBaseRate>[]):
		Promise<FetchResult<responses.CreateUtilityBaseRatesResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateUtilityBaseRatesResponse>({
			mutation: mutations.createUtilityBaseRates,
			variables: { utilityBaseRates },
		}));
	}

	public deleteUtilityServiceFee(id: number): Promise<FetchResult<responses.UpdateUtilityServiceFeeResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateUtilityServiceFeeResponse>({
			mutation: mutations.deleteUtilityServiceFee,
			variables: { id },
		}));
	}

	public deleteUtilityServiceFees(id: string): Promise<FetchResult<responses.DeleteUtilityServiceFeesResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.DeleteUtilityServiceFeesResponse>({
			mutation: mutations.deleteUtilityServiceFees,
			variables: { id },
		}));
	}

	public updateUtilityServiceFee(id: number, utilityServiceFee: Subset<models.UtilityServiceFee>):
		Promise<FetchResult<responses.UpdateUtilityServiceFeeResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateUtilityServiceFeeResponse>({
			mutation: mutations.updateUtilityServiceFee,
			variables: { id, utilityServiceFee },
		}));
	}

	public createUtilityServiceFee(utilityServiceFee: Subset<models.UtilityServiceFee>):
		Promise<FetchResult<responses.CreateUtilityServiceFeeResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateUtilityServiceFeeResponse>({
			mutation: mutations.createUtilityServiceFee,
			variables: { utilityServiceFee },
		}));
	}

	public updateAlert(id: number, alert: models.Alert): Promise<FetchResult<responses.UpdateAlertResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateAlertResponse>({
			mutation: mutations.updateAlert,
			variables: { id, alert },
		}));
	}

	public sendRfqSessionSupplierInquiry(criteria: any): Promise<FetchResult<responses.SendSupplierSupportInquiryResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.SendSupplierSupportInquiryResponse>({
			mutation: mutations.sendSupplierSupportInquiry,
			variables: { criteria },
		}));
	}

	public sendRfqSessionSupplierExtensionRequest(criteria: any):
		Promise<FetchResult<responses.SendSupplierSupportExtensionRequestResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.SendSupplierSupportExtensionRequestResponse>({
			mutation: mutations.sendSupplierSupportExtensionRequest,
			variables: { criteria },
		}));
	}

	public getExtendAuctionReasonAddEditData(id: number): Promise<ApolloQueryResult<responses.ExtendAuctionReasonAddEditData>> {
		return lastValueFrom(this.apollo.query<responses.ExtendAuctionReasonAddEditData>({
			query: queries.getExtendAuctionReasonAddEditData,
			variables: { id },
		}));
	}

	public createRfqSessionExtendAuctionReason(rfqSessionExtendAuctionReason: Subset<models.RfqSessionExtendAuctionReasonType>):
		Promise<FetchResult<responses.CreateExtendAuctionReasonType>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateExtendAuctionReasonType>({
			mutation: mutations.createRfqSessionExtendAuctionReason,
			variables: { rfqSessionExtendAuctionReason },
		}));
	}

	public updateRfqSessionExtendAuctionReason(id: number, rfqSessionExtendAuctionReason: Subset<models.RfqSessionExtendAuctionReasonType>):
		Promise<FetchResult<responses.UpdateExtendAuctionReasonType>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateExtendAuctionReasonType>({
			mutation: mutations.updateRfqSessionExtendAuctionReason,
			variables: { id, rfqSessionExtendAuctionReason },
		}));
	}

	public getExtendAuctionReasonsListData(): Promise<ApolloQueryResult<responses.ExtendAuctionReasonListData>> {
		return lastValueFrom(this.apollo.query<responses.ExtendAuctionReasonListData>({
			query: queries.getExtendAuctionReasonsListData,
		}));
	}

	public getEmailPreferencesListData(): Promise<ApolloQueryResult<responses.EmailPreferencesListData>> {
		return lastValueFrom(this.apollo.query<responses.EmailPreferencesListData>({
			query: queries.getEmailPreferencesListData,
		}));
	}

	public getEmailPreferenceAddEditData(id: number): Promise<ApolloQueryResult<responses.EmailPreferenceAddEditData>> {
		return lastValueFrom(this.apollo.query<responses.EmailPreferenceAddEditData>({
			query: queries.getEmailPreferenceAddEditData,
			variables: { id },
		}));
	}

	public getLeadLinkSetupData(): Promise<ApolloQueryResult<responses.LeadLinkSetupData>> {
		return lastValueFrom(this.apollo.query<responses.LeadLinkSetupData>({
			query: queries.getLeadLinkSetupData,
		}));
	}

	public getEclLoadFactorBracketsListData(): Promise<ApolloQueryResult<responses.EclLoadFactorBracketsListData>> {
		return lastValueFrom(this.apollo.query<responses.EclLoadFactorBracketsListData>({
			query: queries.getEclLoadFactorBracketsListData,
		}));
	}

	public getEclLoadFactorBracketsAddEditData(id: number):
		Promise<ApolloQueryResult<responses.EclLoadFactorBracketAddEditData>> {
		return lastValueFrom(this.apollo.query<responses.EclLoadFactorBracketAddEditData>({
			query: queries.getEclLoadFactorBracketsAddEditData,
			variables: { id },
		}));
	}

	public getEclPackagesListData(): Promise<ApolloQueryResult<responses.EclPackagesListData>> {
		return lastValueFrom(this.apollo.query<responses.EclPackagesListData>({
			query: queries.getEclPackagesListData,
		}));
	}

	public getEclPackageAddEditPageData(): Promise<ApolloQueryResult<responses.EclPackageAddEditPageData>> {
		return lastValueFrom(this.apollo.query<responses.EclPackageAddEditPageData>({
			query: queries.getEclPackageAddEditPageData,
		}));
	}

	public getEclPackageAddEditData(id: number): Promise<ApolloQueryResult<responses.EclPackageAddEditData>> {
		return lastValueFrom(this.apollo.query<responses.EclPackageAddEditData>({
			query: queries.getEclPackageAddEditData,
			variables: { id },
		}));
	}

	public getEclCouponsListData(): Promise<ApolloQueryResult<responses.EclCouponsListData>> {
		return lastValueFrom(this.apollo.query<responses.EclCouponsListData>({
			query: queries.getEclCouponsListData,
		}));
	}

	public getEclCouponAddEditData(id: number): Promise<ApolloQueryResult<responses.EclCouponAddEditData>> {
		return lastValueFrom(this.apollo.query<responses.EclCouponAddEditData>({
			query: queries.getEclCouponAddEditData,
			variables: { id },
		}));
	}

	public getEclAnnualUsageBracketsListData(): Promise<ApolloQueryResult<responses.EclAnnualUsageBracketListData>> {
		return lastValueFrom(this.apollo.query<responses.EclAnnualUsageBracketListData>({
			query: queries.getEclAnnualUsageBracketsListData,
		}));
	}

	public getEclAnnualUsageBracketsAddEditData(id: number): Promise<ApolloQueryResult<responses.EclAnnualUsageBracketAddEditData>> {
		return lastValueFrom(this.apollo.query<responses.EclAnnualUsageBracketAddEditData>({
			query: queries.getEclAnnualUsageBracketsAddEditData,
			variables: { id },
		}));
	}

	public createEmailPreference(emailPreference: Subset<models.EmailPreference>): Promise<FetchResult<responses.CreateEmailPreference>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateEmailPreference>({
			mutation: mutations.createEmailPreference,
			variables: { emailPreference },
		}));
	}

	public updateEmailPreference(id: number, emailPreference: Subset<models.EmailPreference>):
		Promise<FetchResult<responses.UpdateEmailPreference>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateEmailPreference>({
			mutation: mutations.updateEmailPreference,
			variables: { id, emailPreference },
		}));
	}

	public createEclLoadFactorBracket(eclLoadFactorBracket: Subset<models.EclLoadFactorBracket>):
		Promise<FetchResult<responses.CreateEclLoadFactorBracket>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateEclLoadFactorBracket>({
			mutation: mutations.createEclLoadFactorBracket,
			variables: { eclLoadFactorBracket },
		}));
	}

	public updateEclLoadFactorBracket(id: number, eclLoadFactorBracket: Subset<models.EclLoadFactorBracket>):
		Promise<FetchResult<responses.UpdateEclLoadFactorBracket>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateEclLoadFactorBracket>({
			mutation: mutations.updateEclLoadFactorBracket,
			variables: { id, eclLoadFactorBracket },
		}));
	}

	public createEclPackage(eclPackage: Subset<models.EclPackage>): Promise<FetchResult<responses.CreateEclPackage>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateEclPackage>({
			mutation: mutations.createEclPackage,
			variables: { eclPackage },
		}));
	}

	public updateEclPackage(id: number, eclPackage: Subset<models.EclPackage>): Promise<FetchResult<responses.UpdateEclPackage>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateEclPackage>({
			mutation: mutations.updateEclPackage,
			variables: { id, eclPackage },
		}));
	}

	public createEclCoupon(eclCoupon: Subset<models.EclCoupon>): Promise<FetchResult<responses.CreateEclCoupon>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateEclCoupon>({
			mutation: mutations.createEclCoupon,
			variables: { eclCoupon },
		}));
	}

	public updateEclCoupon(id: number, eclCoupon: Subset<models.EclCoupon>): Promise<FetchResult<responses.UpdateEclCoupon>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateEclCoupon>({
			mutation: mutations.updateEclCoupon,
			variables: { id, eclCoupon },
		}));
	}

	public createEclAnnualUsageBracket(eclAnnualUsageBracket: Subset<models.EclAnnualUsageBracket>):
		Promise<FetchResult<responses.CreateEclAnnualUsageBracket>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateEclAnnualUsageBracket>({
			mutation: mutations.createEclAnnualUsageBracket,
			variables: { eclAnnualUsageBracket },
		}));
	}

	public updateEclAnnualUsageBracket(id: number, eclAnnualUsageBracket: Subset<models.EclAnnualUsageBracket>):
		Promise<FetchResult<responses.UpdateEclAnnualUsageBracket>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateEclAnnualUsageBracket>({
			mutation: mutations.updateEclAnnualUsageBracket,
			variables: { id, eclAnnualUsageBracket },
		}));
	}

	public createAgentEclState(agentId: string, agentEclState: models.AgentEclState):
		Promise<FetchResult<responses.CreateAgentEclState>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateAgentEclState>({
			mutation: mutations.createAgentEclState,
			variables: { agentId, agentEclState },
		}));
	}

	public deleteAgentEclState(agentId: string, agentEclState: models.AgentEclState):
		Promise<FetchResult<responses.DeleteAgentEclState>> {
		return lastValueFrom(this.apollo.mutate<responses.DeleteAgentEclState>({
			mutation: mutations.deleteAgentEclState,
			variables: { agentId, agentEclState },
		}));
	}

	public createAgentEclAnnualUsageBracket(agentId: string, agentEclAnnualUsageBracket: models.AgentEclAnnualUsageBracket):
		Promise<FetchResult<responses.CreateAgentEclAnnualUsageBracket>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateAgentEclAnnualUsageBracket>({
			mutation: mutations.createAgentEclAnnualUsageBracket,
			variables: { agentId, agentEclAnnualUsageBracket },
		}));
	}

	public deleteAgentEclAnnualUsageBracket(agentId: string, agentEclAnnualUsageBracket: models.AgentEclAnnualUsageBracket):
		Promise<FetchResult<responses.DeleteAgentEclAnnualUsageBracket>> {
		return lastValueFrom(this.apollo.mutate<responses.DeleteAgentEclAnnualUsageBracket>({
			mutation: mutations.deleteAgentEclAnnualUsageBracket,
			variables: { agentId, agentEclAnnualUsageBracket },
		}));
	}

	public createAgentEclLoadFactorBracket(agentId: string, agentEclLoadFactorBracket: models.AgentEclLoadFactorBracket):
		Promise<FetchResult<responses.CreateAgentEclLoadFactorBracket>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateAgentEclLoadFactorBracket>({
			mutation: mutations.createAgentEclLoadFactorBracket,
			variables: { agentId, agentEclLoadFactorBracket },
		}));
	}

	public deleteAgentEclLoadFactorBracket(agentId: string, agentEclLoadFactorBracket: models.AgentEclLoadFactorBracket):
		Promise<FetchResult<responses.DeleteAgentEclLoadFactorBracket>> {
		return lastValueFrom(this.apollo.mutate<responses.DeleteAgentEclLoadFactorBracket>({
			mutation: mutations.deleteAgentEclLoadFactorBracket,
			variables: { agentId, agentEclLoadFactorBracket },
		}));
	}

	public createAgentEmailPreference(agentId: string, agentEmailPreference: Subset<models.AgentEmailPreference>):
		Promise<FetchResult<responses.CreateAgentEmailPreference>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateAgentEmailPreference>({
			mutation: mutations.createAgentEmailPreference,
			variables: { agentId, agentEmailPreference },
		}));
	}

	public deleteAgentEmailPreference(agentId: string, agentEmailPreference: Subset<models.AgentEmailPreference>):
		Promise<FetchResult<responses.DeleteAgentEmailPreference>> {
		return lastValueFrom(this.apollo.mutate<responses.DeleteAgentEmailPreference>({
			mutation: mutations.deleteAgentEmailPreference,
			variables: { agentId, agentEmailPreference },
		}));
	}

	public createCustomerEmailPreference(customerId: string, customerEmailPreference: models.CustomerEmailPreference):
		Promise<FetchResult<responses.CreateCustomerEmailPreference>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateCustomerEmailPreference>({
			mutation: mutations.createCustomerEmailPreference,
			variables: { customerId, customerEmailPreference },
		}));
	}

	public deleteCustomerEmailPreference(customerId: string, customerEmailPreference: models.CustomerEmailPreference):
		Promise<FetchResult<responses.DeleteCustomerEmailPreference>> {
		return lastValueFrom(this.apollo.mutate<responses.DeleteCustomerEmailPreference>({
			mutation: mutations.deleteCustomerEmailPreference,
			variables: { customerId, customerEmailPreference },
		}));
	}

	public createDisclaimer(disclaimer: Subset<models.Disclaimer>): Promise<FetchResult<responses.CreateDisclaimer>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateDisclaimer>({
			mutation: mutations.createDisclaimer,
			variables: { disclaimer },
		}));
	}

	public updateDisclaimer(disclaimerId: number, disclaimer: Subset<models.Disclaimer>): Promise<FetchResult<responses.UpdateDisclaimer>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateDisclaimer>({
			mutation: mutations.updateDisclaimer,
			variables: { disclaimerId, disclaimer },
		}));
	}

	public getRateMatrixLoadersListData(): Promise<ApolloQueryResult<responses.RateMatrixLoadersListData>> {
		return lastValueFrom(this.apollo.query<responses.RateMatrixLoadersListData>({
			query: queries.getRateMatrixLoadersListData,
		}));
	}

	public getRateMatrixLoadersAddEditData(id: number): Promise<ApolloQueryResult<responses.RateMatrixLoadersAddEditData>> {
		return lastValueFrom(this.apollo.query<responses.RateMatrixLoadersAddEditData>({
			query: queries.getRateMatrixLoadersAddEditData,
			variables: { id },
		}));
	}

	public getRateMatrixLoadersEditPageData(): Promise<ApolloQueryResult<responses.RateMatrixLoadersEditPageData>> {
		return lastValueFrom(this.apollo.query<responses.RateMatrixLoadersEditPageData>({
			query: queries.getRateMatrixLoadersEditPageData,
		}));
	}

	public getRateMatrixRemovalPageData(): Promise<ApolloQueryResult<responses.RateMatrixRemovalPageData>> {
		return lastValueFrom(this.apollo.query<responses.RateMatrixRemovalPageData>({
			query: queries.getRateMatrixRemovalPageData,
		}));
	}

	public deleteRateMatrix(criteria: models.DeleteRateMatrixInput): Promise<FetchResult<responses.DeleteRateMatrixResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.DeleteRateMatrixResponse>({
			mutation: mutations.deleteRateMatrix,
			variables: { criteria },
		}));
	}

	public createRateMatrixLoader(rateMatrixLoader: Subset<models.RateMatrixLoader>):
		Promise<FetchResult<responses.CreateRateMatrixLoaderResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateRateMatrixLoaderResponse>({
			mutation: mutations.createRateMatrixLoader,
			variables: { rateMatrixLoader },
		}));
	}

	public updateRateMatrixLoader(id: number, rateMatrixLoader: Subset<models.RateMatrixLoader>):
		Promise<FetchResult<responses.UpdateRateMatrixLoaderResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateRateMatrixLoaderResponse>({
			mutation: mutations.updateRateMatrixLoader,
			variables: { id, rateMatrixLoader },
		}));
	}

	public getNew1099VendorsReportPageData(reportId: string): Promise<ApolloQueryResult<responses.New1099VendorsReportPageData>> {
		return lastValueFrom(this.apollo.query<responses.New1099VendorsReportPageData>({
			query: queries.getNew1099VendorsReportPageData,
			variables: { reportId },
		}));
	}

	public getNew1099VendorsReportData(reportId: string, criteria: any): Promise<ApolloQueryResult<responses.New1099VendorsReportData>> {
		return lastValueFrom(this.apollo.query<responses.New1099VendorsReportData>({
			query: queries.getNew1099VendorsReportData,
			variables: { reportId, criteria },
		}));
	}

	public updateEdiUtility(id: number, ediUtility: Subset<models.EdiUtility>):
		Promise<FetchResult<responses.UpdateEdiUtilityResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateEdiUtilityResponse>({
			mutation: mutations.updateEdiUtility,
			variables: { id, ediUtility },
		}));
	}

	public createEdiUtility(ediUtility: Subset<models.EdiUtility>): Promise<FetchResult<responses.CreateEdiUtilityResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateEdiUtilityResponse>({
			mutation: mutations.createEdiUtility,
			variables: { ediUtility },
		}));
	}

	public updateUtilityWebPortal(id: number, utilityWebPortal: Subset<models.UtilityWebPortal>):
		Promise<FetchResult<responses.UpdateUtilityWebPortalResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateUtilityWebPortalResponse>({
			mutation: mutations.updateUtilityWebPortal,
			variables: { id, utilityWebPortal },
		}));
	}

	public createUtilityWebPortal(utilityWebPortal: Subset<models.UtilityWebPortal>):
		Promise<FetchResult<responses.CreateUtilityWebPortalResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateUtilityWebPortalResponse>({
			mutation: mutations.createUtilityWebPortal,
			variables: { utilityWebPortal },
		}));
	}

	public convertContractToResidential(contractId: string): Promise<FetchResult<responses.ConvertContractToResidentialResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.ConvertContractToResidentialResponse>({
			mutation: mutations.convertContractToResidential,
			variables: { contractId },
		}));
	}

	public getAgentLocationCommissions(contractId: string, contractLocationId: string, agentId: string):
		Promise<ApolloQueryResult<responses.GetAgentLocationCommissionsResponse>> {
		return lastValueFrom(this.apollo.query<responses.GetAgentLocationCommissionsResponse>({
			query: queries.getAgentLocationCommissions,
			variables: { contractId, contractLocationId, agentId },
		}));
	}

	public createCustomCommissionPayable(contractId: string, commissionPayable: models.CommissionPayable):
		Promise<FetchResult<responses.CreateCustomCommissionPayableResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateCustomCommissionPayableResponse>({
			mutation: mutations.createCustomCommissionPayable,
			variables: { contractId, commissionPayable },
		}));
	}

	public sendUrgentCallRequestEmail(urgentCallRequest: any): Promise<ApolloQueryResult<responses.StringResponse>> {
		return lastValueFrom(this.apollo.query<responses.StringResponse>({
			query: queries.sendUrgentCallRequestEmail,
			variables: { urgentCallRequest },
		}));
	}

	public getCommissionPayPlanFormData(contractDate: string, companyId: string):
		Promise<ApolloQueryResult<responses.CommissionPayPlanFormData>> {
		return lastValueFrom(this.apollo.query<responses.CommissionPayPlanFormData>({
			query: queries.getCommissionPayPlanFormData,
			variables: { contractDate, companyId },
		}));
	}

	public getContractCommissionPayPlanFormData(contractId: string):
		Promise<ApolloQueryResult<responses.ContractCommissionPayPlanFormData>> {
		return lastValueFrom(this.apollo.query<responses.ContractCommissionPayPlanFormData>({
			query: queries.getContractCommissionPayPlanFormData,
			variables: { contractId },
		}));
	}

	public getEmailCriteriaListPageData(): Promise<ApolloQueryResult<responses.GetEmailCriteriaListPageData>> {
		return lastValueFrom(this.apollo.query<responses.GetEmailCriteriaListPageData>({
			query: queries.getEmailCriteriaListPageData,
		}));
	}

	public getEmailCriteriaListEmailAccountsData(companyId: string):
		Promise<ApolloQueryResult<responses.GetEmailCriteriaListEmailAccountsData>> {
		return lastValueFrom(this.apollo.query<responses.GetEmailCriteriaListEmailAccountsData>({
			query: queries.getEmailCriteriaListEmailAccountsData,
			variables: { companyId },
		}));
	}

	public getEdiResponsesLatestUsage(contractId: string): Promise<ApolloQueryResult<responses.EdiResponsesLatestUsageData>> {
		return lastValueFrom(this.apollo.query<responses.EdiResponsesLatestUsageData>({
			query: queries.getEdiResponsesLatestUsage,
			variables: { contractId },
		}));
	}

	public getAuditsListData(): Promise<ApolloQueryResult<responses.AuditsListData>> {
		return lastValueFrom(this.apollo.query<responses.AuditsListData>({
			query: queries.getAuditsListData,
			variables: {},
		}));
	}

	public updateAudit(id: number, audit: any): Promise<FetchResult<responses.UpdateAuditResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateAuditResponse>({
			mutation: mutations.updateAudit,
			variables: { id, audit },
		}));
	}

	public getFaqCategoryListData(): Promise<FetchResult<responses.FaqCategoryListData>> {
		return lastValueFrom(this.apollo.query<responses.FaqCategoryListData>({
			query: queries.getFaqCategoryListData,
		}));
	}

	public getEducationCategoryListData(): Promise<FetchResult<responses.EducationCategoryListData>> {
		return lastValueFrom(this.apollo.query<responses.EducationCategoryListData>({
			query: queries.getEducationCategoryListData,
		}));
	}

	public getEducationTypeListData(): Promise<FetchResult<responses.EducationTypeListData>> {
		return lastValueFrom(this.apollo.query<responses.EducationTypeListData>({
			query: queries.getEducationTypeListData,
		}));
	}

	public getFlowChecksListData(): Promise<ApolloQueryResult<responses.FlowChecksListData>> {
		return lastValueFrom(this.apollo.query<responses.FlowChecksListData>({
			query: queries.getFlowChecksListData,
			variables: {},
		}));
	}

	public getFaqCategoryData(id: number): Promise<FetchResult<responses.FaqCategoryData>> {
		return lastValueFrom(this.apollo.query<responses.FaqCategoryData>({
			query: queries.getFaqCategoryData,
			variables: { id },
		}));
	}

	public getFaqSearchSub(criteria: any):
		Observable<{ criteria: any; result: ApolloQueryResult<responses.FaqSearchSubData> }> {
		return this.apollo.query<responses.FaqSearchSubData>({
			query: queries.getFaqSearchSub,
			variables: {
				faqSearchCriteria: {
					...criteria,
					faqCategoryIdNE: 1,
					isActive: true,
				},
			},
		}).pipe(
			map(res => ({
				criteria,
				result: res,
			})),
		);
	}

	public getSlideoutSearchSub(criteria: string, user: models.User):
		Observable<{ criteria: string; result: ApolloQueryResult<responses.FaqSearchSubData> }> {
		let showTrainingVideos = true;
		if (user.agentId) {
			if (user.agent.parentId) {
				showTrainingVideos = user.agent.showTrainingVideos && user.agent.parent.showTrainingVideos;
			} else {
				showTrainingVideos = user.agent.showTrainingVideos;
			}
		}


		const educationSearchCriteria: any = { search: criteria, isActive: true };
		if (!showTrainingVideos) {
			educationSearchCriteria.educationCategoryIdNE = '8'; // weekly training videos
		}

		return this.apollo.query<responses.FaqSearchSubData>({
			query: queries.getSlideoutSearchSub,
			variables: {
				siteSectionSearchCriteria: {
					search: criteria,
					faqCategoryId: 1,
					isActive: true,
				},
				faqSearchCriteria: {
					search: criteria,
					faqCategoryIdNE: 1,
					isActive: true,
				},
				educationSearchCriteria,
			},
		}).pipe(
			map(res => ({
				criteria,
				result: res,
			})),
		);
	}

	public getFaqsByCategory(faqCategoryId: number, max: number): Promise<ApolloQueryResult<responses.FaqsByCategoryData>> {
		return lastValueFrom(this.apollo.query<responses.FaqsByCategoryData>({
			query: queries.getFaqsByCategory,
			variables: {
				criteria: {
					faqCategoryId,
					isActive: true,
				},
				max,
			},
		}));
	}

	public getEducationCategoryData(id: number): Promise<FetchResult<responses.EducationCategoryData>> {
		return lastValueFrom(this.apollo.query<responses.EducationCategoryData>({
			query: queries.getEducationCategoryData,
			variables: { id },
		}));
	}

	public getEducationTypeData(id: number): Promise<FetchResult<responses.EducationTypeData>> {
		return lastValueFrom(this.apollo.query<responses.EducationTypeData>({
			query: queries.getEducationTypeData,
			variables: { id },
		}));
	}

	public getEducationSearchSub(criteria: any, user: models.User):
		Observable<{ criteria: any; result: ApolloQueryResult<responses.EducationSearchSubData> }> {
		const educationSearchCriteria = criteria;
		if (user.agentId && user.agent.parentId) {
			educationSearchCriteria.educationCategoryIdNE = '8'; // weekly training videos
		}

		educationSearchCriteria.isActive = true;

		return this.apollo.query<responses.EducationSearchSubData>({
			query: queries.getEducationSearchSub,
			variables: { criteria: educationSearchCriteria },
		}).pipe(
			map(res => ({
				criteria,
				result: res,
			})),
		);
	}

	public getEducationsByCategory(educationCategoryId: string, max: number):
		Promise<ApolloQueryResult<responses.EducationsByCategoryData>> {
		return lastValueFrom(this.apollo.query<responses.EducationsByCategoryData>({
			query: queries.getEducationsByCategory,
			variables: {
				criteria: {
					educationCategoryId,
					isActive: true,
				},
				max,
			},
		}));
	}

	public getEducationsByAgent(agentId: string, max: number):
		Promise<ApolloQueryResult<responses.EducationsByAgentData>> {
		return lastValueFrom(this.apollo.query<responses.EducationsByAgentData>({
			query: queries.getEducationsByAgent,
			variables: {
				criteria: {
					agentId,
					isActive: true,
				},
				max,
			},
		}));
	}

	public getFaqSearchModalData(): Promise<ApolloQueryResult<responses.FaqSearchModalData>> {
		return lastValueFrom(this.apollo.query<responses.FaqSearchModalData>({
			query: queries.getFaqSearchModalData,
		}));
	}

	public updateFlowCheck(id: number, flowCheck: any): Promise<FetchResult<responses.UpdateFlowCheckResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateFlowCheckResponse>({
			mutation: mutations.updateFlowCheck,
			variables: { id, flowCheck },
		}));
	}

	public createFlowCheck(flowCheck: any): Promise<FetchResult<responses.CreateFlowCheckResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateFlowCheckResponse>({
			mutation: mutations.createFlowCheck,
			variables: { flowCheck },
		}));
	}

	public sendFlowCheckEmail(id: number): Promise<FetchResult<responses.SendFlowCheckEmailResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.SendFlowCheckEmailResponse>({
			mutation: mutations.sendFlowCheckEmail,
			variables: { id },
		}));
	}

	public sendAuditEmail(id: number, supplierId: string): Promise<FetchResult<responses.SendAuditEmailResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.SendAuditEmailResponse>({
			mutation: mutations.sendAuditEmail,
			variables: { id, supplierId },
		}));
	}

	public auditWorkbook(id: number, supplierId: string): Promise<FetchResult<responses.AuditWorkbookResponse>> {
		return lastValueFrom(this.apollo.query<responses.AuditWorkbookResponse>({
			query: queries.auditWorkbook,
			variables: { id, supplierId },
		}));
	}

	public getAdminConfirmationsData(): Promise<ApolloQueryResult<responses.AdminConfirmationsData>> {
		return lastValueFrom(this.apollo.query<responses.AdminConfirmationsData>({
			query: queries.getAdminConfirmationsData,
		}));
	}

	public getAdminCommissionsData(): Promise<ApolloQueryResult<responses.AdminCommissionsData>> {
		return lastValueFrom(this.apollo.query<responses.AdminCommissionsData>({
			query: queries.getAdminCommissionsData,
		}));
	}

	public updateContractCommissionPayPlan(contractId: string, commissionPayPlanId: number):
		Promise<FetchResult<responses.UpdateContractCommissionPayPlanResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateContractCommissionPayPlanResponse>({
			mutation: mutations.updateContractCommissionPayPlan,
			variables: { contractId, commissionPayPlanId },
		}));
	}

	public updateContractPurchasingLayerPurchaseHistories(contractId: string, purchasingLayerPurchaseHistories: any):
		Promise<FetchResult<responses.UpdateContractPurchasingLayerPurchaseHistoriesResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateContractPurchasingLayerPurchaseHistoriesResponse>({
			mutation: mutations.updateContractPurchasingLayerPurchaseHistories,
			variables: { contractId, purchasingLayerPurchaseHistories },
		}));
	}

	public updateContractUsePurchasingLayer(contractId: string, usePurchasingLayer: boolean):
		Promise<FetchResult<responses.UpdateContractUsePurchasingLayerResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateContractUsePurchasingLayerResponse>({
			mutation: mutations.updateContractUsePurchasingLayer,
			variables: { contractId, usePurchasingLayer },
		}));
	}


	public sendPurchasingLayerShareEmail(id: number, email: string):
		Promise<FetchResult<responses.SendPurchasingLayerShareEmailResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.SendPurchasingLayerShareEmailResponse>({
			mutation: mutations.sendPurchasingLayerShareEmail,
			variables: { id, email },
		}));
	}

	public sendContractPerformanceShareEmail(contractId: string, email: string):
		Promise<FetchResult<responses.SendContractPerformanceShareEmailResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.SendContractPerformanceShareEmailResponse>({
			mutation: mutations.sendContractPerformanceShareEmail,
			variables: { contractId, email },
		}));
	}

	public requestUsage(contractLocationId: string): Promise<FetchResult<responses.RequestUsageResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.RequestUsageResponse>({
			mutation: mutations.requestUsage,
			variables: { contractLocationId },
		}));
	}

	public getAdminCommissionsContractLocationsData(contractId: string):
		Promise<ApolloQueryResult<responses.AdminCommissionsContractLocationsData>> {
		return lastValueFrom(this.apollo.query<responses.AdminCommissionsContractLocationsData>({
			query: queries.getAdminCommissionsContractLocationsData,
			variables: { contractId },
		}));
	}

	public getAdminCommissionsInvoicesPageData(invoiceStatusId: string | number):
		Promise<ApolloQueryResult<responses.AdminCommissionsInvoicesPageData>> {
		return lastValueFrom(this.apollo.query<responses.AdminCommissionsInvoicesPageData>({
			query: queries.getAdminCommissionsInvoicesPageData,
			variables: { invoiceStatusId },
		}));
	}

	public getAdminCommissionsInvoiceStatsData(invoiceStatusId: string | number):
		Promise<ApolloQueryResult<responses.AdminCommissionsInvoiceStatsData>> {
		return lastValueFrom(this.apollo.query<responses.AdminCommissionsInvoiceStatsData>({
			query: queries.getAdminCommissionsInvoiceStatsData,
			variables: { invoiceStatusId },
		}));
	}

	public getAdminCommissionsViewInvoicesData(criteria: Partial<models.ViewInvoiceInput>):
		Promise<ApolloQueryResult<responses.AdminCommissionsViewInvoicesData>> {
		return lastValueFrom(this.apollo.query<responses.AdminCommissionsViewInvoicesData>({
			query: queries.getAdminCommissionsViewInvoicesData,
			variables: { criteria },
		}));
	}

	public getAdminCommissionsInvoiceTotalsData(startDate: string, endDate: string):
		Promise<ApolloQueryResult<responses.AdminCommissionsInvoiceTotalsData>> {
		return lastValueFrom(this.apollo.query<responses.AdminCommissionsInvoiceTotalsData>({
			query: queries.getAdminCommissionsInvoiceTotalsData,
			variables: { startDate, endDate },
		}));
	}

	public getAdminCommissionsEditInvoiceData(invoiceId: string | number, supplierId: string):
		Promise<ApolloQueryResult<responses.AdminCommissionsEditInvoiceData>> {
		return lastValueFrom(this.apollo.query<responses.AdminCommissionsEditInvoiceData>({
			query: queries.getAdminCommissionsEditInvoiceData,
			variables: { invoiceId, supplierId },
		}));
	}

	public getAdminCommissionsSupplierDropdownData(criteria: any):
		Promise<ApolloQueryResult<responses.AdminCommissionsSupplierDropdownData>> {
		return lastValueFrom(this.apollo.query<responses.AdminCommissionsSupplierDropdownData>({
			query: queries.getAdminCommissionsSupplierDropdownData,
			variables: { criteria },
		}));
	}

	public updateInvoicesStatus(id: string, invoiceStatusId: number):
		Promise<FetchResult<responses.UpdateInvoicesStatusData>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateInvoicesStatusData>({
			mutation: mutations.updateInvoicesStatus,
			variables: { id, invoiceStatusId },
		}));
	}

	public deleteInvoices(id: string):
		Promise<FetchResult<responses.DeleteInvoicesData>> {
		return lastValueFrom(this.apollo.mutate<responses.DeleteInvoicesData>({
			mutation: mutations.deleteInvoices,
			variables: { id },
		}));
	}

	public createInvoice(invoice: Subset<models.Invoice>):
		Promise<FetchResult<responses.CreateInvoiceResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateInvoiceResponse>({
			mutation: mutations.createInvoice,
			variables: { invoice },
		}));
	}

	public updateInvoice(id: number, invoice: Subset<models.Invoice>):
		Promise<FetchResult<responses.UpdateInvoiceResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateInvoiceResponse>({
			mutation: mutations.updateInvoice,
			variables: { id, invoice },
		}));
	}

	public getContractLocationCommissionContractData(contractId: string):
		Promise<ApolloQueryResult<responses.ContractLocationCommissionContractData>> {
		return lastValueFrom(this.apollo.query<responses.ContractLocationCommissionContractData>({
			query: queries.getContractLocationCommissionContractData,
			variables: { contractId },
		}));
	}

	public getContractLocationPendingCommissionContractData(contractId: string):
		Promise<ApolloQueryResult<responses.ContractLocationPendingCommissionContractData>> {
		return lastValueFrom(this.apollo.query<responses.ContractLocationPendingCommissionContractData>({
			query: queries.getContractLocationPendingCommissionContractData,
			variables: { contractId },
		}));
	}

	public createFaqAudit(faqAudit: Subset<models.FaqAudit>):
		Promise<FetchResult<responses.CreateFaqAuditResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateFaqAuditResponse>({
			mutation: mutations.createFaqAudit,
			variables: { faqAudit },
		}));
	}

	public createFaqAuditArticle(id: number, faqAuditArticle: Subset<models.FaqAuditArticle>):
		Promise<FetchResult<responses.CreateFaqAuditArticleResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateFaqAuditArticleResponse>({
			mutation: mutations.createFaqAuditArticle,
			variables: { id, faqAuditArticle },
		}));
	}

	public createEducationAudit(educationAudit: Subset<models.EducationAudit>):
		Promise<FetchResult<responses.CreateEducationAuditResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateEducationAuditResponse>({
			mutation: mutations.createEducationAudit,
			variables: { educationAudit },
		}));
	}

	public createEducationAuditArticle(id: number, educationAuditArticle: models.EducationAuditArticle):
		Promise<FetchResult<responses.CreateEducationAuditArticleResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateEducationAuditArticleResponse>({
			mutation: mutations.createEducationAuditArticle,
			variables: { id, educationAuditArticle },
		}));
	}

	public getCommissionPreviewReportPageData():
		Promise<ApolloQueryResult<responses.GetCommissionPreviewReportPageData>> {
		return lastValueFrom(this.apollo.query<responses.GetCommissionPreviewReportPageData>({
			query: queries.getCommissionPreviewReportPageData,
			variables: {},
		}));
	}

	public getSupplierCommissionAttachmentPreview(id: number):
		Promise<ApolloQueryResult<responses.GetSupplierCommissionAttachmentPreviewResponse>> {
		return lastValueFrom(this.apollo.query<responses.GetSupplierCommissionAttachmentPreviewResponse>({
			query: queries.getSupplierCommissionAttachmentPreview,
			variables: { id },
		}));
	}

	public getCommissionMappingPageData(): Promise<ApolloQueryResult<responses.GetCommissionMappingPageData>> {
		return lastValueFrom(this.apollo.query<responses.GetCommissionMappingPageData>({
			query: queries.getCommissionMappingPageData,
			variables: {},
		}));
	}

	public getSupplierCommissionTypeMapData(): Promise<ApolloQueryResult<responses.SupplierCommissionTypeMapData>> {
		return lastValueFrom(this.apollo.query<responses.SupplierCommissionTypeMapData>({
			query: queries.getSupplierCommissionTypeMapData,
			variables: {},
		}));
	}

	public getContractLocationCommissionModalData(commissionId: string):
		Promise<ApolloQueryResult<responses.ContractLocationCommissionModalData>> {
		return lastValueFrom(this.apollo.query<responses.ContractLocationCommissionModalData>({
			query: queries.getContractLocationCommissionModalData,
			variables: { commissionId },
		}));
	}

	public getContractLocationPendingCommissionModalData(id: number):
		Promise<ApolloQueryResult<responses.ContractLocationPendingCommissionModalData>> {
		return lastValueFrom(this.apollo.query<responses.ContractLocationPendingCommissionModalData>({
			query: queries.getContractLocationPendingCommissionModalData,
			variables: { id },
		}));
	}

	public getCommissionEarlyPaymentPageData(): Promise<ApolloQueryResult<responses.CommissionEarlyPaymentPageData>> {
		return lastValueFrom(this.apollo.query<responses.CommissionEarlyPaymentPageData>({
			query: queries.getCommissionEarlyPaymentPageData,
		}));
	}

	public getContractCommissionEstimates(contractId: string): Promise<ApolloQueryResult<responses.ContractCommissionEstimatesData>> {
		return lastValueFrom(this.apollo.query<responses.ContractCommissionEstimatesData>({
			query: queries.getContractCommissionEstimates,
			variables: { contractId },
		}));
	}

	public getContractLocationCommissionEstimates(contractLocationId: string):
		Promise<ApolloQueryResult<responses.ContractLocationCommissionEstimatesData>> {
		return lastValueFrom(this.apollo.query<responses.ContractLocationCommissionEstimatesData>({
			query: queries.getContractLocationCommissionEstimates,
			variables: { contractLocationId },
		}));
	}

	public createCommission(commission: Subset<models.Commission>):
		Promise<FetchResult<responses.CreateCommissionResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateCommissionResponse>({
			mutation: mutations.createCommission,
			variables: { commission },
		}));
	}

	public updateCommission(commissionId: string, commission: Subset<models.Commission>):
		Promise<FetchResult<responses.UpdateCommissionResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateCommissionResponse>({
			mutation: mutations.updateCommission,
			variables: { commissionId, commission },
		}));
	}

	public deleteCommission(commissionId: string): Promise<FetchResult<responses.DeleteCommissionResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.DeleteCommissionResponse>({
			mutation: mutations.deleteCommission,
			variables: { commissionId },
		}));
	}

	public deleteSupplierCommissionMap(id: number): Promise<FetchResult<responses.DeleteSupplierCommissionMapResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.DeleteSupplierCommissionMapResponse>({
			mutation: mutations.deleteSupplierCommissionMap,
			variables: { id },
		}));
	}

	public updateSupplierCommissionMap(id: number, supplierCommissionMap: Subset<models.SupplierCommissionMap>):
		Promise<FetchResult<responses.UpdateSupplierCommissionMapResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateSupplierCommissionMapResponse>({
			mutation: mutations.updateSupplierCommissionMap,
			variables: { id, supplierCommissionMap },
		}));
	}

	public createSupplierCommissionMap(supplierCommissionMap: any):
		Promise<FetchResult<responses.CreateSupplierCommissionMapResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateSupplierCommissionMapResponse>({
			mutation: mutations.createSupplierCommissionMap,
			variables: { supplierCommissionMap },
		}));
	}

	public updateSupplierCommissionTypeMap(id: number, supplierCommissionTypeMap: Subset<models.SupplierCommissionTypeMap>):
		Promise<FetchResult<responses.UpdateSupplierCommissionTypeMapResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateSupplierCommissionTypeMapResponse>({
			mutation: mutations.updateSupplierCommissionTypeMap,
			variables: { id, supplierCommissionTypeMap },
		}));
	}

	public updateCommissionEstimate(id: number, commissionEstimate: any): Promise<FetchResult<responses.UpdateCommissionEstimateResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateCommissionEstimateResponse>({
			mutation: mutations.updateCommissionEstimate,
			variables: { id, commissionEstimate },
		}));
	}

	public createCommissionEstimate(commissionEstimate: any): Promise<FetchResult<responses.CreateCommissionEstimateResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateCommissionEstimateResponse>({
			mutation: mutations.createCommissionEstimate,
			variables: { commissionEstimate },
		}));
	}

	public deleteCommissionEstimate(id: number): Promise<FetchResult<responses.DeleteCommissionEstimateResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.DeleteCommissionEstimateResponse>({
			mutation: mutations.deleteCommissionEstimate,
			variables: { id },
		}));
	}

	public getCurrentUserSurvey(criteria: Partial<models.UserSurveyInput>):
		Promise<ApolloQueryResult<responses.GetCurrentUserSurveyResponse>> {
		return lastValueFrom(this.apollo.query<responses.GetCurrentUserSurveyResponse>({
			query: queries.getCurrentUserSurvey,
			variables: { criteria },
		}));
	}

	public updateUserSurvey(id: number, userSurvey: Partial<models.UserSurvey>):
		Promise<FetchResult<responses.UpdateUserSurveyResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateUserSurveyResponse>({
			mutation: mutations.updateUserSurvey,
			variables: { id, userSurvey },
		}));
	}

	public updateUserSurveySetting(id: number, userSurveySetting: Partial<models.UserSurveySetting>):
		Promise<FetchResult<responses.UpdateUserSurveyResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateUserSurveyResponse>({
			mutation: mutations.updateUserSurveySetting,
			variables: { id, userSurveySetting },
		}));
	}

	public createUserSurvey(userSurvey: Partial<models.UserSurveyInput>): Promise<FetchResult<responses.CreateUserSurveyResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateUserSurveyResponse>({
			mutation: mutations.createUserSurvey,
			variables: { userSurvey },
		}));
	}

	public getUserSurveyHistoricData(): Promise<FetchResult<responses.GetUserSurveyHistoricDataResponse>> {
		return lastValueFrom(this.apollo.query<responses.GetUserSurveyHistoricDataResponse>({
			query: queries.getUserSurveyHistoricData,
		}));
	}

	public createUserAudit(userAudit: any): Promise<FetchResult<responses.CreateUserAuditResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateUserAuditResponse>({
			mutation: mutations.createUserAudit,
			variables: { userAudit },
		}));
	}

	public createUserAuditType(userAuditType: any): Promise<FetchResult<responses.CreateUserAuditTypeResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateUserAuditTypeResponse>({
			mutation: mutations.createUserAuditType,
			variables: { userAuditType },
		}));
	}

	public updateUserAuditType(id: number, userAuditType: any): Promise<FetchResult<responses.UpdateUserAuditTypeResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateUserAuditTypeResponse>({
			mutation: mutations.updateUserAuditType,
			variables: { id, userAuditType },
		}));
	}

	public createMarginChangeReasonType(marginChangeReasonType: Subset<models.MarginChangeReasonType>):
		Promise<FetchResult<responses.CreateMarginChangeReasonTypeResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateMarginChangeReasonTypeResponse>({
			mutation: mutations.createMarginChangeReasonType,
			variables: { marginChangeReasonType },
		}));
	}

	public updateMarginChangeReasonType(id: number, marginChangeReasonType: Subset<models.MarginChangeReasonType>):
		Promise<FetchResult<responses.UpdateMarginChangeReasonTypeResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateMarginChangeReasonTypeResponse>({
			mutation: mutations.updateMarginChangeReasonType,
			variables: { id, marginChangeReasonType },
		}));
	}

	public getMarginChangeReasonTypesAddEditData(id: number): Promise<ApolloQueryResult<responses.MarginChangeReasonTypesAddEditData>> {
		return lastValueFrom(this.apollo.query<responses.MarginChangeReasonTypesAddEditData>({
			query: queries.getMarginChangeReasonTypesAddEditData,
			variables: { id },
		}));
	}

	public getMarginChangeReasonTypesListData(): Promise<ApolloQueryResult<responses.MarginChangeReasonTypesListData>> {
		return lastValueFrom(this.apollo.query<responses.MarginChangeReasonTypesListData>({
			query: queries.getMarginChangeReasonTypesListData,
			variables: {},
		}));
	}

	public getTicketComments(ticketId: number): Promise<ApolloQueryResult<responses.TicketCommentData>> {
		return lastValueFrom(this.apollo.query<responses.TicketCommentData>({
			query: queries.getTicketComments,
			variables: { ticketId },
		}));
	}

	public getCustomerUnsubscribePageData(customerId: string): Promise<ApolloQueryResult<responses.CustomerUnsubscribePageData>> {
		return lastValueFrom(this.apollo.query<responses.CustomerUnsubscribePageData>({
			query: queries.getCustomerUnsubscribePageData,
			variables: { customerId },
		}));
	}

	public getLeadUnsubscribePageData(id: number): Promise<ApolloQueryResult<responses.LeadUnsubscribePageData>> {
		return lastValueFrom(this.apollo.query<responses.LeadUnsubscribePageData>({
			query: queries.getLeadUnsubscribePageData,
			variables: { id },
		}));
	}

	public sendInvoiceEmail(criteria: any):
		Promise<FetchResult<responses.SendInvoiceEmail>> {
		return lastValueFrom(this.apollo.mutate<responses.SendInvoiceEmail>({
			mutation: mutations.sendInvoiceEmail,
			variables: { criteria },
		}));
	}

	public getMonthlyCommission(companyId: string): Promise<FetchResult<responses.MonthlyCommissionResponse>> {
		return lastValueFrom(this.apollo.query<responses.MonthlyCommissionResponse>({
			query: queries.getMonthlyCommission,
			variables: { companyId },
		}));
	}

	public getContractBanners(): Promise<FetchResult<responses.ContractBannersResponse>> {
		return lastValueFrom(this.apollo.query<responses.ContractBannersResponse>({
			query: queries.getContractBanners,
		}));
	}

	public getTermValueBookedGraph(companyId: string): Promise<FetchResult<responses.TermValueBookedGraphResponse>> {
		return lastValueFrom(this.apollo.query<responses.TermValueBookedGraphResponse>({
			query: queries.getTermValueBookedGraph,
			variables: { companyId },
		}));
	}

	public getOwnerCompanySnapshot(companyId: string): Promise<FetchResult<responses.OwnerCompanySnapshotResponse>> {
		return lastValueFrom(this.apollo.query<responses.OwnerCompanySnapshotResponse>({
			query: queries.getOwnerCompanySnapshot,
			variables: { companyId },
		}));
	}

	public getOwnerTopAgents(companyId: string): Promise<FetchResult<responses.OwnerTopAgentsResponse>> {
		return lastValueFrom(this.apollo.query<responses.OwnerTopAgentsResponse>({
			query: queries.getOwnerTopAgents,
			variables: { companyId },
		}));
	}

	public getOwnerTermValueBookedSummary(companyId: string): Promise<FetchResult<responses.OwnerTermValueBookedSummaryResponse>> {
		return lastValueFrom(this.apollo.query<responses.OwnerTermValueBookedSummaryResponse>({
			query: queries.getOwnerTermValueBookedSummary,
			variables: { companyId },
		}));
	}

	public getOwnerTopSuppliers(companyId: string): Promise<FetchResult<responses.OwnerTopSuppliersResponse>> {
		return lastValueFrom(this.apollo.query<responses.OwnerTopSuppliersResponse>({
			query: queries.getOwnerTopSuppliers,
			variables: { companyId },
		}));
	}

	public getOwnerMonthlyRevenue(companyId: string): Promise<FetchResult<responses.OwnerMonthlyRevenueResponse>> {
		return lastValueFrom(this.apollo.query<responses.OwnerMonthlyRevenueResponse>({
			query: queries.getOwnerMonthlyRevenue,
			variables: { companyId },
		}));
	}

	public getFinanceCompanySnapshot(companyId: string): Promise<FetchResult<responses.FinanceCompanySnapshotResponse>> {
		return lastValueFrom(this.apollo.query<responses.FinanceCompanySnapshotResponse>({
			query: queries.getFinanceCompanySnapshot,
			variables: { companyId },
		}));
	}

	public getFinanceCommissionsSummary(companyId: string): Promise<FetchResult<responses.FinanceCommissionsSummaryResponse>> {
		return lastValueFrom(this.apollo.query<responses.FinanceCommissionsSummaryResponse>({
			query: queries.getFinanceCommissionsSummary,
			variables: { companyId },
		}));
	}

	public getFinanceCommissionsGraph(companyId: string): Promise<FetchResult<responses.FinanceCommissionsGraphResponse>> {
		return lastValueFrom(this.apollo.query<responses.FinanceCommissionsGraphResponse>({
			query: queries.getFinanceCommissionsGraph,
			variables: { companyId },
		}));
	}

	public getFinanceTotalValueDroppedReinstated(companyId: string):
		Promise<FetchResult<responses.FinanceTotalValueDroppedReinstatedResponse>> {
		return lastValueFrom(this.apollo.query<responses.FinanceTotalValueDroppedReinstatedResponse>({
			query: queries.getFinanceTotalValueDroppedReinstated,
			variables: { companyId },
		}));
	}

	public getSalesTeamPerformance(agentId: string): Promise<FetchResult<responses.SalesTeamPerformanceResponse>> {
		return lastValueFrom(this.apollo.query<responses.SalesTeamPerformanceResponse>({
			query: queries.getSalesTeamPerformance,
			variables: { agentId },
		}));
	}

	public getSalesYourSnapshot(agentId: string): Promise<FetchResult<responses.SalesYourShapshotResponse>> {
		return lastValueFrom(this.apollo.query<responses.SalesYourShapshotResponse>({
			query: queries.getSalesYourSnapshot,
			variables: { agentId },
		}));
	}

	public getOperationsCompanySnapshot(companyId: string): Promise<FetchResult<responses.OperationsCompanySnapshotResponse>> {
		return lastValueFrom(this.apollo.query<responses.OperationsCompanySnapshotResponse>({
			query: queries.getOperationsCompanySnapshot,
			variables: { companyId },
		}));
	}

	public getOperationsTermValueBookedSummary(companyId: string):
		Promise<FetchResult<responses.OperationsTermValueBookedSummaryResponse>> {
		return lastValueFrom(this.apollo.query<responses.OperationsTermValueBookedSummaryResponse>({
			query: queries.getOperationsTermValueBookedSummary,
			variables: { companyId },
		}));
	}

	public getOperationsAuctionsEndingToday(companyId: string): Promise<FetchResult<responses.OperationsAuctionsEndingTodayResponse>> {
		return lastValueFrom(this.apollo.query<responses.OperationsAuctionsEndingTodayResponse>({
			query: queries.getOperationsAuctionsEndingToday,
			variables: { companyId },
		}));
	}

	public getEclUploadData(): Promise<ApolloQueryResult<responses.EclUploadData>> {
		return lastValueFrom(this.apollo.query<responses.EclUploadData>({
			query: queries.getEclUploadData,
		}));
	}

	public getCommercialEcls(criteria: any, sort: string, max: number, offset: number):
		Promise<ApolloQueryResult<responses.CommercialEclData>> {
		return lastValueFrom(this.apollo.query<responses.CommercialEclData>({
			query: queries.getCommercialEcls,
			variables: { criteria, sort, max, offset },
		}));
	}

	public getEclHomePageData(): Promise<ApolloQueryResult<responses.EclHomePageData>> {
		return lastValueFrom(this.apollo.query<responses.EclHomePageData>({
			query: queries.getEclHomePageData,
		}));
	}

	public updateAgentEcl(agentId: string, agentEcl: models.AgentEcl):
		Promise<FetchResult<responses.UpdateAgentEclResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateAgentEclResponse>({
			mutation: mutations.updateAgentEcl,
			variables: { agentId, agentEcl },
		}));
	}

	public getAvailableEclPackagesData(criteria: any): Promise<ApolloQueryResult<responses.AvailableEclPackagesData>> {
		return lastValueFrom(this.apollo.query<responses.AvailableEclPackagesData>({
			query: queries.getAvailableEclPackagesData,
			variables: { criteria },
		}));
	}

	public createAgentEclPackage(agentId: string, agentEclPackage: models.AgentEclPackage):
		Promise<FetchResult<responses.CreateAgentEclPackageResponse>> {
		return lastValueFrom(this.apollo.query<responses.CreateAgentEclPackageResponse>({
			query: mutations.createAgentEclPackage,
			variables: { agentId, agentEclPackage },
		}));
	}

	public createRejectReasonCategory(rejectReasonCategory: Subset<models.RejectReasonCategory>):
		Promise<FetchResult<responses.CreateRejectReasonCategoryResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateRejectReasonCategoryResponse>({
			mutation: mutations.createRejectReasonCategory,
			variables: { rejectReasonCategory },
		}));
	}

	public updateRejectReasonCategory(id: number, rejectReasonCategory: Subset<models.RejectReasonCategory>):
		Promise<FetchResult<responses.UpdateRejectReasonCategoryResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateRejectReasonCategoryResponse>({
			mutation: mutations.updateRejectReasonCategory,
			variables: { id, rejectReasonCategory },
		}));
	}

	public getRejectReasonCategoryAddEditData(id: number): Promise<ApolloQueryResult<responses.RejectReasonCategoryAddEditData>> {
		return lastValueFrom(this.apollo.query<responses.RejectReasonCategoryAddEditData>({
			query: queries.getRejectReasonCategoryAddEditData,
			variables: { id },
		}));
	}

	public getRejectReasonCategoryAddEditPageData(): Promise<ApolloQueryResult<responses.RejectReasonCategoryAddEditPageData>> {
		return lastValueFrom(this.apollo.query<responses.RejectReasonCategoryAddEditPageData>({
			query: queries.getRejectReasonCategoryAddEditPageData,
		}));
	}

	public getRejectReasonCategoriesListData(): Promise<ApolloQueryResult<responses.RejectReasonCategoriesListData>> {
		return lastValueFrom(this.apollo.query<responses.RejectReasonCategoriesListData>({
			query: queries.getRejectReasonCategoriesListData,
		}));
	}

	public getBusinessPipelineReportPageData(reportId: string): Promise<ApolloQueryResult<responses.BusinessPipelineReportPageData>> {
		return lastValueFrom(this.apollo.query<responses.BusinessPipelineReportPageData>({
			query: queries.getBusinessPipelineReportPageData,
			variables: { reportId },
		}));
	}

	public getBusinessPipelineReportData(reportId: string, criteria: any, sort: string, max: number, offset: number):
		Promise<ApolloQueryResult<responses.BusinessPipelineReportData>> {
		return lastValueFrom(this.apollo.query<responses.BusinessPipelineReportData>({
			query: queries.getBusinessPipelineReportData,
			variables: { reportId, criteria, sort, max, offset },
		}));
	}

	public getBusinessPipelineReportDetailData(reportId: string, criteria: any, sort: string):
		Promise<ApolloQueryResult<responses.BusinessPipelineReportData>> {
		return lastValueFrom(this.apollo.query<responses.BusinessPipelineReportData>({
			query: queries.getBusinessPipelineReportDetailData,
			variables: { reportId, criteria, sort },
		}));
	}

	public getBusinessPipelineFutureProjectionReportData(criteria: any):
		Promise<ApolloQueryResult<responses.BusinessPipelineFutureProjectionReportData>> {
		return lastValueFrom(this.apollo.query<responses.BusinessPipelineFutureProjectionReportData>({
			query: queries.getBusinessPipelineFutureProjectionReportData,
			variables: { criteria },
		}));
	}

	public getRebuildEsignDocumentModalData(criteria: any): Promise<ApolloQueryResult<responses.RebuildEsignDocumentModalData>> {
		return lastValueFrom(this.apollo.query<responses.RebuildEsignDocumentModalData>({
			query: queries.getRebuildEsignDocumentModalData,
			variables: { criteria },
		}));
	}

	public getFraudulentReasonModalData(criteria: any): Promise<ApolloQueryResult<responses.FraudulentReasonModalData>> {
		return lastValueFrom(this.apollo.query<responses.FraudulentReasonModalData>({
			query: queries.getFraudulentReasonModalData,
			variables: { criteria },
		}));
	}

	public getSupplierResidentialConfirmationsData(criteria: any, sort: string, max: number, offset: number):
		Promise<ApolloQueryResult<responses.SupplierResidentialConfirmationsData>> {
		return lastValueFrom(this.apollo.query<responses.SupplierResidentialConfirmationsData>({
			query: queries.getSupplierResidentialConfirmationsData,
			variables: { criteria, sort, max, offset },
		}));
	}

	public supplierConfirmResidentialContract(supplierId: string, criteria: any):
		Promise<FetchResult<responses.SupplierConfirmResidentialContractResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.SupplierConfirmResidentialContractResponse>({
			mutation: mutations.supplierConfirmResidentialContract,
			variables: { supplierId, criteria },
		}));
	}

	public supplierRejectResidentialContract(supplierId: string, criteria: any):
		Promise<FetchResult<responses.SupplierRejectResidentialContractResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.SupplierRejectResidentialContractResponse>({
			mutation: mutations.supplierRejectResidentialContract,
			variables: { supplierId, criteria },
		}));
	}

	public supplierDropResidentialContract(supplierId: string, criteria: any):
		Promise<FetchResult<responses.SupplierDropResidentialContractResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.SupplierDropResidentialContractResponse>({
			mutation: mutations.supplierDropResidentialContract,
			variables: { supplierId, criteria },
		}));
	}

	public updateSupplierAuctionMonitorEmail(id: number, supplierAuctionMonitorEmail: any):
		Promise<FetchResult<responses.UpdateSupplierAuctionMonitorEmailResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateSupplierAuctionMonitorEmailResponse>({
			mutation: mutations.updateSupplierAuctionMonitorEmail,
			variables: { id, supplierAuctionMonitorEmail },
		}));
	}

	public updateServiceTypeUnit(id: number, serviceTypeUnit: Subset<models.ServiceTypeUnit>):
		Promise<FetchResult<responses.UpdateServiceTypeUnitResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateServiceTypeUnitResponse>({
			mutation: mutations.updateServiceTypeUnit,
			variables: { id, serviceTypeUnit },
		}));
	}

	public deleteServiceTypeUnit(id: number): Promise<FetchResult<responses.UpdateServiceTypeUnitResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateServiceTypeUnitResponse>({
			mutation: mutations.deleteServiceTypeUnit,
			variables: { id },
		}));
	}

	public createServiceTypeUnit(serviceTypeUnit: Subset<models.ServiceTypeUnit>):
		Promise<FetchResult<responses.CreateServiceTypeUnitResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateServiceTypeUnitResponse>({
			mutation: mutations.createServiceTypeUnit,
			variables: { serviceTypeUnit },
		}));
	}

	public sendWelcomeEmail(userId: string): Promise<FetchResult<responses.SendWelcomeEmailResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.SendWelcomeEmailResponse>({
			mutation: mutations.sendWelcomeEmail,
			variables: { userId },
		}));
	}

	public createUserRfqSetting(userRfqSetting: Subset<models.UserRfqSetting>):
		Promise<FetchResult<responses.CreateUserRfqSettingResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateUserRfqSettingResponse>({
			mutation: mutations.createUserRfqSetting,
			variables: { userRfqSetting },
		}));
	}

	public updateUserRfqSetting(id: number, userRfqSetting: Subset<models.UserRfqSetting>):
		Promise<FetchResult<responses.UpdateUserRfqSettingResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateUserRfqSettingResponse>({
			mutation: mutations.updateUserRfqSetting,
			variables: { id, userRfqSetting },
		}));
	}

	public updateUserRfqSettings(userRfqSettings: Partial<models.UserRfqSetting>[]):
		Promise<FetchResult<responses.UpdateUserRfqSettingsResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateUserRfqSettingsResponse>({
			mutation: mutations.updateUserRfqSettings,
			variables: { userRfqSettings },
		}));
	}

	public deleteUserRfqSetting(id: number): Promise<FetchResult<responses.DeleteUserRfqSettingResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.DeleteUserRfqSettingResponse>({
			mutation: mutations.deleteUserRfqSetting,
			variables: { id },
		}));
	}

	public deleteUserRfqSettings(id: string): Promise<FetchResult<responses.DeleteUserRfqSettingsResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.DeleteUserRfqSettingsResponse>({
			mutation: mutations.deleteUserRfqSettings,
			variables: { id },
		}));
	}

	public createCompanySupplier(companySupplier: Subset<models.CompanySupplier>):
		Promise<FetchResult<responses.CreateCompanySupplierResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateCompanySupplierResponse>({
			mutation: mutations.createCompanySupplier,
			variables: { companySupplier },
		}));
	}

	public updateCompanySupplier(id: number, companySupplier: Subset<models.CompanySupplier>):
		Promise<FetchResult<responses.UpdateCompanySupplierResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateCompanySupplierResponse>({
			mutation: mutations.updateCompanySupplier,
			variables: { id, companySupplier },
		}));
	}

	public getContractPurchasingLayer(contractId: string, today: string):
		Promise<ApolloQueryResult<responses.ContractPurchasingLayerData>> {
		return lastValueFrom(this.apollo.query<responses.ContractPurchasingLayerData>({
			query: queries.getContractPurchasingLayer,
			variables: { contractId, today },
		}));
	}

	public getPurchasingLayerIndicativeHedgedPrices(criteria: any, sort?: string, max?: number, offset?: number):
		Promise<ApolloQueryResult<responses.PurchasingLayerIndicativeHedgedPricesData>> {
		return lastValueFrom(this.apollo.query<responses.PurchasingLayerIndicativeHedgedPricesData>({
			query: queries.getPurchasingLayerIndicativeHedgedPrices,
			variables: { criteria, sort, max, offset },
		}));
	}


	public getContractPerformance(contractId: string): Promise<ApolloQueryResult<responses.ContractPerformanceData>> {
		return lastValueFrom(this.apollo.query<responses.ContractPerformanceData>({
			query: queries.getContractPerformance,
			variables: { contractId },
		}));
	}

	public getRfqSessionSupplierDashboardData(rfqSessionSupplierId: string):
		Promise<ApolloQueryResult<responses.RfqSessionSupplierDashboardData>> {
		return lastValueFrom(this.apollo.query<responses.RfqSessionSupplierDashboardData>({
			query: queries.getRfqSessionSupplierDashboardData,
			variables: { rfqSessionSupplierId },
		}));
	}

	public getCommissionProcessPageData(): Promise<ApolloQueryResult<responses.CommissionProcessPageData>> {
		return lastValueFrom(this.apollo.query<responses.CommissionProcessPageData>({
			query: queries.getCommissionProcessPageData,
		}));
	}

	public getPostProcessingPageData(): Promise<ApolloQueryResult<responses.PostProcessingPageData>> {
		return lastValueFrom(this.apollo.query<responses.PostProcessingPageData>({
			query: queries.getPostProcessingPageData,
		}));
	}


	public deleteContractLocationForecastOverrides(contractLocationId: string):
		Promise<FetchResult<responses.DeleteContractLocationForecastOverridesResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.DeleteContractLocationForecastOverridesResponse>({
			mutation: mutations.deleteContractLocationForecastOverrides,
			variables: { contractLocationId },
		}));
	}

	public getJobsListData(sort: string): Promise<ApolloQueryResult<responses.JobsData>> {
		return lastValueFrom(this.apollo.query<responses.JobsData>({
			query: queries.getJobsListData,
			variables: { sort },
		}));
	}

	public getJobEditData(id: number): Promise<ApolloQueryResult<responses.JobData>> {
		return lastValueFrom(this.apollo.query<responses.JobData>({
			query: queries.getJobEditData,
			variables: { id },
		}));
	}

	public createJob(job: Subset<models.Job>): Promise<FetchResult<responses.CreateJobResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateJobResponse>({
			mutation: mutations.createJob,
			variables: { job },
		}));
	}

	public updateJob(id: number, job: Subset<models.Job>): Promise<FetchResult<responses.UpdateJobResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateJobResponse>({
			mutation: mutations.updateJob,
			variables: { id, job },
		}));
	}

	public runJob(id: number): Promise<FetchResult<responses.RunJobResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.RunJobResponse>({
			mutation: mutations.runJob,
			variables: { id },
		}));
	}

	public getPendingCommissionTimeLogsListData(
		criteria: any, sort?: string,
	): Promise<ApolloQueryResult<responses.PendingCommissionTimeLogsData>> {
		return lastValueFrom(this.apollo.query<responses.PendingCommissionTimeLogsData>({
			query: queries.getPendingCommissionTimeLogsListData,
			variables: { criteria, sort },
		}));
	}

	public createPendingCommissionTimeLog(
		pendingCommissionTimeLog: models.PendingCommissionTimeLog,
	): Promise<FetchResult<responses.CreatePendingCommissionTimeLogResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreatePendingCommissionTimeLogResponse>({
			mutation: mutations.createPendingCommissionTimeLog,
			variables: { pendingCommissionTimeLog },
		}));
	}

	public updatePendingCommissionTimeLog(
		id: number,
		pendingCommissionTimeLog: models.PendingCommissionTimeLog,
	): Promise<FetchResult<responses.UpdatePendingCommissionTimeLogResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdatePendingCommissionTimeLogResponse>({
			mutation: mutations.updatePendingCommissionTimeLog,
			variables: { id, pendingCommissionTimeLog },
		}));
	}

	public getDeliveryTypesListData(sort: string): Promise<ApolloQueryResult<responses.DeliveryTypesData>> {
		return lastValueFrom(this.apollo.query<responses.DeliveryTypesData>({
			query: queries.getDeliveryTypesListData,
			variables: { sort },
		}));
	}

	public getDeliveryTypeEditData(id: number): Promise<ApolloQueryResult<responses.DeliveryTypeData>> {
		return lastValueFrom(this.apollo.query<responses.DeliveryTypeData>({
			query: queries.getDeliveryTypeEditData,
			variables: { id },
		}));
	}

	public createDeliveryType(deliveryType: Subset<models.DeliveryType>): Promise<FetchResult<responses.CreateDeliveryTypeResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateDeliveryTypeResponse>({
			mutation: mutations.createDeliveryType,
			variables: { deliveryType },
		}));
	}

	public updateDeliveryType(id: number, deliveryType: Subset<models.DeliveryType>):
		Promise<FetchResult<responses.UpdateDeliveryTypeResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateDeliveryTypeResponse>({
			mutation: mutations.updateDeliveryType,
			variables: { id, deliveryType },
		}));
	}

	public getNightlyProcessLogReportData(reportId: string): Promise<ApolloQueryResult<responses.NightlyProcessLogReportData>> {
		return lastValueFrom(this.apollo.query<responses.NightlyProcessLogReportData>({
			query: queries.getNightlyProcessLogReportData,
			variables: { reportId },
		}));
	}

	public getNightlyProcessLogReport(
		reportId: string,
		criteria: Subset<models.NightlyProcessLog>,
		sort: string,
		max: number,
		offset: number,
	): Promise<ApolloQueryResult<responses.NightlyProcessLogReportResponse>> {
		return lastValueFrom(this.apollo.query<responses.NightlyProcessLogReportResponse>({
			query: queries.getNightlyProcessLogReport,
			variables: { reportId, criteria, sort, max, offset },
		}));
	}

	public getAgentMarketOpportunities(agentId: string): Promise<ApolloQueryResult<responses.AgentMarketOpportunities>> {
		return lastValueFrom(this.apollo.query<responses.AgentMarketOpportunities>({
			query: queries.getAgentMarketOpportunities,
			variables: { agentId },
		}));
	}

	public getUnpaidPayables(criteria: any, sort?: string, max?: number, offset?: number):
		Promise<ApolloQueryResult<responses.GetUnpaidPayablesResponse>> {
		return lastValueFrom(this.apollo.query<responses.GetUnpaidPayablesResponse>({
			query: queries.getUnpaidPayables,
			variables: { criteria, sort, max, offset },
		}));
	}

	public completeReviewUnpaidPayables(ids: string[]): Promise<FetchResult<responses.CompleteReviewUnpaidPayablesResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CompleteReviewUnpaidPayablesResponse>({
			mutation: mutations.completeReviewUnpaidPayables,
			variables: { ids },
		}));
	}

	public incompleteReviewUnpaidPayables(ids: string[]): Promise<FetchResult<responses.IncompleteReviewUnpaidPayablesResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.IncompleteReviewUnpaidPayablesResponse>({
			mutation: mutations.incompleteReviewUnpaidPayables,
			variables: { ids },
		}));
	}

	public rejectUnpaidPayables(ids: string[]): Promise<FetchResult<responses.RejectUnpaidPayablesResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.RejectUnpaidPayablesResponse>({
			mutation: mutations.rejectReviewUnpaidPayables,
			variables: { ids },
		}));
	}

	public deleteUnpaidPayables(ids: string[]): Promise<FetchResult<responses.DeleteUnpaidPayablesResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.DeleteUnpaidPayablesResponse>({
			mutation: mutations.deleteUnpaidPayables,
			variables: { ids },
		}));
	}

	public getCommissionPayablesDashboardData(criteria: any):
		Promise<ApolloQueryResult<responses.GetCommissionPayablesDashboardData>> {
		return lastValueFrom(this.apollo.query<responses.GetCommissionPayablesDashboardData>({
			query: queries.getCommissionPayablesDashboardData,
			variables: { criteria },
		}));
	}

	public markAsPaid(commissionId: string, accountAdjustmentId: string): Promise<FetchResult<responses.MarkAsPaidResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.MarkAsPaidResponse>({
			mutation: mutations.markAsPaid,
			variables: { commissionId, accountAdjustmentId },
		}));
	}

	public overrideAgentCommissionPayPlanOption(id: number, agentCommissionPayPlanOptionOverride: any):
		Promise<FetchResult<responses.OverrideAgentCommissionPayPlanOptionResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.OverrideAgentCommissionPayPlanOptionResponse>({
			mutation: mutations.overrideAgentCommissionPayPlanOption,
			variables: { id, agentCommissionPayPlanOptionOverride },
		}));
	}

	public bulkOverrideAgentCommissionPayPlanOptions(agentId: string, bulkOverrideRateParameters: any):
		Promise<FetchResult<responses.OverrideAgentCommissionPayPlanOptionResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.OverrideAgentCommissionPayPlanOptionResponse>({
			mutation: mutations.bulkOverrideAgentCommissionPayPlanOptions,
			variables: { agentId, bulkOverrideRateParameters },
		}));
	}

	public revertAgentCommissionPayPlanOptionOverride(id: number):
		Promise<FetchResult<responses.RevertAgentCommissionPayPlanOptionOverrideResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.RevertAgentCommissionPayPlanOptionOverrideResponse>({
			mutation: mutations.revertAgentCommissionPayPlanOptionOverride,
			variables: { id },
		}));
	}

	public getAgentCommissionPayPlanOptionsListPageData(): Promise<ApolloQueryResult<responses.AgentCommissionPayPlanOptionsListPageData>> {
		return lastValueFrom(this.apollo.query<responses.AgentCommissionPayPlanOptionsListPageData>({
			query: queries.getAgentCommissionPayPlanOptionsListPageData,
		}));
	}

	public viewAgentCommissionPayPlanOptions(agentId: any):
		Promise<ApolloQueryResult<responses.ViewAgentCommissionPayPlanOptions>> {
		return lastValueFrom(this.apollo.query<responses.ViewAgentCommissionPayPlanOptions>({
			query: queries.viewAgentCommissionPayPlanOptions,
			variables: { agentId },
		}));
	}

	public getSupplierAllowedCommissionPayPlansListPageData():
		Promise<ApolloQueryResult<responses.SupplierAllowedCommissionPayPlansListPageData>> {
		return lastValueFrom(this.apollo.query<responses.SupplierAllowedCommissionPayPlansListPageData>({
			query: queries.getSupplierAllowedCommissionPayPlansListPageData,
		}));
	}

	public viewSupplierAllowedCommissionPayPlanTypes(criteria: any, sort: string):
		Promise<ApolloQueryResult<responses.ViewSupplierAllowedCommissionPayPlanTypes>> {
		return lastValueFrom(this.apollo.query<responses.ViewSupplierAllowedCommissionPayPlanTypes>({
			query: queries.viewSupplierAllowedCommissionPayPlanTypes,
			variables: { criteria, sort },
		}));
	}

	public getCommissionPayablesEditModalData(contractId: string):
		Promise<ApolloQueryResult<responses.GetCommissionPayablesEditModalData>> {
		return lastValueFrom(this.apollo.query<responses.GetCommissionPayablesEditModalData>({
			query: queries.getCommissionPayablesEditModalData,
			variables: { contractId },
		}));
	}

	public getCommissionEditModalData(contractId: string):
		Promise<ApolloQueryResult<responses.GetCommissionEditModalData>> {
		return lastValueFrom(this.apollo.query<responses.GetCommissionEditModalData>({
			query: queries.getCommissionEditModalData,
			variables: { contractId },
		}));
	}

	public getCommissionData():
		Promise<ApolloQueryResult<responses.GetCommissionData>> {
		return lastValueFrom(this.apollo.query<responses.GetCommissionData>({
			query: queries.getCommissionData,
		}));
	}

	public updateContractCommissionPayables(contractId: string, months: any[]):
		Promise<FetchResult<responses.UpdateContractCommissionPayablesResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateContractCommissionPayablesResponse>({
			mutation: mutations.updateContractCommissionPayables,
			variables: { contractId, months },
		}));
	}

	public updateCommissionPayable(id: number, commissionPayable: Subset<models.CommissionPayable>):
		Promise<FetchResult<responses.UpdateCommissionPayableResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateCommissionPayableResponse>({
			mutation: mutations.updateCommissionPayable,
			variables: { id, commissionPayable },
		}));
	}

	public createSupplierAllowedCommissionPayPlanType(supplierAllowedCommissionPayPlanType: any):
		Promise<FetchResult<responses.CreateSupplierAllowedCommissionPayPlanType>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateSupplierAllowedCommissionPayPlanType>({
			mutation: mutations.createSupplierAllowedCommissionPayPlanType,
			variables: { supplierAllowedCommissionPayPlanType },
		}));
	}

	public deleteSupplierAllowedCommissionPayPlanType(id: number):
		Promise<FetchResult<responses.DeleteSupplierAllowedCommissionPayPlanType>> {
		return lastValueFrom(this.apollo.mutate<responses.DeleteSupplierAllowedCommissionPayPlanType>({
			mutation: mutations.deleteSupplierAllowedCommissionPayPlanType,
			variables: { id },
		}));
	}

	public createAgentAllowedCommissionPayPlanType(agentAllowedCommissionPayPlanType: any):
		Promise<FetchResult<responses.CreateAgentAllowedCommissionPayPlanType>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateAgentAllowedCommissionPayPlanType>({
			mutation: mutations.createAgentAllowedCommissionPayPlanType,
			variables: { agentAllowedCommissionPayPlanType },
		}));
	}

	public deleteAgentAllowedCommissionPayPlanType(id: number):
		Promise<FetchResult<responses.DeleteAgentAllowedCommissionPayPlanType>> {
		return lastValueFrom(this.apollo.mutate<responses.DeleteAgentAllowedCommissionPayPlanType>({
			mutation: mutations.deleteAgentAllowedCommissionPayPlanType,
			variables: { id },
		}));
	}

	public getSupplierAllowedCommissionPayPlansRules(criteria: any):
		Promise<ApolloQueryResult<responses.GetSupplierAllowedCommissionPayPlansRules>> {
		return lastValueFrom(this.apollo.query<responses.GetSupplierAllowedCommissionPayPlansRules>({
			query: queries.getSupplierAllowedCommissionPayPlansRules,
			variables: { criteria },
		}));
	}

	public getAgentUpcomingRenewalsPageData(agentId: string): Promise<ApolloQueryResult<responses.AgentUpcomingRenewalsPageData>> {
		return lastValueFrom(this.apollo.query<responses.AgentUpcomingRenewalsPageData>({
			query: queries.getAgentUpcomingRenewalsPageData,
			variables: { agentId },
		}));
	}

	public getAgentUpcomingRenewals(agentId: string): Promise<ApolloQueryResult<responses.GetAgentUpcomingRenewals>> {
		return lastValueFrom(this.apollo.query<responses.GetAgentUpcomingRenewals>({
			query: queries.getAgentUpcomingRenewals,
			variables: { agentId },
		}));
	}

	public getCompanyUpcomingRenewals(companyId: string): Promise<ApolloQueryResult<responses.GetCompanyUpcomingRenewals>> {
		return lastValueFrom(this.apollo.query<responses.GetCompanyUpcomingRenewals>({
			query: queries.getCompanyUpcomingRenewals,
			variables: { companyId },
		}));
	}

	public getForecastedPayablesSummary(criteria: any): Promise<ApolloQueryResult<responses.GetForecastedPayablesSummary>> {
		return lastValueFrom(this.apollo.query<responses.GetForecastedPayablesSummary>({
			query: queries.getForecastedPayablesSummary,
			variables: { criteria },
		}));
	}

	public getForecastedReceivablesSummary(criteria: any): Promise<ApolloQueryResult<responses.GetForecastedReceivablesSummary>> {
		return lastValueFrom(this.apollo.query<responses.GetForecastedReceivablesSummary>({
			query: queries.getForecastedReceivablesSummary,
			variables: { criteria },
		}));
	}

	public getActualPayablesSummary(criteria: any): Promise<ApolloQueryResult<responses.GetActualPayablesSummary>> {
		return lastValueFrom(this.apollo.query<responses.GetActualPayablesSummary>({
			query: queries.getActualPayablesSummary,
			variables: { criteria },
		}));
	}

	public getActualReceivablesSummary(criteria: any): Promise<ApolloQueryResult<responses.GetActualReceivablesSummary>> {
		return lastValueFrom(this.apollo.query<responses.GetActualReceivablesSummary>({
			query: queries.getActualReceivablesSummary,
			variables: { criteria },
		}));
	}

	public getCustomerEngagementEmailsPageData(criteria: any): Promise<ApolloQueryResult<responses.GetCustomerEngagementEmailsPageData>> {
		return lastValueFrom(this.apollo.query<responses.GetCustomerEngagementEmailsPageData>({
			query: queries.getCustomerEngagementEmailsPageData,
			variables: { criteria },
		}));
	}

	public getCustomerEngagementEmailsAgentEmailPreferences(agentId: string):
		Promise<ApolloQueryResult<responses.GetCustomerEngagementEmailsAgentEmailPreferences>> {
		return lastValueFrom(this.apollo.query<responses.GetCustomerEngagementEmailsAgentEmailPreferences>({
			query: queries.getCustomerEngagementEmailsAgentEmailPreferences,
			variables: { agentId },
		}));
	}

	public getCustomerEngagementEmailsEditPageData(id: number, agentEmailPreferenceSettingCriteria: any):
		Promise<ApolloQueryResult<responses.GetCustomerEngagementEmailsEditPageData>> {
		return lastValueFrom(this.apollo.query<responses.GetCustomerEngagementEmailsEditPageData>({
			query: queries.getCustomerEngagementEmailsEditPageData,
			variables: { id, agentEmailPreferenceSettingCriteria },
		}));
	}

	public getCustomerEngagementEmailsEditData(emailSectionId: number | string, agentId: string):
		Promise<ApolloQueryResult<responses.GetCustomerEngagementEmailsEditData>> {
		return lastValueFrom(this.apollo.query<responses.GetCustomerEngagementEmailsEditData>({
			query: queries.getCustomerEngagementEmailsEditData,
			variables: { emailSectionId, agentId },
		}));
	}

	public getCustomerEngagementEmailPreview(emailPreferenceId: number, agentEmailSections: Subset<models.AgentEmailSection>[]):
		Promise<ApolloQueryResult<responses.GetCustomerEngagementEmailPreviewResponse>> {
		return lastValueFrom(this.apollo.query<responses.GetCustomerEngagementEmailPreviewResponse>({
			query: queries.getCustomerEngagementEmailPreview,
			variables: { emailPreferenceId, agentEmailSections },
		}));
	}

	public sendCustomerEngagementEmailPreview(
		email: string, emailPreferenceId: number, agentEmailSections: Subset<models.AgentEmailSection>[]):
		Promise<ApolloQueryResult<responses.SendCustomerEngagementEmailPreviewResponse>> {
		return lastValueFrom(this.apollo.query<responses.SendCustomerEngagementEmailPreviewResponse>({
			query: queries.sendCustomerEngagementEmailPreview,
			variables: { email, emailPreferenceId, agentEmailSections },
		}));
	}

	public updateAgentEmailSections(agentEmailSections: Subset<models.AgentEmailSection>[]):
		Promise<FetchResult<responses.UpdateAgentEmailSectionsResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateAgentEmailSectionsResponse>({
			mutation: mutations.updateAgentEmailSections,
			variables: { agentEmailSections },
		}));
	}

	public getEmailPreferenceSettingTargetDateOptions(emailPreferenceTypeId: number):
		Promise<ApolloQueryResult<responses.GetEmailPreferenceSettingTargetDateOptions>> {
		return lastValueFrom(this.apollo.query<responses.GetEmailPreferenceSettingTargetDateOptions>({
			query: queries.getEmailPreferenceSettingTargetDateOptions,
			variables: { criteria: { emailPreferenceTypeId } },
		}));
	}

	public updateAgentEmailPreferenceSetting(id: number, agentEmailPreferenceSetting: any):
		Promise<FetchResult<responses.UpdateEmailPreferenceSettingResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateEmailPreferenceSettingResponse>({
			mutation: mutations.updateAgentEmailPreferenceSetting,
			variables: { id, agentEmailPreferenceSetting },
		}));
	}

	public getViewEmailPreferenceData(id: number): Promise<ApolloQueryResult<responses.GetViewEmailPreferenceData>> {
		return lastValueFrom(this.apollo.query<responses.GetViewEmailPreferenceData>({
			query: queries.getViewEmailPreferenceData,
			variables: { id },
		}));
	}

	public convertAgentCommissionPayPlans(agentId: string, overrideCommissionPayPlanTypeId: number):
		Promise<FetchResult<responses.ConvertAgentCommissionPayPlansResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.ConvertAgentCommissionPayPlansResponse>({
			mutation: mutations.convertAgentCommissionPayPlans,
			variables: { agentId, overrideCommissionPayPlanTypeId },
		}));
	}

	public getViewComplianceMonitorContracts(
		criteria: Subset<models.ViewComplianceMonitorContract>,
		sort: string, max: number, offset: number): Promise<ApolloQueryResult<responses.GetViewComplianceMonitorContracts>> {
		return lastValueFrom(this.apollo.query<responses.GetViewComplianceMonitorContracts>({
			query: queries.getViewComplianceMonitorContracts,
			variables: { criteria, sort, max, offset },
		}));
	}

	public getComplianceMonitorDashboardContractsData(): Promise<ApolloQueryResult<responses.GetComplianceMonitorDashboardContractsData>> {
		return lastValueFrom(this.apollo.query<responses.GetComplianceMonitorDashboardContractsData>({
			query: queries.getComplianceMonitorDashboardContractsData,
		}));
	}

	public exportComplianceMonitorContracts(criteria: any, sort: string):
		Promise<ApolloQueryResult<responses.ExportComplianceMonitorContractsData>> {
		return lastValueFrom(this.apollo.query<responses.ExportComplianceMonitorContractsData>({
			query: queries.exportComplianceMonitorContracts,
			variables: { criteria, sort },
		}));
	}

	public getNoteTypePageData(): Promise<ApolloQueryResult<responses.GetNoteTypePageData>> {
		return lastValueFrom(this.apollo.query<responses.GetNoteTypePageData>({
			query: queries.getNoteTypePageData,
		}));
	}

	public getComplianceMonitorContractDetailsModalData(contractId: string, agentId: string, noteTypeCategoryId: number):
		Promise<ApolloQueryResult<responses.GetComplianceMonitorContractDetailsModalData>> {
		return lastValueFrom(this.apollo.query<responses.GetComplianceMonitorContractDetailsModalData>({
			query: queries.getComplianceMonitorContractDetailsModalData,
			variables: { contractId, agentId, noteTypeCategoryId },
		}));
	}

	public getComplianceMonitorAgents(
		criteria: Subset<models.ComplianceMonitorAgent>,
		sort: string, max: number, offset: number): Promise<ApolloQueryResult<responses.GetComplianceMonitorAgents>> {
		return lastValueFrom(this.apollo.query<responses.GetComplianceMonitorAgents>({
			query: queries.getComplianceMonitorAgents,
			variables: { criteria, sort, max, offset },
		}));
	}

	public getComplianceMonitorDashboardAgentsData(): Promise<ApolloQueryResult<responses.GetComplianceMonitorDashboardAgentsData>> {
		return lastValueFrom(this.apollo.query<responses.GetComplianceMonitorDashboardAgentsData>({
			query: queries.getComplianceMonitorDashboardAgentsData,
		}));
	}

	public exportComplianceMonitorAgents(criteria: any, sort: string):
		Promise<ApolloQueryResult<responses.ExportComplianceMonitorAgentsData>> {
		return lastValueFrom(this.apollo.query<responses.ExportComplianceMonitorAgentsData>({
			query: queries.exportComplianceMonitorAgents,
			variables: { criteria, sort },
		}));
	}

	public getComplianceMonitorAgentDetailsModalData(agentId: string):
		Promise<ApolloQueryResult<responses.GetComplianceMonitorAgentDetailsModalData>> {
		return lastValueFrom(this.apollo.query<responses.GetComplianceMonitorAgentDetailsModalData>({
			query: queries.getComplianceMonitorAgentDetailsModalData,
			variables: { agentId },
		}));
	}

	public getCommissionsPaymentPageData(): Promise<ApolloQueryResult<responses.GetCommissionsPaymentPageData>> {
		return lastValueFrom(this.apollo.query<responses.GetCommissionsPaymentPageData>({
			query: queries.getCommissionsPaymentPageData,
		}));
	}

	public getCommissionPayablesPageData(): Promise<ApolloQueryResult<responses.GetCommissionPayablesPageData>> {
		return lastValueFrom(this.apollo.query<responses.GetCommissionPayablesPageData>({
			query: queries.getCommissionPayablesPageData,
		}));
	}


	public getAgentSalesReportLicenseDetailsSub(criteria: any, sort: string, max: number, offset: number):
		Observable<ApolloQueryResult<responses.GetAgentSalesReportLicenseDetailsData>> {
		return this.apollo.query<responses.GetAgentSalesReportLicenseDetailsData>({
			query: queries.getAgentSalesReportLicenseDetailsData,
			variables: { criteria, sort, max, offset },
		});
	}

	public getAgentSalesReportContractsDashboardSub(criteria: any, sort: string, max: number, offset: number):
		Observable<ApolloQueryResult<responses.GetAgentSalesReportContractsDashboardData>> {
		return this.apollo.query<responses.GetAgentSalesReportContractsDashboardData>({
			query: queries.getAgentSalesReportContractsDashboardData,
			variables: { criteria, sort, max, offset },
		});
	}

	public getBankAccountAddEditFormData(agentId: string): Promise<ApolloQueryResult<responses.GetBankAccountAddEditFormData>> {
		return lastValueFrom(this.apollo.query<responses.GetBankAccountAddEditFormData>({
			query: queries.getBankAccountAddEditFormData,
			variables: { agentId },
		}));
	}

	public createBankAccount(bankAccount: Partial<models.BankAccount>): Promise<FetchResult<responses.CreateBankAccountResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateBankAccountResponse>({
			mutation: mutations.createBankAccount,
			variables: { bankAccount },
		}));
	}

	public sendBrokerReferralEmail(criteria: any): Promise<FetchResult<responses.StringResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.StringResponse>({
			mutation: mutations.sendBrokerReferralEmail,
			variables: { criteria },
		}));
	}

	public sendAnnualMembershipEmail(): Promise<FetchResult<responses.StringResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.StringResponse>({
			mutation: mutations.sendAnnualMembershipEmail,
		}));
	}

	public sendLeadLinkEmail(): Promise<FetchResult<responses.StringResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.StringResponse>({
			mutation: mutations.sendLeadLinkEmail,
		}));
	}

	public sendCustomerReferralEmail(criteria: any): Promise<FetchResult<responses.StringResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.StringResponse>({
			mutation: mutations.sendCustomerReferralEmail,
			variables: { criteria },
		}));
	}

	public updateContractResidentialExternalId(contractId: string, externalId: string):
		Promise<FetchResult<responses.UpdateContractResidentialExternalIdResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateContractResidentialExternalIdResponse>({
			mutation: mutations.updateContractResidentialExternalId,
			variables: { contractId, externalId },
		}));
	}

	public refreshContractResidentialExternalStatus(contractId: string):
		Promise<FetchResult<responses.RefreshContractResidentialExternalStatusResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.RefreshContractResidentialExternalStatusResponse>({
			mutation: mutations.refreshContractResidentialExternalStatus,
			variables: { contractId },
		}));
	}

	public bulkUpsertSupplierRateSettings(supplierRateSetting: any):
		Promise<FetchResult<responses.BulkUpsertSupplierRateSettingsResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.BulkUpsertSupplierRateSettingsResponse>({
			mutation: mutations.bulkUpsertSupplierRateSettings,
			variables: { supplierRateSetting },
		}));
	}

	public getEmailCriteriaTypeEditData(id: number): Promise<ApolloQueryResult<responses.EmailCriteriaTypeData>> {
		return lastValueFrom(this.apollo.query<responses.EmailCriteriaTypeData>({
			query: queries.getEmailCriteriaTypeEditData,
			variables: { id },
		}));
	}

	public getEmailCriteriaTypesListData(sort: string): Promise<ApolloQueryResult<responses.EmailCriteriaTypesData>> {
		return lastValueFrom(this.apollo.query<responses.EmailCriteriaTypesData>({
			query: queries.getEmailCriteriaTypesListData,
			variables: { sort },
		}));
	}

	public updateEmailCriteriaType(id: number, emailCriteriaType: Partial<models.EmailCriteriaType>):
		Promise<FetchResult<responses.UpdateEmailCriteriaTypeResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateEmailCriteriaTypeResponse>({
			mutation: mutations.updateEmailCriteriaType,
			variables: { id, emailCriteriaType },
		}));
	}

	public createEmailCriteriaType(emailCriteriaType: Partial<models.EmailCriteriaType>):
		Promise<FetchResult<responses.CreateEmailCriteriaTypeResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateEmailCriteriaTypeResponse>({
			mutation: mutations.createEmailCriteriaType,
			variables: { emailCriteriaType },
		}));
	}

	public getAgentEngagementOverviewPageData(criteria: any): Promise<ApolloQueryResult<responses.GetAgentEngagementOverviewPageData>> {
		return lastValueFrom(this.apollo.query<responses.GetAgentEngagementOverviewPageData>({
			query: queries.getAgentEngagementOverviewPageData,
			variables: { criteria },
		}));
	}

	public updateAgentEngagementEvent(id: number, agentEngagementEvent: Partial<models.AgentEngagementEvent>):
		Promise<FetchResult<responses.UpdateAgentEngagementEventResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateAgentEngagementEventResponse>({
			mutation: mutations.updateAgentEngagementEvent,
			variables: { id, agentEngagementEvent },
		}));
	}

	public getAgentEngagementEventDetailModalData(id: number):
		Promise<ApolloQueryResult<responses.GetAgentEngagementEventDetailModalData>> {
		return lastValueFrom(this.apollo.query<responses.GetAgentEngagementEventDetailModalData>({
			query: queries.getAgentEngagementEventDetailModalData,
			variables: { id },
		}));
	}

	public getAgentEngagementEventDetailModalActionsData(noteTypeCategoryId: number, agentId: string):
		Promise<ApolloQueryResult<responses.GetAgentEngagementEventDetailModalActionsData>> {
		return lastValueFrom(this.apollo.query<responses.GetAgentEngagementEventDetailModalActionsData>({
			query: queries.getAgentEngagementEventDetailModalActionsData,
			variables: { noteTypeCategoryId, agentId },
		}));
	}

	public getAgentEngagementEventArchiveData(criteria: any, sort: string, max: number, offset: number):
		Promise<ApolloQueryResult<responses.GetAgentEngagementEventArchiveData>> {
		return lastValueFrom(this.apollo.query<responses.GetAgentEngagementEventArchiveData>({
			query: queries.getAgentEngagementEventArchiveData,
			variables: { criteria, sort, max, offset },
		}));
	}

	public getAgentEngagementEventArchivePageData():
		Promise<ApolloQueryResult<responses.GetAgentEngagementEventArchivePageData>> {
		return lastValueFrom(this.apollo.query<responses.GetAgentEngagementEventArchivePageData>({
			query: queries.getAgentEngagementEventArchivePageData,
		}));
	}

	public updateAgentEngagementEvents(agentEngagementEvents: Partial<models.AgentEngagementEvent>[]):
		Promise<FetchResult<responses.UpdateAgentEngagementEventsResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateAgentEngagementEventsResponse>({
			mutation: mutations.updateAgentEngagementEvents,
			variables: { agentEngagementEvents },
		}));
	}

	public getAgentEngagementDashboardEmailsPageData(criteria: any):
		Promise<ApolloQueryResult<responses.GetAgentEngagementEmailsPageData>> {
		return lastValueFrom(this.apollo.query<responses.GetAgentEngagementEmailsPageData>({
			query: queries.getAgentEngagementDashboardEmailsPageData,
			variables: { criteria },
		}));
	}

	public getAgentEngagementEmailsEditPageData(id: number, agentEmailPreferenceSettingCriteria: any):
		Promise<ApolloQueryResult<responses.GetAgentEngagementEmailsEditPageData>> {
		return lastValueFrom(this.apollo.query<responses.GetAgentEngagementEmailsEditPageData>({
			query: queries.getAgentEngagementEmailsEditPageData,
			variables: { id, agentEmailPreferenceSettingCriteria },
		}));
	}

	public getAgentEngagementEmailsEditData(emailSectionId: number | string, agentId: string):
		Promise<ApolloQueryResult<responses.GetAgentEngagementEmailsEditData>> {
		return lastValueFrom(this.apollo.query<responses.GetAgentEngagementEmailsEditData>({
			query: queries.getAgentEngagementEmailsEditData,
			variables: { emailSectionId, agentId },
		}));
	}

	public getAgentEngagementEmailPreview(agentId, emailPreferenceId: number, agentEmailSections: Subset<models.AgentEmailSection>[]):
		Promise<ApolloQueryResult<responses.GetAgentEngagementEmailPreviewResponse>> {
		return lastValueFrom(this.apollo.query<responses.GetAgentEngagementEmailPreviewResponse>({
			query: queries.getAgentEngagementEmailPreview,
			variables: { agentId, emailPreferenceId, agentEmailSections },
		}));
	}

	public sendAgentEngagementEmailPreview(
		email: string, agentId: string, emailPreferenceId: number,
		agentEmailSections: Subset<models.AgentEmailSection>[]
	): Promise<ApolloQueryResult<responses.SendAgentEngagementEmailPreviewResponse>> {
		return lastValueFrom(this.apollo.query<responses.SendAgentEngagementEmailPreviewResponse>({
			query: queries.sendAgentEngagementEmailPreview,
			variables: { email, agentId, emailPreferenceId, agentEmailSections },
		}));
	}

	public getAgentEngagementEmailsAgentEmailPreferences(agentId: string):
		Promise<ApolloQueryResult<responses.GetAgentEngagementEmailsAgentEmailPreferences>> {
		return lastValueFrom(this.apollo.query<responses.GetAgentEngagementEmailsAgentEmailPreferences>({
			query: queries.getAgentEngagementEmailsAgentEmailPreferences,
			variables: { agentId },
		}));
	}

	public getAdminFlowChecksData(): Promise<ApolloQueryResult<responses.AdminFlowChecksData>> {
		return lastValueFrom(this.apollo.query<responses.AdminFlowChecksData>({
			query: queries.getAdminFlowChecksData,
		}));
	}

	public listPendingFlowChecks(criteria: any, sort: string, max: number, offset: number):
		Promise<ApolloQueryResult<responses.ListPendingFlowChecksResponse>> {
		return lastValueFrom(this.apollo.query<responses.ListPendingFlowChecksResponse>({
			query: queries.listPendingFlowChecks,
			variables: { criteria, sort, max, offset },
		}));
	}

	public listPendingFlowChecksQueue(criteria: any): Promise<ApolloQueryResult<responses.ListPendingFlowChecksQueueResponse>> {
		return lastValueFrom(this.apollo.query<responses.ListPendingFlowChecksQueueResponse>({
			query: queries.listPendingFlowChecksQueue,
			variables: { criteria },
		}));
	}

	public updatePendingFlowCheck(id: number, pendingFlowCheck: Partial<models.PendingFlowCheck>):
		Promise<FetchResult<responses.UpdatePendingFlowCheckResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdatePendingFlowCheckResponse>({
			mutation: mutations.updatePendingFlowCheck,
			variables: { id, pendingFlowCheck },
		}));
	}

	public updatePendingFlowChecks(pendingFlowChecks: Partial<models.PendingFlowCheck>[]):
		Promise<FetchResult<responses.UpdatePendingFlowChecksResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdatePendingFlowChecksResponse>({
			mutation: mutations.updatePendingFlowChecks,
			variables: { pendingFlowChecks },
		}));
	}

	public getReferralExchangeDashboardPageData(): Promise<ApolloQueryResult<responses.GetReferralExchangeDashboardPageData>> {
		return lastValueFrom(this.apollo.query<responses.GetReferralExchangeDashboardPageData>({
			query: queries.getReferralExchangeDashboardPageData,
		}));
	}

	public getReferralExchangeDashboardAgentReferralServices(criteria: any, sort: string):
		Promise<ApolloQueryResult<responses.GetReferralExchangeDashboardAgentReferralServices>> {
		return lastValueFrom(this.apollo.query<responses.GetReferralExchangeDashboardAgentReferralServices>({
			query: queries.getReferralExchangeDashboardAgentReferralServices,
			variables: { criteria, sort },
		}));
	}

	public getReferralExchangeDetailsPageData(id: number):
		Promise<ApolloQueryResult<responses.GetReferralExchangeDetailsPageData>> {
		return lastValueFrom(this.apollo.query<responses.GetReferralExchangeDetailsPageData>({
			query: queries.getReferralExchangeDetailsPageData,
			variables: { id },
		}));
	}

	public getReactivationTicket(): Promise<ApolloQueryResult<responses.GetReactivationTicket>> {
		return lastValueFrom(this.apollo.query<responses.GetReactivationTicket>({
			query: queries.getReactivationTicket,
		}));
	}

	public sendAgentReferralServiceRequest(agentReferralServiceId: number, criteria: any):
		Promise<ApolloQueryResult<responses.SendAgentReferralServiceRequestResponse>> {
		return lastValueFrom(this.apollo.query<responses.SendAgentReferralServiceRequestResponse>({
			query: queries.sendAgentReferralServiceRequest,
			variables: { agentReferralServiceId, criteria },
		}));
	}

	public confirmEnrollmentAfterDocusign(contractId: string): Promise<FetchResult<responses.ConfirmEnrollmentAfterDocusignResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.ConfirmEnrollmentAfterDocusignResponse>({
			mutation: mutations.confirmEnrollmentAfterDocusign,
			variables: { contractId },
		}));
	}

	public getUtilityAuctionIsoData(utilityId: string): Promise<ApolloQueryResult<responses.GetUtilityAuctionIsoData>> {
		return lastValueFrom(this.apollo.query<responses.GetUtilityAuctionIsoData>({
			query: queries.getUtilityAuctionIsoData,
			variables: { utilityId },
		}));
	}

	public getUtilityZoneEditPageData(): Promise<ApolloQueryResult<responses.GetUtilityZoneEditPageData>> {
		return lastValueFrom(this.apollo.query<responses.GetUtilityZoneEditPageData>({
			query: queries.getUtilityZoneEditPageData,
		}));
	}

	public getUtilityZoneEditData(utilityId: string): Promise<ApolloQueryResult<responses.GetUtilityZoneEditData>> {
		return lastValueFrom(this.apollo.query<responses.GetUtilityZoneEditData>({
			query: queries.getUtilityZoneEditData,
			variables: { utilityId },
		}));
	}

	public getUtilityMarketSettingPageData(utilityId: string): Promise<ApolloQueryResult<responses.GetUtilityMarketSettingPageData>> {
		return lastValueFrom(this.apollo.query<responses.GetUtilityMarketSettingPageData>({
			query: queries.getUtilityMarketSettingPageData,
			variables: { utilityId },
		}));
	}

	public getUtilityMarketSettingEditPageData(criteria: any): Promise<ApolloQueryResult<responses.GetUtilityMarketSettingEditPageData>> {
		return lastValueFrom(this.apollo.query<responses.GetUtilityMarketSettingEditPageData>({
			query: queries.getUtilityMarketSettingEditPageData,
			variables: { criteria },
		}));
	}

	public getContractSendSignedContractModalData(contractId: string):
		Promise<ApolloQueryResult<responses.ContractSendSignedContractModalData>> {
		return lastValueFrom(this.apollo.query<responses.ContractSendSignedContractModalData>({
			query: queries.getContractSendSignedContractModalData,
			variables: { contractId },
		}));
	}

	public getRateMatrixLoaderEditCompanySettingsPageData(rateMatrixLoaderId: number):
		Promise<ApolloQueryResult<responses.GetRateMatrixLoaderEditCompanySettingsPageData>> {
		return lastValueFrom(this.apollo.query<responses.GetRateMatrixLoaderEditCompanySettingsPageData>({
			query: queries.getRateMatrixLoaderEditCompanySettingsPageData,
			variables: { rateMatrixLoaderId },
		}));
	}

	public getCompanyRateMatrixLoaderEditData(rateMatrixLoaderId: number, companyId: string):
		Promise<ApolloQueryResult<responses.GetCompanyRateMatrixLoaderEditData>> {
		return lastValueFrom(this.apollo.query<responses.GetCompanyRateMatrixLoaderEditData>({
			query: queries.getCompanyRateMatrixLoaderEditData,
			variables: { rateMatrixLoaderId, companyId },
		}));
	}

	public getRateMatrixLoaderGeneralData(id: number): Promise<ApolloQueryResult<responses.GetRateMatrixLoaderGeneralData>> {
		return lastValueFrom(this.apollo.query<responses.GetRateMatrixLoaderGeneralData>({
			query: queries.getRateMatrixLoaderGeneralData,
			variables: { id },
		}));
	}

	public updateCompanyRateMatrixLoader(id: number, companyRateMatrixLoader: Subset<models.EmailCriteria>):
		Promise<FetchResult<responses.UpdateCompanyRateMatrixLoader>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateCompanyRateMatrixLoader>({
			mutation: mutations.updateCompanyRateMatrixLoader,
			variables: { id, companyRateMatrixLoader },
		}));
	}

	public createCompanyRateMatrixLoader(companyRateMatrixLoader: Subset<models.EmailCriteria>):
		Promise<FetchResult<responses.CreateCompanyRateMatrixLoader>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateCompanyRateMatrixLoader>({
			mutation: mutations.createCompanyRateMatrixLoader,
			variables: { companyRateMatrixLoader },
		}));
	}

	public getLeadDashboardDataSub(criteria: any, sort: string, max: number, offset: number):
		Observable<ApolloQueryResult<responses.GetLeadDashboardDataSub>> {
		return this.apollo.query<responses.GetLeadDashboardDataSub>({
			query: queries.getLeadDashboardDataSub,
			variables: { criteria, sort, max, offset },
		});
	}

	public getLeadDuplicatesDashboardDataSub(criteria: any, sort: string, max: number, offset: number):
		Observable<ApolloQueryResult<responses.GetLeadDuplicatesDashboardDataSub>> {
		return this.apollo.query<responses.GetLeadDuplicatesDashboardDataSub>({
			query: queries.getLeadDuplicatesDashboardDataSub,
			variables: { criteria, sort, max, offset },
		});
	}

	public getBrokerLeadDashboardPageData(leadTypeId: number, userId: string, gridId: string):
		Promise<ApolloQueryResult<responses.GetBrokerLeadDashboardPageData>> {
		return lastValueFrom(this.apollo.query<responses.GetBrokerLeadDashboardPageData>({
			query: queries.getBrokerLeadDashboardPageData,
			variables: { leadTypeId, userId, gridId },
		}));
	}

	public getLeadDashboardPageData(agentId: string, leadTypeId: number, userId: string, gridId: string):
		Promise<ApolloQueryResult<responses.GetLeadDashboardPageData>> {
		return lastValueFrom(this.apollo.query<responses.GetLeadDashboardPageData>({
			query: queries.getLeadDashboardPageData,
			variables: { agentId, leadTypeId, userId, gridId },
		}));
	}

	public getBrokerLeadDuplicatesDashboardPageData(leadTypeId: number):
		Promise<ApolloQueryResult<responses.GetBrokerLeadDuplicatesDashboardPageData>> {
		return lastValueFrom(this.apollo.query<responses.GetBrokerLeadDuplicatesDashboardPageData>({
			query: queries.getBrokerLeadDuplicatesDashboardPageData,
			variables: { leadTypeId },
		}));
	}

	public getLeadDuplicatesDashboardPageData(agentId: string, leadTypeId: number):
		Promise<ApolloQueryResult<responses.GetLeadDuplicatesDashboardPageData>> {
		return lastValueFrom(this.apollo.query<responses.GetLeadDuplicatesDashboardPageData>({
			query: queries.getLeadDuplicatesDashboardPageData,
			variables: { agentId, leadTypeId },
		}));
	}

	public getLeadDuplicatesDashboardDetailData(id: number):
		Promise<ApolloQueryResult<responses.GetLeadDuplicatesDashboardDetailData>> {
		return lastValueFrom(this.apollo.query<responses.GetLeadDuplicatesDashboardDetailData>({
			query: queries.getLeadDuplicatesDashboardDetailData,
			variables: { id },
		}));
	}

	public getLeadDashboardStatsPageData(leadTypeId: number):
		Promise<ApolloQueryResult<responses.GetLeadDashboardStatsPageData>> {
		return lastValueFrom(this.apollo.query<responses.GetLeadDashboardStatsPageData>({
			query: queries.getLeadDashboardStatsPageData,
			variables: { leadTypeId },
		}));
	}

	public getLeadDuplicatesModalData(id: number): Promise<ApolloQueryResult<responses.GetLeadDuplicatesModalData>> {
		return lastValueFrom(this.apollo.query<responses.GetLeadDuplicatesModalData>({
			query: queries.getLeadDuplicatesModalData,
			variables: { id },
		}));
	}

	public getLeadAddEditModalData(noteTypeCategoryId: number): Promise<ApolloQueryResult<responses.GetLeadAddEditModalData>> {
		return lastValueFrom(this.apollo.query<responses.GetLeadAddEditModalData>({
			query: queries.getLeadAddEditModalData,
			variables: { noteTypeCategoryId },
		}));
	}

	public getLeadAddEditModalLeadData(leadId: number): Promise<ApolloQueryResult<responses.GetLeadAddEditModalLeadData>> {
		return lastValueFrom(this.apollo.query<responses.GetLeadAddEditModalLeadData>({
			query: queries.getLeadAddEditModalLeadData,
			variables: { leadId },
		}));
	}

	public getLeadAddEditModalLeadReminderData(leadId: number): Promise<ApolloQueryResult<responses.GetLeadAddEditModalLeadReminderData>> {
		return lastValueFrom(this.apollo.query<responses.GetLeadAddEditModalLeadReminderData>({
			query: queries.getLeadAddEditModalLeadReminderData,
			variables: { leadId },
		}));
	}

	public getLeadAddEditModalLeadEmailCampaignData(leadId: number):
		Promise<ApolloQueryResult<responses.GetLeadAddEditModalLeadEmailCampaignData>> {
		return lastValueFrom(this.apollo.query<responses.GetLeadAddEditModalLeadEmailCampaignData>({
			query: queries.getLeadAddEditModalLeadEmailCampaignData,
			variables: { leadId },
		}));
	}

	public getLeadAddEditModalLeadActionsDataSub(criteria: any, sort: string, max: number, offset: number):
		Observable<ApolloQueryResult<responses.GetLeadAddEditModalLeadActionsData>> {
		return this.apollo.query<responses.GetLeadAddEditModalLeadActionsData>({
			query: queries.getLeadAddEditModalLeadActionsDataSub,
			variables: { criteria, sort, max, offset },
		});
	}

	public createLead(lead: Partial<models.Lead>): Promise<FetchResult<responses.CreateLead>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateLead>({
			mutation: mutations.createLead,
			variables: { lead },
		}));
	}

	public createLeads(leads: Partial<models.Lead>[]): Promise<FetchResult<responses.CreateLeads>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateLeads>({
			mutation: mutations.createLeads,
			variables: { leads },
		}));
	}

	public updateLeads(leads: Partial<models.Lead>[]): Promise<FetchResult<responses.UpdateLeads>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateLeads>({
			mutation: mutations.updateLeads,
			variables: { leads },
		}));
	}

	public updateLead(id: number, lead: Partial<models.Lead>): Promise<FetchResult<responses.UpdateLead>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateLead>({
			mutation: mutations.updateLead,
			variables: { id, lead },
		}));
	}

	public deleteLead(id: number): Promise<FetchResult<responses.DeleteLead>> {
		return lastValueFrom(this.apollo.mutate<responses.DeleteLead>({
			mutation: mutations.deleteLead,
			variables: { id },
		}));
	}

	public deleteLeads(id: string): Promise<FetchResult<responses.DeleteLeads>> {
		return lastValueFrom(this.apollo.mutate<responses.DeleteLeads>({
			mutation: mutations.deleteLeads,
			variables: { id },
		}));
	}

	public updateLeadReminder(id: number, leadReminder: Partial<models.LeadReminder>): Promise<FetchResult<responses.UpdateLeadReminder>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateLeadReminder>({
			mutation: mutations.updateLeadReminder,
			variables: { id, leadReminder },
		}));
	}

	public updateLeadDuplicates(leadDuplicates: Partial<models.LeadDuplicate>[]): Promise<FetchResult<responses.UpdateLeadDuplicates>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateLeadDuplicates>({
			mutation: mutations.updateLeadDuplicates,
			variables: { leadDuplicates },
		}));
	}

	public unsubscribeEmailAnalytics(apiId: number): Promise<FetchResult<responses.UnsubscribeEmailAnalytics>> {
		return lastValueFrom(this.apollo.mutate<responses.UnsubscribeEmailAnalytics>({
			mutation: mutations.unsubscribeEmailAnalytics,
			variables: { apiId },
		}));
	}

	public getLeadDuplicatesDashboardLeadDuplicates(criteria: any):
		Promise<ApolloQueryResult<responses.GetLeadDuplicatesDashboardLeadDuplicates>> {
		return lastValueFrom(this.apollo.query<responses.GetLeadDuplicatesDashboardLeadDuplicates>({
			query: queries.getLeadDuplicatesDashboardLeadDuplicates,
			variables: { criteria },
		}));
	}

	public getLeadActivityReportPageData(reportId: string):
		Promise<ApolloQueryResult<responses.GetLeadActivityReportPageData>> {
		return lastValueFrom(this.apollo.query<responses.GetLeadActivityReportPageData>({
			query: queries.getLeadActivityReportPageData,
			variables: { reportId },
		}));
	}

	public getLeadActivityReportTotalsData(reportId: string, leadCriteria: any, agentCriteria: any):
		Promise<ApolloQueryResult<responses.GetLeadActivityReportTotalsData>> {
		return lastValueFrom(this.apollo.query<responses.GetLeadActivityReportTotalsData>({
			query: queries.getLeadActivityReportTotalsData,
			variables: { reportId, leadCriteria, agentCriteria },
		}));
	}

	public getLeadActivityReportData(reportId: string, criteria: any):
		Promise<ApolloQueryResult<responses.GetLeadActivityReportData>> {
		return lastValueFrom(this.apollo.query<responses.GetLeadActivityReportData>({
			query: queries.getLeadActivityReportData,
			variables: { reportId, criteria },
		}));
	}

	public getLayeredPurchasingDashboardKpiData(): Promise<ApolloQueryResult<responses.GetLayeredPurchasingDashboardKpiData>> {
		return lastValueFrom(this.apollo.query<responses.GetLayeredPurchasingDashboardKpiData>({
			query: queries.getLayeredPurchasingDashboardKpiData,
		}));
	}

	public getLayeredPurchasingDashboardContractsPageData(companyId: string):
		Promise<ApolloQueryResult<responses.GetLayeredPurchasingDashboardContractsPageData>> {
		return lastValueFrom(this.apollo.query<responses.GetLayeredPurchasingDashboardContractsPageData>({
			query: queries.getLayeredPurchasingDashboardContractsPageData,
			variables: { companyId },
		}));
	}

	public getLayeredPurchasingDashboardContractsData(criteria: any, sort: string, max: number, offset: number):
		Promise<ApolloQueryResult<responses.GetLayeredPurchasingDashboardContractsData>> {
		return lastValueFrom(this.apollo.query<responses.GetLayeredPurchasingDashboardContractsData>({
			query: queries.getLayeredPurchasingDashboardContractsData,
			variables: { criteria, sort, max, offset },
		}));
	}

	public getHedgePriceRequestContractsBySupplier(supplierId: string):
		Promise<ApolloQueryResult<responses.GetHedgePriceRequestContractsBySupplier>> {
		return lastValueFrom(this.apollo.query<responses.GetHedgePriceRequestContractsBySupplier>({
			query: queries.getHedgePriceRequestContractsBySupplier,
			variables: { supplierId },
		}));
	}

	public getLayeredPurchasingHedgePriceRequestPreview(criteria: any):
		Promise<ApolloQueryResult<responses.GetLayeredPurchasingHedgePriceRequestPreview>> {
		return lastValueFrom(this.apollo.query<responses.GetLayeredPurchasingHedgePriceRequestPreview>({
			query: queries.getLayeredPurchasingHedgePriceRequestPreview,
			variables: { criteria },
		}));
	}

	public sendHedgePriceRequest(criteria: any): Promise<ApolloQueryResult<responses.SendHedgePriceRequest>> {
		return lastValueFrom(this.apollo.query<responses.SendHedgePriceRequest>({
			query: queries.sendHedgePriceRequest,
			variables: { criteria },
		}));
	}

	public getLayeredPurchasingActionsPageData(noteTypeCategoryId: number):
		Promise<ApolloQueryResult<responses.GetLayeredPurchasingActionsPageData>> {
		return lastValueFrom(this.apollo.query<responses.GetLayeredPurchasingActionsPageData>({
			query: queries.getLayeredPurchasingActionsPageData,
			variables: { noteTypeCategoryId },
		}));
	}

	public getLayeredPurchasingActionsDataSub(criteria: any, sort: string, max: number, offset: number):
		Observable<ApolloQueryResult<responses.GetLayeredPurchasingActionsDataSub>> {
		return this.apollo.query<responses.GetLayeredPurchasingActionsDataSub>({
			query: queries.getLayeredPurchasingActionsDataSub,
			variables: { criteria, sort, max, offset },
		});
	}

	public getLayeredPurchasingHedgePriceRequestPreviewModalData(id: number):
		Promise<ApolloQueryResult<responses.GetLayeredPurchasingHedgePriceRequestPreviewModalData>> {
		return lastValueFrom(this.apollo.query<responses.GetLayeredPurchasingHedgePriceRequestPreviewModalData>({
			query: queries.getLayeredPurchasingHedgePriceRequestPreviewModalData,
			variables: { id },
		}));
	}

	public createAgentEngagementEventBufferMarginModification(agentEngagementEventBufferMarginModification: any):
		Promise<FetchResult<responses.CreateAgentEngagementEventBufferMarginModification>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateAgentEngagementEventBufferMarginModification>({
			mutation: mutations.createAgentEngagementEventBufferMarginModification,
			variables: { agentEngagementEventBufferMarginModification },
		}));
	}

	public createAgentEngagementEventDismissPostpone(agentEngagementEventDismissPostpone: any):
		Promise<FetchResult<responses.CreateAgentEngagementEventDismissPostpone>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateAgentEngagementEventDismissPostpone>({
			mutation: mutations.createAgentEngagementEventDismissPostpone,
			variables: { agentEngagementEventDismissPostpone },
		}));
	}

	public createLeadStatusReasonType(leadStatusReasonType: any): Promise<FetchResult<responses.CreateLeadStatusReasonType>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateLeadStatusReasonType>({
			mutation: mutations.createLeadStatusReasonType,
			variables: { leadStatusReasonType },
		}));
	}

	public createLeadAction(leadId: number, leadAction: any): Promise<FetchResult<responses.CreateLeadAction>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateLeadAction>({
			mutation: mutations.createLeadAction,
			variables: { leadId, leadAction },
		}));
	}

	public getLeadEmailCampaignSelectModalData(criteria: any):
		Promise<ApolloQueryResult<responses.GetLeadEmailCampaignSelectModalData>> {
		return lastValueFrom(this.apollo.query<responses.GetLeadEmailCampaignSelectModalData>({
			query: queries.getLeadEmailCampaignSelectModalData,
			variables: { criteria },
		}));
	}

	public getLeadEmailCampaignEmailPreview(emailPreferenceId: number, leadEmailSections: Subset<models.LeadEmailSection>[]):
		Promise<ApolloQueryResult<responses.GetLeadEmailCampaignEmailPreview>> {
		return lastValueFrom(this.apollo.query<responses.GetLeadEmailCampaignEmailPreview>({
			query: queries.getLeadEmailCampaignEmailPreview,
			variables: { emailPreferenceId, leadEmailSections },
		}));
	}

	public getLeadEmailCampaignCampaignSettingsPageData(emailCampaignId: number, leadId: number):
		Promise<ApolloQueryResult<responses.GetLeadEmailCampaignCampaignSettingsPageData>> {
		return lastValueFrom(this.apollo.query<responses.GetLeadEmailCampaignCampaignSettingsPageData>({
			query: queries.getLeadEmailCampaignCampaignSettingsPageData,
			variables: { emailCampaignId, leadId },
		}));
	}

	public getLeadEmailCampaignSendingSchedule(startDate: string, numEmails: number):
		Promise<ApolloQueryResult<responses.GetLeadEmailCampaignSendingSchedule>> {
		return lastValueFrom(this.apollo.query<responses.GetLeadEmailCampaignSendingSchedule>({
			query: queries.getLeadEmailCampaignSendingSchedule,
			variables: { startDate, numEmails },
		}));
	}

	public getLeadEmailCampaignCustomizeEmailsPageData(id: number, leadId: number):
		Promise<ApolloQueryResult<responses.GetLeadEmailCampaignCustomizeEmailsPageData>> {
		return lastValueFrom(this.apollo.query<responses.GetLeadEmailCampaignCustomizeEmailsPageData>({
			query: queries.getLeadEmailCampaignCustomizeEmailsPageData,
			variables: { id, leadId },
		}));
	}

	public updateLeadEmailSections(leadEmailSections: Subset<models.LeadEmailSection>[]):
		Promise<FetchResult<responses.UpdateLeadEmailSectionsResponse>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateLeadEmailSectionsResponse>({
			mutation: mutations.updateLeadEmailSections,
			variables: { leadEmailSections },
		}));
	}

	public getLeadEmailCampaignReviewCampaignPageData(emailCampaignId: number, leadId: number):
		Promise<ApolloQueryResult<responses.GetLeadEmailCampaignReviewCampaignPageData>> {
		return lastValueFrom(this.apollo.query<responses.GetLeadEmailCampaignReviewCampaignPageData>({
			query: queries.getLeadEmailCampaignReviewCampaignPageData,
			variables: { emailCampaignId, leadId },
		}));
	}

	public createLeadEmailCampaign(leadEmailCampaign: Subset<models.LeadEmailCampaign>):
		Promise<FetchResult<responses.CreateLeadEmailCampaign>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateLeadEmailCampaign>({
			mutation: mutations.createLeadEmailCampaign,
			variables: { leadEmailCampaign },
		}));
	}

	public deleteLeadEmailPreference(id: number, leadEmailPreference: models.LeadEmailPreference):
		Promise<FetchResult<responses.DeleteLeadEmailPreference>> {
		return lastValueFrom(this.apollo.mutate<responses.DeleteLeadEmailPreference>({
			mutation: mutations.deleteLeadEmailPreference,
			variables: { id, leadEmailPreference },
		}));
	}

	public getLeadEmailCampaign(id: number): Promise<ApolloQueryResult<responses.GetLeadEmailCampaign>> {
		return lastValueFrom(this.apollo.query<responses.GetLeadEmailCampaign>({
			query: queries.getLeadEmailCampaign,
			variables: { id },
		}));
	}

	public updateLeadEmailCampaign(id: number, leadEmailCampaign: Subset<models.LeadEmailCampaign>):
		Promise<FetchResult<responses.UpdateLeadEmailCampaign>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateLeadEmailCampaign>({
			mutation: mutations.updateLeadEmailCampaign,
			variables: { id, leadEmailCampaign },
		}));
	}

	public getTopBannerAlertLearnMoreModalData(): Promise<ApolloQueryResult<responses.GetTopBannerAlertLearnMoreModalData>> {
		return lastValueFrom(this.apollo.query<responses.GetTopBannerAlertLearnMoreModalData>({
			query: queries.getTopBannerAlertLearnMoreModalData,
		}));
	}

	public getTinyMceCategories(): Promise<ApolloQueryResult<responses.GetTinyMceCategories>> {
		return lastValueFrom(this.apollo.query<responses.GetTinyMceCategories>({
			query: queries.getTinyMceCategories,
		}));
	}

	public getTinyMceTemplate(id: number | string): Promise<ApolloQueryResult<responses.GetTinyMceTemplate>> {
		return lastValueFrom(this.apollo.query<responses.GetTinyMceTemplate>({
			query: queries.getTinyMceTemplate,
			variables: { id },
		}));
	}

	public createTinyMceCategory(category: Subset<models.TinyMceCategory>):
		Promise<FetchResult<responses.CreateTinyMceCategory>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateTinyMceCategory>({
			mutation: mutations.createTinyMceCategory,
			variables: { category },
		}));
	}

	public updateTinyMceCategory(id: number | string, category: Subset<models.TinyMceCategory>):
		Promise<FetchResult<responses.UpdateTinyMceCategory>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateTinyMceCategory>({
			mutation: mutations.updateTinyMceCategory,
			variables: { id, category },
		}));
	}

	public deleteTinyMceCategory(id: number | string): Promise<FetchResult<responses.DeleteTinyMceCategory>> {
		return lastValueFrom(this.apollo.mutate<responses.DeleteTinyMceCategory>({
			mutation: mutations.deleteTinyMceCategory,
			variables: { id },
		}));
	}

	public createTinyMceTemplate(template: Subset<models.TinyMceTemplate>): Promise<FetchResult<responses.CreateTinyMceTemplate>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateTinyMceTemplate>({
			mutation: mutations.createTinyMceTemplate,
			variables: { template },
		}));
	}

	public updateTinyMceTemplate(id: number | string, template: Subset<models.TinyMceTemplate>):
		Promise<FetchResult<responses.UpdateTinyMceTemplate>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateTinyMceTemplate>({
			mutation: mutations.updateTinyMceTemplate,
			variables: { id, template },
		}));
	}

	public updateTinyMceTemplatesByCategory(template: Subset<models.TinyMceTemplate>):
		Promise<FetchResult<responses.UpdateTinyMceTemplatesByCategory>> {
		return lastValueFrom(this.apollo.mutate<responses.UpdateTinyMceTemplatesByCategory>({
			mutation: mutations.updateTinyMceTemplatesByCategory,
			variables: { template },
		}));
	}

	public deleteTinyMceTemplate(id: number | string): Promise<FetchResult<responses.DeleteTinyMceTemplate>> {
		return lastValueFrom(this.apollo.mutate<responses.DeleteTinyMceTemplate>({
			mutation: mutations.deleteTinyMceTemplate,
			variables: { id },
		}));
	}

	public createAgentEngagementEventAction(agentEngagementEventId: number, agentEngagementEventAction: any):
		Promise<FetchResult<responses.CreateAgentEngagementEventAction>> {
		return lastValueFrom(this.apollo.mutate<responses.CreateAgentEngagementEventAction>({
			mutation: mutations.createAgentEngagementEventAction,
			variables: { agentEngagementEventId, agentEngagementEventAction },
		}));
	}

	public createPurchasingLayerAction(purchasingLayerId: number, purchasingLayerAction: any):
		Promise<FetchResult<responses.CreatePurchasingLayerAction>> {
		return lastValueFrom(this.apollo.mutate<responses.CreatePurchasingLayerAction>({
			mutation: mutations.createPurchasingLayerAction,
			variables: { purchasingLayerId, purchasingLayerAction },
		}));
	}

	public chooseMarketSuggestion(rfqSessionId: string, chooseMarketSuggestion: any):
		Promise<FetchResult<responses.ChooseMarketSuggestion>> {
		return lastValueFrom(this.apollo.mutate<responses.ChooseMarketSuggestion>({
			mutation: mutations.chooseMarketSuggestion,
			variables: { rfqSessionId, chooseMarketSuggestion },
		}));
	}
}
