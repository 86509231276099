import * as moment from 'moment-timezone';

import { State } from './state';
import { Utility } from './utility';

export class RateMatrixOptimizedPrice {
	public supplierId: string;
	public stateId: string;
	public utilityId: string;
	public rateClass: string;
	public zone: string;
	public effectiveDate: string;
	public term: number;
	public usage: number;

	public rate: number;
	public rank: number;
	public bestRate: number;
	public nextRate: number;
	public bestDelta: number;
	public nextDelta: number;
	public clusterScore: number;
	public optimizedPrice: number;
	public adjustment: number;
	public productName: string;
	public ptc: number;
	public ptcDelta: number;
	public bestRateOverall: number;
	public bestRateOverallDelta: number;
	public clusterOptimizedPrice: number;
	public clusterSelectionChance: number;
	public clusterSuggestedAdjustment: number;

	public utility: Utility;
	public state: State;

	constructor(rateMatrixOptimizedPrice: RateMatrixOptimizedPrice) {
		Object.assign(this, rateMatrixOptimizedPrice);

		this.effectiveDate = this.effectiveDate ? moment(this.effectiveDate).format('MMMM YYYY') : this.effectiveDate;
	}
}
