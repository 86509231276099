export class ReportDirectSalePaidCommission {

	public employeeList: ReportDirectSalePaidCommissionRow[];
	public finalPayList: ReportDirectSalePaidCommissionRow[];
	public otherList: ReportDirectSalePaidCommissionRow[];

	constructor(reportDirectSalePaidCommission: ReportDirectSalePaidCommission) {
		Object.assign(this, reportDirectSalePaidCommission);
	}
}

export class ReportDirectSalePaidCommissionRow {

	agentId: string;
	bankAccountId: number;
	isEmployee: boolean;
	parent: string;
	payeeName: string;
	totalCommissionAmount: number;
	negativeBalance: number;
	receivedAmount: number;
	accountNum: string;
	routingNum: string;
	totalAmount: number;
	commissionPaymentApprovalId: number;
	approvingUser1Id: string;
	approvingUser1Name: string;
	approvingUser2Id: string;
	approvingUser2Name: string;
	forecastPayableAmount: number;

	get bankAmount() {
		return this.totalAmount ? Math.round(this.totalAmount * 100) : this.totalAmount;
	}

	get deviation() {
		return Math.abs(this.totalCommissionAmount - this.forecastPayableAmount);
	}

	get variance() {
		if (this.forecastPayableAmount) {
			return Math.round(this.deviation / this.forecastPayableAmount * 100);
		}

		return 100;
	}

	get needsApproval() {
		if (this.variance > 15 && this.deviation > 50) {
			return true;
		}

		return false;
	}

	get approvedBy() {
		return (this.approvingUser1Id ? this.approvingUser1Name : '') + (this.approvingUser2Id ? ', ' + this.approvingUser2Name : '');
	}

	constructor(reportDirectSalePaidCommissionRow: ReportDirectSalePaidCommissionRow) {
		Object.assign(this, reportDirectSalePaidCommissionRow);
	}
}
