

export class ContractPrice {

	public date: string | Date;
	public price: number;
	public componentPrice: number;
	public hedgedPrice: number;
	public componentHedgedPrice: number;

	constructor(contractPrice?: ContractPrice) {
		Object.assign(this, contractPrice);
	}
}
